/** @format */
/** @format */

import nProgress from "nprogress";
import ActionTypes from "../../../constants/action_types";
import GiftCardReducerInterface from "./GiftCardReducerInterface";

/** @format */

const initialState: GiftCardReducerInterface = {
  isLoading: false,
};

const reducer = (
  state = initialState,
  action: any
): GiftCardReducerInterface => {
  switch (action.type) {
    case ActionTypes.GET_ALL_GIFT_CARDS_REQUEST:
      nProgress.start();
      return {
        ...state,
        isLoading: true,
        isGiftCardsFetchSuccessful: false,
      };
    case ActionTypes.GET_ALL_GIFT_CARDS_SUCCESS:
      nProgress.done();
      return {
        ...state,
        isLoading: false,
        isGiftCardsFetchSuccessful: true,
        giftCardImages: action.payload.data,
        // productDetailsDataModel: action.payload.data,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.GET_ALL_GIFT_CARDS_FAILURE:
      nProgress.done();

      return {
        ...state,
        isLoading: false,
        isGiftCardsFetchSuccessful: false,
      };
    case ActionTypes.SEND_GIFT_CARDS_REQUEST:
      return {
        ...state,
        sendGiftCardLoading: true,
        isSendGiftCardSuccessful: false,
      };
    case ActionTypes.SEND_GIFT_CARDS_SUCCESS:
      return {
        ...state,
        sendGiftCardLoading: false,
        isGiftCardsFetchSuccessful: true,
        isSendGiftCardSuccessful: true,
        successMessage: action.payload?.data?.message,
        // productDetailsDataModel: action.payload.data,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.SEND_GIFT_CARDS_FAILURE:
      return {
        ...state,
        sendGiftCardLoading: false,
        isGiftCardsFetchSuccessful: false,
        isSendGiftCardSuccessful: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.CHECK_GIFT_CARD_BALANCE_REQUEST:
      return {
        ...state,
        getGiftCardBalanceLoading: true,
        isGetGiftCardBalanceSuccessful: false,
      };
    case ActionTypes.CHECK_GIFT_CARD_BALANCE_SUCCESS:
      return {
        ...state,
        getGiftCardBalanceLoading: false,
        isGetGiftCardBalanceSuccessful: true,
        giftCardBalance: action.payload?.data,
        successMessage: action.payload?.data?.message?.[0]?.message,
        // productDetailsDataModel: action.payload.data,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.CHECK_GIFT_CARD_BALANCE_FAILURE:
      return {
        ...state,
        getGiftCardBalanceLoading: false,
        isGetGiftCardBalanceSuccessful: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };

    case ActionTypes.RESET_GIFT_CARD_STATE:
      return {
        ...state,
        isGiftCardsFetchSuccessful: false,
        error: null,
        successMessage: null,
      };
    default:
      return state;
  }
};

export default reducer;
