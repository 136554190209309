/** @format */

import { Tag } from "antd";
import React from "react";
import { CartProduct } from "../../redux/reducers/CommonReducer/CommonReducerInterface";
import { useRetailHelper } from "../../utils/useRetailHelper";

interface ProductSelectedAttributeProps {
  product: CartProduct;
}

const ProductSelectedAttribute: React.FC<ProductSelectedAttributeProps> = ({
  product,
}) => {
  const { currencySymbol } = useRetailHelper();
  return (
    <div
      className="d-flex col-md-12 mt-2"
      style={{
        flexWrap: "wrap",
        gap: "3px",
      }}
    >
      {" "}
      <Tag bordered={false}>Variation: {product?.productVariation?.name}</Tag>
      {product.productFilterTypeFilterOptions?.map((parent, index) => {
        if (parent.childrens.length > 0) {
          if (parent.childrens.filter((item) => item.isChecked).length > 0) {
            return (
              <Tag bordered={false} key={parent.id} className="w-20 d-flex">
                {parent.name} {" : "}
                {parent.childrens.map((child, index) => {
                  if (child.isChecked) {
                    return (
                      <div key={child.id}>
                        <div>
                          {child.name} {"("}
                          {child.filterTypeOptions.map((child, item) => {
                            if (child.isChecked) {
                              return (
                                <>
                                  {child.name}
                                  {")"}
                                </>
                              );
                            }
                          })}
                        </div>
                      </div>
                    );
                  }
                })}
              </Tag>
            );
          }
        } else {
          if (
            parent.filterTypeOptions.filter((item) => item.isChecked).length > 0
          ) {
            return (
              <Tag bordered={false} key={parent.id} className="w-20 d-flex">
                <span>
                  {parent.name} {" :  "}
                </span>

                {parent.filterTypeOptions
                  ?.filter((item) => item.isChecked)
                  ?.map((children, index) => {
                    return (
                      <div key={children.id}>
                        <div>
                          {children.name}
                          <span className="me-1 ms-1">
                            {index < parent.filterTypeOptions.length - 1 && ","}
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </Tag>
            );
          }
        }
      })}
      {product.priceModifiers?.map((item, index) => {
        return (
          <Tag bordered={false}>{`${item.name}( ${currencySymbol()} ${
            item.price
          } )`}</Tag>
        );
      })}
      {product.description && (
        <Tag bordered={false}>Order Note: {product.description}</Tag>
      )}
    </div>
  );
};

export default ProductSelectedAttribute;
