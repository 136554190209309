/** @format */

import { Button } from "antd";
import React from "react";
import Slider from "react-slick";
import ActionTypes from "../../../constants/action_types";
import ProductSingleContainer from "../../../containers/product_single_container/product_single_container";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { QueryString } from "../../../redux/reducers/FilterProductsReducer/FilterProductsInterface";
import {
  Product,
  TemplatePageCategoryDetail,
} from "../../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import { useApiCalls } from "../../../utils/useApiCalls";

/** @format */
interface SingleCategoryWithProductsProps {
  item: TemplatePageCategoryDetail;
  categoriesSlickSettings1: any;
}

const SingleCategoryWithProducts: React.FC<SingleCategoryWithProductsProps> = ({
  item,
  categoriesSlickSettings1,
}) => {
  const { getAllFilterProducts } = useApiCalls();
  const dispatch = useAppDispatch();

  return (
    <section className="pt-2 pb-50 newproducts mt-2" key={item.id}>
      <div className="container ">
        {/* row */}
        <div className="row mb-4 justify-content-between">
          <div className="col-xl-10 col-lg-9 col-8">
            <h4 className="text-dark header-title fw-bold">{item.labelName}</h4>
          </div>
          <div className="col-xl-2 col-lg-2 col-2 text-end justify-content-end d-flex">
            <Button
              onClick={() => {
                getAllFilterProducts({
                  type: item.type,
                  slug: item.slug,
                });
                dispatch({
                  type: ActionTypes.SET_QUERY_STRINGS,
                  payload: {
                    type: item.type,
                    slug: item.slug,
                  } as QueryString,
                });
              }}
              type="default"
              className="d-flex align-items-center justify-content-end fw-bold"
            >
              View All
            </Button>
          </div>
        </div>
        {/* row */}
        <div className="row">
          <div className="col-12">
            {/* tab */}
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade active show"
                id="nav-fruitsandveg"
                role="tabpanel"
                aria-labelledby="nav-fruitsandveg-tab"
                tabIndex={0}
              >
                {/* row */}
                <div className="cent_cat">
                  <Slider {...categoriesSlickSettings1}>
                    {item.templatePageCategorySectionDetails &&
                      item.templatePageCategorySectionDetails?.[0]?.products.map(
                        (item: Product) => {
                          return (
                            <div key={item.id}>
                              <ProductSingleContainer product={item} />
                            </div>
                          );
                        }
                      )}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SingleCategoryWithProducts;
