/** @format */
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import Routes_Constants from "../../constants/routes_configs";
import { useRetailHelper } from "../../utils/useRetailHelper";
const EmptyCart = () => {
  const { closeCartPopover } = useRetailHelper();
  const navigate = useNavigate();
  return (
    <div className="cart-popover-container">
      <>
        <svg
          fill="#000000"
          width="100px"
          height="100px"
          viewBox="0 0 24 24"
          id="cart-close"
          data-name="Flat Line"
          xmlns="http://www.w3.org/2000/svg"
          className="icon flat-line"
        >
          <line
            id="primary-upstroke"
            x1="10.95"
            y1="20.5"
            x2="11.05"
            y2="20.5"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: "2.5",
            }}
          />
          <line
            id="primary-upstroke-2"
            data-name="primary-upstroke"
            x1="16.95"
            y1="20.5"
            x2="17.05"
            y2="20.5"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: "2.5",
            }}
          />
          <line
            id="primary"
            x1={12}
            y1={9}
            x2={16}
            y2={5}
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 2,
            }}
          />
          <line
            id="primary-2"
            data-name="primary"
            x1={16}
            y1={9}
            x2={12}
            y2={5}
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 2,
            }}
          />
          <path
            id="primary-3"
            data-name="primary"
            d="M3,3H5.2a1,1,0,0,1,1,.78L8.82,15.22a1,1,0,0,0,1,.78h8.42a1,1,0,0,0,1-.76L21,8"
            style={{
              fill: "none",
              stroke: "rgb(0, 0, 0)",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 2,
            }}
          />
        </svg>
      </>
      <h3 className="mb-3 fs-sm text-grey" style={{ color: "#383F50" }}>
        No Items in your cart !
      </h3>
      <Button
        onClick={() => {
          closeCartPopover();
          navigate(Routes_Constants.HOME);
        }}
        loading={false}
        type="default"
        className="w-60"
      >
        Continue Shopping
      </Button>
    </div>
  );
};

export default EmptyCart;
