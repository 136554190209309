/** @format */

import { Alert } from "antd";
import useAppSelector from "../../hooks/useAppSelector";

const AlertHome: React.FC = () => {
  const { homePageDataModel } = useAppSelector((state) => state.homePage);
  return (
    <Alert
      message={homePageDataModel?.storeDetails?.alertDescription}
      type="warning"
      closable
      className="text-center"
    />
  );
};

export default AlertHome;
