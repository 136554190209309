/** @format */

import { UserOutlined } from "@ant-design/icons";
import { Form, Input, Select } from "antd";
import React from "react";
import { BsHouseDoor } from "react-icons/bs";
import { EMPTY_FIELD_MESSAGE } from "../../constants/definitions";
import useAppSelector from "../../hooks/useAppSelector";

interface OrderRecieverInfoProps {
  isActive: boolean;
}

const OrderRecieverInfo: React.FC<OrderRecieverInfoProps> = ({ isActive }) => {
  const { countires } = useAppSelector((state) => state.common);

  return (
    <div className="row border ms-0 p-2">
      <div className="col-md-6">
        <div className="mb-3">
          <Form.Item
            label="Receiver Name"
            name="recieverName"
            rules={[
              {
                required: isActive,
                message: EMPTY_FIELD_MESSAGE,
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Receiver Name"
            />
          </Form.Item>
        </div>
      </div>
      {/* <div className="col-md-6">
        <div className="mb-3">
          <Form.Item
            label="Receiver Email"
            name="recieverEmail"
            rules={[
              {
                required: isActive,
                message: EMPTY_FIELD_MESSAGE,
              },
            ]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>
        </div>
      </div> */}
      <div className="col-md-6">
        <div className="mb-3">
          <Form.Item
            label={
              <span>
                Phone{" "}
                <small style={{ color: "red", fontWeight: "bold" }}>
                  ( If you don't have the recipient's phone number, leave it
                  blank. )
                </small>
                {""}
              </span>
            }
            name="recieverPhoneNumber"
            // rules={[
            //   {
            //     required: isActive,
            //     message: EMPTY_FIELD_MESSAGE,
            //   },
            //   {
            //     validator: validateNumber,
            //   },
            // ]}
          >
            <Input
              addonBefore={
                <Form.Item name="recieverCountryPhoneNumberPrefixId" noStyle>
                  <Select
                    placeholder="Prefix"
                    style={{
                      width: 80,
                    }}
                  >
                    {countires &&
                      countires?.map((item) => (
                        <Select.Option value={item.id} key={item.id}>
                          {" "}
                          <span
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            {item.countryPhoneNumberPrefix}
                          </span>
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              }
              placeholder="Phone"
            />
          </Form.Item>
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <Form.Item
            label="Country"
            name="recieverCountryId"
            rules={[
              {
                required: isActive,
                message: EMPTY_FIELD_MESSAGE,
              },
            ]}
          >
            <Select placeholder="Country">
              {countires &&
                countires?.map((item) => (
                  <Select.Option value={item.id} key={item.id}>
                    {" "}
                    <span style={{ fontSize: "14px" }}>
                      <img
                        src={item.imageUrl}
                        style={{
                          height: "20px",
                          width: "30px",
                          marginRight: "4px",
                        }}
                      />
                      {item.value}
                    </span>
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <Form.Item
            label="Postal Code"
            name="recieverPostalCode"
            // rules={[
            //   {
            //     required: isActive,
            //     message: EMPTY_FIELD_MESSAGE,
            //   },
            //   {
            //     validator: validateNumber,
            //   },
            // ]}
          >
            <Input
              prefix={<BsHouseDoor className="site-form-item-icon" />}
              placeholder="Postal Code"
            />
          </Form.Item>
        </div>
      </div>

      <div className="col-md-6">
        <div className="mb-3">
          <Form.Item
            label="Message"
            name="message"
            // rules={[
            //   {
            //     required: true,
            //     message: EMPTY_FIELD_MESSAGE,
            //   },
            // ]}
          >
            <Input placeholder="Please enter Message" />
          </Form.Item>
        </div>
      </div>
      {/* <div className="col-md-6">
        <div className="mb-3">
          <Form.Item
            label="Do you want to send email to reciever ?"
            name="sendEmailToReceiver"
            initialValue={false}
            labelCol={{ span: 16 }} // Adjust the span value as needed
            wrapperCol={{ span: 12 }} // Adjust the span value as needed
          >
            <Switch defaultChecked={false} />
          </Form.Item>
        </div>
      </div> */}
    </div>
  );
};

export default OrderRecieverInfo;
