/** @format */

import {
  getLocalStorage,
  setLocalStorage,
} from "../../../Helpers/FrontendHelper";
import ActionTypes from "../../../constants/action_types";
import { CART_KEY_LOCAL_STORAGE } from "../../../constants/definitions";
import CommonReducerInterface, { CartProduct } from "./CommonReducerInterface";

/** @format */

const initialState: CommonReducerInterface = {
  isLoading: false,
  isCartPopoverOpen: false,
  isLoginPopupOpen: false,
  loginPopupMessage: "",
  isAddToCartPopupOpen: false,
  isSubscribePopupClosed: false,
  cartData:
    getLocalStorage(CART_KEY_LOCAL_STORAGE) ||
    getLocalStorage(CART_KEY_LOCAL_STORAGE)?.length > 0
      ? getLocalStorage(CART_KEY_LOCAL_STORAGE)
      : [],
};

const reducer = (state = initialState, action: any): CommonReducerInterface => {
  var newCartData: CartProduct[];
  switch (action.type) {
    case ActionTypes.OPEN_LOGIN_POPUP:
      return {
        ...state,
        isLoginPopupOpen: true,
      };

    case ActionTypes.CLOSE_LOGIN_POPUP:
      return {
        ...state,
        isLoginPopupOpen: false,
      };
    case ActionTypes.OPEN_ADD_TO_CART_POPUP:
      return {
        ...state,
        isAddToCartPopupOpen: true,
      };
    case ActionTypes.CLOSE_ADD_TO_CART_POPUP:
      return {
        ...state,
        isAddToCartPopupOpen: false,
      };
    case ActionTypes.SET_LOGIN_POPUP_MESSAGE:
      return {
        ...state,
        loginPopupMessage: action.payload,
      };
    case ActionTypes.SET_CALLBACK_FUNCTION:
      return {
        ...state,
        callback: action.payload,
      };
    case ActionTypes.MAKE_SUBSCRIBE_POPUP_CLOSED:
      return {
        ...state,
        isSubscribePopupClosed: true,
      };
    case ActionTypes.SEARCH_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.SEARCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        searchResult: action.payload.data,
      };
    case ActionTypes.SEARCH_PRODUCTS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.LIST_COUNTRIES_REQUEST:
      return {
        ...state,
      };
    case ActionTypes.LIST_COUNTRIES_SUCCESS:
      return {
        ...state,
        countires: action.payload.data,
      };
    case ActionTypes.LIST_COUNTRIES_FAILURE:
      return {
        ...state,
      };
    case ActionTypes.GET_ALL_BANNERS_REQUEST:
      return {
        ...state,
      };
    case ActionTypes.GET_ALL_BANNERS_SUCCESS:
      return {
        ...state,
        banners: action.payload.data,
      };
    case ActionTypes.GET_ALL_BANNERS_FAILURE:
      return {
        ...state,
      };
    case ActionTypes.SEND_ENQUIERY_REQUEST:
      return {
        ...state,
        sendEnquiryLoading: true,
        isSendEnquirySuccessful: false,
      };
    case ActionTypes.SEND_ENQUIERY_SUCCESS:
      return {
        ...state,
        sendEnquiryLoading: false,
        isSendEnquirySuccessful: true,
      };
    case ActionTypes.SEND_ENQUIERY_FAILURE:
      return {
        ...state,
        sendEnquiryLoading: false,
        isSendEnquirySuccessful: false,
      };
    case ActionTypes.OPEN_CART_POPOVER:
      return {
        ...state,
        isCartPopoverOpen: true,
      };
    case ActionTypes.CLOSE_CART_POPOVER:
      return {
        ...state,
        isCartPopoverOpen: false,
      };
    case ActionTypes.ADD_PRODUCT_TO_CART:
      // const existingProductIndex = state.cartData.findIndex(
      //   (cartItem) => cartItem.productId === action.payload.productId
      // );

      // if (existingProductIndex !== -1) {
      //   // Product already exists in the cart, increase the quantity
      //   newCartData = [...state.cartData];
      //   newCartData[existingProductIndex] = {
      //     ...newCartData[existingProductIndex],
      //     quantity:
      //       newCartData[existingProductIndex].quantity +
      //       action.payload.quantity,
      //   };
      // } else {
      // Product is not in the cart, add it to the cart
      newCartData = [...state.cartData, action.payload];
      // }

      setLocalStorage(CART_KEY_LOCAL_STORAGE, newCartData);
      return {
        ...state,
        cartData: newCartData,
      };
    case ActionTypes.REMOVE_PRODUCT_FROM_CART:
      newCartData = state.cartData.filter((cartItem, index) => {
        return index !== action.payload;
      });
      setLocalStorage(CART_KEY_LOCAL_STORAGE, newCartData);
      return {
        ...state,
        cartData: newCartData,
      };
    case ActionTypes.INCREASE_QUANTITY_OF_PRODUCT_IN_CART:
      if (action.payload >= 0 && action.payload < state.cartData.length) {
        // Clone the cart data array to avoid mutating the state directly
        newCartData = [...state.cartData];
        // Increase the quantity of the product at the specified action.payload
        newCartData[action.payload] = {
          ...newCartData[action.payload],
          quantity: newCartData[action.payload].quantity + 1,
        };
        // Update local storage with the updated cart data
        setLocalStorage(CART_KEY_LOCAL_STORAGE, newCartData);
        // Return the updated state with the new cart data
        return {
          ...state,
          cartData: newCartData,
        };
      }
      // If the index is out of bounds, return the current state without any changes
      return state;
    case ActionTypes.DECREASE_QUANTITY_OF_PRODUCT_IN_CART:
      if (action.payload >= 0 && action.payload < state.cartData.length) {
        newCartData = [...state.cartData];
        if (newCartData[action.payload].quantity > 1) {
          newCartData[action.payload] = {
            ...newCartData[action.payload],
            quantity: newCartData[action.payload].quantity - 1,
          };
          setLocalStorage(CART_KEY_LOCAL_STORAGE, newCartData);
          return {
            ...state,
            cartData: newCartData,
          };
        }
      }
      return state;
    case ActionTypes.CLEAR_CART:
      setLocalStorage(CART_KEY_LOCAL_STORAGE, []);
      return {
        ...state,
        cartData: [],
      };
    default:
      return state;
  }
};

export default reducer;
