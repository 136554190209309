/** @format */

import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createEpicMiddleware } from "redux-observable";
import { rootReducer } from "./reducers/index";
import { rootEpic } from "./rootEpics";

const epicMiddleware = createEpicMiddleware();

// Create the Redux store instance
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(epicMiddleware))
);

// Run the root epic
epicMiddleware.run(rootEpic);

export default store;

export type AppDispatch = typeof store.dispatch;
