/** @format */

import { CaretDownOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import React from "react";
import ActionTypes from "../../constants/action_types";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { QueryString } from "../../redux/reducers/FilterProductsReducer/FilterProductsInterface";
import {
  ProductCategory,
  TemplatePageCategoryDetail,
  homePageDataModel,
} from "../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import isMobile from "../../utils/isMobile";
import { useApiCalls } from "../../utils/useApiCalls";

interface MegaMenuProps {
  item: TemplatePageCategoryDetail;
  homePageDataModel?: homePageDataModel;
}

const MegaMenu: React.FC<MegaMenuProps> = ({ item, homePageDataModel }) => {
  const dispatch = useAppDispatch();
  const { getAllFilterProducts } = useApiCalls();
  const { megaMenuDataModel } = useAppSelector((state) => state.megaMenu);

  return (
    <div key={item.id} className="sticky-top shadow-sm">
      <header>
        <div className="main_nav">
          <nav
            className={`${
              isMobile
                ? "navbar-expand-lg  bg-header"
                : "navbar-expand-lg  bg-header navbar"
            }`}
          >
            <div className="container">
              <div className="collapse navbar-collapse" id="navbar-content">
                <ul className="navbar-nav m-auto mb-2 mb-lg-0 text-header">
                  {homePageDataModel?.productCategories?.map(
                    (item: ProductCategory) => {
                      // Check if the length of children is greater than 0
                      if (item.childrens.length > 0) {
                        var items: MenuProps["items"] = item.childrens.map(
                          (child) => {
                            return {
                              key: child.id,
                              label: child.name,

                              onClick: () => {
                                getAllFilterProducts({
                                  categoryIds: [`${child.slug}`],
                                });
                                dispatch({
                                  type: ActionTypes.SET_QUERY_STRINGS,
                                  payload: {
                                    categoryIds: [`${child.slug}`],
                                  } as QueryString,
                                });
                              },
                            };
                          }
                        );

                        return (
                          <Dropdown key={item.id} menu={{ items }}>
                            <p className="nav-item">
                              <a
                                className="nav-link d-flex align-items-center justify-content-between justify-content-md-start"
                                aria-current="page"
                                onClick={() => {
                                  getAllFilterProducts({
                                    categoryIds: [`${item.slug}`],
                                  });
                                  dispatch({
                                    type: ActionTypes.SET_QUERY_STRINGS,
                                    payload: {
                                      categoryIds: [`${item.slug}`],
                                    } as QueryString,
                                  });
                                }}
                              >
                                {item.name}
                                <CaretDownOutlined className="ms-1 mt-1" />
                              </a>
                            </p>
                          </Dropdown>
                        );
                      }

                      // Render only the item name if there are no children
                      return (
                        <p key={item.id} className="nav-item ">
                          <a
                            className="nav-link"
                            aria-current="page"
                            onClick={() => {
                              getAllFilterProducts({
                                categoryIds: [`${item.slug}`],
                              });
                              dispatch({
                                type: ActionTypes.SET_QUERY_STRINGS,
                                payload: {
                                  categoryIds: [`${item.slug}`],
                                } as QueryString,
                              });
                            }}
                          >
                            {item.name}
                          </a>
                        </p>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default MegaMenu;
