/** @format */

import { CloseOutlined } from "@ant-design/icons";
import { Modal, Tag, Typography } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { ANIMATION_CLASS } from "../../constants/definitions";
import useAppSelector from "../../hooks/useAppSelector";
import isMobile from "../../utils/isMobile";
import { useRetailHelper } from "../../utils/useRetailHelper";

interface OrderDetailModalProps {
  isOrderDetailModalOpen: boolean;
  setIsOrderDetailModalOpen: Dispatch<SetStateAction<boolean>>;
}

const OrderDetailModal: React.FC<OrderDetailModalProps> = ({
  isOrderDetailModalOpen,
  setIsOrderDetailModalOpen,
}) => {
  const { getOrderDetailLoading, orderDetailDataModel } = useAppSelector(
    (state) => state.profile
  );
  const { currencySymbol, isStoreTaxInclusive } = useRetailHelper();

  return (
    <Modal
      visible={isOrderDetailModalOpen}
      onCancel={() => {
        setIsOrderDetailModalOpen(false);
      }}
      maskClosable={getOrderDetailLoading ? false : true}
      footer={null}
      closable={isMobile ? true : false}
      centered
      style={{ maxWidth: "80vw", top: 20 }}
      width={1100}
      closeIcon={isMobile ? true : false}
    >
      {isOrderDetailModalOpen && (
        <div
          className="custom-close-button"
          onClick={() => setIsOrderDetailModalOpen(false)}
        >
          <CloseOutlined className="close-icon" />
        </div>
      )}
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "40vh" }}
      >
        {getOrderDetailLoading ? (
          <div className="d-flex flex-column gap-4 justify-content-center align-items-center text-align-center">
            <div
              className="loader-container"
              style={{ width: "55px", margin: "auto", padding: "10px" }}
            >
              <ClipLoader
                loading={true}
                color="black"
                size={34}
                aria-label="Loading Spinner"
                data-testid="loader"
                className="loader-page"
              />
            </div>
            <Typography.Text strong style={{ textAlign: "center" }}>
              While we retrieve the details of your order, please wait a moment.
            </Typography.Text>
          </div>
        ) : (
          <div className="w-100">
            <div className="modal-header">
              <h5 className="modal-title" id="orderdetailsModalLabel">
                {/* Order Details ({orderDetail?.orderDetailsViewModel?.orderNumber}) */}
              </h5>
            </div>
            <div className="modal-body" data-aos={ANIMATION_CLASS}>
              <div className="card shadow-md mb-3">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr className="table-light">
                          <td>Tax Amount</td>
                          {/* <td>Table No.</td> */}
                          <td>Total Amount</td>
                          <td>Delivery Address</td>
                          <td>Delivery Amount</td>
                          <td>Discount Amount</td>
                        </tr>
                        <tr>
                          <td>
                            {currencySymbol()}{" "}
                            {orderDetailDataModel?.orderDetails?.taxAmount}
                          </td>

                          <td>
                            {currencySymbol()}{" "}
                            {orderDetailDataModel?.orderDetails?.totalAmount}
                          </td>
                          <td>
                            {
                              orderDetailDataModel?.orderDetails
                                ?.deliveryAddress
                            }
                          </td>
                          <td>
                            {currencySymbol()}{" "}
                            {isStoreTaxInclusive()
                              ? orderDetailDataModel
                                  ?.orderPaymentWithStatusDetails
                                  ?.deliveryAmountWithTax
                              : orderDetailDataModel
                                  ?.orderPaymentWithStatusDetails
                                  ?.deliveryAmount}
                          </td>
                          <td>
                            {currencySymbol()}{" "}
                            {isStoreTaxInclusive()
                              ? orderDetailDataModel
                                  ?.orderPaymentWithStatusDetails
                                  ?.discountWithTax
                              : orderDetailDataModel
                                  ?.orderPaymentWithStatusDetails?.discount}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <div className="detailtable">
                <h6 className="fw-bold text-theme">Customer User</h6>
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr className="table-light">
                      <td>Customer Name</td>
                      <td>Email</td>
                      <td>Phone</td>
                    </tr>
                    <tr>
                      <td>{orderDetail?.customerUserViewModel?.name}</td>
                      <td>{orderDetail?.customerUserViewModel?.email}</td>
                      <td>{orderDetail?.customerUserViewModel?.phoneNumber}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div> */}

              <div className="detailtable ">
                <h6 className="fw-bold text-theme mb-3">
                  Order Payment Details
                </h6>
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr className="table-light">
                        <td>Paid Amount</td>
                        <td>Payment Status</td>
                        <td>Payment Method</td>
                        <td>Customer Name</td>
                      </tr>
                      <tr>
                        <td>
                          {currencySymbol()}
                          {
                            orderDetailDataModel?.orderPaymentWithStatusDetails
                              ?.orderPaymentsDetails?.[0]?.paidAmount
                          }
                        </td>
                        <td>
                          <span
                            className="badge"
                            style={{
                              background: "green",
                            }}
                          >
                            {
                              orderDetailDataModel
                                ?.orderPaymentWithStatusDetails?.paymentStatus
                            }
                          </span>
                        </td>
                        <td>
                          {
                            orderDetailDataModel?.orderPaymentWithStatusDetails
                              ?.orderPaymentsDetails?.[0]?.paymentMethod
                          }
                        </td>
                        <td>
                          {" "}
                          {
                            orderDetailDataModel?.orderPaymentWithStatusDetails
                              ?.orderPaymentsDetails?.[0]?.customerName
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="detailtable">
                <h6 className="fw-bold text-theme mb-3">
                  Product with Price Details
                </h6>
                {orderDetailDataModel?.orderDetails?.description && (
                  <Tag className="mb-4">
                    Order Note:{" "}
                    {orderDetailDataModel?.orderDetails?.description}
                  </Tag>
                )}
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr className="table-light">
                        <td className="align-middle">Name</td>
                        <td className="align-middle">Quantity</td>
                        <td className="align-middle">Total</td>
                        <td className="align-middle">Description</td>
                        <td className="align-middle">Image</td>
                      </tr>
                      {orderDetailDataModel?.productDetails?.map((item) => (
                        <tr key={item.id} className="align-middle">
                          <td>
                            {item.name}{" "}
                            <p className="mt-2">
                              {item.orderItemSelectOptionsDetails?.map(
                                (item, index) => {
                                  return (
                                    <Tag>
                                      {item.selectOptionName} :{" "}
                                      {item.selectOptionValue}
                                    </Tag>
                                  );
                                }
                              )}
                              {item.orderItemsPriceModifierDetails?.map(
                                (item, index) => {
                                  return (
                                    <Tag>{`${
                                      item.modifierName
                                    }( ${currencySymbol()} ${
                                      item.modifierPrice
                                    } )`}</Tag>
                                  );
                                }
                              )}
                            </p>
                          </td>
                          <td>{item.quantity}</td>
                          <td>
                            {currencySymbol()} {item.total}
                          </td>
                          <td>{item.description}</td>
                          <td>
                            <img
                              style={{ width: "80px" }}
                              src={item.image}
                              className="img-fluid width50"
                              alt=""
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default OrderDetailModal;
