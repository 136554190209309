/** @format */

import React from "react";
import {
  ImageAd,
  TemplatePageCategoryDetail,
} from "../../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import LazyImage from "../../../utils/Lazy Image/LazyImage";

import { Button } from "antd";
import DOMPurify from "dompurify";
import Carousel from "react-bootstrap/Carousel";

/** @format */
interface SingleLinearAdsProps {
  item: TemplatePageCategoryDetail;
}

const SingleLinearAds: React.FC<SingleLinearAdsProps> = ({ item }) => {
  return (
    <section className="pb-4 pt-40" key={item.id}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Carousel indicators={false}>
              {item?.imageAds?.map((item: ImageAd, index) => {
                return (
                  <Carousel.Item key={index}>
                    <LazyImage
                      link={item?.imageUrl}
                      className="d-block w-100"
                      src={item.imageUrl}
                    />
                    <div className="row">
                      <div className="col-md-6">
                        <Carousel.Caption>
                          {item.title && <h1>{item.title}</h1>}
                          {item.description && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  item.description ?? ""
                                ),
                              }}
                            />
                          )}

                          {item.linkText && (
                            <a href={item.link}>
                              <Button className="banner-btn" danger>
                                {item.linkText}
                              </Button>
                            </a>
                          )}
                        </Carousel.Caption>
                      </div>
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SingleLinearAds;
