/** @format */

import React, { useEffect, useRef, useState } from "react";
import { useRetailHelper } from "../useRetailHelper";

interface LazyImageProps {
  src?: string;
  alt?: string;
  placeholder?: string;
  className?: string;
  style?: React.CSSProperties;
  width?: number | string;
  height?: number | string;
  link?: string;
}

function LazyImage({
  src,
  alt,
  placeholder,
  className,
  style,
  width,
  height,
  link,
}: LazyImageProps) {
  const [imageSrc, setImageSrc] = useState<string | undefined>(
    "assets/images/error_Lazy/Loading.png"
  );
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const { handleRedirectMegaMenu } = useRetailHelper();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (src) {
            setImageSrc(src);
          } else {
            setImageSrc("assets/images/error_Lazy/Loading.png");
          }

          observer.disconnect();
        }
      },
      { rootMargin: "0px 0px 50px 0px" } // adjust this to your liking
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [src]);

  function handleImageError(e: any) {
    e.target.src = "assets/images/error_Lazy/Loading.png";
  }

  // Render just the image tag without a link
  if (link) {
    return (
      <a href={link} target="_blank">
        {" "}
        <img
          onError={handleImageError}
          width={width}
          ref={imgRef}
          src={imageSrc}
          alt={alt}
          style={{ ...style, cursor: link ? "pointer" : "" }}
          className={className}
          height={height}
          onLoad={() => {
            setLoaded(true);
          }}
        />
      </a>
    );
  }
  return (
    <img
      onError={handleImageError}
      width={width}
      ref={imgRef}
      src={imageSrc}
      alt={alt}
      style={{ ...style, cursor: link ? "pointer" : "" }}
      className={className}
      height={height}
      onLoad={() => {
        setLoaded(true);
      }}
    />
  );
}

export default LazyImage;
