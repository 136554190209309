/** @format */

import React from "react";
import { MegaMenuWithChildrenAndCardsViewModel } from "../../redux/reducers/MegaMenuReducer/MegaMenuInterface";
import { useRetailHelper } from "../../utils/useRetailHelper";

interface VerticalCardBlockMegaMProps {
  megaMenu: MegaMenuWithChildrenAndCardsViewModel[];
  isSecondLevel?: boolean;
}

const VerticalCardBlockMegaM: React.FC<VerticalCardBlockMegaMProps> = ({
  megaMenu,
  isSecondLevel,
}) => {
  const { handleRedirectMegaMenu } = useRetailHelper();

  return (
    <div
      className={`${
        isSecondLevel
          ? "dropdown-menu-list-submenu shadow-sm"
          : " dropdown-menu pb-0 animated fadeIn shadow-sm dropdown-fullwidth"
      }`}
      style={{ height: "70vh", overflowY: "auto", overflowX: "hidden" }}
    >
      <div className="row p-2 p-lg-4">
        {megaMenu.map((childMegaMenu, index) => {
          return (
            <div
              className="col-lg-3 col-12 mb-4 mb-lg-0"
              key={childMegaMenu.id}
            >
              <h6
                onClick={() => handleRedirectMegaMenu(childMegaMenu.link ?? "")}
                className="text-primary "
              >
                {childMegaMenu.name}
              </h6>
              {childMegaMenu.childrens?.map((item, index) => {
                return (
                  <a
                    key={item.id}
                    className="dropdown-item"
                    onClick={() =>
                      handleRedirectMegaMenu(childMegaMenu.link ?? "")
                    }
                  >
                    {item.name}
                  </a>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VerticalCardBlockMegaM;
