/** @format */

// import brotli from "brotli";
import React from "react";
import { Helmet } from "react-helmet";
import useAppSelector from "../../hooks/useAppSelector";
import LayoutContainer from "../layout/layout_container";
import ProductDetailsContainer from "./product_details_container";

const ProductDetailsTest: React.FC = () => {
  const { homePageDataModel } = useAppSelector((state) => state.homePage);

  return (
    <div>
      <Helmet>
        <title>{homePageDataModel?.storeDetails?.storeName}</title>
        <meta
          property="og:title"
          content={homePageDataModel?.storeDetails?.storeName}
        />
        <meta
          property="og:description"
          content={homePageDataModel?.storeDetails?.storeName}
        />
        <meta
          property="og:image"
          content={homePageDataModel?.storeDetails?.imageUrl}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <LayoutContainer>
        <ProductDetailsContainer />
      </LayoutContainer>
    </div>
  );
};

export default ProductDetailsTest;
