/** @format */

import { combineEpics } from "redux-observable";
import {
  addDeliveryLocationEpic,
  addProductToWishlistEpic,
  authenticateUserEpic,
  changePasswordEpic,
  changePasswordProfileEpic,
  checkGiftCardBalanceEpic,
  deleteWishlistItemEpic,
  forgetPasswordEpic,
  getAllBannersEpic,
  getAllBlogsEpic,
  getAllCategoriesEpic,
  getAllGiftCardsEpic,
  getAllMegaMenuEpic,
  getAllProductsEpic,
  getAllWishlistProductsEpic,
  getBlogDetailEpic,
  getDeliveryLocationOfUserEpic,
  getLandingPagedataEpic,
  getOrderDetailEpic,
  getOrderHistoryEpic,
  getPageDetailEpic,
  getProductDetailsCopyEpic,
  getProductDetailsEpic,
  getProfileDetailEpic,
  getStoreDetailsEpic,
  initializeStoreEpic,
  listCountiresEpic,
  makePaymentEpic,
  redeemVoucherEpic,
  registerUserEpic,
  searchProductsEpic,
  sendEnquiryEpic,
  sendGiftCardEpic,
  sendOTPEpic,
  sendOTPResendEpic,
  updateProfileDetailEpic,
} from "./myEpics";

export const rootEpic = combineEpics(
  initializeStoreEpic,
  getLandingPagedataEpic,
  getAllCategoriesEpic,
  getStoreDetailsEpic,
  getProductDetailsEpic,
  getProductDetailsCopyEpic,
  getAllProductsEpic,
  searchProductsEpic,
  authenticateUserEpic,
  sendOTPEpic,
  sendOTPResendEpic,
  registerUserEpic,
  listCountiresEpic,
  forgetPasswordEpic,
  getAllGiftCardsEpic,
  checkGiftCardBalanceEpic,
  sendGiftCardEpic,
  redeemVoucherEpic,
  addDeliveryLocationEpic,
  getDeliveryLocationOfUserEpic,
  makePaymentEpic,
  changePasswordEpic,
  changePasswordProfileEpic,
  getProfileDetailEpic,
  updateProfileDetailEpic,
  getOrderDetailEpic,
  getOrderHistoryEpic,
  getPageDetailEpic,
  addProductToWishlistEpic,
  getAllWishlistProductsEpic,
  getAllBannersEpic,
  deleteWishlistItemEpic,
  sendEnquiryEpic,
  getAllBlogsEpic,
  getBlogDetailEpic,
  getAllMegaMenuEpic
);
