/** @format */

import {
  DownOutlined,
  HeartOutlined,
  LogoutOutlined,
  OrderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown } from "antd";
import moment from "moment";
import React, { ReactNode } from "react";
import Loadable from "react-loadable";
import { Link, useNavigate } from "react-router-dom";
import ActionTypes from "../../constants/action_types";
import Routes_Constants from "../../constants/routes_configs";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import {
  ProductCategory,
  StoreDetails,
} from "../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import { useApiCalls } from "../../utils/useApiCalls";
import { useRetailHelper } from "../../utils/useRetailHelper";
import Footer from "../footer/Footer";
import Footer2 from "../footer/Footer2";
import Header from "../header/Header";
import SubscribeModal from "../popup_subscribe/popup_subcribe";
import WithApiCalls from "../withApiCalls/WithApiCalls";

const LoginLoadable = Loadable({
  loader: () => import("../../containers/login_container/login_container"),
  loading: () => null,
});
interface LayoutProps {
  storeDetails?: StoreDetails;
  productCategories?: ProductCategory[];
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  storeDetails,
  productCategories,
}) => {
  const { initializeStoreDataModel, homePageDataModel } = useAppSelector(
    (state) => state.homePage
  );
  const {
    handleNavigation,
    getCartProducts,
    currencySymbol,
    getTotalAmountOfCart,
    formatDate,
  } = useRetailHelper();
  const navigate = useNavigate();
  const { isLoginSuccessFul, loginResponse } = useAppSelector(
    (state) => state.authentication
  );
  const dispatch = useAppDispatch();

  const { getAllFilterProducts, getProfileDetails, getOrdersHistory } =
    useApiCalls();

  return (
    <>
      <WithApiCalls>
        <SubscribeModal />
        <Header
          storeDetails={storeDetails}
          productCategories={productCategories}
          homePageDataModel={homePageDataModel}
        />
        <main className="mainbody">{children}</main>
        {homePageDataModel?.storeDetails?.enableFooter && <Footer />}
        {homePageDataModel?.storeDetails?.enableCopyRightFooter && (
          <Footer2 homePageDataModel={homePageDataModel} />
        )}

        <div className="mobile-toolbar d-block d-md-none">
          <div className="row table-layout-fixed w-100">
            <Link
              className="col-4 mobile-toolbar-item"
              to={Routes_Constants.HOME}
            >
              <span className="mobile-toolbar-icon">
                <i className="fas fa-home" />
              </span>{" "}
              <span className="mobile-toolbar-label">Home</span>
            </Link>
            <a
              className="col-4 mobile-toolbar-item"
              onClick={() => {
                if (getCartProducts().length) {
                  navigate(Routes_Constants.CHECKOUT);
                }
              }}
            >
              <span className="mobile-toolbar-icon">
                <i className="fas fa-shopping-cart" />
                <span className="badge bg-theme rounded-pill ms-1">
                  {getCartProducts().length}
                </span>
              </span>
              <span className="mobile-toolbar-label">
                {" "}
                {currencySymbol()} {getTotalAmountOfCart()}
              </span>
            </a>

            <a className="col-4 mobile-toolbar-item">
              <span className="mobile-toolbar-icon">
                <i
                  className="navbar-tool-icon fas fa-user"
                  style={{ height: "33px" }}
                />
              </span>{" "}
              <span className="mobile-toolbar-label">
                {" "}
                {isLoginSuccessFul ? (
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "1",
                          label: (
                            <a
                              onClick={() => {
                                getProfileDetails();
                              }}
                            >
                              Account
                            </a>
                          ),
                          icon: <UserOutlined />,
                        },
                        {
                          key: "2",
                          label: (
                            <a
                              onClick={() => {
                                getOrdersHistory({
                                  Page: 1,
                                  PageSize: 10,
                                  SearchKeywords: "",
                                  ExternalFilter: {
                                    OrderedDateFrom: formatDate(
                                      moment()
                                        .subtract(15, "days")
                                        .format("YYYY-MM-DD")
                                    ),
                                    OrderedDateTo: formatDate(
                                      moment().format("YYYY-MM-DD")
                                    ),
                                  },
                                });
                              }}
                            >
                              My Orders
                            </a>
                          ),
                          icon: <OrderedListOutlined />,
                        },
                        {
                          key: "3",
                          label: (
                            <a
                              onClick={() => {
                                navigate(Routes_Constants.WISHLIST);
                              }}
                            >
                              Wishlist
                            </a>
                          ),
                          icon: <HeartOutlined />,
                        },
                        {
                          key: "4",
                          danger: true,
                          label: (
                            <a
                              onClick={() => {
                                dispatch({
                                  type: ActionTypes.LOGOUT_REQUEST,
                                });
                                navigate(Routes_Constants.HOME);
                              }}
                            >
                              Logout
                            </a>
                          ),
                          icon: <LogoutOutlined />,
                        },
                      ],
                    }}
                  >
                    <div>
                      {loginResponse?.name}
                      <DownOutlined />
                    </div>
                  </Dropdown>
                ) : (
                  <a
                    style={{ marginTop: "-10px" }}
                    onClick={() => {
                      handleNavigation(
                        Routes_Constants.AUTHENTICATE,
                        LoginLoadable
                      );
                    }}
                  >
                    Login/Register
                  </a>
                )}
              </span>
            </a>
          </div>
        </div>
      </WithApiCalls>
    </>
  );
};

export default Layout;
