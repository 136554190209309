/** @format */

import nProgress from "nprogress";
import ActionTypes from "../../../constants/action_types";
import PageInterface from "./PageReducerInterface";

/** @format */

const initialState: PageInterface = {
  isLoading: false,
};

const reducer = (state = initialState, action: any): PageInterface => {
  switch (action.type) {
    case ActionTypes.GET_PAGE_DETAIL_REQUEST:
      nProgress.start();
      return {
        ...state,
        isLoading: true,
        isPageDetailFetchSuccessful: false,
        error: null,
      };
    case ActionTypes.GET_PAGE_DETAIL_SUCCESS:
      nProgress.done();
      return {
        ...state,
        isLoading: false,
        isPageDetailFetchSuccessful: true,
        pageDetails: action.payload.data,
        error: null,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.GET_PAGE_DETAIL_FAILURE:
      nProgress.done();
      return {
        ...state,
        isLoading: false,
        isPageDetailFetchSuccessful: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.SET_ACTIVE_SLUG_PAGE:
      return {
        ...state,
        activeSlug: action.payload,
      };

    case ActionTypes.RESET_PAGE_STATE:
      return {
        ...state,
        isPageDetailFetchSuccessful: false,
        error: null,
        successMessage: null,
      };
    default:
      return state;
  }
};

export default reducer;
