/** @format */

import { ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Pagination,
  Tooltip,
} from "antd";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { Helmet } from "react-helmet";
import CustomBreadcrumb from "../../components/custom_breadcrump/CustomBreadcrumb";
import Layout from "../../components/layout/Layout";
import PageLoader from "../../components/page_loader/PageLoader";
import { OrderHistoryDetails } from "../../redux/reducers/ProfileReducer/ProfileReducerInterface";
import { ProfileFormValues } from "../../types/commonInterfaces";
import { useApiCalls } from "../../utils/useApiCalls";
import { useRetailHelper } from "../../utils/useRetailHelper";
import OrderDetailModal from "./OrderDetailModal";

interface OrderHistoryProps {
  searchOrderHistoryForm: FormInstance<ProfileFormValues>;
  isLoading: boolean;
  orderHistoryDetails: OrderHistoryDetails | undefined;
  onSearchOrderHandler: (values: any) => void;
  getOrderDetail: (orderId: string) => void;
  isOrderDetailModalOpen: boolean;
  setIsOrderDetailModalOpen: Dispatch<SetStateAction<boolean>>;
}

const OrderHistory: React.FC<OrderHistoryProps> = ({
  searchOrderHistoryForm,
  orderHistoryDetails,
  isLoading,
  onSearchOrderHandler,
  getOrderDetail,
  isOrderDetailModalOpen,
  setIsOrderDetailModalOpen,
}) => {
  const { currencySymbol, formatDate } = useRetailHelper();
  const { getOrdersHistory } = useApiCalls();

  return (
    <>
      <Layout>
        <Helmet>
          <title>{"My Orders"}</title>
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        <OrderDetailModal
          isOrderDetailModalOpen={isOrderDetailModalOpen}
          setIsOrderDetailModalOpen={setIsOrderDetailModalOpen}
        />
        <PageLoader loading={!orderHistoryDetails}>
          <section className="pt-3 pb-3">
            <div className="container">
              <CustomBreadcrumb items={[{ title: "My Orders", link: "" }]} />

              <div className="tab-vertical1">
                <div className="text-center pb-4 mt-4">
                  <h6 className="text-center fw-bold text-theme">
                    Your Order History
                  </h6>
                  <small className="text-muted">
                    Explore your past orders to keep track of your shopping
                    journey.
                  </small>
                </div>
                <div className="row g-0 d-flex gap-4 justify-content-center mt-3">
                  <div className="col-md-11">
                    <div className="card  rounded-3 border-0">
                      <div className="card-body">
                        <h5 className="fw-bold">Search By</h5>
                        <div className="d-flex row justify-content-start justify-content-sm-start align-items-center pt-2 ">
                          <Form
                            className="w-100 row align-items-center"
                            form={searchOrderHistoryForm}
                            onFinish={onSearchOrderHandler}
                            labelAlign="left"
                            labelCol={{
                              span: 24,
                            }}
                            initialValues={{
                              orderDateFrom: null,
                              orderDateTo: null,
                            }}
                          >
                            <Form.Item
                              label="Order No"
                              name="orderNo"
                              className="col-md-3"
                            >
                              <Input placeholder="Order No" />
                            </Form.Item>
                            <Form.Item
                              label="Order Date From"
                              name="orderDateFrom"
                              className="col-md-3"
                            >
                              <DatePicker
                                // format={"MM/DD/YYYY"}
                                className="w-100"
                              />
                            </Form.Item>
                            <Form.Item
                              label="Order Date To"
                              name="orderDateTo"
                              className="col-md-3"
                            >
                              <DatePicker
                                // format={"MM/DD/YYYY"}
                                className="w-100"
                              />
                            </Form.Item>
                            <div className="col-md-3 mt-md-4 mt-0 d-flex gap-3">
                              <Button htmlType="submit" type="primary">
                                Search
                              </Button>
                              <Tooltip title="Reset Filters">
                                <Button
                                  onClick={() => {
                                    searchOrderHistoryForm.resetFields();
                                    getOrdersHistory();
                                  }}
                                  type="default"
                                  icon={<ReloadOutlined />}
                                ></Button>
                              </Tooltip>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-11">
                    <div className=" card0 border-0 ">
                      <div className="all_restro">
                        <div className="card mt-3 p-3 pt-0 border-0 bg-white">
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <strong>Order Id</strong>
                                    </td>
                                    <td>
                                      <strong>Order Date</strong>
                                    </td>
                                    <td>
                                      <strong>Order Status</strong>
                                    </td>
                                    <td>
                                      <strong>Order Amount</strong>
                                    </td>
                                    <td>
                                      <strong>Order Details</strong>
                                    </td>
                                  </tr>
                                  {orderHistoryDetails?.data.length == 0 && (
                                    <tr>
                                      <td align="center" colSpan={6}>
                                        <p>No Orders Found !</p>
                                      </td>
                                    </tr>
                                  )}
                                  {orderHistoryDetails?.data?.map(
                                    (item, index) => {
                                      return (
                                        <tr key={item.orderId}>
                                          <td>{item.orderNumber}</td>
                                          <td>{item.orderDate}</td>
                                          <td>
                                            <span
                                              className="badge"
                                              style={
                                                item.statusEnumValue == "1"
                                                  ? {
                                                      background: "red",
                                                    }
                                                  : item.statusEnumValue == "8"
                                                  ? {
                                                      background: "red",
                                                    }
                                                  : item.statusEnumValue == "7"
                                                  ? {
                                                      background: "green",
                                                    }
                                                  : item.statusEnumValue == "2"
                                                  ? {
                                                      background: "orange",
                                                    }
                                                  : item.statusEnumValue == "3"
                                                  ? {
                                                      background: "black",
                                                    }
                                                  : {
                                                      background: "blue",
                                                    }
                                              }
                                            >
                                              {item.status}
                                            </span>
                                          </td>
                                          <td>
                                            {currencySymbol()}{" "}
                                            {item.totalAmount}
                                          </td>
                                          <td>
                                            <Tooltip title="View Details">
                                              <a
                                                className=""
                                                onClick={() => {
                                                  getOrderDetail(item.orderId);
                                                  setIsOrderDetailModalOpen(
                                                    true
                                                  );
                                                }}
                                              >
                                                <i
                                                  className="fas fa-eye edit"
                                                  aria-hidden="true"
                                                />
                                              </a>
                                            </Tooltip>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                            {orderHistoryDetails?.data &&
                              orderHistoryDetails?.data?.length > 0 && (
                                <div className="d-flex justify-content-end">
                                  <Pagination
                                    pageSize={10}
                                    showTotal={(total) => (
                                      <span className="fw-bold text-theme d-none d-md-block">
                                        {" "}
                                        Total {total} items
                                      </span>
                                    )}
                                    total={orderHistoryDetails.total}
                                    onChange={(page) => {
                                      getOrdersHistory({
                                        Page: page,
                                        PageSize: 10,
                                        SearchKeywords:
                                          searchOrderHistoryForm.getFieldValue(
                                            "orderNo"
                                          ),
                                        ExternalFilter: {
                                          OrderedDateFrom: formatDate(
                                            searchOrderHistoryForm.getFieldValue(
                                              "orderDateFrom"
                                            )
                                              ? formatDate(
                                                  searchOrderHistoryForm.getFieldValue(
                                                    "orderDateFrom"
                                                  )
                                                )
                                              : moment()
                                                  .subtract(15, "days")
                                                  .format("MM/DD/YYYY HH:mm:ss")
                                          ),
                                          OrderedDateTo: formatDate(
                                            searchOrderHistoryForm.getFieldValue(
                                              "orderDateFrom"
                                            )
                                              ? formatDate(
                                                  searchOrderHistoryForm.getFieldValue(
                                                    "orderDateFrom"
                                                  )
                                                )
                                              : moment().format(
                                                  "MM/DD/YYYY HH:mm:ss"
                                                )
                                          ),
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                        {/* end */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </PageLoader>
      </Layout>
    </>
  );
};

export default OrderHistory;
