/** @format */

import nProgress from "nprogress";
import ActionTypes from "../../../constants/action_types";
import ProfileInterface from "./ProfileReducerInterface";

/** @format */

const initialState: ProfileInterface = {
  isLoading: false,
};

const reducer = (state = initialState, action: any): ProfileInterface => {
  switch (action.type) {
    case ActionTypes.GET_PROFILE_DETAILS_REQUEST:
      nProgress.start();
      return {
        ...state,
        isLoading: true,
        isProfileDetailFetchSuccessful: false,
      };
    case ActionTypes.GET_PROFILE_DETAILS_SUCCESS:
      nProgress.done();
      return {
        ...state,
        isLoading: false,
        isProfileDetailFetchSuccessful: true,
        profileDetails: action.payload.data,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.GET_PROFILE_DETAILS_FAILURE:
      nProgress.done();

      return {
        ...state,
        isLoading: false,
        isProfileDetailFetchSuccessful: false,
      };
    case ActionTypes.UPDATE_PROFILE_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isProfileDetailFetchSuccessful: false,
      };
    case ActionTypes.UPDATE_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isProfileUpdateSuccessful: true,
        successMessage: action.payload?.data?.message,

        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.UPDATE_PROFILE_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isProfileUpdateSuccessful: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.GET_ORDER_HISTORY_REQUEST:
      nProgress.start();
      return {
        ...state,

        isOrderHistoryFetchSuccessful: false,
      };
    case ActionTypes.GET_ORDER_HISTORY_SUCCESS:
      nProgress.done();
      return {
        ...state,

        isOrderHistoryFetchSuccessful: true,
        orderHistoryDetails: action.payload.data,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.GET_ORDER_HISTORY_FAILURE:
      nProgress.done();

      return {
        ...state,

        isProfileDetailFetchSuccessful: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.GET_ORDER_DETAIL_REQUEST:
      return {
        ...state,
        getOrderDetailLoading: true,
      };
    case ActionTypes.GET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        getOrderDetailLoading: false,
        orderDetailDataModel: action.payload.data,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.GET_ORDER_DETAIL_FAILURE:
      return {
        ...state,
        getOrderDetailLoading: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.RESET_PROFILE_STATE:
      return {
        ...state,
        isProfileUpdateSuccessful: false,
        isProfileDetailFetchSuccessful: false,
        isOrderHistoryFetchSuccessful: false,
        error: null,
        successMessage: null,
      };
    default:
      return state;
  }
};

export default reducer;
