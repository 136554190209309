/** @format */

import {
  DownOutlined,
  HeartOutlined,
  LogoutOutlined,
  OrderedListOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Dropdown, Input, Popover } from "antd";
import React, { useEffect, useState } from "react";
import Loadable from "react-loadable";
import { Link, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import ActionTypes from "../../constants/action_types";
import { MegaMenuLayoutTypes } from "../../constants/definitions";
import Routes_Constants from "../../constants/routes_configs";
import CartPopoverContainer from "../../containers/cart_popover_container/cart_popover_container";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { QueryString } from "../../redux/reducers/FilterProductsReducer/FilterProductsInterface";
import {
  TemplatePageCategoryDetail,
  homePageDataModel,
} from "../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import LazyImage from "../../utils/Lazy Image/LazyImage";
import isMobile from "../../utils/isMobile";
import { useApiCalls } from "../../utils/useApiCalls";
import { useRetailHelper } from "../../utils/useRetailHelper";
import HorizontalCardBlock from "./HorizontalCardBlock";
import HorizontalMegaMenu from "./HorizontalMegaMenu";
import MobileMegaMenu from "./MobileMegaMenu";
import VerticalCardBlockMegaM from "./VerticalCardBlockMegaM";
import VerticalMegaMenu from "./VerticalMegaMenu";

const LoginLoadable = Loadable({
  loader: () => import("../../containers/login_container/login_container"),
  loading: () => null,
});

const HomeLoadable = Loadable({
  loader: () => import("../../pages/home"),
  loading: () => null,
});
interface Header2Props {
  item: TemplatePageCategoryDetail;
  homePageDataModel?: homePageDataModel;
}

const Header2: React.FC<Header2Props> = ({ item, homePageDataModel }) => {
  const dispatch = useAppDispatch();
  const { handleNavigation, formatDate, handleRedirectMegaMenu } =
    useRetailHelper();
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [isMobileMegaMenuOpen, setIsMobileMegaMenuOpen] =
    useState<boolean>(false);
  const [isSearchPopoverOpen, setIsSearchPopoverOpen] =
    useState<boolean>(false);

  const { isLoading, searchResult } = useAppSelector((state) => state.common);
  const { isLoginSuccessFul, loginResponse } = useAppSelector(
    (state) => state.authentication
  );
  const { megaMenuDataModel } = useAppSelector((state) => state.megaMenu);

  const navigate = useNavigate();
  const {
    getAllFilterProducts,
    getProfileDetails,
    getOrdersHistory,
    getAllWishlistsProduct,
  } = useApiCalls();

  useEffect(() => {
    if (searchKeyword) {
      const searchTimeout = setTimeout(() => {
        dispatch({
          type: ActionTypes.SEARCH_PRODUCTS_REQUEST,
          payload: {
            Page: 1,
            PageSize: 10,
            SearchKeywords: searchKeyword,
            StoreId: homePageDataModel?.storeDetails?.id,
          },
        });
      }, 500);
      return () => {
        clearTimeout(searchTimeout);
      };
    }
  }, [searchKeyword]);
  const brandsArray = Array.from(
    new Set(
      searchResult &&
        searchResult
          .map((item) => item.brand)
          .filter((brand) => brand !== null && brand !== undefined)
    )
  );

  const categoriesArray = Array.from(
    new Set(
      searchResult &&
        searchResult
          .map((item) => item.category)
          .filter((category) => category !== null && category !== undefined)
    )
  );

  return (
    <div key={item.id} className="">
      {" "}
      <div className="shadow-sm bg-header">
        <div className="navbar navbar-expand-lg navbar-light pt-2 pb-0 mynav">
          <div className="container">
            <Link
              to={Routes_Constants.HOME}
              className="navbar-brand flex-shrink-0 d-sm-none"
            >
              <LazyImage
                src={homePageDataModel?.storeDetails?.imageUrl}
                width={142}
                alt="logo"
              />
            </Link>
            <Link
              className="navbar-brand d-none d-md-block flex-shrink-0 me-2 d-sm-none"
              to={Routes_Constants.HOME}
            >
              <LazyImage
                src={homePageDataModel?.storeDetails?.imageUrl}
                width={74}
                alt="logo"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => setIsMobileMegaMenuOpen(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={32}
                height={32}
                fill="#fff"
                className="bi bi-text-indent-left text-primary"
                viewBox="0 0 16 16"
              >
                <path d="M2 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm.646 2.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L4.293 8 2.646 6.354a.5.5 0 0 1 0-.708zM7 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
              </svg>
            </button>
            <div className="input-group d-lg-flex align-items-center flex-nowrap mx-0 mx-md-4 w-100 w-md-50 position-relative mb-2">
              <a
                onClick={() => {
                  handleNavigation(Routes_Constants.HOME, HomeLoadable);
                }}
                className="navbar-brand d-sm-none flex-shrink-0 me-2 d-none"
              >
                <img
                  src={homePageDataModel?.storeDetails?.imageUrl}
                  width={74}
                  alt="logo"
                />
              </a>
              {isLoading && (
                <div className=" position-absolute loadericon">
                  <BeatLoader
                    loading={true}
                    size={8}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              )}

              <i className="fas fa-search position-absolute top-50 end-0 translate-middle-y me-3 searchicon" />

              <div className="w-100">
                <Popover
                  id={
                    searchResult == undefined || !searchKeyword
                      ? "popover-search-hide"
                      : ""
                  }
                  placement="bottom"
                  trigger={"click"}
                  open={isSearchPopoverOpen}
                  onOpenChange={(e) => {
                    setIsSearchPopoverOpen(e);
                  }}
                  content={
                    searchResult && searchResult.length == 0 ? (
                      <div className="home-search d-flex justify-content-center">
                        <div className="d-flex align-items-center gap-2">
                          <SearchOutlined style={{ fontSize: "18px" }} />
                          <p>No Results Found.</p>
                        </div>
                      </div>
                    ) : (
                      <div className="row home-search">
                        <div className="col-12 col-md-7">
                          <ul className="search_desc ">
                            {searchResult?.map((item, index) => {
                              return (
                                <li
                                  className="d-flex py-2"
                                  key={index}
                                  onClick={() => {
                                    getAllFilterProducts({
                                      searchKeywords: item.name,
                                    });
                                    dispatch({
                                      type: ActionTypes.SET_QUERY_STRINGS,
                                      payload: {
                                        searchKeywords: item.name,
                                      } as QueryString,
                                    });
                                    setIsSearchPopoverOpen(false);
                                  }}
                                >
                                  <a>
                                    <img
                                      src={item.imageUrl}
                                      className="me-3 img-fluid"
                                      width="45px"
                                      height="45px"
                                      alt=""
                                    />
                                  </a>
                                  <p>
                                    <a>
                                      {item.name}
                                      <span className="d-block">
                                        {item.category && `${item.category}`}
                                        {item.brand && `${item.brand}`}
                                      </span>
                                    </a>
                                  </p>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="col-md-5">
                          <div className="search_brand bg-light p-4">
                            {brandsArray && brandsArray.length > 0 && (
                              <>
                                <span className="desc fw-bold">
                                  Recommended Brands
                                </span>
                                <ul>
                                  {brandsArray.map((item, index) => {
                                    return (
                                      <li key={index}>
                                        <a href="">{item} </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </>
                            )}
                            {categoriesArray && categoriesArray.length > 0 && (
                              <div className="mt-2">
                                <span className="desc fw-bold mt-4">
                                  Related Categories
                                </span>
                                <ul>
                                  {categoriesArray.map((item, index) => {
                                    return (
                                      <li key={index}>
                                        <a href="">{item} </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  }
                >
                  <Input
                    onPressEnter={() => {
                      if (searchKeyword) {
                        getAllFilterProducts({
                          searchKeywords: searchKeyword,
                        });
                        dispatch({
                          type: ActionTypes.SET_QUERY_STRINGS,
                          payload: {
                            searchKeywords: searchKeyword,
                          } as QueryString,
                        });
                        setIsSearchPopoverOpen(false);
                      }
                    }}
                    style={{
                      border: "1px solid #ced4d9",
                      borderRadius: "50%",
                    }}
                    value={searchKeyword}
                    onChange={(e) => {
                      setSearchKeyword(e.target.value);
                    }}
                    className="w-100 input-bg-white bg-white rounded-start border-radius ps-3 form-control"
                    type="text"
                    placeholder="Search for products"
                  />
                </Popover>
              </div>
            </div>
            <div className="navbar-toolbar d-none d-md-flex flex-shrink-0 align-items-center">
              <div
                className="navbar-tool text-header ms-1  me-lg-3"
                // href="#loginModal"
                // data-bs-toggle="modal"
              >
                {isLoginSuccessFul ? (
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "1",
                          label: (
                            <a
                              onClick={() => {
                                getProfileDetails();
                              }}
                            >
                              Account
                            </a>
                          ),
                          icon: <UserOutlined />,
                        },
                        {
                          key: "2",
                          label: (
                            <a
                              onClick={() => {
                                getOrdersHistory();
                              }}
                            >
                              My Orders
                            </a>
                          ),
                          icon: <OrderedListOutlined />,
                        },
                        {
                          key: "3",
                          label: (
                            <a
                              onClick={() => {
                                getAllWishlistsProduct();
                              }}
                            >
                              My Wishlists
                            </a>
                          ),
                          icon: <HeartOutlined />,
                        },
                        {
                          key: "4",
                          danger: true,
                          label: (
                            <a
                              onClick={() => {
                                dispatch({
                                  type: ActionTypes.LOGOUT_REQUEST,
                                });
                                dispatch({
                                  type: ActionTypes.CLEAR_WISHLIST_PRODUCTS,
                                });
                                navigate(Routes_Constants.HOME);
                              }}
                            >
                              Logout
                            </a>
                          ),
                          icon: <LogoutOutlined />,
                        },
                      ],
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="navbar-tool-icon-box ">
                        <i className="navbar-tool-icon fas fa-user" />
                      </div>
                      <a className="d-flex align-items-center gap-1 text-header">
                        {loginResponse?.name}
                        <DownOutlined />
                      </a>
                    </div>
                  </Dropdown>
                ) : (
                  <a
                    onClick={() => {
                      handleNavigation(
                        Routes_Constants.AUTHENTICATE,
                        LoginLoadable
                      );
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="navbar-tool-icon-box">
                        <i className="navbar-tool-icon fas fa-user " />
                      </div>
                      <div className="navbar-tool-text ms-n3 text-header">
                        Login/Register
                      </div>
                    </div>
                  </a>
                )}
              </div>
              <div className="navbar-tool dropdown  ms-3">
                <CartPopoverContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_nav mega-menu-navbar">
        <nav className="navbar navbar-expand-lg  bg-megamenu p-0">
          <div className="container">
            <div className="collapse navbar-collapse" id="navbar-content">
              <ul className="navbar-nav m-auto mb-2 mb-lg-0 animate-dropdown">
                {megaMenuDataModel
                  ?.find(
                    (megaMenu) =>
                      megaMenu.templateSectionIdentifier == item.identifier
                  )
                  ?.megaMenuWithChildrenAndCardsViewModel?.map(
                    (parentMegaMenu, index) => {
                      return (
                        <li
                          className="nav-item dropdown w-100 w-lg-auto dropdown-fullwidth"
                          key={parentMegaMenu.id}
                        >
                          <a
                            onClick={() =>
                              handleRedirectMegaMenu(parentMegaMenu.link ?? "")
                            }
                            className={`nav-link ${
                              parentMegaMenu.childrens.length > 0 &&
                              "dropdown-toggle"
                            } `}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-bs-auto-close="outside"
                          >
                            {parentMegaMenu.imageUrl && (
                              <Avatar
                                className="me-2"
                                src={parentMegaMenu.imageUrl}
                              ></Avatar>
                            )}

                            {parentMegaMenu.name}
                          </a>
                          {parentMegaMenu.childMegaMenuType ==
                            MegaMenuLayoutTypes.Vertical && (
                            <VerticalMegaMenu
                              megaMenu={parentMegaMenu.childrens}
                            />
                          )}
                          {parentMegaMenu.childMegaMenuType ==
                            MegaMenuLayoutTypes.VerticalCardBlock && (
                            <VerticalCardBlockMegaM
                              megaMenu={parentMegaMenu.childrens}
                            />
                          )}
                          {parentMegaMenu.childMegaMenuType ==
                            MegaMenuLayoutTypes.HorizontalCardBlock && (
                            <HorizontalCardBlock
                              megaMenu={parentMegaMenu.childrens}
                            />
                          )}
                          {parentMegaMenu.childMegaMenuType ==
                            MegaMenuLayoutTypes.Horizontal && (
                            <HorizontalMegaMenu
                              megaMenu={parentMegaMenu.childrens}
                            />
                          )}
                        </li>
                      );
                    }
                  )}
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {/* END Mega Menu HTML */}
      {isMobile && (
        <MobileMegaMenu
          megaMenu={
            megaMenuDataModel?.find(
              (megaMenu) =>
                megaMenu.templateSectionIdentifier == item.identifier
            )?.megaMenuWithChildrenAndCardsViewModel
          }
          isMobileMegaMenuOpen={isMobileMegaMenuOpen}
          setIsMobileMegaMenuOpen={setIsMobileMegaMenuOpen}
        />
      )}
    </div>
  );
};

export default Header2;
