/** @format */

import { setLocalStorage } from "../../../Helpers/FrontendHelper";
import ActionTypes from "../../../constants/action_types";
import { PRODUCT_VERSION_LOCAL_STORAGE } from "../../../constants/definitions";
import HomePageReducerInterface from "./HomePageReducerInterface";

/** @format */

const initialState: HomePageReducerInterface = {
  isLoading: false,
  getHomePageDataLoading: false,
};

const reducer = (
  state = initialState,
  action: any
): HomePageReducerInterface => {
  switch (action.type) {
    case ActionTypes.INITIALIZE_STORE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.INITIALIZE_STORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        initializeStoreDataModel: action.payload.data,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.INITIALIZE_STORE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.GET_LANDING_PAGE_DATA_REQUEST:
      return {
        ...state,
        getHomePageDataLoading: true,
      };
    case ActionTypes.GET_LANDING_PAGE_DATA_SUCCESS:
      if (!localStorage.getItem(PRODUCT_VERSION_LOCAL_STORAGE)) {
        localStorage.setItem(
          PRODUCT_VERSION_LOCAL_STORAGE,
          action?.payload?.data?.productVersion
        );
      }

      return {
        ...state,
        getHomePageDataLoading: false,
        homePageDataModel: {
          ...state.homePageDataModel,
          ...action.payload.data,
        },
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.GET_LANDING_PAGE_DATA_FAILURE:
      return {
        ...state,
        getHomePageDataLoading: false,
      };
    case ActionTypes.GET_ALL_CATEGORIES_REQUEST:
      return {
        ...state,
      };
    case ActionTypes.GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,

        homePageDataModel: {
          ...state.homePageDataModel,
          productCategories: action.payload.data,
        },
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.GET_ALL_CATEGORIES_FAILURE:
      return {
        ...state,
      };
    case ActionTypes.GET_STORE_DETAILS_REQUEST:
      return {
        ...state,
      };
    case ActionTypes.GET_STORE_DETAILS_SUCCESS:
      setLocalStorage(
        "isGuestChecout",
        action.payload.data?.enableGuestCheckOut ?? false
      );
      return {
        ...state,

        homePageDataModel: {
          ...state.homePageDataModel,
          storeDetails: action.payload.data,
        },
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.GET_STORE_DETAILS_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default reducer;
