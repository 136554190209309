/** @format */

import AOS from "aos";
import "aos/dist/aos.css";
import { SnackbarProvider } from "notistack";
import "nprogress/nprogress.css";
import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import "./App.css";
import store from "./redux/store";
import RoutesConfig from "./routes/routes_configs";
import "./styles/antd-reset.css";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 600,
      offset: 0,
    });
  }, []);
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <HelmetProvider>
          {" "}
          <RoutesConfig />
        </HelmetProvider>
      </SnackbarProvider>
    </Provider>
  );
}

export default App;
