/** @format */

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HomeSection from "../../components/home_section/HomeSection";
import Routes_Constants from "../../constants/routes_configs";
import useAppSelector from "../../hooks/useAppSelector";
import isMobile from "../../utils/isMobile";
import { useRetailHelper } from "../../utils/useRetailHelper";

const categoriesSlickSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: isMobile ? 2 : 5,
  slidesToScroll: 1,

  // More settings as per your requirements
};
const categoriesSlickSettings1 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: isMobile ? 2 : 5,
  slidesToScroll: 1,

  // More settings as per your requirements
};

const HomeSectionContainer: React.FC = () => {
  const { homePageDataModel } = useAppSelector((state) => state.homePage);
  const { isRedirectToChangePassword, getValueFromQuery } = useRetailHelper();
  const navigate = useNavigate();

  useEffect(() => {
    if (isRedirectToChangePassword()) {
      navigate(
        `${Routes_Constants.CHANGE_PASSWORD}?code=${getValueFromQuery(
          "code"
        )}&email=${getValueFromQuery("email")}`
      );
    }
  }, []);

  return (
    <HomeSection
      categoriesSlickSettings={categoriesSlickSettings}
      categoriesSlickSettings1={categoriesSlickSettings1}
      homePageDataModel={homePageDataModel}
    />
  );
};

export default HomeSectionContainer;
