/** @format */

import React from "react";
import { homePageDataModel } from "../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import isMobile from "../../utils/isMobile";

interface Footer2Props {
  homePageDataModel: homePageDataModel;
}

const Footer2: React.FC<Footer2Props> = ({ homePageDataModel }) => {
  return (
    <footer
      className=" p-2 mt-0 bg-darker footer-2"
      style={isMobile ? { marginBottom: "60px" } : {}}
    >
      <div className="">
        {/* Bottom Credits */}
        <div className="col-md-12 text-center">
          <div
            className="fw-bold footer-text"
            dangerouslySetInnerHTML={{
              __html:
                homePageDataModel.storeDetails?.copyRightFooterDescription ??
                "",
            }}
          />
        </div>
      </div>
      {/* /container */}
      {/* Go To Top Link */}
    </footer>
  );
};

export default Footer2;
