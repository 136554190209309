/** @format */

import { Form } from "antd";
import { useEffect, useState } from "react";
import ActionTypes from "../../constants/action_types";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useSnackbar from "../../hooks/useSnackbar";
import Profile from "../../pages/profile/Profile";
import { ProfileFormValues } from "../../types/commonInterfaces";
import { useApiCalls } from "../../utils/useApiCalls";

export interface ImageState {
  file: File | null;
  url: string | null;
}

const ProfileContainer = () => {
  const dispatch = useAppDispatch();
  const { getProfileDetails } = useApiCalls();
  const [profileForm] = Form.useForm<ProfileFormValues>();
  const {
    profileDetails,
    isProfileUpdateSuccessful,
    isLoading,
    error,
    successMessage,
  } = useAppSelector((state) => state.profile);
  const { loginResponse } = useAppSelector((state) => state.authentication);
  const { countires } = useAppSelector((state) => state.common);
  const { showSnackbar } = useSnackbar();

  const [image, setImage] = useState<ImageState>({
    file: null,
    url: null,
  });

  const onProfileUpdateSubmitHandler = (values: ProfileFormValues) => {
    let myDataform = new FormData();
    myDataform.append("Image", image.file ?? "");
    myDataform.append(
      "Request",
      JSON.stringify({
        Id: loginResponse?.userId,
        ...values,
      })
    );
    dispatch({
      type: ActionTypes.UPDATE_PROFILE_DETAILS_REQUEST,
      payload: myDataform,
    });
  };
  useEffect(() => {
    if (!profileDetails) {
      getProfileDetails();
    }
  }, []);

  useEffect(() => {
    if (profileDetails) {
      setImage((prev) => {
        return {
          ...prev,
          url: profileDetails.image,
        };
      });
      const formData = profileDetails;
      // Create an array to store field values
      const fields = [];

      // Loop through the formData object
      for (const key in formData) {
        if (Object.prototype.hasOwnProperty.call(formData, key)) {
          // Push each field object to the fields array
          // Putting condition as name is not in the form
          if (key == "name") {
            fields.push({
              name: "fullName",
              value: formData[key],
            });
          } else {
            fields.push({
              name: key,
              value: formData[key],
            });
          }
        }
      }

      profileForm.setFields(fields);
    }
  }, [profileDetails]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];

    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      setImage({
        file: selectedFile,
        url: imageUrl,
      });
    }
  };
  useEffect(() => {
    if (isProfileUpdateSuccessful) {
      getProfileDetails();
    }
  }, [isProfileUpdateSuccessful]);

  useEffect(() => {
    if (error) {
      showSnackbar(error, {
        variant: "error",
      });
      dispatch({
        type: ActionTypes.RESET_PROFILE_STATE,
      });
    }
  }, [error]);

  useEffect(() => {
    if (successMessage) {
      showSnackbar(successMessage, {
        variant: "success",
      });
      dispatch({
        type: ActionTypes.RESET_PROFILE_STATE,
      });
    }
  }, [successMessage]);
  return (
    <Profile
      profileForm={profileForm}
      onProfileUpdateSubmitHandler={onProfileUpdateSubmitHandler}
      countries={countires}
      image={image}
      setImage={setImage}
      isLoading={isLoading}
      profileDetails={profileDetails}
    />
  );
};

export default ProfileContainer;
