/** @format */

import { Avatar } from "antd";
import React, { useState } from "react";
import { MegaMenuLayoutTypes } from "../../constants/definitions";
import useAppSelector from "../../hooks/useAppSelector";
import {
  TemplatePageCategoryDetail,
  homePageDataModel,
} from "../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import isMobile from "../../utils/isMobile";
import { useRetailHelper } from "../../utils/useRetailHelper";
import HorizontalCardBlock from "./HorizontalCardBlock";
import HorizontalMegaMenu from "./HorizontalMegaMenu";
import MobileMegaMenu from "./MobileMegaMenu";
import VerticalCardBlockMegaM from "./VerticalCardBlockMegaM";
import VerticalMegaMenu from "./VerticalMegaMenu";
import VerticalWithHorizontalCardBlock from "./VerticalWithHorizontalCardBlock";

interface MenuItemType {
  key: React.Key;
  label: React.ReactNode;
  onClick?: () => void;
}
interface MegaMenuProProps {
  item: TemplatePageCategoryDetail;
  homePageDataModel?: homePageDataModel;
}

const MegaMenuPro: React.FC<MegaMenuProProps> = ({
  item,
  homePageDataModel,
}) => {
  const { handleRedirectMegaMenu } = useRetailHelper();
  const [isMobileMegaMenuOpen, setIsMobileMegaMenuOpen] =
    useState<boolean>(false);

  const { megaMenuDataModel } = useAppSelector((state) => state.megaMenu);

  return (
    <div key={item.id} className="shadow-sm">
      {" "}
      <header>
        <button
          className="navbar-toggler d-block d-md-none"
          type="button"
          onClick={() => setIsMobileMegaMenuOpen(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            fill="#fff"
            className="bi bi-text-indent-left text-primary"
            viewBox="0 0 16 16"
          >
            <path d="M2 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm.646 2.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L4.293 8 2.646 6.354a.5.5 0 0 1 0-.708zM7 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
          </svg>
        </button>

        <div className="main_nav mega-menu-navbar bg-megamenu ">
          <nav className="navbar navbar-expand-lg  bg-megamenu p-0">
            <div className="container">
              <div className="collapse navbar-collapse" id="navbar-content">
                <ul className="navbar-nav mx-5 mb-2 mb-lg-0 animate-dropdown">
                  {megaMenuDataModel
                    ?.find(
                      (megaMenu) =>
                        megaMenu.templateSectionIdentifier == item.identifier
                    )
                    ?.megaMenuWithChildrenAndCardsViewModel?.map(
                      (parentMegaMenu, index) => {
                        return (
                          <li
                            className="nav-item dropdown w-100 w-lg-auto dropdown-fullwidth"
                            key={parentMegaMenu.id}
                          >
                            <a
                              onClick={() =>
                                handleRedirectMegaMenu(
                                  parentMegaMenu.link ?? ""
                                )
                              }
                              className={`nav-link ${
                                parentMegaMenu.childrens.length > 0 &&
                                "dropdown-toggle"
                              } `}
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              data-bs-auto-close="outside"
                            >
                              {parentMegaMenu.imageUrl && (
                                <Avatar
                                  className="me-2"
                                  src={parentMegaMenu.imageUrl}
                                ></Avatar>
                              )}

                              {parentMegaMenu.name}
                            </a>
                            {parentMegaMenu.childMegaMenuType ==
                              MegaMenuLayoutTypes.Vertical && (
                              <VerticalMegaMenu
                                megaMenu={parentMegaMenu.childrens}
                              />
                            )}
                            {parentMegaMenu.childMegaMenuType ==
                              MegaMenuLayoutTypes.VerticalCardBlock && (
                              <VerticalCardBlockMegaM
                                megaMenu={parentMegaMenu.childrens}
                              />
                            )}
                            {parentMegaMenu.childMegaMenuType ==
                              MegaMenuLayoutTypes.HorizontalCardBlock && (
                              <HorizontalCardBlock
                                megaMenu={parentMegaMenu.childrens}
                              />
                            )}
                            {parentMegaMenu.childMegaMenuType ==
                              MegaMenuLayoutTypes.Horizontal && (
                              <HorizontalMegaMenu
                                megaMenu={parentMegaMenu.childrens}
                              />
                            )}
                            {parentMegaMenu.childMegaMenuType ==
                              MegaMenuLayoutTypes.VerticalWithHorizontalCardBlock && (
                              <VerticalWithHorizontalCardBlock
                                megaMenu={parentMegaMenu.childrens}
                              />
                            )}
                          </li>
                        );
                      }
                    )}
                </ul>
              </div>
            </div>
          </nav>
        </div>

        {/* END Mega Menu HTML */}
      </header>
      {isMobile && (
        <MobileMegaMenu
          megaMenu={
            megaMenuDataModel?.find(
              (megaMenu) =>
                megaMenu.templateSectionIdentifier == item.identifier
            )?.megaMenuWithChildrenAndCardsViewModel
          }
          isMobileMegaMenuOpen={isMobileMegaMenuOpen}
          setIsMobileMegaMenuOpen={setIsMobileMegaMenuOpen}
        />
      )}
    </div>
  );
};

export default MegaMenuPro;
