/** @format */

// import brotli from "brotli";
import React from "react";
import { Helmet } from "react-helmet";
import useAppSelector from "../../hooks/useAppSelector";
import LayoutContainer from "../layout/layout_container";
import CheckoutContainer from "./checkout_container";

const CheckoutTest: React.FC = () => {
  const { homePageDataModel } = useAppSelector((state) => state.homePage);

  return (
    <>
      <Helmet>
        <title>{homePageDataModel?.storeDetails?.storeName}</title>
        <meta
          property="og:title"
          content={homePageDataModel?.storeDetails?.storeName}
        />
        <meta
          property="og:description"
          content={homePageDataModel?.storeDetails?.storeName}
        />
        <meta
          property="og:image"
          content={homePageDataModel?.storeDetails?.imageUrl}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <LayoutContainer>
        <CheckoutContainer />
      </LayoutContainer>
    </>
  );
};

export default CheckoutTest;
