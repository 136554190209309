/** @format */
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import moment from "moment";
import { homePageDataModel } from "../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import MegaMenu from "../mega_menu/MegaMenu";
import AboutUs from "./home_section_components/AboutUs";
import AdsWithContents from "./home_section_components/AdsWithContents";
import Banner1 from "./home_section_components/Banner1";
import Banner2 from "./home_section_components/Banner2";
import Banner3 from "./home_section_components/Banner3";
import Banner4 from "./home_section_components/Banner4";
import CollageAds from "./home_section_components/CollageAds";
import DiscountProducts from "./home_section_components/DiscountProducts";
import FeaturedProducts from "./home_section_components/FeaturedProducts";
import GeneralDealsSection from "./home_section_components/GeneralDealSection";
import MultipleLinearAds from "./home_section_components/MultipleLinearAds";
import MultipleLinearAds2 from "./home_section_components/MultipleLinearAds2";
import NewArrival from "./home_section_components/NewArrival";
import ProductsMultiSection from "./home_section_components/ProductsMultiSection";
import ProductsWithAdsImage from "./home_section_components/ProductsWithAdsImage";
import SingleCategoryWithProducts from "./home_section_components/SingleCategoryWithProducts";
import SingleLinearAds from "./home_section_components/SingleLinearAds";
import StockClearance from "./home_section_components/StockClearance";
import TopBrands from "./home_section_components/TopBrands";
import TopCategories from "./home_section_components/TopCategories";
import TopRatedProducts from "./home_section_components/TopRatedProducts";

interface HomeSectionProps {
  categoriesSlickSettings: any;
  categoriesSlickSettings1: any;
  homePageDataModel?: homePageDataModel;
}

const HomeSection: React.FC<HomeSectionProps> = ({
  categoriesSlickSettings,
  categoriesSlickSettings1,
  homePageDataModel,
}) => {
  return (
    <>
      <div className="mainbody">
        {homePageDataModel?.templatePageDetails
          ?.find((item) => item.isDefault)
          ?.pageDetails?.templatePageCategoryDetails?.map((item) => {
            switch (item.identifier) {
              case "megaMenu1":
                return (
                  <MegaMenu
                    key={item.id}
                    item={item}
                    homePageDataModel={homePageDataModel}
                  />
                );

              case "4":
                return <Banner1 key={item.id} item={item} />;
              case "7":
                return (
                  <TopCategories
                    key={item.id}
                    item={item}
                    categoriesSlickSettings1={categoriesSlickSettings1}
                  />
                );
              case "5":
                return <Banner2 key={item.id} item={item} />;
              case "6":
                return (
                  <Banner3
                    key={item.id}
                    item={item}
                    homePageDataModel={homePageDataModel}
                  />
                );
              case "ban4":
                return (
                  <Banner4
                    key={item.id}
                    item={item}
                    homePageDataModel={homePageDataModel}
                  />
                );
              case "8":
                return (
                  <NewArrival
                    key={item.id}
                    item={item}
                    categoriesSlickSettings1={categoriesSlickSettings1}
                  />
                );
              case "9":
                return <FeaturedProducts key={item.id} item={item} />;
              case "10":
                return <TopRatedProducts key={item.id} item={item} />;
              case "11":
                return (
                  <DiscountProducts
                    key={item.id}
                    item={item}
                    categoriesSlickSettings1={categoriesSlickSettings1}
                  />
                );
              case "12":
                return (
                  <SingleCategoryWithProducts
                    key={item.id}
                    item={item}
                    categoriesSlickSettings1={categoriesSlickSettings1}
                  />
                );
              case "13":
                return <ProductsWithAdsImage key={item.id} item={item} />;
              case "14":
                return (
                  <ProductsMultiSection
                    key={item.id}
                    item={item}
                    categoriesSlickSettings1={categoriesSlickSettings1}
                  />
                );
              case "18":
                return (
                  <StockClearance
                    key={item.id}
                    item={item}
                    categoriesSlickSettings1={categoriesSlickSettings1}
                  />
                );
              case "19":
                return <SingleLinearAds key={item.id} item={item} />;
              case "20":
                return <MultipleLinearAds key={item.id} item={item} />;
              case "21":
                return <MultipleLinearAds2 key={item.id} item={item} />;
              case "17":
                if (
                  moment(
                    item.templatePageCategorySectionDetails &&
                      item.templatePageCategorySectionDetails[0]?.dateTo
                  ).diff(moment()) > 0
                ) {
                  return <GeneralDealsSection key={item.id} item={item} />;
                }
                return null; // Return null if the condition isn't met
              case "22":
                if (
                  item.templatePageCategorySectionDetails &&
                  item.templatePageCategorySectionDetails.length > 0
                ) {
                  return <CollageAds key={item.id} item={item} />;
                }
                return null; // Return null if the condition isn't met

              case "23":
                return <TopBrands key={item.id} item={item} />;
              case "27":
                return <AdsWithContents key={item.id} item={item} />;

              case "aboutus":
                return <AboutUs key={item.id} item={item} />;

              default:
                return null; // Return null for unknown identifiers
            }
          })}
      </div>
    </>
  );
};

export default HomeSection;
