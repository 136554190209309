/** @format */

import { Button } from "antd";
import moment from "moment";
import React from "react";
import ActionTypes from "../../../constants/action_types";
import ProductSingleContainer from "../../../containers/product_single_container/product_single_container";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { QueryString } from "../../../redux/reducers/FilterProductsReducer/FilterProductsInterface";
import { TemplatePageCategoryDetail } from "../../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import { useApiCalls } from "../../../utils/useApiCalls";
import CountdownComponent from "../Countdown";

/** @format */
interface GeneralDealsSectionProps {
  item: TemplatePageCategoryDetail;
}

const GeneralDealsSection: React.FC<GeneralDealsSectionProps> = ({ item }) => {
  const { getAllFilterProducts } = useApiCalls();
  const dispatch = useAppDispatch();
  return (
    <section className=" pt-50 " key={item.id}>
      <div className="flashsale_product">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-xs-12 text-center justify-content-center align-items-center d-flex bg-theme">
              <div className="product-flash-list">
                <h5>Flash Sale</h5>
                <div id="productcountdown1" className="productcountdown">
                  <div className="countdown-text">The Offer Ends In:</div>
                </div>
                <CountdownComponent
                  timeDifference={moment(
                    item.templatePageCategorySectionDetails &&
                      item.templatePageCategorySectionDetails[0]?.dateTo
                  ).diff(moment())}
                />
                <div className="button mt-4">
                  <Button
                    onClick={() => {
                      getAllFilterProducts({
                        type: item.type,
                        slug: item.slug,
                      });
                      dispatch({
                        type: ActionTypes.SET_QUERY_STRINGS,
                        payload: {
                          type: item.type,
                          slug: item.slug,
                        } as QueryString,
                      });
                    }}
                    type="default"
                  >
                    View More
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row bg-white p-3 border">
                {item.templatePageCategorySectionDetails &&
                  item.templatePageCategorySectionDetails[0]?.products
                    ?.slice(0, 4)
                    ?.map((item) => {
                      return (
                        <div key={item.id} className="col-lg-3 col-md-6 col-6">
                          <ProductSingleContainer product={item} />
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GeneralDealsSection;
