/** @format */

import { useEffect } from "react";
import useAppSelector from "../../hooks/useAppSelector";
import Wishlist from "../../pages/wishlist/Wishlist";
import { useApiCalls } from "../../utils/useApiCalls";

const WishlistContainer = () => {
  const { getAllWishlistsProduct } = useApiCalls();
  const { wishlistDetails, isLoading, isWishlistChanged } = useAppSelector(
    (state) => state.wishlist
  );

  useEffect(() => {
    if (!wishlistDetails) {
      getAllWishlistsProduct();
    }
  }, []);

  return <Wishlist wishlistDetails={wishlistDetails} isLoading={isLoading} />;
};

export default WishlistContainer;
