/** @format */

import nProgress from "nprogress";

import ActionTypes from "../../../constants/action_types";
import FilterProductsInterface from "./FilterProductsInterface";

/** @format */

const initialState: FilterProductsInterface = {
  isLoading: false,
  shouldLockFilterOptions: false,
  shouldClearCheckedFilters: false,
};

const reducer = (
  state = initialState,
  action: any
): FilterProductsInterface => {
  switch (action.type) {
    case ActionTypes.GET_ALL_PRODUCTS_REQUEST:
      // Preloading the code of filter product screen

      nProgress.start();
      return {
        ...state,
        isLoading: true,
        isFilterProductFetchSuccesful: false,
        error: null,
      };
    case ActionTypes.GET_ALL_PRODUCTS_SUCCESS:
      nProgress.done();
      return {
        ...state,
        isLoading: false,
        filterProductsDataModel: state.shouldLockFilterOptions
          ? {
              ...state.filterProductsDataModel,
              productList: action.payload.data?.productList,
              imageAds: action.payload.data?.imageAds,
            }
          : action.payload.data,
        error: null,
        isFilterProductFetchSuccesful: true,

        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.GET_ALL_PRODUCTS_FAILURE:
      nProgress.done();
      return {
        ...state,
        isLoading: false,
        isFilterProductFetchSuccesful: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.RESET_FILTER_PRODUCT_STATE:
      return {
        ...state,
        isFilterProductFetchSuccesful: false,
        error: null,
      };
    case ActionTypes.SET_QUERY_STRINGS:
      return {
        ...state,
        queryStrings: action.payload,
      };
    case ActionTypes.LOCK_FILTER_OPTIONS:
      return {
        ...state,
        shouldLockFilterOptions: true,
      };
    case ActionTypes.UNLOCK_FILTER_OPTIONS:
      return {
        ...state,
        shouldLockFilterOptions: false,
      };
    case ActionTypes.SHOULD_CLEAR_CHECKED_FILTERS:
      return {
        ...state,
        shouldClearCheckedFilters: true,
      };
    case ActionTypes.SHOULD_NOT_CLEAR_CHECKED_FILTERS:
      return {
        ...state,
        shouldClearCheckedFilters: false,
      };
    default:
      return state;
  }
};

export default reducer;
