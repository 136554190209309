import React from "react";
import { useRetailHelper } from "../../utils/useRetailHelper";
import useAppSelector from "../../hooks/useAppSelector";
import {
  TemplatePageCategoryDetail,
  homePageDataModel,
} from "../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import { useApiCalls } from "../../utils/useApiCalls";
import ActionTypes from "../../constants/action_types";
import { QueryString } from "../../redux/reducers/FilterProductsReducer/FilterProductsInterface";
import useAppDispatch from "../../hooks/useAppDispatch";
import LazyImage from "../../utils/Lazy Image/LazyImage";
import { Avatar } from "antd";

interface MegaMenuHeaderCategoryProps {
  item: TemplatePageCategoryDetail;
  homePageDataModel?: homePageDataModel;
}

const MegaMenuHeaderCategoryPopup: React.FC<MegaMenuHeaderCategoryProps> = ({
  item,
  homePageDataModel,
}) => {
  const { handleRedirectMegaMenu } = useRetailHelper();
  const { megaMenuDataModel } = useAppSelector((state) => state.megaMenu);
  const { getAllFilterProducts } = useApiCalls();
  const dispatch = useAppDispatch();

  return (
    <nav
      className="navbar everestheader navbar-expand-lg navbar-light navbar-default bg-white"
      aria-label="Offcanvas navbar large"
    >
      <div className="container">
        <div
          className="offcanvas offcanvas-start"
          tabIndex={-1}
          id="navbar-default"
          aria-labelledby="navbar-defaultLabel"
        >
          <div className="offcanvas-header pb-1">
            {/* <a href="">
              <img
                src="assets/images/elogo.webp"
                alt="eCommerce HTML Template"
              />
            </a> */}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            />
          </div>
          <div className="offcanvas-body align-items-center">
            <div className="d-block d-lg-none mb-4">
              <a
                className="btn  w-100 d-flex justify-content-center align-items-center  bg-everest text-white"
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <span className="me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="fas fa-grid"
                  >
                    <rect x={3} y={3} width={7} height={7} />
                    <rect x={14} y={3} width={7} height={7} />
                    <rect x={14} y={14} width={7} height={7} />
                    <rect x={3} y={14} width={7} height={7} />
                  </svg>
                </span>{" "}
                All Categories
              </a>
              <div className="collapse mt-2" id="collapseExample">
                <div className="card card-body">
                  <ul className="mb-0 list-unstyled">
                    {homePageDataModel?.productCategories?.map(
                      (item, index) => {
                        return (
                          <li key={index} className="d-flex mb-1 ">
                            <div className="flex-shrink-1">
                              {" "}
                              {item.imageUrl && (
                                <img
                                  src={item.imageUrl}
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "100%",
                                  }}
                                />
                              )}
                            </div>
                            <div className="flex-grow-1">
                              {" "}
                              <a
                                onClick={() => {
                                  getAllFilterProducts({
                                    categoryIds: [`${item.slug}`],
                                  });
                                  dispatch({
                                    type: ActionTypes.SET_QUERY_STRINGS,
                                    payload: {
                                      categoryIds: [`${item.slug}`],
                                    } as QueryString,
                                  });
                                }}
                                className="dropdown-item"
                              >
                                {item.name}
                              </a>
                            </div>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="dropdown me-3 d-none d-lg-block">
              <button
                className="btn bg-everest px-6 text-white fw-bold"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="me-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-grid"
                  >
                    <rect x={3} y={3} width={7} height={7} />
                    <rect x={14} y={3} width={7} height={7} />
                    <rect x={14} y={14} width={7} height={7} />
                    <rect x={3} y={14} width={7} height={7} />
                  </svg>
                </span>
                All Categories
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
                style={{
                  maxHeight: "500px",
                  overflowY: "scroll",
                  minWidth: "250px",
                }}
              >
                {homePageDataModel?.productCategories?.map((item, index) => {
                  return (
                    <li key={index} className="d-flex mb-1 ">
                      <div className="flex-shrink-1">
                        {" "}
                        {item.imageUrl && (
                          <img
                            src={item.imageUrl}
                            style={{
                              height: "30px",
                              width: "30px",
                              borderRadius: "100%",
                            }}
                          />
                        )}
                      </div>
                      <div className="flex-grow-1">
                        {" "}
                        <a
                          onClick={() => {
                            getAllFilterProducts({
                              categoryIds: [`${item.slug}`],
                            });
                            dispatch({
                              type: ActionTypes.SET_QUERY_STRINGS,
                              payload: {
                                categoryIds: [`${item.slug}`],
                              } as QueryString,
                            });
                          }}
                          className="dropdown-item"
                        >
                          {item.name}
                        </a>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div>
              <ul className="navbar-nav align-items-center ">
                {megaMenuDataModel
                  ?.find(
                    (megaMenu) =>
                      megaMenu.templateSectionIdentifier == item.identifier
                  )
                  ?.megaMenuWithChildrenAndCardsViewModel?.map(
                    (parentMegaMenu, index) => {
                      return (
                        <li
                          className="nav-item w-100 w-lg-auto "
                          key={index}
                          onClick={() =>
                            handleRedirectMegaMenu(parentMegaMenu.link ?? "")
                          }
                        >
                          <a className="nav-link fw-bold">
                            {parentMegaMenu.name}
                          </a>
                        </li>
                      );
                    }
                  )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MegaMenuHeaderCategoryPopup;
