/** @format */

import nProgress from "nprogress";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import PageNotFound from "../components/not_found/PageNotFound";
import Protected from "../components/protected_route/ProtectedRoute";
import WholeScreenLoader from "../components/whole_screen_loader/whole_screen_loader";
import ActionTypes from "../constants/action_types";
import { themeColorDetails } from "../constants/definitions";
import Routes_Constants from "../constants/routes_configs";

import ChangePasswordContainer from "../containers/change-password-container/change-password-container";
import ChangePasswordProfileContainer from "../containers/change-password-profile-container/chnge-password-profile-container";
import CheckoutTest from "../containers/checkout_container/checkout_cont_test";
import FilterTest from "../containers/filter_products_container/filterproduct-cont";
import GiftCardTest from "../containers/gift_cart_container/gift_card_cont_test";
import MakePaymentTest from "../containers/make_payment_container/make_payment_cont_test";
import OrderHistoryContainer from "../containers/order-history-container/order-history-container";
import ProductDetailsTest from "../containers/product_details_container/product_details_cont_test";
import ProfileContainer from "../containers/profile_container/profile_container";
import WishlistContainer from "../containers/wishlist-container/wishlist_container";
import useAppDispatch from "../hooks/useAppDispatch";
import useAppSelector from "../hooks/useAppSelector";
import ErrorPage from "../pages/error-page/ErrorPage";
import Home from "../pages/home";
import { getCurrentLocation } from "../utils/getCurrentLocation";
import isMobile from "../utils/isMobile";
import applyThemeColorDetails from "../utils/loadAppColor";

const RoutesConfig: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    homePageDataModel,
    getHomePageDataLoading,
    initializeStoreDataModel,
    isLoading,
    error,
  } = useAppSelector((state) => state.homePage);
  const { url } = getCurrentLocation();
  const [count, setCount] = useState(0);

  useEffect(() => {
    nProgress.configure({
      minimum: 0.7,
      easing: "ease",
      speed: 600,
    });
  }, []);

  // useEffect(() => {
  //   if (isLoading) {
  //     nProgress.start();
  //   } else {
  //     nProgress.done();
  //   }
  // }, [isLoading]);

  useEffect(() => {
    var head = document.head;
    var link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = isMobile ? "mobile.css" : "style.css";

    head.appendChild(link);
    dispatch({
      type: ActionTypes.INITIALIZE_STORE_REQUEST,
      payload: {
        // StoreId: "A697F7FA-B96A-4103-9A09-525844AE5B89",
        url: url,
      },
    });
  }, []);
  useEffect(() => {
    if (initializeStoreDataModel?.storeId) {
      dispatch({
        type: ActionTypes.GET_LANDING_PAGE_DATA_REQUEST,
        payload: {
          StoreId: initializeStoreDataModel.storeId,
        },
      });
      dispatch({
        type: ActionTypes.GET_STORE_DETAILS_REQUEST,
        payload: {
          StoreId: initializeStoreDataModel.storeId,
        },
      });

      dispatch({
        type: ActionTypes.GET_ALL_CATEGORIES_REQUEST,
        payload: {
          StoreId: initializeStoreDataModel.storeId,
        },
      });
      dispatch({
        type: ActionTypes.GET_ALL_MEGA_MENUS_REQUEST,
        payload: {
          StoreId: initializeStoreDataModel.storeId,
        },
      });
    }
  }, [initializeStoreDataModel?.storeId]);
  useEffect(() => {
    if (count < 3) {
      setTimeout(() => {
        setCount((prev) => prev + 1);
      }, 1000);
    }
  }, [count]);

  useEffect(() => {
    // Check if initializeStoreDataModel is valid and contains an imageUrl property
    if (initializeStoreDataModel && initializeStoreDataModel.favIconImageUrl) {
      let link = document.querySelector(
        "link[rel~='icon']"
      ) as HTMLLinkElement | null;
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      if (link) {
        link.href = initializeStoreDataModel.favIconImageUrl;
      }
    }
  }, [initializeStoreDataModel]);

  useEffect(() => {
    if (homePageDataModel?.storeDetails?.onlineThemeColorDetails) {
      applyThemeColorDetails(themeColorDetails, homePageDataModel);
    }
  }, [homePageDataModel?.storeDetails?.onlineThemeColorDetails]);

  if (error) {
    return <ErrorPage error={error} />;
  }

  const CheckoutContainer = lazy(
    () => import("../containers/checkout_container/checkout_container")
  );
  const FilterProductsContainer = lazy(
    () =>
      import(
        "../containers/filter_products_container/filter_products_container"
      )
  );
  const ForgetPasswordContainer = lazy(
    () =>
      import(
        "../containers/forget-password-container/forget-password-container"
      )
  );
  const GiftCardContainer = lazy(
    () => import("../containers/gift_cart_container/gift_card_container")
  );
  const LoginContainer = lazy(
    () => import("../containers/login_container/login_container")
  );
  const MakePaymentContainer = lazy(
    () => import("../containers/make_payment_container/make_payment_container")
  );
  const ProductDetailsContainer = lazy(
    () =>
      import(
        "../containers/product_details_container/product_details_container"
      )
  );
  const RegisterContainer = lazy(
    () => import("../containers/register_container/register_container")
  );
  const SendOtpContainer = lazy(
    () => import("../containers/send_otp_container/send_otp_container")
  );
  const PageDetailContainer = lazy(
    () => import("../containers/page_detail_container/page_detail_container")
  );

  const ContactUsContainer = lazy(
    () => import("../containers/contact_us_container/contact_us_container")
  );
  const BlogContainer = lazy(
    () => import("../containers/blog_container/blog_container")
  );
  const BlogDetail = lazy(() => import("../pages/blog_detail/BlogDetail"));

  return (
    <>
      <Helmet>
        <title>{initializeStoreDataModel?.storeName}</title>
      </Helmet>
      {getHomePageDataLoading && initializeStoreDataModel ? (
        <WholeScreenLoader initializeDataModel={initializeStoreDataModel} />
      ) : (
        <>
          {homePageDataModel && (
            <Router>
              <Suspense fallback={null}>
                <Routes>
                  <Route path={Routes_Constants.HOME} element={<Home />} />
                  <Route
                    path={Routes_Constants.AUTHENTICATE}
                    element={<LoginContainer />}
                  />
                  <Route
                    path={Routes_Constants.REGISTER}
                    element={<RegisterContainer />}
                  />
                  <Route
                    path={Routes_Constants.SEND_OTP}
                    element={<SendOtpContainer />}
                  />
                  <Route
                    path={Routes_Constants.FORGET_PASSWORD}
                    element={<ForgetPasswordContainer />}
                  />
                  <Route
                    path={Routes_Constants.CHANGE_PASSWORD}
                    element={<ChangePasswordContainer />}
                  />
                  <Route
                    path={Routes_Constants.CHANGE_PASSWORD_PROFILE}
                    element={<ChangePasswordProfileContainer />}
                  />
                  <Route
                    path={Routes_Constants.PROFILE}
                    element={
                      <Protected>
                        <ProfileContainer />
                      </Protected>
                    }
                  />
                  <Route
                    path={Routes_Constants.ORDER}
                    element={
                      <Protected>
                        {" "}
                        <OrderHistoryContainer />
                      </Protected>
                    }
                  />
                  <Route
                    path={Routes_Constants.WISHLIST}
                    element={
                      <Protected>
                        <WishlistContainer />
                      </Protected>
                    }
                  />
                  <Route
                    path={Routes_Constants.GIFT_CARD}
                    element={<GiftCardTest />}
                  />

                  <Route
                    path={Routes_Constants.FILTER_PRODUCTS}
                    element={<FilterTest />}
                  />
                  <Route
                    path={Routes_Constants.CHECKOUT}
                    element={<CheckoutTest />}
                  />
                  <Route
                    path={Routes_Constants.MAKE_PAYMENT}
                    element={
                      <Protected isMakePaymentRoute={true}>
                        {" "}
                        <MakePaymentTest />
                      </Protected>
                    }
                  />

                  <Route
                    path={Routes_Constants.CONTACT_US}
                    element={<ContactUsContainer />}
                  />
                  <Route
                    path={Routes_Constants.PRODUCT_DETAILS}
                    element={<ProductDetailsTest />}
                  />
                  <Route
                    path={Routes_Constants.PAGE_DETAILS}
                    element={<PageDetailContainer />}
                  />
                  <Route
                    path={Routes_Constants.BLOG}
                    element={<BlogContainer />}
                  />
                  <Route
                    path={Routes_Constants.BLOG_DETAIL}
                    element={<BlogDetail />}
                  />
                  <Route path={"*"} element={<PageNotFound />} />
                </Routes>
              </Suspense>
            </Router>
          )}
        </>
      )}
    </>
  );
};

export default RoutesConfig;
