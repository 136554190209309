/** @format */

import {
  Button,
  Checkbox,
  Drawer,
  Input,
  Radio,
  Rate,
  Select,
  Tabs,
  Tooltip,
  message,
} from "antd";
import { Dispatch, SetStateAction } from "react";
import ReactImageMagnify from "react-image-magnify";
import CustomBreadcrumb from "../../components/custom_breadcrump/CustomBreadcrumb";
import PageLoader from "../../components/page_loader/PageLoader";
import ActionTypes from "../../constants/action_types";
import {
  ADDED_TO_CART_MESSAGE,
  ANIMATION_CLASS,
  UNAUTHORIZED_WISHLIST_MESSAGE,
} from "../../constants/definitions";
import Routes_Constants from "../../constants/routes_configs";
import ProductSingleContainer from "../../containers/product_single_container/product_single_container";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { SnackbarHandler } from "../../hooks/useSnackbar";
import {
  FilterTypeOption,
  PriceVariation,
  ProductFilterTypeFilterOption,
  ProductImage,
  ProductPriceVariationModifiers,
  productDetailsDataModel,
} from "../../redux/reducers/ProductDetailsReducer/ProductDetailsInterface";
import useProtectedNavigation from "../../utils/handleProtectedNavigation";
import { useApiCalls } from "../../utils/useApiCalls";
import { useRetailHelper } from "../../utils/useRetailHelper";

interface ProductDetailsProps {
  productDetailsDataModel?: productDetailsDataModel;
  activeImage?: ProductImage;
  onActiveImageChange?: (image: ProductImage) => void;
  activePriceVariation?: PriceVariation;
  onActivePriceVariationChange?: (priceVariation: PriceVariation) => void;
  showSnackbar: SnackbarHandler;
  quantity: number;
  setQuantity: Dispatch<SetStateAction<number>>;
  filterTypeFilterOptions: ProductFilterTypeFilterOption[];
  setFilterTypeFilterOptions: Dispatch<
    SetStateAction<ProductFilterTypeFilterOption[]>
  >;
  activeFilterOption: FilterTypeOption | undefined;
  setActiveFilterOption: Dispatch<SetStateAction<FilterTypeOption | undefined>>;
  isFilterOptionDrawerOpen: boolean;
  setIsFilterOptionDrawerOpen: Dispatch<SetStateAction<boolean>>;
  selectedPriceModifiers: ProductPriceVariationModifiers[] | [];
  setSelectedPriceModifiers: Dispatch<
    SetStateAction<ProductPriceVariationModifiers[] | []>
  >;
  customDescription?: string;
  setCustomDescription?: Dispatch<SetStateAction<string>>;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({
  productDetailsDataModel,
  activeImage,
  onActiveImageChange,
  activePriceVariation,
  onActivePriceVariationChange,
  showSnackbar,
  quantity,
  setQuantity,
  filterTypeFilterOptions,
  setFilterTypeFilterOptions,
  activeFilterOption,
  setActiveFilterOption,
  isFilterOptionDrawerOpen,
  setIsFilterOptionDrawerOpen,
  selectedPriceModifiers,
  setSelectedPriceModifiers,
  customDescription,
  setCustomDescription,
}) => {
  const {
    openCartPopover,
    addProductToCart,
    currencySymbol,
    totalPriceOfModifiers,
    isProductInWishlist,
  } = useRetailHelper();
  const { getProductDetailBySlug, deleteWishlistProduct } = useApiCalls();
  const { isAddToCartPopupOpen } = useAppSelector((state) => state.common);
  // Calculate the total price of selected modifiers
  const productUrl = window.location.href;
  const [handleProtectedNavigation] = useProtectedNavigation();
  const { wishlistDetails } = useAppSelector((state) => state.wishlist);
  const dispatch = useAppDispatch();

  console.log("---filtter type filter options", filterTypeFilterOptions);

  return (
    <>
      {/* <Seo
        title={productDetailsDataModel?.name}
        description={
          productDetailsDataModel?.description
            ? productDetailsDataModel?.description
            : productDetailsDataModel?.name
        }
        ogImage={
          productDetailsDataModel?.productImages?.find((item) => item.isDefault)
            ?.imageUrl
        }
        ogUrl={window.location.href}
      /> */}

      <Drawer
        open={isFilterOptionDrawerOpen}
        onClose={() => {
          setIsFilterOptionDrawerOpen(false);
        }}
        title={productDetailsDataModel?.name}
      >
        {activeFilterOption && activeFilterOption.description && (
          <div
            dangerouslySetInnerHTML={{
              __html: activeFilterOption.description,
            }}
          />
        )}
        {activeFilterOption && activeFilterOption.imageUrl && (
          <img
            src={activeFilterOption.imageUrl}
            alt={activeFilterOption.name}
          />
        )}
      </Drawer>
      <PageLoader loading={!productDetailsDataModel}>
        <section className="category">
          <div className="container bg-white p-3">
            {!isAddToCartPopupOpen && (
              <CustomBreadcrumb
                items={[
                  {
                    title: "Products" ?? "",
                    link: Routes_Constants.FILTER_PRODUCTS,
                  },
                  { title: productDetailsDataModel?.name ?? "", link: "" },
                ]}
              />
            )}

            <div
              className="quickviewnow"
              data-aos={isAddToCartPopupOpen ? ANIMATION_CLASS : ""}
            >
              <div className="row">
                <div className="col-md-7">
                  <div className="product-gallery product-gallery-vertical">
                    <figure className="product-main-image order-sm-2 w-100">
                      <ReactImageMagnify
                        {...{
                          smallImage: {
                            alt: "Wristwatch by Ted Baker London",
                            isFluidWidth: true,
                            src:
                              activeImage?.imageUrl ??
                              "assets/images/error_Lazy/Loading.png",
                          },
                          largeImage: {
                            src:
                              activeImage?.imageUrl ??
                              "assets/images/error_Lazy/Loading.png", // Replace with the URL of your product image
                            width: 1200, // Adjust the width of the zoomed-in image as per your requirements
                            height: 800, // Adjust the height of the zoomed-in image as per your requirements
                          },
                          enlargedImageContainerStyle: {
                            zIndex: 9999,
                          },
                          enlargedImageContainerDimensions: {
                            width: "100%",
                            height: "100%",
                          },
                          magnifierStyle: {
                            borderColor: "red", // Customize the border color of the focus part
                            borderWidth: 2, // Adjust the border width of the focus part
                            shape: "square", // Make the focus part square
                          },
                        }}
                      />
                    </figure>
                    {/* End .product-main-image */}
                    <div
                      id="product-zoom-gallery"
                      className="product-image-gallery order-sm-1 "
                    >
                      <div className=" product_thumb">
                        {productDetailsDataModel?.productImages?.map(
                          (item: ProductImage, index) => {
                            return (
                              <a
                                key={item.id}
                                className="product-gallery-item active cursor-pointer"
                                onClick={() => {
                                  if (onActiveImageChange) {
                                    onActiveImageChange(item);
                                  }
                                }}
                              >
                                <img src={item.imageUrl} alt="Product Image" />
                              </a>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* End .col-md-6 */}
                <div className="col-md-5">
                  <div className="product_details">
                    <div className="product_description">
                      <div className="product_tag">
                        <ul className="list-unstyled list-inline">
                          <li className="list-inline-item">
                            <a>{productDetailsDataModel?.categoryName}</a>
                          </li>
                        </ul>
                      </div>
                      <div className="product_name">
                        <p>{productDetailsDataModel?.name}</p>
                      </div>
                      {!isAddToCartPopupOpen &&
                        productDetailsDataModel?.description && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: productDetailsDataModel?.description,
                            }}
                          />
                        )}
                      {!isAddToCartPopupOpen &&
                        productDetailsDataModel?.ratings && (
                          <div className="product_ratings">
                            <ul className="list-unstyled list-inline">
                              <Rate
                                disabled
                                defaultValue={
                                  parseInt(
                                    productDetailsDataModel?.ratings ?? "0"
                                  ) ?? 0
                                }
                              />
                              <li className="list-inline-item">
                                <p>
                                  ( {productDetailsDataModel?.ratings ?? 0}{" "}
                                  Ratings )
                                </p>
                              </li>
                            </ul>
                          </div>
                        )}

                      <div className="product_price">
                        <ul className="list-unstyled list-inline m-0">
                          {productDetailsDataModel?.enablePriceRange ? (
                            <li className="text-decoration-none fs-5 text-theme fw-bold">
                              {productDetailsDataModel.priceRange}
                            </li>
                          ) : (
                            <>
                              <li>
                                {currencySymbol()}{" "}
                                {totalPriceOfModifiers(selectedPriceModifiers) +
                                  parseFloat(
                                    activePriceVariation?.discountedPrice || "0"
                                  )}
                              </li>
                              {parseFloat(
                                activePriceVariation?.discountPercentage ?? ""
                              ) > 0 && (
                                <li
                                  style={{
                                    fontSize: 15,
                                    textDecoration: "line-through",
                                  }}
                                >
                                  {currencySymbol()}{" "}
                                  {activePriceVariation?.actualPrice}
                                </li>
                              )}
                            </>
                          )}
                        </ul>

                        {activePriceVariation?.stockCount && (
                          <p>
                            Availability : <span>In Stock</span>{" "}
                            <label>
                              ({activePriceVariation?.stockCount} Available)
                            </label>
                          </p>
                        )}
                      </div>
                      <div className="product_color_size">
                        <div className="size">
                          {activePriceVariation && (
                            <ul className="list-unstyled list-inline display-inline">
                              <li>
                                {
                                  productDetailsDataModel
                                    ?.productPriceVariations?.labelName
                                }{" "}
                                :
                              </li>
                              <Radio.Group
                                defaultValue={activePriceVariation?.id}
                                buttonStyle="outline"
                              >
                                {productDetailsDataModel?.productPriceVariations?.priceVariations?.map(
                                  (item, index) => {
                                    return (
                                      <Radio.Button
                                        onClick={() => {
                                          if (onActivePriceVariationChange) {
                                            onActivePriceVariationChange(item);
                                          }
                                        }}
                                        key={item.id}
                                        value={item.id}
                                      >
                                        {item.name}
                                      </Radio.Button>
                                    );
                                  }
                                )}
                              </Radio.Group>
                            </ul>
                          )}
                        </div>
                        {activePriceVariation?.productPriceVariationModifiers &&
                          activePriceVariation?.productPriceVariationModifiers
                            ?.length > 0 && (
                            <div className="size">
                              <ul className="list-unstyled list-inline display-inline">
                                <li>Modifiers :</li>
                                <Checkbox.Group
                                  onChange={(selectedValues) => {
                                    const selectedModifiers =
                                      activePriceVariation?.productPriceVariationModifiers?.filter(
                                        (item) =>
                                          selectedValues.includes(item.id)
                                      );
                                    setSelectedPriceModifiers(
                                      selectedModifiers || []
                                    );
                                  }}
                                  options={activePriceVariation?.productPriceVariationModifiers?.map(
                                    (item, index) => {
                                      return {
                                        label: (
                                          <span>
                                            {" "}
                                            {item.name} ({currencySymbol()}{" "}
                                            {item.price})
                                          </span>
                                        ),
                                        value: item.id,
                                      };
                                    }
                                  )}
                                ></Checkbox.Group>
                              </ul>
                            </div>
                          )}

                        <div className="color">
                          {filterTypeFilterOptions?.map(
                            (
                              parentFilterType: ProductFilterTypeFilterOption,
                              parentFilterTypeIndex
                            ) => {
                              return (
                                <ul
                                  className="list-unstyled list-inline mb-4"
                                  key={parentFilterType.id}
                                >
                                  <li className="fw-bold text-dark">
                                    {parentFilterType.name} :
                                  </li>
                                  {parentFilterType.childrens.length > 0 ? (
                                    <>
                                      <Select
                                        className="w-50"
                                        placeholder={parentFilterType.name}
                                        onChange={(e) => {
                                          setFilterTypeFilterOptions(
                                            filterTypeFilterOptions.map(
                                              (item, index) => {
                                                if (
                                                  index == parentFilterTypeIndex
                                                ) {
                                                  return {
                                                    ...item,
                                                    childrens:
                                                      item.childrens.map(
                                                        (iChild, index) => {
                                                          if (iChild.id == e) {
                                                            return {
                                                              ...iChild,
                                                              isChecked: true,
                                                            };
                                                          } else {
                                                            return {
                                                              ...iChild,
                                                              isChecked: false,
                                                            };
                                                          }
                                                        }
                                                      ),
                                                  };
                                                } else {
                                                  return item;
                                                }
                                              }
                                            )
                                          );
                                        }}
                                      >
                                        {parentFilterType.childrens?.map(
                                          (children, index) => {
                                            return (
                                              <Select.Option
                                                value={children.id}
                                                key={children.id}
                                              >
                                                {children.name}
                                              </Select.Option>
                                            );
                                          }
                                        )}
                                      </Select>
                                      {parentFilterType.childrens.find(
                                        (item) => item.isChecked
                                      )?.filterTypeOptions && (
                                        <div className="mt-3">
                                          {parentFilterType.childrens
                                            .find((item) => item.isChecked)
                                            ?.filterTypeOptions.map(
                                              (children, childIndexx) => {
                                                return (
                                                  <Checkbox
                                                    value={children.id}
                                                    checked={children.isChecked}
                                                    key={children.id}
                                                    onChange={(e) => {
                                                      setFilterTypeFilterOptions(
                                                        filterTypeFilterOptions.map(
                                                          (item, index) => {
                                                            if (
                                                              index ==
                                                              parentFilterTypeIndex
                                                            ) {
                                                              return {
                                                                ...item,
                                                                childrens:
                                                                  item.childrens.map(
                                                                    (
                                                                      childItem,
                                                                      childIndex
                                                                    ) => {
                                                                      if (
                                                                        parentFilterType.childrens.find(
                                                                          (
                                                                            item
                                                                          ) =>
                                                                            item.isChecked
                                                                        )?.id ==
                                                                        childItem.id
                                                                      ) {
                                                                        return {
                                                                          ...childItem,
                                                                          filterTypeOptions:
                                                                            childItem.filterTypeOptions.map(
                                                                              (
                                                                                childd,
                                                                                indexx
                                                                              ) => {
                                                                                if (
                                                                                  indexx ==
                                                                                  childIndexx
                                                                                ) {
                                                                                  return {
                                                                                    ...childd,
                                                                                    isChecked:
                                                                                      e
                                                                                        .target
                                                                                        .checked,
                                                                                  };
                                                                                } else {
                                                                                  if (
                                                                                    parentFilterType.selectionType ==
                                                                                    "0"
                                                                                  ) {
                                                                                    return {
                                                                                      ...childd,
                                                                                      isChecked:
                                                                                        false,
                                                                                    };
                                                                                  } else {
                                                                                    return {
                                                                                      ...childd,
                                                                                    };
                                                                                  }
                                                                                }
                                                                              }
                                                                            ),
                                                                        };
                                                                      } else {
                                                                        return childItem;
                                                                      }
                                                                    }
                                                                  ),
                                                              };
                                                            } else {
                                                              return item;
                                                            }
                                                          }
                                                        )
                                                      );
                                                    }}
                                                  >
                                                    {children.name}
                                                  </Checkbox>
                                                );
                                              }
                                            )}
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {parentFilterType.selectionType == "2" ? (
                                        <>
                                          {parentFilterType?.filterTypeOptions?.map(
                                            (item, index) => {
                                              return (
                                                <span
                                                  key={item.id}
                                                  onClick={() => {
                                                    if (
                                                      item.description ||
                                                      item.imageUrl
                                                    ) {
                                                      setActiveFilterOption(
                                                        item
                                                      );
                                                      setIsFilterOptionDrawerOpen(
                                                        true
                                                      );
                                                    }
                                                  }}
                                                >
                                                  {item.name}
                                                  <span className="me-1 ms-1">
                                                    {index <
                                                      parentFilterType
                                                        .filterTypeOptions
                                                        .length -
                                                        1 && "/"}
                                                  </span>
                                                </span>
                                              );
                                            }
                                          )}
                                        </>
                                      ) : (
                                        parentFilterType.filterTypeOptions?.map(
                                          (children, childIndex) => {
                                            return (
                                              <Checkbox
                                                value={children.id}
                                                key={children.id}
                                                checked={children.isChecked}
                                                onChange={(e) => {
                                                  setFilterTypeFilterOptions(
                                                    filterTypeFilterOptions.map(
                                                      (item, index) => {
                                                        if (
                                                          index ==
                                                          parentFilterTypeIndex
                                                        ) {
                                                          return {
                                                            ...item,
                                                            filterTypeOptions:
                                                              item.filterTypeOptions.map(
                                                                (
                                                                  item,
                                                                  index
                                                                ) => {
                                                                  if (
                                                                    index ==
                                                                    childIndex
                                                                  ) {
                                                                    return {
                                                                      ...item,
                                                                      isChecked:
                                                                        e.target
                                                                          .checked
                                                                          ? true
                                                                          : false,
                                                                    };
                                                                  } else {
                                                                    if (
                                                                      parentFilterType.selectionType ==
                                                                      "0"
                                                                    ) {
                                                                      return {
                                                                        ...item,
                                                                        isChecked:
                                                                          false,
                                                                      };
                                                                    }
                                                                    return item;
                                                                  }
                                                                }
                                                              ),
                                                          };
                                                        } else {
                                                          return item;
                                                        }
                                                      }
                                                    )
                                                  );
                                                }}
                                              >
                                                {children.name}
                                              </Checkbox>
                                            );
                                          }
                                        )
                                      )}
                                    </>
                                  )}
                                </ul>
                              );
                            }
                          )}
                        </div>

                        <div className="qty-box d-flex align-items-center gap-2">
                          <ul className="list-unstyled list-inline">
                            <li className="list-inline-item">Qty :</li>
                            <li className="list-inline-item quantity buttons_added">
                              <input
                                type="button"
                                defaultValue="-"
                                className="minus"
                                onClick={() => {
                                  if (quantity > 1) {
                                    setQuantity((prev) => prev - 1);
                                  }
                                }}
                              />
                              <input
                                value={quantity}
                                className="qty text"
                                readOnly
                              />
                              <input
                                onClick={() => {
                                  if (
                                    quantity + 1 >
                                      activePriceVariation?.stockCount &&
                                    activePriceVariation?.stockCount != null
                                  ) {
                                    message.error(
                                      `Stock is not available more than ${activePriceVariation?.stockCount}`
                                    );
                                  } else {
                                    setQuantity((prev) => prev + 1);
                                  }
                                }}
                                type="button"
                                defaultValue="+"
                                className="plus"
                              />
                            </li>
                          </ul>
                          <p>
                            {" "}
                            {productDetailsDataModel?.enablePriceRange && (
                              <>
                                <li className="fw-bold fs-5">
                                  {currencySymbol()}{" "}
                                  {totalPriceOfModifiers(
                                    selectedPriceModifiers
                                  ) +
                                    parseFloat(
                                      activePriceVariation?.discountedPrice ||
                                        "0"
                                    )}
                                </li>
                                {parseFloat(
                                  activePriceVariation?.discountPercentage ?? ""
                                ) > 0 && (
                                  <li
                                    style={{
                                      fontSize: 15,
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    {currencySymbol()}{" "}
                                    {activePriceVariation?.actualPrice}
                                  </li>
                                )}
                              </>
                            )}
                          </p>
                        </div>
                        {productDetailsDataModel?.productCustomFilterDescriptions?.map(
                          (item, index) => {
                            return (
                              <div
                                key={item.id}
                                className="qty-box d-flex mb-1"
                              >
                                <ul className="list-unstyled list-inline">
                                  <li className="list-inline-item">
                                    {item.name}
                                  </li>
                                </ul>
                                <div className="sizeguide">
                                  <p className="fw-normal">
                                    {item.description}
                                  </p>
                                </div>
                              </div>
                            );
                          }
                        )}
                        <div className="qty-box mb-1 mt-1">
                          <ul className="list-unstyled list-inline">
                            <li className="list-inline-item">Specifications</li>
                            <p>
                              <Input.TextArea
                                value={customDescription}
                                onChange={(e) => {
                                  if (setCustomDescription) {
                                    setCustomDescription(e.target.value);
                                  }
                                }}
                                placeholder="enter custom modifications if any"
                              />
                            </p>
                          </ul>
                        </div>
                      </div>

                      <div className="product_buttons">
                        <Button
                          type="primary"
                          onClick={() => {
                            // openCartPopover();
                            if (
                              quantity + 1 > activePriceVariation?.stockCount &&
                              activePriceVariation?.stockCount != null
                            ) {
                              message.error(`Stock is not available !`);
                              return;
                            }

                            showSnackbar(ADDED_TO_CART_MESSAGE, {
                              variant: "success",
                            });

                            addProductToCart({
                              productId: productDetailsDataModel?.id ?? "",
                              name: productDetailsDataModel?.name ?? "",
                              price:
                                Number(
                                  activePriceVariation?.discountPercentage
                                    ? activePriceVariation?.discountedPrice
                                    : activePriceVariation?.actualPrice
                                ) ?? 0,
                              actualPrice: Number(
                                activePriceVariation?.actualPrice
                              ),
                              quantity: quantity,
                              productVariationId:
                                activePriceVariation?.id ?? "",
                              imageUrl:
                                productDetailsDataModel?.productImages?.find(
                                  (product) => product?.isDefault
                                )?.imageUrl,
                              tax: productDetailsDataModel?.productTax ?? "",
                              productFilterTypeFilterOptions:
                                filterTypeFilterOptions,
                              slug: productDetailsDataModel?.slug ?? "",
                              storeId: productDetailsDataModel?.storeId ?? "",
                              priceModifiers: selectedPriceModifiers,
                              productVariation: activePriceVariation,
                              description: customDescription,
                            });
                            dispatch({
                              type: ActionTypes.CLOSE_ADD_TO_CART_POPUP,
                            });

                            //@ts-ignore
                            setCustomDescription("");
                          }}
                          className="cart me-2 w-50 fw-bold"
                        >
                          ADD TO CART
                        </Button>
                        {/* <a className="cart bg-theme1">Buy Now</a> */}
                        <Tooltip
                          title={
                            isProductInWishlist(
                              productDetailsDataModel?.id ?? ""
                            )
                              ? "Remove From Wishlist"
                              : "Add To Wishlist"
                          }
                        >
                          <button
                            onClick={() => {
                              if (
                                isProductInWishlist(
                                  productDetailsDataModel?.id ?? ""
                                )
                              ) {
                                const wishlist = wishlistDetails?.find(
                                  (item) =>
                                    item.productId ==
                                    productDetailsDataModel?.id
                                );
                                deleteWishlistProduct(wishlist?.id ?? "");
                              } else {
                                handleProtectedNavigation(
                                  UNAUTHORIZED_WISHLIST_MESSAGE,
                                  "",
                                  productDetailsDataModel?.id
                                );
                              }
                            }}
                            className="wishlist-product-detail btn-sm m-2"
                            style={
                              isProductInWishlist(
                                productDetailsDataModel?.id ?? ""
                              )
                                ? {}
                                : { color: "black" }
                            }
                            type="button"
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            aria-label="Add to wishlist"
                            data-bs-original-title="Add to wishlist"
                          >
                            {isProductInWishlist(
                              productDetailsDataModel?.id ?? ""
                            ) ? (
                              <i
                                className="fas fa-heart"
                                style={{ fontSize: "20px" }}
                              />
                            ) : (
                              <i
                                className="far fa-heart"
                                style={{ fontSize: "20px" }}
                              />
                            )}
                          </button>
                        </Tooltip>

                        {/* <a href="" class="fav-com" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Compare"><span class="icon-compare"></span></a> */}
                      </div>
                    </div>
                  </div>
                  {/* End .product-details */}
                </div>
                {/* End .col-md-6 */}
              </div>

              {/* <div className="specifications bg-light p-3">
                    <h5 className="fw-bold">Specifications</h5>
                    <table className="table table-bordered success mt-3">
                      <thead>
                        <tr>
                          <th className="t-pro">1-199 Pieces</th>
                          <th className="t-pro">200-499 Pieces</th>
                          <th className="t-price">500-999 Pieces</th>
                          <th className="t-qty">&gt;&gt;=1000 Pieces</th>
                          <th className="t-qty">&gt;&gt;=1000 Pieces</th>
                          <th className="t-qty">&gt;&gt;=1000 Pieces</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="t-price">Rs.1000</td>
                          <td className="t-price">Rs.1000</td>
                          <td className="t-price">Rs.1000</td>
                          <td className="t-price">Rs.1000</td>
                          <td className="t-price">Rs.1000</td>
                          <td className="t-price">Rs.1000</td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
              {!isAddToCartPopupOpen &&
                productDetailsDataModel?.productCustomDescriptions &&
                productDetailsDataModel?.productCustomDescriptions.length >
                  0 && (
                  <section className="product_desc pb-50">
                    <div className="container card  d-flex justify-content-center mt-5 border-0">
                      <div className="card-body">
                        <Tabs
                          type="card"
                          size="middle"
                          defaultActiveKey="1"
                          items={productDetailsDataModel?.productCustomDescriptions.map(
                            (item, index) => {
                              return {
                                key: item.id,
                                label: item.labelName,
                                children: (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                  />
                                ),
                              };
                            }
                          )}
                        />
                      </div>
                    </div>
                  </section>
                )}
            </div>
          </div>
        </section>
      </PageLoader>

      {!isAddToCartPopupOpen &&
        productDetailsDataModel?.otherProductsDetails &&
        productDetailsDataModel?.otherProductsDetails?.length > 0 && (
          <section className="pt-2 pb-50">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="">
                      <div className="section-header-left mb-3 text-start">
                        <div className="row">
                          <div className="col-md-12">
                            <h4 className="text-dark header-title fw-bold">
                              Related product
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="row row-cols-2 row-cols-xxl-5 row-cols-md-5 g-4">
                        {productDetailsDataModel?.otherProductsDetails?.map(
                          (item) => {
                            return (
                              <div
                                key={item.id}
                                className="cols"
                                data-aos={ANIMATION_CLASS}
                              >
                                <ProductSingleContainer
                                  product={item}
                                  hideAddToCartButton={true}
                                />
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
    </>
  );
};

export default ProductDetails;
