/** @format */

import { Button, Form, FormInstance, Input, Switch, Tooltip } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import { AiFillInfoCircle } from "react-icons/ai";
import BeatLoader from "react-spinners/BeatLoader";
import Layout from "../../components/layout/Layout";
import { EMPTY_FIELD_MESSAGE } from "../../constants/definitions";
import { ChangePasswordFormValues } from "../../types/commonInterfaces";
import { validatePassword } from "../../utils/validators";

interface IChangePasswordFormProps {
  changePasswordForm: FormInstance<ChangePasswordFormValues>;
  onChangePasswordFormSubmit: (values: ChangePasswordFormValues) => void;
  isLoading: boolean;
}

const ChangePasswordProfile: React.FC<IChangePasswordFormProps> = ({
  changePasswordForm,
  onChangePasswordFormSubmit,
  isLoading,
}) => {
  return (
    <Layout>
      <Helmet>
        <title>Change Your Password</title>
      </Helmet>
      <div className="login-container log1 ">
        <div className="card card0 border-0 w-auto">
          <div className="row d-flex">
            <div className="col-lg-0">
              <div className="card1 "></div>
            </div>
            <div className="col-lg-12 t">
              <div className="card2 card border-0 w-30 bg-white mt-4 mb-4 p-4">
                <div className="text-center pb-4">
                  <h6 className="text-center fw-bold text-theme">
                    Change Password
                  </h6>
                  <small className="text-muted">
                    Your new password will be used to access your account. Make
                    it strong and memorable.
                  </small>
                </div>

                <Form
                  onFinish={onChangePasswordFormSubmit}
                  form={changePasswordForm}
                  labelAlign="left"
                  labelCol={{
                    span: 24,
                  }}
                  name="normal_login"
                  className="login-form"
                  //   onFinish={onFinish}
                >
                  <div className="row ">
                    {" "}
                    <Form.Item
                      label="Old Password"
                      name="oldPassword"
                      rules={[
                        {
                          required: true,
                          message: EMPTY_FIELD_MESSAGE,
                        },
                        {
                          validator: validatePassword,
                        },
                      ]}
                    >
                      <Input.Password placeholder="Please Enter Old Password" />
                    </Form.Item>
                  </div>
                  <div className="row ">
                    {" "}
                    <Form.Item
                      label="New Password"
                      name="newPassword"
                      rules={[
                        {
                          required: true,
                          message: EMPTY_FIELD_MESSAGE,
                        },
                        {
                          validator: validatePassword,
                        },
                      ]}
                    >
                      <Input.Password placeholder="Please Enter New Password" />
                    </Form.Item>
                  </div>
                  <div className="row ">
                    {" "}
                    <Form.Item
                      label="Confirm New Password"
                      name="confirmNewPassword"
                      rules={[
                        {
                          required: true,
                          message: EMPTY_FIELD_MESSAGE,
                        },
                        {
                          validator: validatePassword,
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("newPassword") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder="Please Confirm Your New Password" />
                    </Form.Item>
                  </div>
                  <div className="row ">
                    {" "}
                    <Form.Item
                      label={
                        <>
                          <span>Stay Signed In ? </span>
                          <Tooltip title="If you uncheck this, You will be logged out from all of your devices.">
                            <AiFillInfoCircle
                              className="ms-1"
                              size={18}
                              style={{ color: "#00205A" }}
                            />
                          </Tooltip>
                        </>
                      }
                      name="isStaySigned"
                      labelCol={{ span: 9 }} // Adjust the span value as needed
                      wrapperCol={{ span: 12 }}
                      initialValue={true}
                    >
                      <Switch defaultChecked={true} />
                    </Form.Item>
                  </div>
                  <div className="row mb-3 px-3 mt-2">
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="d-flex align-items-center justify-content-center gap-2"
                      loading={isLoading}
                    >
                      {isLoading && (
                        <BeatLoader
                          loading={true}
                          color="white"
                          size={8}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )}
                      {isLoading ? "Loading..." : "Change Password"}
                    </Button>
                    {/* <a href="index.html" class="btn btn-blue text-center bg-success ms-2">Back to Home</a> */}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ChangePasswordProfile;
