/** @format */
import React from "react";
import "./ErrorPage.css";

interface ErrorPageProps {
  error: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ error }) => {
  return (
    <div className="server-error-page">
      <svg
        className="server-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
      >
        <circle cx="50" cy="50" r="40" fill="#c0392b" />
        <line
          x1="30"
          y1="30"
          x2="70"
          y2="70"
          stroke="#fff"
          strokeWidth="10"
          strokeLinecap="round"
        />
        <line
          x1="30"
          y1="70"
          x2="70"
          y2="30"
          stroke="#fff"
          strokeWidth="10"
          strokeLinecap="round"
        />
      </svg>
      <h1 className="server-error-heading">Oops</h1>
      <p className="server-error-text">{error}</p>
    </div>
  );
};

export default ErrorPage;
