/** @format */

import { Form } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import ActionTypes from "../../constants/action_types";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useSnackbar from "../../hooks/useSnackbar";
import OrderHistory from "../../pages/order-history/OrderHistory";
import { useApiCalls } from "../../utils/useApiCalls";
import { useRetailHelper } from "../../utils/useRetailHelper";

export interface ImageState {
  file: File | null;
  url: string | null;
}

const OrderHistoryContainer = () => {
  const dispatch = useAppDispatch();
  const { getOrdersHistory, getOrderDetail } = useApiCalls();
  const { formatDate } = useRetailHelper();
  const { homePageDataModel } = useAppSelector((state) => state.homePage);
  const [searchOrderHistoryForm] = Form.useForm();
  const { isLoading, successMessage, orderHistoryDetails } = useAppSelector(
    (state) => state.profile
  );
  const { showSnackbar } = useSnackbar();
  const [isOrderDetailModalOpen, setIsOrderDetailModalOpen] =
    useState<boolean>(false);

  const onSearchOrderHandler = (values: any) => {
    getOrdersHistory({
      Page: 1,
      PageSize: 10,
      SearchKeywords: values.orderNo,
      ExternalFilter: {
        OrderedDateFrom: values.orderDateFrom
          ? formatDate(values.orderDateFrom)
          : moment()
              .subtract(15, "days")
              .format(
                `${homePageDataModel?.storeDetails?.dateFormat
                  ?.split(" ")[0]
                  ?.toUpperCase()} HH:mm:ss`
              ),
        OrderedDateTo: values.orderDateTo
          ? formatDate(values.orderDateTo)
          : moment().format(
              `${homePageDataModel?.storeDetails?.dateFormat
                ?.split(" ")[0]
                ?.toUpperCase()} HH:mm:ss`
            ),
      },
    });
  };

  useEffect(() => {
    if (!orderHistoryDetails && homePageDataModel?.storeDetails?.dateFormat) {
      getOrdersHistory();
    }
  }, [homePageDataModel?.storeDetails?.dateFormat]);

  useEffect(() => {
    if (successMessage) {
      showSnackbar(successMessage, {
        variant: "success",
      });
      dispatch({
        type: ActionTypes.RESET_PROFILE_STATE,
      });
    }
  }, [successMessage]);
  return (
    <OrderHistory
      searchOrderHistoryForm={searchOrderHistoryForm}
      isLoading={isLoading}
      orderHistoryDetails={orderHistoryDetails}
      onSearchOrderHandler={onSearchOrderHandler}
      getOrderDetail={getOrderDetail}
      isOrderDetailModalOpen={isOrderDetailModalOpen}
      setIsOrderDetailModalOpen={setIsOrderDetailModalOpen}
    />
  );
};

export default OrderHistoryContainer;
