/** @format */

import React from "react";
import { useDispatch } from "react-redux";
import ActionTypes from "../../constants/action_types";
import { QueryString } from "../../redux/reducers/FilterProductsReducer/FilterProductsInterface";
import { homePageDataModel } from "../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import { useApiCalls } from "../../utils/useApiCalls";

interface InfoHeaderWithProductAndGiftCardProps {
  homePageDataModel: homePageDataModel;
}

const InfoHeaderWithProductAndGiftCard: React.FC<
  InfoHeaderWithProductAndGiftCardProps
> = ({ homePageDataModel }) => {
  const dispatch = useDispatch();
  const { getAllFilterProducts } = useApiCalls();

  return (
    <div className="topbar bg-header topbar-dark">
      <div className="container">
        <div className="d-flex align-items-center justify-content-md-between justify-content-center">
          <div className="topbar-text text-nowrap d-none d-md-inline-block py-2">
            {/* <a class="topbar-link text-header me-4" href=""><small>FREE RETURNS. STANDARD SHIPPING ORDERS $99+</small></a> */}
            <a className="topbar-link text-header me-4">
              <i className="fas fa-phone" />{" "}
              <small>{homePageDataModel?.storeDetails?.phoneNumber}</small>
            </a>
            <a
              className="topbar-link text-header me-4"
              href="mailto:00331697720"
            >
              <i className="fas fa-envelope" />{" "}
              <small>{homePageDataModel?.storeDetails?.email}</small>
            </a>
            <a
              className="topbar-link text-header me-4"
              href="mailto:00331697720"
            >
              <i className="fas fa-home" />{" "}
              <small>{homePageDataModel?.storeDetails?.address}</small>
            </a>
          </div>

          <div className="d-flex align-items-center justify-content-center text-header">
            <div className="navbar navbar-expand-lg text-center navbar-light bg-transparent pt-0 pb-0 nav_left">
              <div className="container">
                <div className=" navbar-collapse" id="navbarSupportedContent">
                  <ul className="m-auto mb-0   mb-lg-0 d-flex">
                    <li className="nav-item ">
                      <a
                        className="nav-link pe-1 text-header fw-bold"
                        onClick={() => {
                          getAllFilterProducts({
                            pageSize: 24,
                          });
                          dispatch({
                            type: ActionTypes.SET_QUERY_STRINGS,
                            payload: {} as QueryString,
                          });
                        }}
                      >
                        Products
                      </a>
                    </li>

                    <li>
                      <a
                        onClick={() => {
                          dispatch({
                            type: ActionTypes.GET_ALL_GIFT_CARDS_REQUEST,
                            payload: {
                              StoreId: homePageDataModel.storeDetails?.id,
                            },
                          });
                        }}
                        className="widget-header me-1 text-header btn bg-primary m-none pe-0 newbtn"
                      >
                        <div className="icon d-flex align-items-center text-header ">
                          <i className="fas fa-gift h6 me-2 mb-0" />{" "}
                          <span>Gift Cards</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="separator" />
            <div className="topbar-custom-text text-header">
              <div className="share-links text-header">
                {homePageDataModel?.storeDetails?.socialMediaDetails?.find(
                  (item) => item.identifier == "1"
                )?.isActive && (
                  <a
                    target="_blank"
                    className="share-facebook text-header"
                    href={
                      homePageDataModel?.storeDetails?.socialMediaDetails?.find(
                        (item) => item.identifier == "1"
                      )?.link
                    }
                  >
                    <i
                      className="fab fa-facebook-f text-header"
                      aria-hidden="true"
                    />
                  </a>
                )}

                {homePageDataModel?.storeDetails?.socialMediaDetails?.find(
                  (item) => item.identifier == "2"
                )?.isActive && (
                  <a
                    target="_blank"
                    className="share-twitter"
                    href={
                      homePageDataModel?.storeDetails?.socialMediaDetails?.find(
                        (item) => item.identifier == "2"
                      )?.link
                    }
                  >
                    <i
                      className="fab fa-twitter text-header"
                      aria-hidden="true"
                    />
                  </a>
                )}
                {homePageDataModel?.storeDetails?.socialMediaDetails?.find(
                  (item) => item.identifier == "3"
                )?.isActive && (
                  <a
                    target="_blank"
                    className="share-instagram"
                    href={
                      homePageDataModel?.storeDetails?.socialMediaDetails?.find(
                        (item) => item.identifier == "3"
                      )?.link
                    }
                  >
                    <i
                      className="fab fa-instagram text-header"
                      aria-hidden="true"
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoHeaderWithProductAndGiftCard;
