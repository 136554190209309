/** @format */

import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Result, Typography } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import ActionTypes from "../../constants/action_types";
import Routes_Constants from "../../constants/routes_configs";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { useApiCalls } from "../../utils/useApiCalls";
import { useRetailHelper } from "../../utils/useRetailHelper";
import OrderDetailModal from "../order-history/OrderDetailModal";

interface TransactionModalProps {
  isGuestCheckout: boolean;
}

const TransactionModal: React.FC<TransactionModalProps> = ({
  isGuestCheckout,
}) => {
  const {
    isTransactionInitiated,
    makePaymentLoading,
    isMakePaymentSuccessful,
    error,
    makePaymentResponse,
  } = useAppSelector((state) => state.makePayment);
  const dispatch = useAppDispatch();
  const { currencySymbol } = useRetailHelper();
  const { getOrdersHistory, getOrderDetail } = useApiCalls();
  const navigate = useNavigate();
  const [isOrderDetailModalOpen, setIsOrderDetailModalOpen] = useState(false);

  return (
    <>
      <OrderDetailModal
        isOrderDetailModalOpen={isOrderDetailModalOpen}
        setIsOrderDetailModalOpen={setIsOrderDetailModalOpen}
      />
      <Modal
        visible={isTransactionInitiated}
        onCancel={() => {
          dispatch({
            type: ActionTypes.MAKE_TRANSACTION_COMPLETED,
          });
        }}
        maskClosable={false}
        closeIcon={false}
        footer={null}
        closable={error ? true : false}
        centered
      >
        {makePaymentLoading ? (
          <div className="d-flex flex-column gap-4 justify-content-center align-items-center text-align-center">
            <div
              className="loader-container"
              style={{ width: "55px", margin: "auto", padding: "10px" }}
            >
              <ClipLoader
                loading={true}
                color="black"
                size={34}
                aria-label="Loading Spinner"
                data-testid="loader"
                className="loader-page"
              />
            </div>
            <Typography.Text strong style={{ textAlign: "center" }}>
              Processing your payment. This may take a moment. Please do not
              close or refresh the page.
            </Typography.Text>
          </div>
        ) : (
          <>
            <Result
              style={{ padding: "0" }}
              status={isMakePaymentSuccessful ? "success" : "error"}
              title={isMakePaymentSuccessful ? "Payment Successful" : error}
              subTitle={
                isMakePaymentSuccessful ? (
                  <Typography.Text strong>
                    Order Number: {makePaymentResponse?.orderNumber}
                  </Typography.Text>
                ) : (
                  ""
                )
              }
              icon={
                isMakePaymentSuccessful ? (
                  <CheckCircleOutlined
                    style={{ fontSize: 80, color: "#52c41a" }}
                  />
                ) : (
                  <CloseCircleOutlined
                    style={{ fontSize: 80, color: "#f5222d" }}
                  />
                )
              }
            >
              {isMakePaymentSuccessful && (
                <div>
                  <Typography.Text strong>Total Amount:</Typography.Text>{" "}
                  <Typography.Text>
                    {currencySymbol()}
                    {makePaymentResponse?.price}
                  </Typography.Text>
                  <br />
                  <Typography.Text strong>Order Date:</Typography.Text>{" "}
                  <Typography.Text>
                    {makePaymentResponse?.orderedDate}
                  </Typography.Text>
                </div>
              )}
            </Result>
            {isMakePaymentSuccessful ? (
              <div className="d-flex justify-content-center gap-2 mt-2">
                <Button
                  onClick={() => {
                    if (isGuestCheckout) {
                      getOrderDetail(makePaymentResponse?.orderId ?? "");
                      setIsOrderDetailModalOpen(true);
                    } else {
                      getOrdersHistory();
                      dispatch({
                        type: ActionTypes.RESET_MAKE_PAYMENT_STATE,
                      });
                    }
                  }}
                >
                  View Order
                </Button>

                <Button
                  onClick={() => {
                    navigate(Routes_Constants.HOME);
                    dispatch({
                      type: ActionTypes.RESET_MAKE_PAYMENT_STATE,
                    });
                  }}
                  type="primary"
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <div></div>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default TransactionModal;
