/** @format */

import { Form } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ActionTypes from "../../constants/action_types";
import Routes_Constants from "../../constants/routes_configs";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useSnackbar from "../../hooks/useSnackbar";
import ChangePassword from "../../pages/change-password/ChangePassword";
import { ChangePasswordFormValues } from "../../types/commonInterfaces";
import { useRetailHelper } from "../../utils/useRetailHelper";

const ChangePasswordContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading, error, isChangePasswordSuccesful, successMessage } =
    useAppSelector((state) => state.authentication);
  const [changePasswordForm] = Form.useForm<ChangePasswordFormValues>();
  const { showSnackbar } = useSnackbar();
  const { getValueFromQuery } = useRetailHelper();

  const onChangePasswordFormSubmit = (values: ChangePasswordFormValues) => {
    dispatch({
      type: ActionTypes.CHANGE_PASSWORD_REQUEST,
      payload: {
        code: getValueFromQuery("code"),
        email: getValueFromQuery("email"),
        password: values.newPassword,
        confirmPassword: values.confirmNewPassword,
      },
    });
  };
  useEffect(() => {
    if (error) {
      showSnackbar(error, {
        variant: "error",
      });
      dispatch({
        type: ActionTypes.RESET_AUTHENTICATION_STATE,
      });
    }
  }, [error]);
  useEffect(() => {
    if (isChangePasswordSuccesful) {
      showSnackbar(successMessage ?? "Change Password Successful ", {
        variant: "success",
      });
      navigate(Routes_Constants.AUTHENTICATE);
      dispatch({
        type: ActionTypes.RESET_AUTHENTICATION_STATE,
      });
    }
  }, [isChangePasswordSuccesful]);

  return (
    <ChangePassword
      changePasswordForm={changePasswordForm}
      onChangePasswordFormSubmit={onChangePasswordFormSubmit}
      isLoading={isLoading}
    />
  );
};

export default ChangePasswordContainer;
