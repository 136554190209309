/** @format */

import { Breadcrumb } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Routes_Constants from "../../constants/routes_configs";

interface BreadcrumbItem {
  title: string;
  link: string;
}

interface CustomBreadcrumbProps {
  items: BreadcrumbItem[];
}

const CustomBreadcrumb: React.FC<CustomBreadcrumbProps> = ({ items }) => {
  return (
    <Breadcrumb style={{ marginBottom: "24px" }}>
      <Breadcrumb.Item href="/">
        <Link to={Routes_Constants.HOME}>Home</Link>
      </Breadcrumb.Item>
      {items.map((item, index) => {
        if (item.link) {
          return (
            <Breadcrumb.Item key={index}>
              <a href={item.link}>{item.title}</a>
            </Breadcrumb.Item>
          );
        } else {
          return <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item>;
        }
      })}
    </Breadcrumb>
  );
};

export default CustomBreadcrumb;
