/** @format */

import { Button } from "antd";
import React from "react";
import { TemplatePageCategoryDetail } from "../../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import LazyImage from "../../../utils/Lazy Image/LazyImage";
import { useRetailHelper } from "../../../utils/useRetailHelper";

/** @format */
interface MultipleLinearAdsProps {
  item: TemplatePageCategoryDetail;
}

const MultipleLinearAds: React.FC<MultipleLinearAdsProps> = ({ item }) => {
  const { handleRedirectMegaMenu } = useRetailHelper();
  return (
    <div className="homemidbanner pt-50" key={item.id}>
      <div className="container">
        <div className="row">
          <div
            className=" col-md-4 position-relative"
            style={{ position: "relative" }}
          >
            <div className="bannerimg">
              <div style={{ position: "absolute", left: "30px", top: "20px" }}>
                <h3 className="fw-bold text-white">
                  {item?.imageAds?.[0]?.title}
                </h3>
                <p className="text-white">{item?.imageAds?.[0]?.subTitle}</p>
                <Button
                  onClick={() =>
                    handleRedirectMegaMenu(item?.imageAds?.[0]?.link)
                  }
                  type="primary"
                >
                  {item?.imageAds?.[0]?.linkText}
                </Button>
              </div>
              <LazyImage
                link={item?.imageAds?.[0]?.link}
                className="img-fluid"
                alt=""
                src={item?.imageAds?.[0]?.imageUrl}
              />
            </div>
          </div>
          <div className=" col-md-4">
            <div className="bannerimg">
              <div style={{ position: "absolute", left: "30px", top: "20px" }}>
                <h3 className="fw-bold text-white">
                  {item?.imageAds?.[1]?.title}
                </h3>
                <p className="text-white">{item?.imageAds?.[1]?.subTitle}</p>
                <Button
                  onClick={() =>
                    handleRedirectMegaMenu(item?.imageAds?.[1]?.link)
                  }
                  type="primary"
                >
                  {item?.imageAds?.[1]?.linkText}
                </Button>
              </div>
              <LazyImage
                link={item?.imageAds?.[1]?.link}
                className="img-fluid"
                alt=""
                src={item?.imageAds?.[1]?.imageUrl}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="bannerimg">
              <div style={{ position: "absolute", left: "30px", top: "20px" }}>
                <h3 className="fw-bold text-white">
                  {item?.imageAds?.[2]?.title}
                </h3>
                <p className="text-white">{item?.imageAds?.[2]?.subTitle}</p>
                <Button
                  onClick={() =>
                    handleRedirectMegaMenu(item?.imageAds?.[2]?.link)
                  }
                  type="primary"
                >
                  {item?.imageAds?.[2]?.linkText}
                </Button>
              </div>
              <LazyImage
                link={item?.imageAds?.[2]?.link}
                className="img-fluid"
                alt=""
                src={item?.imageAds?.[2]?.imageUrl}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleLinearAds;
