/** @format */

import Loadable from "react-loadable";
import ActionTypes from "../../constants/action_types";
import Routes_Constants from "../../constants/routes_configs";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { QueryString } from "../../redux/reducers/FilterProductsReducer/FilterProductsInterface";
import { useApiCalls } from "../../utils/useApiCalls";
import { useRetailHelper } from "../../utils/useRetailHelper";

const ContactUsLoadable = Loadable({
  loader: () =>
    import("../../containers/contact_us_container/contact_us_container"),
  loading: () => null,
});

const Footer = () => {
  const { homePageDataModel } = useAppSelector((state) => state.homePage);
  const { getAllFilterProducts, getPageDetails, getAllBlogs } = useApiCalls();
  const { handleNavigation, createLoadable } = useRetailHelper();

  const dispatch = useAppDispatch();
  return (
    <footer className="footer  ">
      <div className="pt-5 bg-darker">
        <div className="container">
          <div className="row pb-2">
            <div className="col-md-3 col-12">
              <div className="widget widget-links widget-light pb-2 mb-4">
                <h5 className="widget-title footer-text mb-3 fw-bold">
                  About us
                </h5>
                <p className="footer-text">
                  {homePageDataModel?.storeDetails?.footerDescription}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="row">
                {homePageDataModel?.storeDetails?.pageDetails &&
                  homePageDataModel?.storeDetails?.pageDetails?.length > 0 && (
                    <div className="col-md-6 col-6">
                      <div className="widget widget-links widget-light pb-2 mb-4">
                        <h5 className="widget-title footer-text mb-3 fw-bold">
                          Pages
                        </h5>
                        <ul className="widget-list">
                          {homePageDataModel?.storeDetails?.pageDetails?.map(
                            (item, index) => {
                              return (
                                <li className="widget-list-item" key={item.id}>
                                  <a
                                    onClick={() => {
                                      dispatch({
                                        type: ActionTypes.SET_ACTIVE_SLUG_PAGE,
                                        payload: item.slug,
                                      });
                                      getPageDetails(item.slug);
                                    }}
                                  >
                                    <p className="footer-text">{item.name}</p>
                                  </a>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                {homePageDataModel?.productCategories &&
                  homePageDataModel?.productCategories?.length > 0 && (
                    <div className="col-md-6 col-6">
                      <div className="widget widget-links widget-light pb-2 mb-4">
                        <h5 className="widget-title footer-text mb-3 fw-bold">
                          Categories
                        </h5>
                        <ul className="widget-list">
                          {homePageDataModel?.productCategories
                            ?.slice(0, 6)
                            ?.map((item, index) => {
                              return (
                                <li className="widget-list-item" key={item.id}>
                                  <a
                                    onClick={() => {
                                      dispatch({
                                        type: ActionTypes.SHOULD_CLEAR_CHECKED_FILTERS,
                                      });
                                      getAllFilterProducts({
                                        categoryIds: [`${item.slug}`],
                                      });
                                      dispatch({
                                        type: ActionTypes.SET_QUERY_STRINGS,
                                        payload: {
                                          categoryIds: [`${item.slug}`],
                                        } as QueryString,
                                      });
                                    }}
                                  >
                                    <p className="footer-text">{item.name}</p>
                                  </a>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="widget widget-links widget-light pb-2 mb-4">
                <h5 className="widget-title footer-text mb-3 fw-bold">
                  Useful Links
                </h5>
                <ul className="widget-list">
                  <li className="widget-list-item">
                    <a
                      onClick={() => {
                        dispatch({
                          type: ActionTypes.GET_ALL_GIFT_CARDS_REQUEST,
                          payload: {
                            StoreId: homePageDataModel?.storeDetails?.id,
                          },
                        });
                      }}
                    >
                      <p className="footer-text">Gift Cards</p>
                    </a>
                  </li>{" "}
                  <li className="widget-list-item">
                    <a
                      onClick={() => {
                        getAllFilterProducts({
                          pageSize: 24,
                        });
                        dispatch({
                          type: ActionTypes.SET_QUERY_STRINGS,
                          payload: {} as QueryString,
                        });
                      }}
                    >
                      <p className="footer-text">Products</p>
                    </a>
                  </li>{" "}
                  <li className="widget-list-item">
                    <a
                      onClick={() => {
                        getAllBlogs();
                      }}
                    >
                      <p className="footer-text">Blogs</p>
                    </a>
                  </li>{" "}
                  <li className="widget-list-item">
                    <a
                      onClick={() => {
                        handleNavigation(
                          Routes_Constants.CONTACT_US,
                          ContactUsLoadable
                        );
                      }}
                    >
                      <p className="footer-text">Contact Us</p>
                    </a>
                  </li>{" "}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
