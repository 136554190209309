/** @format */

import {
  Checkbox,
  Empty,
  Pagination,
  Segmented,
  Select,
  Slider,
  Tag,
  Tooltip,
} from "antd";
import React, { Dispatch, SetStateAction } from "react";
import Carousel from "react-bootstrap/Carousel";

import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../components/custom_breadcrump/CustomBreadcrumb";
import PageLoader from "../../components/page_loader/PageLoader";
import {
  ANIMATION_CLASS,
  NO_PRODUCTS_FOUND_MESSAGE,
} from "../../constants/definitions";
import Routes_Constants from "../../constants/routes_configs";
import ProductSingleContainer from "../../containers/product_single_container/product_single_container";
import {
  AdditionalFilter,
  CheckedFilter,
  FilterProductsDataModel,
  ProductCategory,
  QueryString,
} from "../../redux/reducers/FilterProductsReducer/FilterProductsInterface";
import { SegmentedValue } from "../../types/commonInterfaces";
import LazyImage from "../../utils/Lazy Image/LazyImage";
import isMobile from "../../utils/isMobile";
import { useApiCalls } from "../../utils/useApiCalls";
import { useRetailHelper } from "../../utils/useRetailHelper";

const gridStyle: React.CSSProperties = {
  width: "25%",
  textAlign: "center",
};

interface FilterProductsInterface {
  filterProductsDataModel?: FilterProductsDataModel;
  onActiveCategoryChange?: (category: ProductCategory | undefined) => void;
  activeCategory?: ProductCategory;
  viewType: SegmentedValue;
  setViewType: (value: SegmentedValue) => void;
  getFilterProductsWithQuery: (payload?: QueryString) => void;
  selectedFilterOptions: string[];
  setSelectedFilterOptions: Dispatch<SetStateAction<string[]>>;
  activeAddtionalFilter: string;
  setActiveAdditionalFilter: Dispatch<SetStateAction<string>>;
  activeDiscount: string;
  setActiveDiscount: Dispatch<SetStateAction<string>>;
  selectedBrands: string[];
  setSelectedBrands: Dispatch<SetStateAction<string[]>>;
  priceRange: number[];
  minPrice: number;
  maxPrice: number;
  priceAdditionalFilter: AdditionalFilter | undefined;
  setPriceRange: Dispatch<SetStateAction<number[]>>;
  checkedFilters: CheckedFilter[] | [];
  setCheckedFilters: Dispatch<SetStateAction<CheckedFilter[] | []>>;
}

const FliterProducts: React.FC<FilterProductsInterface> = ({
  onActiveCategoryChange,
  activeCategory,
  filterProductsDataModel,
  viewType,
  setViewType,
  getFilterProductsWithQuery,
  selectedFilterOptions,
  setSelectedFilterOptions,
  activeAddtionalFilter,
  setActiveAdditionalFilter,
  selectedBrands,
  setSelectedBrands,
  priceRange,
  setPriceRange,
  minPrice,
  maxPrice,
  activeDiscount,
  setActiveDiscount,
  checkedFilters,
  setCheckedFilters,
  priceAdditionalFilter,
}) => {
  const { currencySymbol } = useRetailHelper();
  const { getAllFilterProducts } = useApiCalls();
  const navigate = useNavigate();

  return (
    <>
      <>
        {/* breadcrumb */}
        <PageLoader loading={!filterProductsDataModel}>
          <section className="category">
            <div className="container">
              <div className="mt-4">
                <CustomBreadcrumb
                  items={[
                    {
                      title: "Products" ?? "",
                      link: Routes_Constants.FILTER_PRODUCTS,
                    },
                    {
                      title:
                        checkedFilters.find((item) => item.isCategory)?.Name ??
                        "All" ??
                        "",
                      link: "",
                    },
                  ]}
                />
              </div>

              <div className="row ">
                <div className="col-md-3 card bg-white p-0 p-md-3 rounded-3">
                  <div className="sticky-top cat_sticky">
                    <div
                      className="offcanvas offcanvas-start border-end-0 offcanvas-collapse"
                      tabIndex={-1}
                      id="offcanvasCategory"
                      aria-labelledby="offcanvasCategoryLabel"
                      aria-modal="true"
                      role="dialog"
                    >
                      <div className="offcanvas-header d-lg-none">
                        <h5
                          className="offcanvas-title"
                          id="offcanvasCategoryLabel"
                        >
                          Filter
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        />
                      </div>
                      <div className="offcanvas-body ps-lg-2 pt-lg-0 border-end-0">
                        {filterProductsDataModel?.searchFilters
                          ?.productCategories &&
                          filterProductsDataModel?.searchFilters
                            ?.productCategories?.length > 0 && (
                            <div className="category-box">
                              <div className="sec-title">
                                <h6>
                                  {activeCategory?.childerns &&
                                  activeCategory?.childerns?.length > 0
                                    ? "Sub Categories"
                                    : "Categories"}
                                </h6>
                              </div>
                              {/* accordion */}
                              <ul
                                className="nav nav-category filter-products-banner"
                                id="categoryCollapseMenu"
                              >
                                {/* {activeCategory ? (
                                    <li
                                      className="nav-item nav-item-active border-bottom w-100 "
                                      style={{ cursor: "pointer" }}
                                    >
                                      <a
                                        className="nav-link collapsed"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#categoryFlushOne"
                                        aria-expanded="false"
                                        aria-controls="categoryFlushOne"
                                      >
                                        {activeCategory.name}
                                      </a>
                                    </li>
                                  ) : (
                                    <> */}
                                {activeCategory?.childerns &&
                                activeCategory?.childerns?.length > 0
                                  ? activeCategory?.childerns?.map(
                                      (item, index) => {
                                        return (
                                          <li
                                            onClick={() => {
                                              if (onActiveCategoryChange) {
                                                onActiveCategoryChange(item);
                                                const existingCategoryIndex =
                                                  checkedFilters.findIndex(
                                                    (filter) =>
                                                      filter.isCategory
                                                  );

                                                if (
                                                  existingCategoryIndex !== -1
                                                ) {
                                                  setCheckedFilters(
                                                    (prevFilters) => {
                                                      const newFilters = [
                                                        ...prevFilters,
                                                      ];
                                                      newFilters[
                                                        existingCategoryIndex
                                                      ] = {
                                                        Name: item.slug,
                                                        Id: item.id,
                                                        isCategory: true,
                                                      };
                                                      return newFilters;
                                                    }
                                                  );
                                                } else {
                                                  setCheckedFilters(
                                                    (prevFilters) => {
                                                      return [
                                                        ...prevFilters,
                                                        {
                                                          Name: item.slug,
                                                          Id: item.id,
                                                          isCategory: true,
                                                        },
                                                      ];
                                                    }
                                                  );
                                                }
                                              }
                                            }}
                                            className="nav-item border-bottom w-100 "
                                            key={item.id}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <a
                                              className={
                                                checkedFilters.findIndex(
                                                  (filter) =>
                                                    filter.Id == item.id
                                                ) != -1
                                                  ? "nav-link collapsed theme-color"
                                                  : "nav-link collapsed"
                                              }
                                              data-bs-toggle="collapse"
                                              data-bs-target="#categoryFlushOne"
                                              aria-expanded="false"
                                              aria-controls="categoryFlushOne"
                                            >
                                              {item.name}
                                              {/* <Tag>{item.productCount}</Tag> */}
                                            </a>
                                          </li>
                                        );
                                      }
                                    )
                                  : filterProductsDataModel?.searchFilters?.productCategories?.map(
                                      (item, index) => {
                                        return (
                                          <li
                                            onClick={() => {
                                              if (onActiveCategoryChange) {
                                                onActiveCategoryChange(item);
                                                const existingCategoryIndex =
                                                  checkedFilters.findIndex(
                                                    (filter) =>
                                                      filter.isCategory
                                                  );

                                                if (
                                                  existingCategoryIndex !== -1
                                                ) {
                                                  setCheckedFilters(
                                                    (prevFilters) => {
                                                      const newFilters = [
                                                        ...prevFilters,
                                                      ];
                                                      newFilters[
                                                        existingCategoryIndex
                                                      ] = {
                                                        Name: item.slug ?? "",
                                                        Id: item.id,
                                                        isCategory: true,
                                                      };
                                                      return newFilters;
                                                    }
                                                  );
                                                } else {
                                                  setCheckedFilters(
                                                    (prevFilters) => {
                                                      return [
                                                        ...prevFilters,
                                                        {
                                                          Name: item.slug,
                                                          Id: item.id,
                                                          isCategory: true,
                                                        },
                                                      ];
                                                    }
                                                  );
                                                }
                                              }
                                            }}
                                            className="nav-item border-bottom w-100 "
                                            key={item.id}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <a
                                              className={
                                                checkedFilters.findIndex(
                                                  (filter) =>
                                                    filter.Id == item.id
                                                ) != -1
                                                  ? "nav-link collapsed theme-color"
                                                  : "nav-link collapsed"
                                              }
                                              data-bs-toggle="collapse"
                                              data-bs-target="#categoryFlushOne"
                                              aria-expanded="false"
                                              aria-controls="categoryFlushOne"
                                            >
                                              {item.name}
                                              {/* <Tag>{item.productCount}</Tag> */}
                                            </a>
                                          </li>
                                        );
                                      }
                                    )}

                                {/* </>
                                  )} */}
                              </ul>
                            </div>
                          )}
                        {priceAdditionalFilter && (
                          <div className="cat-brand mb-35">
                            <div className="sec-title">
                              <h6>Price</h6>
                            </div>
                            <Slider
                              marks={{
                                minPrice: `${currencySymbol()}${minPrice}`,
                                maxPrice: `${currencySymbol()}${maxPrice}`,
                              }}
                              onAfterChange={(value) => {
                                // setPriceRange(value);
                                //
                                if (
                                  !checkedFilters.find(
                                    (item) => item.isPriceRange
                                  )
                                ) {
                                  setCheckedFilters((prev) => {
                                    return [
                                      ...prev,
                                      {
                                        Name: `${currencySymbol()}${
                                          value[0]
                                        } - ${currencySymbol()}${value[1]}`,
                                        Id: "price-range",
                                        isPriceRange: true,
                                        minPrice: value[0],
                                        maxPrice: value[1],
                                      },
                                    ];
                                  });
                                } else {
                                  setCheckedFilters(
                                    checkedFilters.filter(
                                      (item) => !item.isPriceRange
                                    )
                                  );
                                  setCheckedFilters((prev) => {
                                    return [
                                      ...prev,
                                      {
                                        Name: `${currencySymbol()}${
                                          value[0]
                                        } - ${currencySymbol()}${value[1]}`,
                                        Id: "price-range",
                                        isPriceRange: true,
                                        minPrice: value[0],
                                        maxPrice: value[1],
                                      },
                                    ];
                                  });
                                }
                              }}
                              range
                              min={minPrice ?? 0}
                              max={maxPrice ?? 5000}
                            />
                          </div>
                        )}

                        {filterProductsDataModel?.searchFilters.brands &&
                          filterProductsDataModel?.searchFilters.brands.length >
                            0 && (
                            <div className="cat-brand mb-35">
                              <div className="sec-title">
                                <h6>Brands</h6>
                              </div>
                              <div className="brand-box">
                                <ul className="list-unstyled filter-products-banner">
                                  {filterProductsDataModel?.searchFilters.brands.map(
                                    (item, index) => {
                                      return (
                                        <li key={item.id}>
                                          <Checkbox
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                setCheckedFilters((prev) => {
                                                  return [
                                                    ...prev,
                                                    {
                                                      Name: item.name,
                                                      Id: item.id,
                                                      isBrand: true,
                                                    },
                                                  ];
                                                });
                                              } else {
                                                if (
                                                  checkedFilters.length == 1
                                                ) {
                                                  setCheckedFilters([]);
                                                  getFilterProductsWithQuery();
                                                  navigate(
                                                    Routes_Constants.FILTER_PRODUCTS
                                                  );
                                                } else {
                                                  setCheckedFilters((prev) => {
                                                    return prev.filter(
                                                      (checkedFilter) =>
                                                        checkedFilter.Id !=
                                                        item.id
                                                    );
                                                  });
                                                }
                                              }
                                            }}
                                            checked={checkedFilters.some(
                                              (filter) =>
                                                filter.Id === item.id &&
                                                filter.isBrand
                                            )}
                                            value={item}
                                          >
                                            {" "}
                                            {item.name}
                                          </Checkbox>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}

                        {filterProductsDataModel?.searchFilters?.filterTypes?.map(
                          (item, index) => {
                            return (
                              <div className="cat-brand mb-35" key={item.id}>
                                <div className="sec-title">
                                  <h6>{item.name}</h6>
                                </div>
                                <div className="brand-box">
                                  <ul className="list-unstyled">
                                    {item?.filterTypeOptions.map(
                                      (optionItem) => {
                                        const isOptionChecked =
                                          checkedFilters.some(
                                            (filter) =>
                                              filter.Id === optionItem.id &&
                                              filter.isFilterOption
                                          );

                                        return (
                                          <li key={optionItem.id}>
                                            <Checkbox
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  setCheckedFilters((prev) => {
                                                    return [
                                                      ...prev,
                                                      {
                                                        Name: optionItem.name,
                                                        Id: optionItem.id,
                                                        isFilterOption: true,
                                                      },
                                                    ];
                                                  });
                                                } else {
                                                  setCheckedFilters((prev) => {
                                                    return prev.filter(
                                                      (checkedFilter) =>
                                                        checkedFilter.Id !=
                                                        optionItem.id
                                                    );
                                                  });
                                                }
                                              }}
                                              checked={isOptionChecked}
                                              value={optionItem}
                                            >
                                              {optionItem.name}
                                            </Checkbox>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <section>
                    <div className="">
                      <div className="row">
                        <div className="col-md-12">
                          {/* vertical tab */}

                          <div className="row">
                            <div className=" ">
                              {filterProductsDataModel?.imageAds &&
                                filterProductsDataModel?.imageAds?.length >
                                  0 && (
                                  <Carousel indicators={false} className="mb-4">
                                    {filterProductsDataModel?.imageAds?.map(
                                      (item, index) => {
                                        return (
                                          <Carousel.Item key={index}>
                                            <LazyImage
                                              link={item.link}
                                              style={{
                                                maxHeight: "200px",
                                              }}
                                              src={item.imageUrl}
                                              className="img-fluid rounded-3 mb-4"
                                              alt=""
                                            />
                                          </Carousel.Item>
                                        );
                                      }
                                    )}
                                  </Carousel>
                                )}

                              {/* filter */}
                              <div className="d-lg-flex justify-content-between align-items-center filter_right">
                                <div className="mb-3 mb-lg-0">
                                  <p className="mb-0 fw-bold text-theme">
                                    {" "}
                                    <span className="text-theme">
                                      {
                                        filterProductsDataModel?.productList
                                          ?.total
                                      }
                                    </span>{" "}
                                    Products found{" "}
                                  </p>
                                </div>
                                {/* icon */}
                                <div className="d-md-flex justify-content-between align-items-center">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="me-2">
                                      <Segmented
                                        value={viewType}
                                        onChange={(value) => {
                                          if (value == "list") {
                                            setViewType("list");
                                          } else {
                                            setViewType("grid");
                                          }
                                        }}
                                        // Ensure setViewType is passed as-is without any additional wrappers or modifications
                                        options={[
                                          {
                                            value: "grid",
                                            icon: (
                                              <Tooltip title="Grid View">
                                                {" "}
                                                <AppstoreOutlined />
                                              </Tooltip>
                                            ),
                                          },
                                          {
                                            value: "list",
                                            icon: (
                                              <Tooltip title="List View">
                                                <BarsOutlined />
                                              </Tooltip>
                                            ),
                                          },
                                        ]}
                                      />
                                    </div>
                                    <div className="ms-2 d-lg-none">
                                      <a
                                        className="btn btn-outline-gray-400 text-muted"
                                        data-bs-toggle="offcanvas"
                                        href="#offcanvasCategory"
                                        role="button"
                                        aria-controls="offcanvasCategory"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={14}
                                          height={14}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="feather feather-filter me-2"
                                        >
                                          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
                                        </svg>{" "}
                                        Apply Filters
                                      </a>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    {filterProductsDataModel?.searchFilters?.additionalFilters?.find(
                                      (item) => item.type === "2"
                                    )?.filters && (
                                      <div className="w-100 me-2">
                                        <Select
                                          onChange={(e) => {
                                            setActiveDiscount(e);
                                            const selectedDiscount =
                                              filterProductsDataModel?.searchFilters?.additionalFilters
                                                ?.find(
                                                  (item) => item.type === "2"
                                                )
                                                ?.filters?.find(
                                                  (item) => item.value === e
                                                );

                                            // Find the index of the existing discount filter in checkedFilters
                                            const existingDiscountFilterIndex =
                                              checkedFilters.findIndex(
                                                (filter) => filter.isDiscount
                                              );

                                            // Create a new checkedFilters array
                                            let newCheckedFilters = [
                                              ...checkedFilters,
                                            ];

                                            // If existing discount filter found, replace it with the new one
                                            if (
                                              existingDiscountFilterIndex !== -1
                                            ) {
                                              newCheckedFilters[
                                                existingDiscountFilterIndex
                                              ] = {
                                                Name:
                                                  selectedDiscount?.name ?? "",
                                                Id:
                                                  selectedDiscount?.value ?? "",
                                                isDiscount: true,
                                              };
                                            } else {
                                              // Otherwise, add the new discount filter
                                              newCheckedFilters.push({
                                                Name:
                                                  selectedDiscount?.name ?? "",
                                                Id:
                                                  selectedDiscount?.value ?? "",
                                                isDiscount: true,
                                              });
                                            }

                                            setCheckedFilters(
                                              newCheckedFilters
                                            );
                                          }}
                                          value={
                                            checkedFilters.find(
                                              (filter) => filter.isDiscount
                                            )?.Name
                                          }
                                          className="w-100"
                                          placeholder="Select Discount"
                                        >
                                          {/* <Select.Option value="">
                                            Discount
                                          </Select.Option> */}
                                          {filterProductsDataModel?.searchFilters?.additionalFilters
                                            ?.find(
                                              (item, index) => item.type == "2"
                                            )
                                            ?.filters?.map((item, index) => {
                                              return (
                                                <Select.Option
                                                  value={item.value}
                                                  key={item.id}
                                                >
                                                  {item.name}
                                                </Select.Option>
                                              );
                                            })}
                                        </Select>
                                      </div>
                                    )}
                                    {filterProductsDataModel?.searchFilters?.additionalFilters?.find(
                                      (item) => item.type === "1"
                                    )?.filters && (
                                      <div
                                        className="d-flex  mt-lg-0"
                                        // style={{ width: "200px" }}
                                      >
                                        <div className="w-100">
                                          {/* select option */}
                                          <Select
                                            onChange={(e) => {
                                              setActiveAdditionalFilter(e);
                                              const selectedAdditionalFilter =
                                                filterProductsDataModel?.searchFilters?.additionalFilters
                                                  ?.find(
                                                    (item) => item.type === "1"
                                                  )
                                                  ?.filters?.find(
                                                    (item) => item.value === e
                                                  );

                                              // Find the index of the existing additional filter in checkedFilters
                                              const existingAdditionalFilterIndex =
                                                checkedFilters.findIndex(
                                                  (filter) =>
                                                    filter.isAdditionalFilter
                                                );

                                              // Create a new checkedFilters array
                                              let newCheckedFilters = [
                                                ...checkedFilters,
                                              ];

                                              // If existing additional filter found, replace it with the new one
                                              if (
                                                existingAdditionalFilterIndex !==
                                                -1
                                              ) {
                                                newCheckedFilters[
                                                  existingAdditionalFilterIndex
                                                ] = {
                                                  Name:
                                                    selectedAdditionalFilter?.name ??
                                                    "",
                                                  Id:
                                                    selectedAdditionalFilter?.value ??
                                                    "",
                                                  isAdditionalFilter: true,
                                                };
                                              } else {
                                                // Otherwise, add the new additional filter
                                                newCheckedFilters.push({
                                                  Name:
                                                    selectedAdditionalFilter?.name ??
                                                    "",
                                                  Id:
                                                    selectedAdditionalFilter?.value ??
                                                    "",
                                                  isAdditionalFilter: true,
                                                });
                                              }

                                              setCheckedFilters(
                                                newCheckedFilters
                                              );
                                            }}
                                            value={
                                              checkedFilters.find(
                                                (filter) =>
                                                  filter.isAdditionalFilter
                                              )?.Name
                                            }
                                            className="w-100"
                                            placeholder="Sort By Filters"
                                          >
                                            {filterProductsDataModel?.searchFilters?.additionalFilters
                                              ?.find(
                                                (item, index) =>
                                                  item.type == "1"
                                              )
                                              ?.filters?.map((item, index) => {
                                                return (
                                                  <Select.Option
                                                    value={item.value}
                                                    key={item.id}
                                                  >
                                                    {item.name}
                                                  </Select.Option>
                                                );
                                              })}
                                          </Select>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {checkedFilters
                                ?.filter((item) => !item.isPagination)
                                ?.map((item, index) => {
                                  return (
                                    <Tag
                                      style={
                                        isMobile ? { marginTop: "10px" } : {}
                                      }
                                      key={item.Id}
                                      closable
                                      bordered={false}
                                      onClose={() => {
                                        const updatedFilters =
                                          checkedFilters.filter(
                                            (filter) => filter.Id !== item.Id
                                          );
                                        setCheckedFilters(updatedFilters);

                                        if (
                                          checkedFilters.length == 1 ||
                                          (checkedFilters.length == 2 &&
                                            checkedFilters.findIndex(
                                              (item) => item.isPagination
                                            ) != -1)
                                        ) {
                                          getFilterProductsWithQuery();
                                          navigate(
                                            Routes_Constants.FILTER_PRODUCTS
                                          );
                                          setCheckedFilters([]);
                                        }
                                      }}
                                    >
                                      {item.Name}
                                    </Tag>
                                  );
                                })}
                              {
                                <small
                                  className="text-danger"
                                  style={{
                                    textTransform: "uppercase",
                                    cursor: "pointer",
                                    fontSize: "11px",
                                  }}
                                  onClick={() => {
                                    setCheckedFilters([]);

                                    getFilterProductsWithQuery();
                                    navigate(Routes_Constants.FILTER_PRODUCTS);
                                  }}
                                >
                                  Reset Filters
                                </small>
                              }

                              {filterProductsDataModel?.productList?.data
                                ?.length == 0 && (
                                <div className="mt-5">
                                  <Empty
                                    description={NO_PRODUCTS_FOUND_MESSAGE}
                                  />
                                </div>
                              )}

                              <div className="grid-container mb-4">
                                <div className="row g-3 mt-2">
                                  {filterProductsDataModel?.productList?.data?.map(
                                    (item, index) => {
                                      return (
                                        <div
                                          className="col-lg-3 col-md-6 col-6 item "
                                          data-aos={ANIMATION_CLASS}
                                          style={
                                            viewType == "list"
                                              ? {
                                                  maxWidth: "100%",
                                                  flex: "0 0 100%",
                                                }
                                              : {}
                                          }
                                          key={item.id}
                                        >
                                          {" "}
                                          <ProductSingleContainer
                                            product={item}
                                            viewType={viewType}
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                                  <div className="d-flex justify-content-end">
                                    {filterProductsDataModel?.productList
                                      .total &&
                                      filterProductsDataModel?.productList
                                        .total > 0 && (
                                        <Pagination
                                          pageSize={24}
                                          showTotal={(total) => (
                                            <span className="fw-bold text-theme d-none d-md-block">
                                              {" "}
                                              Total {total} items
                                            </span>
                                          )}
                                          total={
                                            filterProductsDataModel?.productList
                                              .total
                                          }
                                          onChange={(page) => {
                                            const existingPaginationIndex =
                                              checkedFilters.findIndex(
                                                (filter) => filter.isPagination
                                              );
                                            let newCheckedFilters = [
                                              ...checkedFilters,
                                            ];
                                            // If existing additional filter found, replace it with the new one
                                            if (
                                              existingPaginationIndex !== -1
                                            ) {
                                              newCheckedFilters[
                                                existingPaginationIndex
                                              ] = {
                                                Name: `Page : ${page}`,
                                                Id: page.toString(),
                                                isPagination: true,
                                              };
                                            } else {
                                              // Otherwise, add the new additional filter
                                              newCheckedFilters.push({
                                                Name: `Page : ${page}`,
                                                Id: page.toString(),
                                                isPagination: true,
                                              });
                                            }

                                            setCheckedFilters(
                                              newCheckedFilters
                                            );
                                            // getFilterProductsWithQuery({
                                            //   page: page,
                                            // });
                                          }}
                                          // defaultCurrent={1}
                                        />
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </PageLoader>
      </>
    </>
  );
};

export default FliterProducts;
