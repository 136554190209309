/** @format */

import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Layout from "../../components/layout/Layout";
import LoginPopup from "../../components/login_popup/LoginPopup";
import ActionTypes from "../../constants/action_types";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import ProductDetailsCopy from "../product_details_container/product_details_copy";

interface LayoutContainerProps {
  children: React.ReactNode;
}
const LayoutContainer: React.FC<LayoutContainerProps> = ({ children }) => {
  const { homePageDataModel } = useAppSelector((state) => state.homePage);
  const { isLoginPopupOpen, loginPopupMessage, isAddToCartPopupOpen } =
    useAppSelector((state) => state.common);
  const { isLoading } = useAppSelector((state) => state.productDetails);
  const dispatch = useAppDispatch();

  return (
    <Layout
      storeDetails={homePageDataModel?.storeDetails}
      productCategories={homePageDataModel?.productCategories}
    >
      <Modal
        open={isLoginPopupOpen}
        onCancel={() => {
          dispatch({
            type: ActionTypes.CLOSE_LOGIN_POPUP,
          });
        }}
        closable={false}
        closeIcon={false}
        footer={null}
      >
        {isLoginPopupOpen && (
          <div
            className="custom-close-button"
            onClick={() =>
              dispatch({
                type: ActionTypes.CLOSE_LOGIN_POPUP,
              })
            }
          >
            <CloseOutlined className="close-icon" />
          </div>
        )}
        <LoginPopup popupMessage={loginPopupMessage} />
      </Modal>
      <Modal
        open={isAddToCartPopupOpen}
        onCancel={() => {
          dispatch({
            type: ActionTypes.CLOSE_ADD_TO_CART_POPUP,
          });
        }}
        closable={false}
        closeIcon={false}
        footer={null}
        width={1000}
        centered={true}
        maskClosable={false}
        style={{ top: 20 }}
      >
        {isAddToCartPopupOpen && (
          <div
            className="custom-close-button"
            onClick={() =>
              dispatch({
                type: ActionTypes.CLOSE_ADD_TO_CART_POPUP,
              })
            }
          >
            <CloseOutlined className="close-icon" />
          </div>
        )}
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "40vh" }}
        >
          {isLoading ? (
            <div className="d-flex flex-column gap-4 justify-content-center align-items-center text-align-center">
              <div
                className="loader-container"
                style={{
                  width: "55px",
                  margin: "auto",
                  padding: "10px",
                }}
              >
                <ClipLoader
                  loading={true}
                  color="black"
                  size={34}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  className="loader-page"
                />
              </div>
            </div>
          ) : (
            <div className="w-100">
              <h5>Choose Variations</h5>

              <ProductDetailsCopy />
            </div>
          )}
        </div>
      </Modal>
      {children}
    </Layout>
  );
};

export default LayoutContainer;
