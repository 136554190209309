/** @format */

import React, { useEffect, useState } from "react";
import Loadable from "react-loadable";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import ActionTypes from "../../constants/action_types";
import Routes_Constants from "../../constants/routes_configs";
import CartPopoverContainer from "../../containers/cart_popover_container/cart_popover_container";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import {
  ProductCategory,
  StoreDetails,
  homePageDataModel,
} from "../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import { useApiCalls } from "../../utils/useApiCalls";
import { useRetailHelper } from "../../utils/useRetailHelper";
import InfoHeader from "../home_section/home_section_components/InfoHeader";
import UserDropdownItems from "../user_dropdown_items/user_dropdown_items";
import Header2 from "./Header2";
import Header3 from "./Header3";
import HeaderNormal from "./HeaderNormal";
import InfoHeaderWithProductAndGiftCard from "./InfoHeaderWithProductAndGiftCard";
import MegaMenuMultiLevelVertical from "./MegaMenuMultiLevelVertical";
import MegaMenuPro from "./MegaMenuPro";
import { SearchHeader } from "./SearchHeader";
import MegaMenuHeaderCategoryPopup from "./MegaMenuHeaderCategoryPopup";

const LoginLoadable = Loadable({
  loader: () => import("../../containers/login_container/login_container"),
  loading: () => null,
});

const HomeLoadable = Loadable({
  loader: () => import("../../pages/home"),
  loading: () => null,
});

interface HeaderProps {
  storeDetails?: StoreDetails;
  productCategories?: ProductCategory[];
  homePageDataModel?: homePageDataModel;
}

const Header: React.FC<HeaderProps> = ({
  storeDetails,
  productCategories,
  homePageDataModel,
}) => {
  const dispatch = useAppDispatch();
  const { handleNavigation, formatDate } = useRetailHelper();
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [isSearchPopoverOpen, setIsSearchPopoverOpen] =
    useState<boolean>(false);

  const { isLoading, searchResult } = useAppSelector((state) => state.common);
  const { isLoginSuccessFul, loginResponse } = useAppSelector(
    (state) => state.authentication
  );

  const navigate = useNavigate();
  const { getAllFilterProducts } = useApiCalls();

  useEffect(() => {
    if (searchKeyword) {
      const searchTimeout = setTimeout(() => {
        dispatch({
          type: ActionTypes.SEARCH_PRODUCTS_REQUEST,
          payload: {
            Page: 1,
            PageSize: 10,
            SearchKeywords: searchKeyword,
            StoreId: homePageDataModel?.storeDetails?.id,
          },
        });
      }, 500);
      return () => {
        clearTimeout(searchTimeout);
      };
    }
  }, [searchKeyword]);
  const brandsArray = Array.from(
    new Set(
      searchResult &&
        searchResult
          .map((item) => item.brand)
          .filter((brand) => brand !== null && brand !== undefined)
    )
  );

  const categoriesArray = Array.from(
    new Set(
      searchResult &&
        searchResult
          .map((item) => item.category)
          .filter((category) => category !== null && category !== undefined)
    )
  );

  return (
    <>
      <header className="sticky-top shadow-sm">
        {homePageDataModel?.templatePageDetails
          ?.find((item) => item.isDefault)
          ?.pageDetails?.templatePageCategoryDetails?.map((item, index) => {
            switch (item.identifier) {
              case "info_headerwithlinkitems":
                return <InfoHeader key={item.id} item={item} />;
              case "header_storeinfoproductgiftcardsearch":
                return (
                  <header className="" key={item.id}>
                    {/* border-bottom-here */}
                    <InfoHeaderWithProductAndGiftCard
                      homePageDataModel={homePageDataModel}
                    />
                    <div className="bg-header">
                      <div className="navbar navbar-expand-lg navbar-light pt-2 pb-2 mynav">
                        <div className="container">
                          <a
                            onClick={() => {
                              handleNavigation(
                                Routes_Constants.HOME,
                                HomeLoadable
                              );
                            }}
                            className="navbar-brand d-none d-md-block"
                          >
                            <img
                              src={homePageDataModel.storeDetails?.imageUrl}
                              alt="logo"
                            />
                          </a>

                          <div className="input-group d-lg-flex align-items-center flex-nowrap mx-0 mx-md-4 w-100 w-md-50 position-relative">
                            <a
                              onClick={() => {
                                handleNavigation(
                                  Routes_Constants.HOME,
                                  HomeLoadable
                                );
                              }}
                              className="navbar-brand d-sm-none flex-shrink-0 me-2 d-none"
                            >
                              <img
                                src={homePageDataModel.storeDetails?.imageUrl}
                                width={74}
                                alt="logo"
                              />
                            </a>
                            {isLoading && (
                              <div className=" position-absolute loadericon">
                                <BeatLoader
                                  loading={true}
                                  size={8}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </div>
                            )}

                            <i className="fas fa-search position-absolute top-50 end-0 translate-middle-y me-3 searchicon" />

                            <SearchHeader
                              storeId={
                                homePageDataModel?.storeDetails?.id ?? ""
                              }
                            />
                          </div>

                          <div className="navbar-toolbar d-none  d-md-flex flex-shrink-0 align-items-center">
                            <div className="list-inline-item ms-3 me-3"></div>

                            <div
                              className="navbar-tool text-header ms-1  me-lg-3"
                              // href="#loginModal"
                              // data-bs-toggle="modal"
                            >
                              {isLoginSuccessFul ? (
                                <UserDropdownItems />
                              ) : (
                                <a
                                  onClick={() => {
                                    handleNavigation(
                                      Routes_Constants.AUTHENTICATE,
                                      LoginLoadable
                                    );
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="navbar-tool-icon-box">
                                      <i className="navbar-tool-icon fas fa-user " />
                                    </div>
                                    <div className="navbar-tool-text ms-n3 text-header">
                                      Login/Register
                                    </div>
                                  </div>
                                </a>
                              )}
                            </div>
                            <div className="navbar-tool dropdown  ms-3">
                              <CartPopoverContainer />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </header>
                );
              case "megamenuheader_categorypopup":
                return (
                  <MegaMenuHeaderCategoryPopup
                    key={item.id}
                    item={item}
                    homePageDataModel={homePageDataModel}
                  />
                );
              case "megamenuheader_normal":
                return (
                  <Header2
                    key={item.id}
                    item={item}
                    homePageDataModel={homePageDataModel}
                  />
                );
              case "header_categorieshorizontalblock":
                return (
                  <Header3
                    key={item.id}
                    item={item}
                    homePageDataModel={homePageDataModel}
                  />
                );
              case "header_normalwithsearch":
                return (
                  <HeaderNormal
                    key={item.id}
                    item={item}
                    homePageDataModel={homePageDataModel}
                  />
                );
              case "megamenu_pro":
                return (
                  <MegaMenuPro
                    key={item.id}
                    homePageDataModel={homePageDataModel}
                    item={item}
                  />
                );
              case "info_headerwithproductandgiftcard":
                return (
                  <InfoHeaderWithProductAndGiftCard
                    homePageDataModel={homePageDataModel}
                  />
                );
              case "megamenu_multilevelvertical":
                return (
                  <MegaMenuMultiLevelVertical
                    homePageDataModel={homePageDataModel}
                    item={item}
                  />
                );
            }
          })}
      </header>
    </>
  );
};

export default Header;
