/** @format */

import React from "react";
import ProductSingleContainer from "../../../containers/product_single_container/product_single_container";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { TemplatePageCategoryDetail } from "../../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import { useApiCalls } from "../../../utils/useApiCalls";

/** @format */
interface ProductsWithAdsImageProps {
  item: TemplatePageCategoryDetail;
}

const ProductsWithAdsImage: React.FC<ProductsWithAdsImageProps> = ({
  item,
}) => {
  const { getAllFilterProducts } = useApiCalls();
  const dispatch = useAppDispatch();

  return (
    <section className="container pt-50 pb-50" key={item.id}>
      <div className="row">
        {/* Banner with controls*/}
        <div className="col-md-5">
          <div className="d-flex flex-column h-100 overflow-hidden rounded-3 secondary-color">
            <div
              className=" pt-5 px-5 px-xl-5 py-5 rounded"
              style={{
                background: `url(${item.templatePageCategorySectionDetails?.[0]?.imageUrl})no-repeat`,
                backgroundSize: "cover",
                height: "100%",
                backgroundPosition: "50%",
              }}
            >
              <div>
                <h3 className="fw-bold text-white">
                  {item.templatePageCategorySectionDetails?.[0]?.title}
                </h3>
                <p className="text-white fs-4 mb-4">
                  {item.templatePageCategorySectionDetails?.[0]?.description}
                </p>
                {item.templatePageCategorySectionDetails?.[0]?.linkText && (
                  <a
                    href={item.templatePageCategorySectionDetails?.[0]?.link}
                    target={"_blank"}
                    className="btn btn-primary"
                  >
                    {item.templatePageCategorySectionDetails?.[0]?.linkText}
                    <i className="feather-icon icon-arrow-right ms-1" />
                  </a>
                )}
              </div>
            </div>
            {/* <a class="d-none d-md-block mt-auto" href="productshop.html"><img class="d-block w-100" src="assets/images/banner11.webp" alt=""></a> */}
          </div>
        </div>
        {/* Product grid (carousel)*/}
        <div className="col-md-7 pt-4 pt-md-0">
          <div className="row row-cols-2 row-cols-xxl-3 row-cols-md-3 g-2">
            {item.templatePageCategorySectionDetails?.[0]?.products
              ?.slice(0, 6)
              ?.map((item) => {
                return (
                  <div key={item.id}>
                    <ProductSingleContainer product={item} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductsWithAdsImage;
