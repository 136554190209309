/** @format */

import ActionTypes from "../../../constants/action_types";
import MegaMenuInterface from "./MegaMenuInterface";

/** @format */

const initialState: MegaMenuInterface = {
  isLoading: false,
};

const reducer = (state = initialState, action: any): MegaMenuInterface => {
  switch (action.type) {
    case ActionTypes.GET_ALL_MEGA_MENUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.GET_ALL_MEGA_MENUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        megaMenuDataModel: action.payload.data,

        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.GET_ALL_MEGA_MENUS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default reducer;
