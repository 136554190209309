/** @format */

import { message } from "antd";
import ActionTypes from "../../../constants/action_types";
import MakePaymentInterface, { DeliveryAddress } from "./MakePaymentInterface";

/** @format */

const initialState: MakePaymentInterface = {
  isLoading: false,
};

const reducer = (state = initialState, action: any): MakePaymentInterface => {
  switch (action.type) {
    case ActionTypes.REDEEM_VOUCHER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isCheckVoucherSuccess: false,
      };
    case ActionTypes.REDEEM_VOUCHER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successMessage: action.payload?.data?.message?.[0]?.message,
        voucherDiscount: action.payload?.data?.discountPercentage,
        isCheckVoucherSuccess: true,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.REDEEM_VOUCHER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isCheckVoucherSuccess: false,
        voucherDiscount: "",
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.ADD_DELIVERY_LOCATION_REQUEST:
      message.loading("Loading...");
      return {
        ...state,
        addDeliverLocationLoading: true,
        isAddDeliveryLocationSuccess: false,
      };
    case ActionTypes.ADD_DELIVERY_LOCATION_SUCCESS:
      message.destroy();
      return {
        ...state,
        addDeliverLocationLoading: false,
        isAddDeliveryLocationSuccess: true,
        deliveryPrice: action.payload?.data?.price,
        successMessage: action.payload?.data?.message?.[0]?.message,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.ADD_DELIVERY_LOCATION_FAILURE:
      message.destroy();

      return {
        ...state,
        addDeliverLocationLoading: false,
        deliveryPrice: undefined,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.GET_DELIVERY_LOCATIONS_OF_USER_REQUEST:
      message.loading("Getting Your Delivery Addresses..");
      return {
        ...state,
      };
    case ActionTypes.GET_DELIVERY_LOCATIONS_OF_USER_SUCCESS:
      message.destroy();

      const newData = action.payload?.data;

      // Check if each item in newData already exists in state.deliveryAddresses
      const updatedDeliveryAddresses = newData.filter(
        (newAddress: DeliveryAddress) =>
          !state.deliveryAddresses?.some(
            (existingAddress) => existingAddress.id === newAddress.id
          )
      );

      return {
        ...state,
        deliveryAddresses: [
          ...(state.deliveryAddresses ?? []),
          ...updatedDeliveryAddresses,
        ],
      };

    case ActionTypes.GET_DELIVERY_LOCATIONS_OF_USER_SUCCESS:
      message.destroy();
      return {
        ...state,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };

    case ActionTypes.MAKE_PAYMENT_REQUEST:
      return {
        ...state,
        makePaymentLoading: true,
        isTransactionInitiated: true,
        isMakePaymentSuccessful: false,
      };
    case ActionTypes.MAKE_PAYMENT_SUCCESS:
      return {
        ...state,
        makePaymentLoading: false,
        isMakePaymentSuccessful: true,
        makePaymentResponse: action.payload?.data,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.MAKE_PAYMENT_FAILURE:
      return {
        ...state,
        makePaymentLoading: false,
        isMakePaymentSuccessful: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.REMOVE_CHECK_VOUCHER:
      return {
        ...state,
        isCheckVoucherSuccess: false,
        voucherDiscount: "",
      };
    case ActionTypes.MAKE_TRANSACTION_COMPLETED:
      return {
        ...state,
        isTransactionInitiated: false,
      };
    case ActionTypes.RESET_MAKE_PAYMENT_STATE:
      return {
        ...state,
        error: null,
        successMessage: null,
        isMakePaymentSuccessful: false,
        isTransactionInitiated: false,
      };
    case "CLEAR_DELIVERY_STATE":
      return {
        ...state,
        deliveryAddresses: undefined,
        deliveryPrice: undefined,
        isAddDeliveryLocationSuccess: false,
        isCheckVoucherSuccess: false,
        voucherDiscount: undefined,
      };
    default:
      return state;
  }
};

export default reducer;
