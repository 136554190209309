/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ActionTypes from "../../constants/action_types";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useSnackbar from "../../hooks/useSnackbar";
import ProductDetails from "../../pages/product-details/ProductDetails";
import {
  FilterTypeOption,
  PriceVariation,
  ProductFilterTypeFilterOption,
  ProductImage,
  ProductPriceVariationModifiers,
} from "../../redux/reducers/ProductDetailsReducer/ProductDetailsInterface";

const ProductDetailsCopy: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isAddToCartPopupOpen } = useAppSelector((state) => state.common);

  const { slug } = useParams<{ slug: string }>();
  const [activeImage, setActiveImage] = useState<ProductImage | undefined>(
    undefined
  );
  const [activePriceVariation, setActivePriceVariation] = useState<
    PriceVariation | undefined
  >(undefined);
  const [filterTypeFilterOptions, setFilterTypeFilterOptions] = useState<
    ProductFilterTypeFilterOption[] | []
  >([]);
  const [selectedPriceModifers, setSelectedPriceModifiers] = useState<
    ProductPriceVariationModifiers[] | []
  >([]);
  const [quantity, setQuantity] = useState<number>(1);
  const { productDetailsDataModel, isLoading, isProductFetchSuccesful } =
    useAppSelector((state) => state.productDetails);
  const [customDescription, setCustomDescription] = useState<string>("");

  const [activeFilterOption, setActiveFilterOption] = useState<
    FilterTypeOption | undefined
  >();
  const [isFilterOptionDrawerOpen, setIsFilterOptionDrawerOpen] =
    useState<boolean>(false);

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (!productDetailsDataModel && !isAddToCartPopupOpen) {
      dispatch({
        type: ActionTypes.GET_PRODUCT_DETAILS_REQUEST,
        payload: {
          Slug: slug,
        },
      });
      dispatch({
        type: ActionTypes.SET_ACTIVE_SLUG,
        payload: slug,
      });
    }
  }, []);

  // settings the active product image
  useEffect(() => {
    if (
      productDetailsDataModel?.productImages &&
      productDetailsDataModel.productImages.length > 0
    ) {
      if (
        productDetailsDataModel.productImages?.find((item) => item.isDefault)
      ) {
        setActiveImage(
          productDetailsDataModel.productImages?.find((item) => item.isDefault)
        );
      } else {
        setActiveImage(productDetailsDataModel.productImages[0]);
      }
    }
  }, [productDetailsDataModel?.productImages]);

  const onActiveImageChange = (image: ProductImage) => {
    setActiveImage(image);
  };

  // setting the active price variation
  useEffect(() => {
    if (
      productDetailsDataModel?.productPriceVariations?.priceVariations &&
      productDetailsDataModel?.productPriceVariations?.priceVariations.length >
        0
    ) {
      const defaultVariation: PriceVariation | undefined =
        productDetailsDataModel?.productPriceVariations?.priceVariations?.find(
          (item, index) => item.isDefault
        );
      if (!defaultVariation) {
        const firstVariation: PriceVariation | undefined =
          productDetailsDataModel?.productPriceVariations?.priceVariations?.[0];
        setActivePriceVariation(firstVariation);
      } else {
        setActivePriceVariation(defaultVariation);
      }
    } else {
      setActivePriceVariation(undefined);
    }
  }, [productDetailsDataModel?.productPriceVariations]);

  useEffect(() => {
    if (
      productDetailsDataModel?.productFilterTypeFilterOptions &&
      productDetailsDataModel?.productFilterTypeFilterOptions.length > 0
    ) {
      setFilterTypeFilterOptions(
        productDetailsDataModel?.productFilterTypeFilterOptions
      );
    }
  }, [productDetailsDataModel?.productPriceVariations]);

  const onActivePriceVariationChange = (priceVariation: PriceVariation) => {
    setActivePriceVariation(priceVariation);
  };

  return (
    <ProductDetails
      productDetailsDataModel={productDetailsDataModel}
      activeImage={activeImage}
      onActiveImageChange={onActiveImageChange}
      activePriceVariation={activePriceVariation}
      onActivePriceVariationChange={onActivePriceVariationChange}
      showSnackbar={showSnackbar}
      quantity={quantity}
      setQuantity={setQuantity}
      filterTypeFilterOptions={filterTypeFilterOptions}
      setFilterTypeFilterOptions={setFilterTypeFilterOptions}
      activeFilterOption={activeFilterOption}
      setActiveFilterOption={setActiveFilterOption}
      isFilterOptionDrawerOpen={isFilterOptionDrawerOpen}
      setIsFilterOptionDrawerOpen={setIsFilterOptionDrawerOpen}
      selectedPriceModifiers={selectedPriceModifers}
      setSelectedPriceModifiers={setSelectedPriceModifiers}
      customDescription={customDescription}
      setCustomDescription={setCustomDescription}
    />
  );
};

export default ProductDetailsCopy;
