/** @format */

import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input } from "antd";
import React, { useEffect } from "react";
import Loadable from "react-loadable";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import {
  getLocalStorage,
  removeLocalStorage,
} from "../../Helpers/FrontendHelper";
import ActionTypes from "../../constants/action_types";
import {
  EMAIL_VALIDATION_MESSAGE,
  EMPTY_FIELD_MESSAGE,
} from "../../constants/definitions";
import Routes_Constants from "../../constants/routes_configs";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useSnackbar from "../../hooks/useSnackbar";
import { LoginFormValues } from "../../types/commonInterfaces";
import { useApiCalls } from "../../utils/useApiCalls";
import { useRetailHelper } from "../../utils/useRetailHelper";
import { validatePassword } from "../../utils/validators";

const SendOtpLoadable = Loadable({
  loader: () =>
    import("../../containers/send_otp_container/send_otp_container"),
  loading: () => null,
});
const RegisterLoadable = Loadable({
  loader: () =>
    import("../../containers/register_container/register_container"),
  loading: () => null,
});
interface LoginProps {
  popupMessage: string;
}

const LoginPopup: React.FC<LoginProps> = ({ popupMessage }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading, error, isLoginSuccessFul } = useAppSelector(
    (state) => state.authentication
  );
  const { callback } = useAppSelector((state) => state.common);
  const [loginForm] = Form.useForm<LoginFormValues>();
  const { showSnackbar } = useSnackbar();
  const { handleNavigation } = useRetailHelper();
  const { addProductToWishlist } = useApiCalls();

  const onLoginFormSubmit = (values: LoginFormValues) => {
    dispatch({
      type: ActionTypes.AUTHENTICATE_USER_REQUEST,
      payload: values as LoginFormValues,
    });
  };
  useEffect(() => {
    if (error) {
      showSnackbar(error, {
        variant: "error",
      });
      dispatch({
        type: ActionTypes.RESET_AUTHENTICATION_STATE,
      });
    }
  }, [error]);

  useEffect(() => {
    if (isLoginSuccessFul) {
      // Check for the stored redirect path in localStorage
      const redirectPath = getLocalStorage("redirectPath");

      if (redirectPath) {
        navigate(redirectPath);

        // If a redirect path is found, navigate the user to that path and remove it from localStorage
        removeLocalStorage("redirectPath");
      } else {
        // this is for unauthorized wishlist
        if (callback) {
          addProductToWishlist(callback);
        }
      }
      // Dispatch action to close the login popup
      dispatch({
        type: ActionTypes.CLOSE_LOGIN_POPUP,
      });
    }
  }, [isLoginSuccessFul]);

  return (
    <>
      <div className="text-center mt-4">
        <h6 className="text-center fw-bold text-theme">Login</h6>
        <small className="text-muted">{popupMessage}</small>
      </div>

      <Form
        onFinish={onLoginFormSubmit}
        form={loginForm}
        labelAlign="left"
        labelCol={{
          span: 24,
        }}
        name="normal_login"
        className="login-form"
        //   onFinish={onFinish}
      >
        <div className="row ">
          {" "}
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: EMPTY_FIELD_MESSAGE,
              },
              {
                type: "email",
                message: EMAIL_VALIDATION_MESSAGE,
              },
            ]}
          >
            <Col span={24}>
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Please Enter Email"
              />
            </Col>
          </Form.Item>
        </div>
        <div className="row">
          {" "}
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: EMPTY_FIELD_MESSAGE,
              },
              {
                validator: validatePassword,
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Please Enter Password"
            />
          </Form.Item>
        </div>

        <div className="row mb-3 px-3">
          <Button
            htmlType="submit"
            type="primary"
            className="d-flex align-items-center justify-content-center gap-2"
            loading={isLoading}
          >
            {isLoading && (
              <BeatLoader
                loading={true}
                color="white"
                size={8}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
            {isLoading ? "Logging In..." : "Login"}
          </Button>
          <div className="row mt-3 justify-content-end">
            {" "}
            <small className="font-weight-bold d-flex justify-content-end gap-1">
              Don't have an account?{" "}
              <a
                onClick={() => {
                  handleNavigation(Routes_Constants.REGISTER, RegisterLoadable);
                }}
                className="text-danger "
              >
                Register
              </a>
            </small>{" "}
          </div>
        </div>
      </Form>
    </>
  );
};

export default LoginPopup;
