/** @format */

import React from "react";
import { TemplatePageCategoryDetail } from "../../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import LazyImage from "../../../utils/Lazy Image/LazyImage";

/** @format */
interface CollageAdsProps {
  item: TemplatePageCategoryDetail;
}

const CollageAds: React.FC<CollageAdsProps> = ({ item }) => {
  return (
    <section className=" pb-50 mt-3" key={item.id}>
      <div className="container">
        <div className="homemidbanner">
          <div className="row">
            <div className=" col-md-4">
              <div className="bannerimg h-100">
                <LazyImage
                  link={item?.imageAds?.[0]?.link}
                  className="img-fluid w-100 h-100"
                  alt="subbanner1"
                  src={item?.imageAds?.[0]?.imageUrl}
                />
              </div>
            </div>
            <div className=" col-md-3">
              <div className="bannerimg">
                <LazyImage
                  link={item?.imageAds?.[0]?.link}
                  className="img-fluid w-100"
                  alt="subbanner2"
                  src={item?.imageAds?.[1]?.imageUrl}
                />

                <LazyImage
                  link={item?.imageAds?.[0]?.link}
                  className="img-fluid mt-4 w-100"
                  alt="subbanner2"
                  src={item?.imageAds?.[2]?.imageUrl}
                />
              </div>
            </div>
            <div className="col-md-5">
              <div className="bannerimg h-100">
                <LazyImage
                  link={item?.imageAds?.[0]?.link}
                  className="img-fluid w-100 h-100"
                  alt="subbanner2"
                  src={item?.imageAds?.[3]?.imageUrl}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CollageAds;
