/** @format */

import { LockOutlined, LogoutOutlined } from "@ant-design/icons";
import { Button, Form, FormInstance, Input, Select, Tooltip } from "antd";
import { Helmet } from "react-helmet";
import Loadable from "react-loadable";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import CustomBreadcrumb from "../../components/custom_breadcrump/CustomBreadcrumb";
import Layout from "../../components/layout/Layout";
import PageLoader from "../../components/page_loader/PageLoader";
import ActionTypes from "../../constants/action_types";
import {
  EMAIL_VALIDATION_MESSAGE,
  EMPTY_FIELD_MESSAGE,
} from "../../constants/definitions";
import Routes_Constants from "../../constants/routes_configs";
import { ImageState } from "../../containers/profile_container/profile_container";
import useAppDispatch from "../../hooks/useAppDispatch";
import { Country } from "../../redux/reducers/CommonReducer/CommonReducerInterface";
import { ProfileDetailsDataModel } from "../../redux/reducers/ProfileReducer/ProfileReducerInterface";
import { ProfileFormValues } from "../../types/commonInterfaces";
import { useRetailHelper } from "../../utils/useRetailHelper";
import { validateNumber } from "../../utils/validators";

const ChangePasswordProfileLoadable = Loadable({
  loader: () =>
    import(
      "../../containers/change-password-profile-container/chnge-password-profile-container"
    ),
  loading: () => null,
});

interface ProfileDetailsProps {
  profileForm: FormInstance<ProfileFormValues>;
  onProfileUpdateSubmitHandler: (values: ProfileFormValues) => void;
  countries?: Country[];
  image: ImageState;
  setImage: React.Dispatch<React.SetStateAction<ImageState>>;
  isLoading: boolean;
  profileDetails: ProfileDetailsDataModel | undefined;
}

const Profile: React.FC<ProfileDetailsProps> = ({
  profileForm,
  onProfileUpdateSubmitHandler,
  countries,
  image,
  setImage,
  isLoading,
  profileDetails,
}) => {
  const { getDefaultSelectedCountry, handleNavigation } = useRetailHelper();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <>
      <Layout>
        <Helmet>
          <title>{"My Account"}</title>

          <meta property="og:url" content={window.location.href} />
        </Helmet>
        <PageLoader loading={!profileDetails}>
          <section className="pt-3 pb-3">
            <div className="container">
              <CustomBreadcrumb items={[{ title: "Profile", link: "" }]} />
              <div className="tab-vertical1">
                <div className="row g-0 d-flex gap-4 justify-content-center">
                  <div className="text-center pb-0">
                    <h6 className="text-center fw-bold text-theme">
                      Manage Your Profile
                    </h6>
                    <small className="text-muted">
                      Update your personal information and keep your details
                      accurate.
                    </small>
                  </div>
                  <div className="col-md-3">
                    <div className="card  rounded-3 border-0">
                      <div className="card-body">
                        <div className="d-md-flex justify-content-center align-items-center text-center text-md-start p-2">
                          <div className="d-md-flex align-items-center flex-column justify-content-center align-items-center">
                            <div className="img-thumbnail rounded-circle position-relative flex-shrink-0 mx-auto mb-2 mx-md-0 mb-md-0">
                              <label
                                htmlFor="profileImage"
                                style={{ cursor: "pointer" }}
                              >
                                <Tooltip title="Change Image">
                                  <img
                                    className="rounded-circle"
                                    src={
                                      image.url
                                        ? image.url
                                        : "assets/images/error_Lazy/Loading.png"
                                    }
                                    style={{
                                      borderRadius: "100%",
                                      width: "80px",
                                      height: "80px",
                                    }}
                                    alt="Profile Image"
                                  />
                                  <input
                                    type="file"
                                    id="profileImage"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      const selectedFile =
                                        event.target.files &&
                                        event.target.files[0];

                                      if (selectedFile) {
                                        const imageUrl =
                                          URL.createObjectURL(selectedFile);
                                        setImage({
                                          file: selectedFile,
                                          url: imageUrl,
                                        });
                                      }
                                    }}
                                  />
                                </Tooltip>
                              </label>
                            </div>
                            <div className="ps-md-3">
                              <h3 className="fs-16 mb-0 fw-bold mt-2 text-center">
                                {profileForm.getFieldValue("fullName")}
                              </h3>
                              <span className="text-accent fs-sm">
                                {profileForm.getFieldValue("email")}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center gap-2 flex-column">
                          <Button
                            onClick={() => {
                              dispatch({
                                type: ActionTypes.LOGOUT_REQUEST,
                              });
                              navigate(Routes_Constants.HOME);
                            }}
                            type="default"
                            icon={<LogoutOutlined />}
                          >
                            Sign Out
                          </Button>
                          <Button
                            onClick={() => {
                              handleNavigation(
                                Routes_Constants.CHANGE_PASSWORD_PROFILE,
                                ChangePasswordProfileLoadable
                              );
                            }}
                            type="default"
                            icon={<LockOutlined />}
                          >
                            Change Password
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <Form
                      form={profileForm}
                      onFinish={onProfileUpdateSubmitHandler}
                      labelAlign="left"
                      labelCol={{
                        span: 24,
                      }}
                      fields={[
                        {
                          name: ["countryId"],
                          value: getDefaultSelectedCountry(),
                        },
                        {
                          name: ["countryPhoneNumberPrefixId"],
                          value: getDefaultSelectedCountry(),
                        },
                      ]}
                      name="normal_login"
                    >
                      <div className="card acc_setting border-0">
                        <div className="card-body">
                          <div className=" bg-white">
                            <div className="py-2">
                              <div className="row">
                                <div className="col-md-6">
                                  {" "}
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        message: EMPTY_FIELD_MESSAGE,
                                      },
                                    ]}
                                    label="Full Name"
                                    name="fullName"
                                  >
                                    <Input placeholder="Full Name" />
                                  </Form.Item>
                                </div>
                                <div className="col-md-6">
                                  {" "}
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        message: EMPTY_FIELD_MESSAGE,
                                      },
                                      {
                                        type: "email",
                                        message: EMAIL_VALIDATION_MESSAGE,
                                      },
                                    ]}
                                    label="Email"
                                    name="email"
                                  >
                                    <Input placeholder="Email" readOnly />
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="row ">
                                <div className="col-md-6 pt-md-0 pt-3">
                                  {" "}
                                  <Form.Item
                                    label="Country"
                                    name="countryId"
                                    rules={[
                                      {
                                        required: true,
                                        message: EMPTY_FIELD_MESSAGE,
                                      },
                                    ]}
                                  >
                                    <Select placeholder="Country">
                                      {countries &&
                                        countries?.map((item) => (
                                          <Select.Option
                                            value={item.id}
                                            key={item.id}
                                          >
                                            {" "}
                                            <span style={{ fontSize: "14px" }}>
                                              <img
                                                src={item.imageUrl}
                                                style={{
                                                  height: "20px",
                                                  width: "30px",
                                                  marginRight: "4px",
                                                }}
                                              />
                                              {item.value}
                                            </span>
                                          </Select.Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </div>
                                <div className="col-md-6">
                                  <Form.Item
                                    label="Phone"
                                    name="phoneNumber"
                                    rules={[
                                      {
                                        required: true,
                                        message: EMPTY_FIELD_MESSAGE,
                                      },
                                      {
                                        validator: validateNumber,
                                      },
                                    ]}
                                  >
                                    <Input
                                      addonBefore={
                                        <Form.Item
                                          name="countryPhoneNumberPrefixId"
                                          noStyle
                                        >
                                          <Select
                                            placeholder="Prefix"
                                            style={{
                                              width: 120,
                                            }}
                                          >
                                            {countries &&
                                              countries?.map((item) => (
                                                <Select.Option
                                                  value={item.id}
                                                  key={item.id}
                                                >
                                                  {" "}
                                                  <span
                                                    style={{
                                                      fontSize: "14px",
                                                    }}
                                                  >
                                                    {
                                                      item.countryPhoneNumberPrefix
                                                    }
                                                  </span>
                                                </Select.Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      }
                                      placeholder="Phone"
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="row">
                                <div
                                  className="col-md-6 pt-md-0 pt-3"
                                  id="lang"
                                >
                                  {" "}
                                  <Form.Item
                                    rules={[
                                      {
                                        validator: validateNumber,
                                      },
                                    ]}
                                    label="Postal Code"
                                    name="postalCode"
                                  >
                                    <Input placeholder="Postal Code" />
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-3 pt-md-0 pt-3">
                                  <Button
                                    htmlType="submit"
                                    type="primary"
                                    loading={isLoading}
                                    className="w-100"
                                  >
                                    {isLoading && (
                                      <BeatLoader
                                        loading={true}
                                        color="white"
                                        size={8}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                        className="me-2"
                                      />
                                    )}
                                    {isLoading ? "Updating.." : "Update"}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </PageLoader>
      </Layout>
    </>
  );
};

export default Profile;
