/** @format */

import { Tooltip } from "antd";
import React from "react";
import ActionTypes from "../../constants/action_types";
import { UNAUTHORIZED_WISHLIST_MESSAGE } from "../../constants/definitions";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { SnackbarHandler } from "../../hooks/useSnackbar";
import {
  Image,
  Product,
} from "../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import LazyImage from "../../utils/Lazy Image/LazyImage";
import useProtectedNavigation from "../../utils/handleProtectedNavigation";
import isMobile from "../../utils/isMobile";
import { useApiCalls } from "../../utils/useApiCalls";
import { useRetailHelper } from "../../utils/useRetailHelper";
interface ProductProps {
  product: Product;
  className?: string;
  showSnackbar: SnackbarHandler;
  viewType?: string;
  hideAddToCartButton?: boolean;
}

const ProductSingle: React.FC<ProductProps> = ({
  product,
  className,
  showSnackbar,
  viewType,
  hideAddToCartButton,
}) => {
  const {
    openCartPopover,
    addProductToCart,
    currencySymbol,
    isProductInWishlist,
  } = useRetailHelper();
  const {
    getProductDetailBySlug,
    addProductToWishlist,
    deleteWishlistProduct,
  } = useApiCalls();
  const dispatch = useAppDispatch();
  const [handleProtectedNavigation] = useProtectedNavigation();
  const { wishlistDetails } = useAppSelector((state) => state.wishlist);

  return (
    <div
      key={product.id}
      className="card product-card card-static pb-1 pb-md-3 h-100"
      style={viewType == "list" ? { flexDirection: "row" } : {}}
    >
      <span className="badge bg-danger badge-shadow">
        {parseFloat(product.discountPercentage) > 0
          ? product.discountPercentage + "% Off"
          : ""}
      </span>
      <Tooltip
        title={
          isProductInWishlist(product.id)
            ? "Remove From Wishlist"
            : "Add To Wishlist"
        }
      >
        <button
          onClick={() => {
            if (isProductInWishlist(product.id)) {
              const wishlist = wishlistDetails?.find(
                (item) => item.productId == product.id
              );
              deleteWishlistProduct(wishlist?.id ?? "");
            } else {
              handleProtectedNavigation(
                UNAUTHORIZED_WISHLIST_MESSAGE,
                "",
                product.id
              );
            }
          }}
          className="wishlist_btn btn-sm m-2"
          style={isProductInWishlist(product.id) ? {} : { color: "black" }}
          type="button"
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          aria-label="Add to wishlist"
          data-bs-original-title="Add to wishlist"
        >
          {isProductInWishlist(product.id) ? (
            <i className="fas fa-heart" style={{ fontSize: "20px" }} />
          ) : (
            <i className="far fa-heart" style={{ fontSize: "20px" }} />
          )}
        </button>
      </Tooltip>

      <span
        onClick={() => {
          dispatch({
            type: ActionTypes.SET_ACTIVE_SLUG,
            payload: product.slug,
          });
          getProductDetailBySlug(product.slug);
        }}
      >
        <LazyImage
          // need to remove the code later
          style={viewType == "list" && isMobile ? { width: "150px" } : {}}
          src={
            product.images?.length > 0
              ? product.images.find((product: Image) => product?.isDefault)
                  ?.imageUrl
              : product.imageUrl
          }
          alt="Product"
          className="cursor-pointer"
        />
      </span>

      <div className="card-body py-2">
        <a className="product-meta d-block fs-xs pb-1">
          {product.category} {product.brand ? product.brand : ""}
        </a>
        <h3
          onClick={() => {
            dispatch({
              type: ActionTypes.SET_ACTIVE_SLUG,
              payload: product.slug,
            });
            getProductDetailBySlug(product.slug);
          }}
          className="product-title fs-sm cursor-pointer"
        >
          <a> {product.name}</a>
        </h3>
        <div className="text-success fs-12 fw-bold my-1">
          {product.promotionalMessage}
        </div>
        <div className="product-price">
          <span className="text-accent">
            {currencySymbol()}{" "}
            {product.discountPercentage
              ? product.discountPrice
              : product.actualPrice}
          </span>
          <del className="fs-sm text-muted">
            {parseFloat(product.discountPercentage) > 0 && (
              <del className="fs-sm text-muted">
                {currencySymbol()} {product.actualPrice}
              </del>
            )}
          </del>
        </div>
      </div>
      {!hideAddToCartButton && (
        <div className="product-floating-btn d-none d-md-block">
          <button
            className="btn btn-primary btn-shadow btn-sm"
            type="button"
            onClick={() => {
              dispatch({
                type: ActionTypes.OPEN_ADD_TO_CART_POPUP,
              });
              dispatch({
                type: ActionTypes.GET_PRODUCT_DETAILS_COPY_WITHOUT_REDIRECTION_REQUEST,
                payload: {
                  Slug: product.slug,
                },
              });

              // showSnackbar(ADDED_TO_CART_MESSAGE, {
              //   variant: "success",
              // });

              // addProductToCart({
              //   productId: product.id,
              //   name: product.name,
              //   price: product.discountPercentage
              //     ? product.discountPrice
              //     : product.actualPrice,
              //   quantity: 1,
              //   productVariationId: product.productVariationId ?? "",
              //   imageUrl: product.images.find((product) => product?.isDefault)
              //     ?.imageUrl,
              //   tax: product.productTax,
              //   slug: product.slug,
              //   storeId: product.storeId,
              //   productVariation: product.productVariations?.find(
              //     (item) => item.isDefault
              //   ),
              // });
            }}
          >
            <i className="fas fa-shopping-cart fs-base " />
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductSingle;
