/** @format */

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Modal, Result, Typography } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import useAppSelector from "../../hooks/useAppSelector";
import isMobile from "../../utils/isMobile";

interface SendGiftCardProps {
  isSendGiftCardOpen: boolean;
  setIsSendGiftCardOpen: Dispatch<SetStateAction<boolean>>;
}

const SendGiftCard: React.FC<SendGiftCardProps> = ({
  isSendGiftCardOpen,
  setIsSendGiftCardOpen,
}) => {
  const {
    sendGiftCardLoading,
    isSendGiftCardSuccessful,
    successMessage,
    error,
  } = useAppSelector((state) => state.giftCard);

  return (
    <Modal
      visible={isSendGiftCardOpen}
      onCancel={() => {
        setIsSendGiftCardOpen(false);
      }}
      maskClosable={false}
      footer={null}
      closable={isMobile ? true : false}
      centered
      style={{ maxWidth: "80vw" }}
      closeIcon={isMobile ? true : false}
    >
      {isSendGiftCardOpen && (
        <div
          className="custom-close-button"
          onClick={() => setIsSendGiftCardOpen(false)}
        >
          <CloseOutlined className="close-icon" />
        </div>
      )}
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "40vh" }}
      >
        {sendGiftCardLoading ? (
          <div className="d-flex flex-column gap-4 justify-content-center align-items-center text-align-center">
            <div
              className="loader-container"
              style={{ width: "55px", margin: "auto", padding: "10px" }}
            >
              <ClipLoader
                loading={true}
                color="black"
                size={34}
                aria-label="Loading Spinner"
                data-testid="loader"
                className="loader-page"
              />
            </div>
            <Typography.Text strong style={{ textAlign: "center" }}>
              Please Wait...Processing Your Gift Card Request.
            </Typography.Text>
          </div>
        ) : (
          <Result
            style={{ padding: "0" }}
            status={sendGiftCardLoading ? "success" : "error"}
            title={
              isSendGiftCardSuccessful ? "Gift Card Sent Successfully!" : error
            }
            icon={
              isSendGiftCardSuccessful ? (
                <CheckCircleOutlined
                  style={{ fontSize: 80, color: "#52c41a" }}
                />
              ) : (
                <CloseCircleOutlined
                  style={{ fontSize: 80, color: "#f5222d" }}
                />
              )
            }
          ></Result>
        )}
      </div>
    </Modal>
  );
};

export default SendGiftCard;
