/** @format */

import { Button } from "antd";
import DOMPurify from "dompurify";
import React from "react";
import Carousel from "react-bootstrap/Carousel";
import {
  ImageAd,
  TemplatePageCategoryDetail,
} from "../../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import LazyImage from "../../../utils/Lazy Image/LazyImage";

/** @format */
interface Banner1Props {
  item: TemplatePageCategoryDetail;
}

const Banner1: React.FC<Banner1Props> = ({ item }) => {
  return (
    <div
      className="container"
      key={item.id}
      style={
        item?.imageAds?.length > 0
          ? { position: "relative" }
          : { display: "none" }
      }
    >
      {/* <div className="overlay" /> */}
      <Carousel indicators={false}>
        {item?.imageAds?.map((item: ImageAd, index) => {
          return (
            <Carousel.Item key={index}>
              <LazyImage
                link={item?.link}
                className="d-block w-100"
                src={item.imageUrl}
              />
              <div className="row">
                <div className="col-md-6">
                  <Carousel.Caption>
                    {item.title && <h1>{item.title}</h1>}
                    {item.description && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item.description ?? ""),
                        }}
                      />
                    )}

                    {item.linkText && (
                      <a href={item.link}>
                        <Button className="banner-btn" danger>
                          {item.linkText}
                        </Button>
                      </a>
                    )}
                  </Carousel.Caption>
                </div>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default Banner1;
