/** @format */

const Routes_Constants = {
  HOME: "/",
  AUTHENTICATE: "/authenticate",
  REGISTER: "/register",
  SEND_OTP: "/send-otp",
  PROFILE: "/profile",
  ORDER: "/my-orders",
  WISHLIST: "/my-wishlist",
  PRODUCT_DETAILS: "/product/:slug",
  FILTER_PRODUCTS: "/filter-products",
  CHECKOUT: "/checkout",
  FORGET_PASSWORD: "/forget-password",
  CHANGE_PASSWORD: "/change-password",
  CHANGE_PASSWORD_PROFILE: "/change-password-profile",
  GIFT_CARD: "/gift-card",
  MAKE_PAYMENT: "/make-payment",
  PAGE_DETAILS: "/page/:slug",
  CONTACT_US: "/page/contact-us",
  BLOG: "/blogs",
  BLOG_DETAIL: "/blogs/:slug",

  // ... add more route paths
};

export default Routes_Constants;
