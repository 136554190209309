/** @format */

import React from "react";
import Slider from "react-slick";
import { TemplatePageCategoryDetail } from "../../../redux/reducers/HomePageReducer/HomePageReducerInterface";

/** @format */
interface AdsWithContentsProps {
  item: TemplatePageCategoryDetail;
}

const AdsWithContents: React.FC<AdsWithContentsProps> = ({ item }) => {
  return (
    <section className="position-relative pt-50" key={item.id}>
      <div className="container p-1">
        <div className="row  g-1 d-none d-lg-flex">
          {item.imageAds &&
            item?.imageAds?.map((item, index) => {
              return (
                <div className="col-md-4" key={index}>
                  <a href="">
                    <div
                      className="home_banner"
                      style={{
                        backgroundImage: `linear-gradient(180deg,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 100%),url(${item?.imageUrl})`,
                      }}
                    >
                      <div className="homebanner_box fullbox">
                        <div className="fullbox_content">
                          <div className="fullbox_container">
                            <h4 className="homebanner_box_header">
                              <span>{item?.title}</span>
                            </h4>
                            <div className="fullbox_description">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
        {/* mobile view */}
        <div className="homecategories_mobile d-md-none ">
          <Slider
            className="p-0"
            {...{ dots: false, slidesToShow: 1, arrows: false }}
          >
            {item.templatePageCategorySectionDetails &&
              item.templatePageCategorySectionDetails?.[0]?.imageAds?.map(
                (item, index) => {
                  return (
                    <div className="homecat_items" key={index}>
                      <a href="">
                        <div
                          className="home_banner"
                          style={{
                            backgroundImage: `linear-gradient(180deg,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 100%),url(${item?.imageUrl})`,
                          }}
                        >
                          <div className="homebanner_box fullbox">
                            <div className="fullbox_content">
                              <div className="fullbox_container">
                                <h4 className="homebanner_box_header">
                                  <span>{item?.title}</span>
                                </h4>
                                <div className="fullbox_description">
                                  <p>{item?.description}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                }
              )}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default AdsWithContents;
