/** @format */

// /** @format */

import axios from "axios";

// const config = {
//   primaryBaseUrl: "https://api.retail.posapt.au",
//   secondaryBaseUrl: "https://api.retail.posapt.au",
// };

// async function checkServerStatus() {
//   try {
//     const response = await axios.get(
//       `${config.primaryBaseUrl}/api/home/checkapistatus`
//     );
//     return response.status === 200; // Server is up
//   } catch (error) {
//     return false; // Server is down or unreachable
//   }
// }

// const API = axios.create({
//   baseURL: config.primaryBaseUrl,
// });

// // Request interceptor to check server status
// API.interceptors.request.use(async (request) => {
//   const isPrimaryServerUp = await checkServerStatus();
//   if (!isPrimaryServerUp) {
//     // Switch to the secondary server if the primary server is down
//     request.baseURL = config.secondaryBaseUrl;
//   }
//   return request;
// });

// export { API };
export const API = axios.create({
  baseURL: `https://retailapi.posapt.au`,
});
