/** @format */

import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import ActionTypes from "../../constants/action_types";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { PopupDetails } from "../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import LazyImage from "../../utils/Lazy Image/LazyImage";

const SubscribeModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const [subscribeModalOpen, setSubscribeModalOpen] = useState<boolean>(false);
  const [activePopup, setActivePopup] = useState<PopupDetails>();
  const { homePageDataModel } = useAppSelector((state) => state.homePage);
  const { isSubscribePopupClosed } = useAppSelector((state) => state.common);

  useEffect(() => {
    if (
      homePageDataModel?.storeDetails?.popUpDetails &&
      homePageDataModel?.storeDetails?.popUpDetails?.length > 0
    ) {
      setSubscribeModalOpen(true);
      setActivePopup(homePageDataModel?.storeDetails?.popUpDetails[0]);
    }
  }, [homePageDataModel?.storeDetails?.popUpDetails]);

  return (
    <Modal
      open={subscribeModalOpen && !isSubscribePopupClosed}
      onCancel={() => {
        setSubscribeModalOpen(false);
        dispatch({
          type: ActionTypes.MAKE_SUBSCRIBE_POPUP_CLOSED,
        });
      }}
      closable={false}
      closeIcon={false}
      footer={null}
      centered={true}
      maskClosable={false}
    >
      {subscribeModalOpen && !isSubscribePopupClosed && (
        <div
          className="custom-close-button"
          onClick={() => {
            setSubscribeModalOpen(false);
            dispatch({
              type: ActionTypes.MAKE_SUBSCRIBE_POPUP_CLOSED,
            });
          }}
        >
          <CloseOutlined className="close-icon" />
        </div>
      )}
      <div>
        <div className="row text-center">
          {activePopup?.imageUrl ? (
            <LazyImage src={activePopup?.imageUrl} alt="popup-image" />
          ) : (
            <>
              <div className="col-md-12 ">
                {/* <img src="/assets_bakery/images/pop.jpeg" class="img-fluid" alt=""> */}

                <h3 className="text-center ">{activePopup?.title}</h3>
                <br />
              </div>
              <br />
              <p
                style={{ color: "#3D3D4E" }}
                className=" text-center fs-6 mb-3 "
              >
                {activePopup?.description}
              </p>
              <span className="input-group-btn">
                {activePopup?.buttonLink == "" ||
                activePopup?.buttonLink == "#" ? (
                  <a
                    className="btn btn-success"
                    onClick={() => {
                      setSubscribeModalOpen(false);
                    }}
                  >
                    {" "}
                    {activePopup?.buttonLabel}
                  </a>
                ) : (
                  <a
                    target=" _blank"
                    // rel="noopener noreferrer"
                    href={activePopup?.buttonLink}
                    className="btn btn-primary"
                  >
                    {" "}
                    {activePopup?.buttonLabel}
                  </a>
                )}
              </span>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SubscribeModal;
