/** @format */

import { Avatar } from "antd";
import React from "react";
import useAppSelector from "../../../hooks/useAppSelector";
import { TemplatePageCategoryDetail } from "../../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import { useRetailHelper } from "../../../utils/useRetailHelper";

interface InfoHeaderProps {
  item: TemplatePageCategoryDetail;
}

const InfoHeader: React.FC<InfoHeaderProps> = ({ item }) => {
  const { megaMenuDataModel } = useAppSelector((state) => state.megaMenu);
  const { handleRedirectMegaMenu } = useRetailHelper();

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-md-end justify-content-end p-2 gap-2 ">
        {megaMenuDataModel
          ?.find(
            (megaMenu) => megaMenu.templateSectionIdentifier == item.identifier
          )
          ?.megaMenuWithChildrenAndCardsViewModel?.map(
            (parentMegaMenu, index) => {
              return (
                <a
                  style={{ fontSize: "14px" }}
                  key={parentMegaMenu.id}
                  onClick={() =>
                    handleRedirectMegaMenu(parentMegaMenu.link ?? "")
                  }
                >
                  {parentMegaMenu.imageUrl && (
                    <Avatar
                      className="me-2"
                      src={parentMegaMenu.imageUrl}
                    ></Avatar>
                  )}

                  {parentMegaMenu.name}
                </a>
              );
            }
          )}
      </div>
    </div>
  );
};

export default InfoHeader;
