/** @format */

import { Form } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ActionTypes from "../../constants/action_types";
import Routes_Constants from "../../constants/routes_configs";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useSnackbar from "../../hooks/useSnackbar";
import ChangePasswordProfile from "../../pages/change-password-profile/ChangePasswordProfile";
import { ChangePasswordFormValues } from "../../types/commonInterfaces";

const ChangePasswordProfileContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    isLoading,
    error,
    isChangePasswordSuccesful,
    successMessage,
    loginResponse,
  } = useAppSelector((state) => state.authentication);
  const [changePasswordForm] = Form.useForm<ChangePasswordFormValues>();
  const { showSnackbar } = useSnackbar();

  const onChangePasswordFormSubmit = (values: ChangePasswordFormValues) => {
    dispatch({
      type: ActionTypes.CHANGE_PASSWORD_PROFILE_REQUEST,
      payload: {
        oldPassword: values.oldPassword,
        email: loginResponse?.email,
        newPassword: values.newPassword,
        isStaySigned: values.isStaySigned,
      },
    });
  };
  useEffect(() => {
    if (error) {
      showSnackbar(error, {
        variant: "error",
      });
      dispatch({
        type: ActionTypes.RESET_AUTHENTICATION_STATE,
      });
    }
  }, [error]);
  useEffect(() => {
    if (isChangePasswordSuccesful) {
      showSnackbar(successMessage ?? "Change Password Successful ", {
        variant: "success",
      });

      dispatch({
        type: ActionTypes.RESET_AUTHENTICATION_STATE,
      });
      changePasswordForm.resetFields();
      navigate(Routes_Constants.AUTHENTICATE);
    }
  }, [isChangePasswordSuccesful]);

  return (
    <ChangePasswordProfile
      changePasswordForm={changePasswordForm}
      onChangePasswordFormSubmit={onChangePasswordFormSubmit}
      isLoading={isLoading}
    />
  );
};

export default ChangePasswordProfileContainer;
