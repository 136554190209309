/** @format */

// useProtectedNavigation.ts
import Loadable from "react-loadable";
import { useNavigate } from "react-router-dom";
import { getCookie, setLocalStorage } from "../Helpers/FrontendHelper";
import ActionTypes from "../constants/action_types";
import { TOKEN_LOCAL_STORAGE } from "../constants/definitions";
import useAppDispatch from "../hooks/useAppDispatch";
import { useApiCalls } from "./useApiCalls";
import { useRetailHelper } from "./useRetailHelper";

// This Protected Navigation has been used for two cases : Unauthorized wishlist and unauthorized payment

const MakePaymentLoadable: any = Loadable({
  loader: () =>
    import("../containers/make_payment_container/make_payment_container"),
  loading: () => null,
});

const useProtectedNavigation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { handleNavigation } = useRetailHelper();
  const { addProductToWishlist } = useApiCalls();

  const handleProtectedNavigation = (
    popupMessage: string,
    pathname?: string,
    productId?: string
  ) => {
    if (getCookie(TOKEN_LOCAL_STORAGE)) {
      if (productId) {
        addProductToWishlist(productId);
      } else {
        handleNavigation(pathname ?? "", MakePaymentLoadable);
      }
    } else {
      // Dispatch action to open the login popup
      dispatch({
        type: ActionTypes.OPEN_LOGIN_POPUP,
      });
      dispatch({
        type: ActionTypes.SET_LOGIN_POPUP_MESSAGE,
        payload: popupMessage,
      });

      // Save the original pathname in localStorage
      if (pathname) {
        setLocalStorage("redirectPath", pathname);
      } else {
        dispatch({
          type: ActionTypes.SET_CALLBACK_FUNCTION,
          payload: productId,
        });
      }
    }
  };

  return [handleProtectedNavigation];
};

export default useProtectedNavigation;
