/** @format */

const APIEndpoints = {
  INITIALIZE_STORE: "/api/online/initialize",
  // https://develop.api.retail.posapt.au/api/online/getLandingPageDatav2
  GET_LANDING_PAGE_DATA: "/api/online/getLandingPageData",
  GET_STORE_DETAILS: "/api/online/getStoreDetailsById",
  GET_ALL_CATEGORIES: "/api/online/getallCategories",
  GET_PRODUCT_DETAILS: "/api/online/getProductDetailBySlug",
  GET_ALL_PRODUCTS: "/api/online/getAllProducts",
  SEARCH_PRODUCTS: "/api/online/searchProducts",
  LOGIN: "/api/account/login",
  SEND_OTP: "api/account/sendOtpCodeToEmailOnBoarding",
  REGISTER_USER: "api/account/registercustomer",
  FORGET_PASSWORD: "api/account/sendForgotPasswordChangeLink",
  CHANGE_PASSWORD: "api/account/resetPassword",
  CHANGE_PASSWORD_PROFILE: "api/account/changePassword",
  LIST_COUNTRIES: "api/online/listCountry",
  GET_ALL_GIFT_CARDS: "api/online/getAllGiftCards",
  CHECK_GIFT_CARD_BALANCE: "api/online/checkGiftCardBalance",
  SEND_GIFT_CARDS: "api/online/sendGiftCard",
  REDEEM_VOUCHER: "api/online/checkVoucherCode",
  ADD_DELIVERY_LOCATION: "api/online/getDeliveryPriceByLocation",
  GET_DELIVERY_LOCATIONS_OF_USER: "api/online/getUserDeliveryAddress",
  MAKE_PAYMENT: "api/online/orderCheckout",
  MAKE_PAYMENT_GUEST: "api/online/orderCheckoutGuest",
  GET_PROFILE_DETAIL: "api/account/getUserDetails",
  UPDATE_PROFILE_DETAIL: "api/account/updateUser",
  GET_ORDER_HISTORY: "api/online/getAllUserOrders",
  GET_ORDER_DETAIL: "api/online/getOrderDetails",
  GET_PAGE_DETAIL: "api/online/getPageDetailBySlug",
  ADD_PRODUCT_WISHLIST: "api/online/addProductToWishList",
  GET_ALL_WISHLIST_PRODUCT: "api/online/getAllWishListProduct",
  DELETE_WISHLIST_ITEM: "api/online/deleteWishListItem",
  GET_ALL_BANNERS: "api/online/getAllBanners",
  SEND_ENQUIRY: "api/online/makeEnquiry",
  GET_ALL_BLOGS: "api/online/getAllBlogs",
  GET_BLOG_DETAIL: "api/online/getBlogBySlug",
  GET_ALL_MEGA_MENUS: "api/online/getAllTemplateSectionMegaMenus",

  // ... add more endpoints as needed
};

export default APIEndpoints;
