/** @format */

import { Tabs } from "antd";
import React from "react";
import Slider from "react-slick";
import ProductSingleContainer from "../../../containers/product_single_container/product_single_container";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { TemplatePageCategoryDetail } from "../../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import { useApiCalls } from "../../../utils/useApiCalls";

/** @format */
interface ProductsMultiSectionProps {
  item: TemplatePageCategoryDetail;
  categoriesSlickSettings1: any;
}

const ProductsMultiSection: React.FC<ProductsMultiSectionProps> = ({
  item,
  categoriesSlickSettings1,
}) => {
  const { getAllFilterProducts } = useApiCalls();
  const dispatch = useAppDispatch();

  return (
    <section className=" newproducts mt-3" key={item.id}>
      <div className="container ">
        {/* row */}
        <div className="row">
          <div className="col-12 section-header-left">
            <div className="mb-6 d-xl-flex justify-content-end align-items-center">
              {/* heading */}

              <div>{/* nav */}</div>
            </div>
          </div>
        </div>
        {/* row */}
        <Tabs
          defaultActiveKey="1"
          type="card"
          size={"middle"}
          items={item.templatePageCategorySectionDetails?.map((item) => {
            return {
              key: item.id,
              label: item.labelName,
              children: (
                <div className="row">
                  <div className="col-12">
                    {/* tab */}
                    <div className="tab-content" id="nav-tabContent">
                      <div
                        className="tab-pane fade active show"
                        id="nav-fruitsandveg"
                        role="tabpanel"
                        aria-labelledby="nav-fruitsandveg-tab"
                        tabIndex={0}
                      >
                        {/* row */}
                        <div className="cent_cat">
                          <Slider {...categoriesSlickSettings1}>
                            {item.products?.map((item) => {
                              return (
                                <div key={item.id}>
                                  <ProductSingleContainer product={item} />
                                </div>
                              );
                            })}
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ),
            };
          })}
        />
      </div>
    </section>
  );
};

export default ProductsMultiSection;
