/** @format */

// import brotli from "brotli";
import React from "react";
import { Helmet } from "react-helmet";
import AlertHome from "../components/alert_home/AlertHome";
import HomeSectionContainer from "../containers/home_section/home_section_container";
import LayoutContainer from "../containers/layout/layout_container";
import useAppSelector from "../hooks/useAppSelector";

const Home: React.FC = () => {
  const { homePageDataModel } = useAppSelector((state) => state.homePage);
  return (
    <div>
      <Helmet>
        <title>{homePageDataModel?.storeDetails?.storeName}</title>
        <meta
          property="og:title"
          content={homePageDataModel?.storeDetails?.storeName}
        />
        <meta
          property="og:description"
          content={homePageDataModel?.storeDetails?.storeName}
        />
        <meta
          property="og:image"
          content={homePageDataModel?.storeDetails?.imageUrl}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      {homePageDataModel?.storeDetails?.alertDescription && <AlertHome />}
      <LayoutContainer>
        <HomeSectionContainer />
      </LayoutContainer>
    </div>
  );
};

export default Home;
