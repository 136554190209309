/** @format */

import React from "react";
import Slider from "react-slick";
import ActionTypes from "../../../constants/action_types";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { QueryString } from "../../../redux/reducers/FilterProductsReducer/FilterProductsInterface";
import { TemplatePageCategoryDetail } from "../../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import LazyImage from "../../../utils/Lazy Image/LazyImage";
import isMobile from "../../../utils/isMobile";
import { useApiCalls } from "../../../utils/useApiCalls";

/** @format */
interface TopCategoriesProps {
  item: TemplatePageCategoryDetail;
  categoriesSlickSettings1: any;
}

const TopCategories: React.FC<TopCategoriesProps> = ({
  item,
  categoriesSlickSettings1,
}) => {
  const { getAllFilterProducts } = useApiCalls();
  const dispatch = useAppDispatch();

  return (
    <section className="cent_cat mt-4 mb-4" key={item.id}>
      <div className="container">
        <div className="section-header-left mb-3 text-center">
          <div className="row">
            <div className="col-md-12">
              <h4 className="text-dark header-title fw-bold">
                {item.labelName}
              </h4>
            </div>
          </div>
        </div>
        <div className="cent_cat">
          {item.templatePageCategorySectionDetails &&
          (isMobile ||
            item.templatePageCategorySectionDetails?.[0]?.productGroups
              ?.length > 5) ? (
            <Slider {...categoriesSlickSettings1}>
              {item.templatePageCategorySectionDetails &&
                item.templatePageCategorySectionDetails?.[0]?.productGroups?.map(
                  (item, index) => {
                    return (
                      <div className="categoryimg" key={item.id}>
                        <a
                          onClick={() => {
                            getAllFilterProducts({
                              categoryIds: [`${item.slug}`],
                            });
                            dispatch({
                              type: ActionTypes.SET_QUERY_STRINGS,
                              payload: {
                                categoryIds: [`${item.slug}`],
                              } as QueryString,
                            });
                          }}
                        >
                          <LazyImage
                            src={item.imageUrl}
                            alt=""
                            className="h-100 w-100"
                          />
                          <h6 className="text-center fw-bold mt-2">
                            {item.name}
                          </h6>
                        </a>
                      </div>
                    );
                  }
                )}
            </Slider>
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-center gap-2">
                {item.templatePageCategorySectionDetails?.[0]?.productGroups?.map(
                  (item, index) => {
                    return (
                      <div className="categoryimg" key={item.id}>
                        <a
                          onClick={() => {
                            getAllFilterProducts({
                              categoryIds: [`${item.slug}`],
                            });
                            dispatch({
                              type: ActionTypes.SET_QUERY_STRINGS,
                              payload: {
                                categoryIds: [`${item.slug}`],
                              } as QueryString,
                            });
                          }}
                        >
                          <img
                            src={item.imageUrl}
                            alt=""
                            className="h-100 w-100"
                          />
                          <h6 className="text-center fw-bold mt-2">
                            {item.name}
                          </h6>
                        </a>
                      </div>
                    );
                  }
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default TopCategories;
