/** @format */

import ProductSingle from "../../components/product_single/product_single";
import useSnackbar from "../../hooks/useSnackbar";
import { Product } from "../../redux/reducers/HomePageReducer/HomePageReducerInterface";
interface ProductProps {
  product: Product;
  className?: string;
  viewType?: string;
  hideAddToCartButton?: boolean;
}
const ProductSingleContainer: React.FC<ProductProps> = ({
  product,
  className,
  viewType,
  hideAddToCartButton,
}) => {
  const { showSnackbar } = useSnackbar();

  return (
    <ProductSingle
      product={product}
      className={className}
      showSnackbar={showSnackbar}
      viewType={viewType}
      hideAddToCartButton={hideAddToCartButton}
    />
  );
};

export default ProductSingleContainer;
