/** @format */

import React from "react";
import ActionTypes from "../../../constants/action_types";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { QueryString } from "../../../redux/reducers/FilterProductsReducer/FilterProductsInterface";
import { TemplatePageCategoryDetail } from "../../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import LazyImage from "../../../utils/Lazy Image/LazyImage";
import { useApiCalls } from "../../../utils/useApiCalls";

/** @format */
interface TopBrandsProps {
  item: TemplatePageCategoryDetail;
}

const TopBrands: React.FC<TopBrandsProps> = ({ item }) => {
  const { getAllFilterProducts } = useApiCalls();
  const dispatch = useAppDispatch();

  return (
    <section className="cent_cat mt-4 mb-4 brand_img" key={item.id}>
      <div className="container">
        <div className="section-header-left mb-3 text-center">
          <div className="row">
            <div className="col-md-12">
              <h4 className="text-dark header-title fw-bold">
                {item.labelName}
              </h4>
            </div>
          </div>
        </div>
        <div className="row row-cols-lg-6 row-cols-2 row-cols-md-4 g-2 justify-content-center">
          {item.templatePageCategorySectionDetails?.[0]?.productGroups
            ?.filter((item) => item.type == "Brand")
            ?.slice(0, 12)
            ?.map((item, index) => {
              return (
                <div className="col" key={item.id}>
                  <a
                    className="d-block bg-light  rounded-0 p-2 mb-grid-gutter"
                    onClick={() => {
                      getAllFilterProducts({
                        brandIds: [`${item.id}`],
                      });
                      dispatch({
                        type: ActionTypes.SET_QUERY_STRINGS,
                        payload: {
                          brandIds: [`${item.id}`],
                        } as QueryString,
                      });
                    }}
                  >
                    <LazyImage
                      className="d-block mx-auto"
                      src={item.imageUrl}
                      alt="Brand"
                    />
                    <h6 className="text-center mt-2">{item.name}</h6>
                  </a>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default TopBrands;
