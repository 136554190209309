/** @format */

import { ColorDetail } from "../types/commonInterfaces";

export default function applyThemeColorDetails(
  themeDetails: ColorDetail[],
  homePageDataModel: any
) {
  for (const detail of themeDetails) {
    const colorDetail =
      homePageDataModel.storeDetails.onlineThemeColorDetails.find(
        (item: any) => item.identifier === detail.identifier
      );
    // This is for background color for inputs . this doesnt come from api.

    // if (colorDetail?.identifier == "5") {
    //   document.documentElement.style.setProperty(
    //     "--bg-color",
    //     colorDetail?.value
    //   );
    // }
    const value = colorDetail?.value ?? detail.defaultValue;

    document.documentElement.style.setProperty(detail.variableName, value);
  }
}
