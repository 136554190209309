/** @format */

import React from "react";
import Countdown, { CountdownRenderProps } from "react-countdown"; // Import Countdown and CountdownRenderProps

interface CountdownProps {
  timeDifference: number;
}

const CountdownComponent: React.FC<CountdownProps> = ({ timeDifference }) => {
  // Renderer function for react-countdown
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: CountdownRenderProps) => {
    if (completed) {
      // Handle case when countdown completes
      return <span>Countdown completed!</span>;
    }

    return (
      <React.Fragment>
        <div className="countdown-days countdown">
          <div className="data">{days}</div>
          <div className="text">Days</div>
        </div>
        <div className="countdown-hours countdown">
          <div className="data">{hours}</div>
          <div className="text">Hours</div>
        </div>
        <div className="countdown-minutes countdown">
          <div className="data">{minutes}</div>
          <div className="text">Min</div>
        </div>
        <div className="countdown-seconds countdown">
          <div className="data">{seconds}</div>
          <div className="text">Sec</div>
        </div>
      </React.Fragment>
    );
  };

  return <Countdown date={Date.now() + timeDifference} renderer={renderer} />;
};

export default CountdownComponent;
