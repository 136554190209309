/** @format */

import { Button } from "antd";
import DOMPurify from "dompurify";
import React from "react";
import { TemplatePageCategoryDetail } from "../../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import { useRetailHelper } from "../../../utils/useRetailHelper";

interface AboutUsProps {
  item: TemplatePageCategoryDetail;
}

const AboutUs: React.FC<AboutUsProps> = ({ item }) => {
  const { handleRedirectMegaMenu } = useRetailHelper();
  return (
    <section className="bg-dark py-5">
      <div className="container">
        <div className="section-header-left mb-3 text-center">
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-10 col-lg-9 col-8">
              <h4 className="text-white header-title fw-bold">{item.title}</h4>

              <div
                style={{ color: "white" }}
                className="text-white fs-6"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(item.description ?? ""),
                }}
              />

              {item.linkText && (
                <div className=" mt-4">
                  <Button
                    onClick={() => handleRedirectMegaMenu(item.link)}
                    type="primary"
                  >
                    {item.linkText}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
