/** @format */

import React, { useState } from "react";
import { MegaMenuWithChildrenAndCardsViewModel } from "../../redux/reducers/MegaMenuReducer/MegaMenuInterface";
import { useRetailHelper } from "../../utils/useRetailHelper";

interface HorizontalMegaMenuProps {
  megaMenu: MegaMenuWithChildrenAndCardsViewModel[];
}

const HorizontalMegaMenu: React.FC<HorizontalMegaMenuProps> = ({
  megaMenu,
}) => {
  const { handleRedirectMegaMenu } = useRetailHelper();
  const [activeTab, setActiveTab] = useState<string>("0");
  return (
    <div
      className=" dropdown-menu pb-0 animated fadeIn shadow-sm dropdown-fullwidth meganav"
      style={{ height: "70vh", overflowY: "auto", overflowX: "hidden" }}
    >
      <ul
        className="nav nav-pills  bg-light align-items-center justify-content-center shadow-sm"
        id="pills-tab"
        role="tablist"
      >
        {megaMenu.map((megaMenuChild, index) => {
          return (
            <li className="nav-item" role="presentation" key={megaMenuChild.id}>
              <button
                className={`nav-link ${
                  index.toString() == activeTab ? "active" : ""
                }`}
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target={`#${index}`}
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                onClick={() => setActiveTab(index.toString())}
              >
                {megaMenuChild.name}
              </button>
            </li>
          );
        })}
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id={activeTab}
          role="tabpanel"
          aria-labelledby="pills-home-tab"
          tabIndex={0}
        >
          <div className="row p-2 p-lg-4">
            {megaMenu
              .find((item, index) => index.toString() === activeTab)
              ?.childrens?.map((childMegaMenu, index) => {
                return (
                  <div
                    className="col-lg-3 col-12 mb-4 mb-lg-0"
                    key={childMegaMenu.id}
                  >
                    <h6
                      onClick={() =>
                        handleRedirectMegaMenu(childMegaMenu.link ?? "")
                      }
                      className="text-primary "
                    >
                      {childMegaMenu.name}
                    </h6>
                    {childMegaMenu.childrens?.map((item, index) => {
                      return (
                        <a
                          key={item.id}
                          className="dropdown-item"
                          onClick={() =>
                            handleRedirectMegaMenu(item.link ?? "")
                          }
                        >
                          {item.name}
                        </a>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalMegaMenu;
