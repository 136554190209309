/** @format */

import { message } from "antd";
import nProgress from "nprogress";
import ActionTypes from "../../../constants/action_types";
import WishlistInterface from "./WishlistReducerInterface";

/** @format */

const initialState: WishlistInterface = {
  isLoading: false,
};

const reducer = (state = initialState, action: any): WishlistInterface => {
  const wishlistRedirection = localStorage.getItem("wishlist-redirection");
  switch (action.type) {
    case ActionTypes.ADD_PRODUCT_WISHLIST_REQUEST:
      message.loading("Loading...");
      return {
        ...state,
        isLoading: true,
        isWishlistChanged: false,
      };
    case ActionTypes.ADD_PRODUCT_WISHLIST_SUCCESS:
      message.destroy();

      return {
        ...state,
        isLoading: false,
        isWishlistChanged: true,

        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.ADD_PRODUCT_WISHLIST_FAILURE:
      message.destroy();

      return {
        ...state,
        isLoading: false,
        isWishlistChanged: false,
      };
    case ActionTypes.DELETE_WISHLIST_ITEM_REQUEST:
      message.loading("Loading...");
      return {
        ...state,
        isLoading: true,
        isWishlistChanged: false,
      };
    case ActionTypes.DELETE_WISHLIST_ITEM_SUCCESS:
      message.destroy();

      return {
        ...state,
        isLoading: false,
        isWishlistChanged: true,

        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.DELETE_WISHLIST_ITEM_FAILURE:
      message.destroy();

      return {
        ...state,
        isLoading: false,
        isWishlistChanged: false,
      };
    case ActionTypes.GET_ALL_WISHLIST_PRODUCT_REQUEST:
      if (wishlistRedirection != "false") {
        nProgress.start();
      }
      return {
        ...state,
        isLoading: true,
        isWishlistFetchSuccessful: false,
      };
    case ActionTypes.GET_ALL_WISHLIST_PRODUCT_SUCCESS:
      nProgress.done();
      const updatedState = {
        ...state,
        isLoading: false,
        isWishlistFetchSuccessful:
          wishlistRedirection == "false" ? false : true,
        wishlistDetails: action.payload.data,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
      localStorage.removeItem("wishlist-redirection");

      return updatedState;
    case ActionTypes.GET_ALL_WISHLIST_PRODUCT_FAILURE:
      nProgress.done();
      return {
        ...state,
        isLoading: false,
        isWishlistFetchSuccessful: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.SET_ACTIVE_SLUG_PAGE:
      return {
        ...state,
        activeSlug: action.payload,
      };

    case ActionTypes.RESET_WISHLIST_STATE:
      return {
        ...state,
        isWishlistFetchSuccessful: false,
        error: null,
        successMessage: null,
        isWishlistChanged: false,
      };
    case ActionTypes.CLEAR_WISHLIST_PRODUCTS:
      return {
        ...state,
        wishlistDetails: [],
      };
    default:
      return state;
  }
};

export default reducer;
