/** @format */

export const getCurrentLocation = (): { location: Location; url: string } => {
  const { location } = window;

  // Extract the location details
  const { protocol, host, port, pathname, search, hash } = location;

  let url = `${protocol}//${host}`;

  return { location, url };
};
