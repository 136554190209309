/** @format */

import { RuleObject } from "antd/lib/form";
import valid from "card-validator";
import Payment from "payment";
import {
  NUMBER_VALIDATION_MESSAGE,
  PASSWORD_VALIDATION_MESSAGE,
} from "../constants/definitions";

export const validateNumber = (
  rule: RuleObject,
  value: any,
  callback: (error?: string) => void
) => {
  // Use a regular expression to check if the value is a valid number
  const numberRegex = /^[0-9]+$/;

  if (!numberRegex.test(value)) {
    callback(NUMBER_VALIDATION_MESSAGE);
  } else {
    callback(); // No error, so call the callback with no arguments
  }
};

export const validatePassword = (
  rule: RuleObject,
  value: any,
  callback: (error?: string) => void
) => {
  // Use a regular expression to check if the value is a valid password
  const passwordRegex =
    /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=£*_#]).*$/;

  if (!passwordRegex.test(value)) {
    callback(PASSWORD_VALIDATION_MESSAGE);
  } else {
    callback(); // No error, so call the callback with no arguments
  }
};
function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}
export function formatCreditCardNumber(
  value: string | undefined
): string | undefined {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue: string;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(
  value: string | undefined,
  prevValue: string | undefined,
  allValues: Record<string, any> = {}
): string | undefined {
  const clearValue = clearNumber(value);
  let maxLength = 3;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    // You can use 'issuer' here if needed.
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(
  value: string | undefined
): string | undefined {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}
export const cardNumberValidator = (_: any, value: string): Promise<void> => {
  if (valid.number(value).isValid) {
    return Promise.resolve();
  } else {
    return Promise.reject(new Error("Invalid Credit Card Number"));
  }
};
export const cvcValidator = (_: any, value: any) => {
  if (valid.cvv(value).isValid) {
    return Promise.resolve();
  } else {
    return Promise.reject(new Error("Invalid CVC Number"));
  }
};
