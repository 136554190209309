/** @format */

import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

interface PageLoaderProps {
  loading: boolean;
  children: React.ReactNode;
}

const PageLoader: React.FC<PageLoaderProps> = ({ loading, children }) => {
  if (loading) {
    return (
      <div
        className=""
        style={{
          height: "40vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="loader">
          <div>
            <div className="page-loader-body w-20">
              <div
                className="loader-container"
                style={{
                  width: "55px",
                  margin: "auto",
                  padding: "10px",
                  marginBottom: "1rem",
                }}
              >
                <ClipLoader
                  loading={true}
                  color="black"
                  size={34}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  className="loader-page"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export default PageLoader;
