/** @format */

import React from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { initializeStoreDataModel } from "../../redux/reducers/HomePageReducer/HomePageReducerInterface";

interface WholeScreenLoaderProps {
  initializeDataModel?: initializeStoreDataModel;
}
const WholeScreenLoader: React.FC<WholeScreenLoaderProps> = ({
  initializeDataModel,
}) => {
  return (
    <div className="preloader">
      <div className="loader">
        <div>
          <a className="loader_logo">
            <img
              src={initializeDataModel?.imageUrl}
              alt=""
              width={300}
              height={80}
            />
          </a>
          <div className="page-loader-body">
            <BeatLoader
              loading={true}
              color="black"
              size={12}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WholeScreenLoader;
