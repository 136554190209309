/** @format */

import React, { ReactNode, useEffect } from "react";
import Loadable from "react-loadable";
import { useNavigate } from "react-router-dom";
import { API } from "../../Helpers/BaseUrlProvider";
import { getCookie } from "../../Helpers/FrontendHelper";
import ActionTypes from "../../constants/action_types";
import {
  CHANNEL_PLATFORM,
  COMMON_ERROR_MESSAGE,
  PRODUCT_VERSION_LOCAL_STORAGE,
  TOKEN_LOCAL_STORAGE,
} from "../../constants/definitions";
import Routes_Constants from "../../constants/routes_configs";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useSnackbar from "../../hooks/useSnackbar";
import { useApiCalls } from "../../utils/useApiCalls";
import { useRetailHelper } from "../../utils/useRetailHelper";

const FilterProductLoadabe: any = Loadable({
  loader: () =>
    import(
      "../../containers/filter_products_container/filter_products_container"
    ),
  loading: () => null,
});

const GiftCardLoadabe: any = Loadable({
  loader: () =>
    import("../../containers/gift_cart_container/gift_card_container"),
  loading: () => null,
});

const ProductDetailLoadabe: any = Loadable({
  loader: () =>
    import(
      "../../containers/product_details_container/product_details_container"
    ),
  loading: () => null,
});

const ProfileLoadabe: any = Loadable({
  loader: () => import("../../containers/profile_container/profile_container"),
  loading: () => null,
});

const OrderHistoryLoadable: any = Loadable({
  loader: () =>
    import("../../containers/order-history-container/order-history-container"),
  loading: () => null,
});

const PageDetailLoadable: any = Loadable({
  loader: () =>
    import("../../containers/page_detail_container/page_detail_container"),
  loading: () => null,
});

const WishlistLoadable: any = Loadable({
  loader: () =>
    import("../../containers/wishlist-container/wishlist_container"),
  loading: () => null,
});
const BlogsLoadable: any = Loadable({
  loader: () => import("../../containers/blog_container/blog_container"),
  loading: () => null,
});
const BlogDetailsLoadable: any = Loadable({
  loader: () => import("../../pages/blog_detail/BlogDetail"),
  loading: () => null,
});

interface WithApiCallsProps {
  children: ReactNode;
}

const WithApiCalls: React.FC<WithApiCallsProps> = ({ children }) => {
  const { initializeStoreDataModel, homePageDataModel } = useAppSelector(
    (state) => state.homePage
  );
  const { isLoginSuccessFul, loginResponse } = useAppSelector(
    (state) => state.authentication
  );
  const { isGiftCardsFetchSuccessful } = useAppSelector(
    (state) => state.giftCard
  );
  const { isProfileDetailFetchSuccessful, isOrderHistoryFetchSuccessful } =
    useAppSelector((state) => state.profile);

  const {
    isPageDetailFetchSuccessful,
    activeSlug: activeSlugPage,
    error: pageError,
  } = useAppSelector((state) => state.page);
  const { error: makePaymentError } = useAppSelector(
    (state) => state.makePayment
  );

  const {
    isWishlistFetchSuccessful,
    error: wishlistError,
    wishlistDetails,
    isWishlistChanged,
    isLoading: wishlistLoading,
  } = useAppSelector((state) => state.wishlist);
  const {
    isAllBlogsFetchSuccessful,
    error: blogsError,
    activeSlug: activeSlugBlog,
    isBlogDetailFetchSuccessful,
  } = useAppSelector((state) => state.blogs);
  const { countires, banners } = useAppSelector((state) => state.common);
  const { showSnackbar } = useSnackbar();
  const { getAllWishlistsProduct } = useApiCalls();
  const { clearCartProducts } = useRetailHelper();

  useEffect(() => {
    if (
      localStorage.getItem(PRODUCT_VERSION_LOCAL_STORAGE) !=
      homePageDataModel?.productVersion
    ) {
      localStorage.clear();
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      dispatch({
        type: ActionTypes.LOGOUT_REQUEST,
      });
      navigate(Routes_Constants.HOME);
      dispatch({
        type: ActionTypes.CLEAR_WISHLIST_PRODUCTS,
      });
      clearCartProducts();
      localStorage.setItem(
        PRODUCT_VERSION_LOCAL_STORAGE,
        homePageDataModel?.productVersion ?? ""
      );
    }
  }, [homePageDataModel?.productVersion]);

  API.interceptors.request.use(
    async (config) => {
      const token = getCookie(TOKEN_LOCAL_STORAGE);
      // const storeId = activeStore
      //   ? activeStore.id
      //   : getLocalStorage("storeDetailsUser")[0]?.storeId;
      const storeId = initializeStoreDataModel?.storeId;
      const userId = loginResponse?.userId;

      config.headers = Object.assign(
        {
          Authorization: `Bearer ${token}`,
          StoreId: storeId,
          ChannelPlatform: CHANNEL_PLATFORM,
          userId,
        },
        config.headers
      );
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  API.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (error?.response.status === 0) {
        // This handles the server error case.
        showSnackbar(COMMON_ERROR_MESSAGE, { variant: "error" });
      }
      if (error?.response?.status === 401) {
        // This handles the session expired case.
        dispatch({
          type: ActionTypes.LOGOUT_REQUEST,
        });
        dispatch({
          type: ActionTypes.CLEAR_WISHLIST_PRODUCTS,
        });
        navigate(Routes_Constants.AUTHENTICATE);
      }

      return Promise.reject(error);
    }
  );
  const { isProductFetchSuccesful, activeSlug } = useAppSelector(
    (state) => state.productDetails
  );
  const { isFilterProductFetchSuccesful, queryStrings, error } = useAppSelector(
    (state) => state.filterProducts
  );
  const { error: errorProfile } = useAppSelector((state) => state.profile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!countires) {
      dispatch({
        type: ActionTypes.LIST_COUNTRIES_REQUEST,
      });
    }
  }, []);
  useEffect(() => {
    if (
      !wishlistDetails &&
      window.location.pathname != Routes_Constants.WISHLIST &&
      loginResponse?.userId &&
      !wishlistLoading
    ) {
      localStorage.setItem("wishlist-redirection", "false");
      getAllWishlistsProduct();
    }
  }, [loginResponse]);

  useEffect(() => {
    if (isWishlistChanged) {
      localStorage.setItem("wishlist-redirection", "false");
      getAllWishlistsProduct();
    }
  }, [isWishlistChanged]);
  useEffect(() => {
    if (!banners && homePageDataModel?.storeDetails?.id) {
      dispatch({
        type: ActionTypes.GET_ALL_BANNERS_REQUEST,
        payload: {
          Identifier: "",
          StoreId: homePageDataModel?.storeDetails?.id,
        },
      });
    }
  }, [homePageDataModel?.storeDetails?.id]);

  useEffect(() => {
    if (isPageDetailFetchSuccessful) {
      PageDetailLoadable.preload().then(() => {
        navigate("/page/" + activeSlugPage);
      });

      dispatch({
        type: ActionTypes.RESET_PAGE_STATE,
      });
    }
  }, [isPageDetailFetchSuccessful]);

  useEffect(() => {
    if (isWishlistFetchSuccessful) {
      WishlistLoadable.preload().then(() => {
        navigate(Routes_Constants.WISHLIST);
      });

      dispatch({
        type: ActionTypes.RESET_WISHLIST_STATE,
      });
    }
  }, [isWishlistFetchSuccessful]);

  useEffect(() => {
    if (isAllBlogsFetchSuccessful) {
      BlogsLoadable.preload().then(() => {
        navigate(Routes_Constants.BLOG);
      });

      dispatch({
        type: ActionTypes.RESET_BLOGS_STATE,
      });
    }
  }, [isAllBlogsFetchSuccessful]);

  useEffect(() => {
    if (isBlogDetailFetchSuccessful) {
      BlogDetailsLoadable.preload().then(() => {
        navigate("/blogs/" + activeSlugBlog);
      });

      dispatch({
        type: ActionTypes.RESET_BLOGS_STATE,
      });
    }
  }, [isBlogDetailFetchSuccessful]);

  useEffect(() => {
    if (isProductFetchSuccesful) {
      ProductDetailLoadabe.preload().then(() => {
        navigate("/product/" + activeSlug);
      });
      //@ts-ignore
      window.scrollTo({ top: 0, behavior: "instant" });
      dispatch({
        type: ActionTypes.RESET_PRODUCT_STATE,
      });
    }
  }, [isProductFetchSuccesful]);

  useEffect(() => {
    if (isProfileDetailFetchSuccessful) {
      ProfileLoadabe.preload().then(() => {
        navigate(Routes_Constants.PROFILE);
      });

      // window.scrollTo(0, 0);
      dispatch({
        type: ActionTypes.RESET_PROFILE_STATE,
      });
    }
  }, [isProfileDetailFetchSuccessful]);

  useEffect(() => {
    if (isOrderHistoryFetchSuccessful) {
      OrderHistoryLoadable.preload().then(() => {
        navigate(Routes_Constants.ORDER);
      });

      // window.scrollTo(0, 0);
      dispatch({
        type: ActionTypes.RESET_PROFILE_STATE,
      });
    }
  }, [isOrderHistoryFetchSuccessful]);

  useEffect(() => {
    if (isGiftCardsFetchSuccessful) {
      GiftCardLoadabe.preload().then(() => {
        navigate(Routes_Constants.GIFT_CARD);
      });

      dispatch({
        type: ActionTypes.RESET_GIFT_CARD_STATE,
      });
    }
  }, [isGiftCardsFetchSuccessful]);

  useEffect(() => {
    if (isFilterProductFetchSuccesful) {
      const navigateToFilterProducts = () => {
        if (queryStrings) {
          const queryParams = new URLSearchParams();
          const {
            page,
            pageSize,
            searchKeywords,
            categoryIds,
            brandIds,
            filterOptionsIds,
            priceMin,
            priceMax,
            sort,
            discount,
            type,
            slug,
          } = queryStrings;

          if (page !== undefined) queryParams.append("page", String(page));
          if (pageSize !== undefined)
            queryParams.append("pageSize", String(pageSize));
          if (searchKeywords)
            queryParams.append("searchKeywords", searchKeywords);
          if (categoryIds && categoryIds.length > 0)
            queryParams.append("categoryIds", categoryIds.join("&"));
          if (brandIds && brandIds.length > 0)
            queryParams.append("brandIds", brandIds.join("&"));
          if (filterOptionsIds && filterOptionsIds.length > 0)
            queryParams.append("filterOptionsIds", filterOptionsIds.join("&"));
          if (priceMin) queryParams.append("priceMin", priceMin);
          if (priceMax) queryParams.append("priceMax", priceMax);
          if (sort) queryParams.append("sort", sort);
          if (discount) queryParams.append("discount", discount);
          if (slug) queryParams.append("slug", slug);
          if (type) queryParams.append("type", type);

          const queryString = queryParams.toString();
          const navigateUrl =
            queryString.length > 0
              ? `${Routes_Constants.FILTER_PRODUCTS}?${queryString}`
              : Routes_Constants.FILTER_PRODUCTS;

          FilterProductLoadabe.preload().then(() => {
            navigate(navigateUrl);
          });
          //@ts-ignore
          window.scrollTo({ top: 0, behavior: "instant" });
          dispatch({
            type: ActionTypes.RESET_FILTER_PRODUCT_STATE,
          });
        }
      };

      navigateToFilterProducts();
    }
  }, [isFilterProductFetchSuccesful]);

  useEffect(() => {
    if (error) {
      showSnackbar(error, {
        variant: "error",
      });
      dispatch({
        type: ActionTypes.RESET_FILTER_PRODUCT_STATE,
      });
    }
  }, [error]);

  useEffect(() => {
    if (errorProfile) {
      showSnackbar(errorProfile, {
        variant: "error",
      });
      dispatch({
        type: ActionTypes.RESET_PROFILE_STATE,
      });
    }
  }, [errorProfile]);
  useEffect(() => {
    if (wishlistError) {
      showSnackbar(wishlistError, {
        variant: "error",
      });
      dispatch({
        type: ActionTypes.RESET_WISHLIST_STATE,
      });
    }
  }, [wishlistError]);
  useEffect(() => {
    if (makePaymentError) {
      showSnackbar(makePaymentError, {
        variant: "error",
      });
      dispatch({
        type: ActionTypes.RESET_MAKE_PAYMENT_STATE,
      });
    }
  }, [makePaymentError]);
  useEffect(() => {
    if (blogsError) {
      showSnackbar(blogsError, {
        variant: "error",
      });
      dispatch({
        type: ActionTypes.RESET_BLOGS_STATE,
      });
    }
  }, [blogsError]);
  useEffect(() => {
    if (pageError) {
      showSnackbar(pageError, {
        variant: "error",
      });
      dispatch({
        type: ActionTypes.RESET_PAGE_STATE,
      });
    }
  }, [pageError]);

  useEffect(() => {
    //@ts-ignore
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [window.location]);

  return <>{children}</>;
};

export default WithApiCalls;
