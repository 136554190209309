/** @format */

import React from "react";
import { TemplatePageCategoryDetail } from "../../../redux/reducers/HomePageReducer/HomePageReducerInterface";

/** @format */
interface Banner2Props {
  item: TemplatePageCategoryDetail;
}

const Banner2: React.FC<Banner2Props> = ({ item }) => {
  return (
    <section className="slidersection pt-50" key={item.id}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 mainban">
            <div className="hero-container">
              <div
                id="heroCarousel"
                className="carousel slide carousel-fade"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner" role="listbox">
                  <div className="carousel-item active">
                    <img
                      src={item?.imageAds?.[0]?.imageUrl}
                      alt=""
                      className="h-100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="sidebar_banner_six">
              <div className="single_banner mb-30">
                <div className="banner_thumb mb-3">
                  <a href="">
                    <img src={item?.imageAds?.[1]?.imageUrl} alt="" />
                  </a>
                </div>
              </div>
              <div className="single_banner">
                <div className="banner_thumb">
                  <a href="">
                    <img src={item?.imageAds?.[2]?.imageUrl} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner2;
