/** @format */

import {
  DownOutlined,
  HeartOutlined,
  LogoutOutlined,
  OrderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ActionTypes from "../../constants/action_types";
import Routes_Constants from "../../constants/routes_configs";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { useApiCalls } from "../../utils/useApiCalls";
import { useRetailHelper } from "../../utils/useRetailHelper";

const UserDropdownItems = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getProfileDetails, getOrdersHistory, getAllWishlistsProduct } =
    useApiCalls();
  const { formatDate, handleNavigation } = useRetailHelper();
  const { loginResponse } = useAppSelector((state) => state.authentication);

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: "1",
            label: (
              <a
                onClick={() => {
                  getProfileDetails();
                }}
              >
                Account
              </a>
            ),
            icon: <UserOutlined />,
          },
          {
            key: "2",
            label: (
              <a
                onClick={() => {
                  getOrdersHistory({
                    Page: 1,
                    PageSize: 10,
                    SearchKeywords: "",
                    ExternalFilter: {
                      OrderedDateFrom: formatDate(
                        moment().subtract(15, "days").format("YYYY-MM-DD")
                      ),
                      OrderedDateTo: formatDate(moment().format("YYYY-MM-DD")),
                    },
                  });
                }}
              >
                My Orders
              </a>
            ),
            icon: <OrderedListOutlined />,
          },
          {
            key: "3",
            label: (
              <a
                onClick={() => {
                  getAllWishlistsProduct();
                }}
              >
                My Wishlists
              </a>
            ),
            icon: <HeartOutlined />,
          },

          {
            key: "5",
            danger: true,
            label: (
              <a
                onClick={() => {
                  dispatch({
                    type: ActionTypes.LOGOUT_REQUEST,
                  });
                  dispatch({
                    type: ActionTypes.CLEAR_WISHLIST_PRODUCTS,
                  });
                  navigate(Routes_Constants.HOME);
                }}
              >
                Logout
              </a>
            ),
            icon: <LogoutOutlined />,
          },
        ],
      }}
    >
      <div className="d-flex align-items-center">
        <div className="navbar-tool-icon-box ">
          <i className="navbar-tool-icon fas fa-user" />
        </div>
        <a className="d-flex align-items-center gap-1 text-header">
          {loginResponse?.name}
          <DownOutlined />
        </a>
      </div>
    </Dropdown>
  );
};

export default UserDropdownItems;
