/** @format */

import CartPopover from "../../components/cart_popover/cart_popover";
import useAppSelector from "../../hooks/useAppSelector";

const CartPopoverContainer = () => {
  const { isCartPopoverOpen, cartData } = useAppSelector(
    (state) => state.common
  );

  return <CartPopover isCartPopoverOpen={isCartPopoverOpen} />;
};

export default CartPopoverContainer;
