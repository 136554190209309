/** @format */

import React from "react";
import { MegaMenuWithChildrenAndCardsViewModel } from "../../redux/reducers/MegaMenuReducer/MegaMenuInterface";
import LazyImage from "../../utils/Lazy Image/LazyImage";
import { useRetailHelper } from "../../utils/useRetailHelper";

interface HorizontalCardBlockProps {
  megaMenu: MegaMenuWithChildrenAndCardsViewModel[];
  isSecondLevel?: boolean;
}

const HorizontalCardBlock: React.FC<HorizontalCardBlockProps> = ({
  megaMenu,
  isSecondLevel,
}) => {
  const { handleRedirectMegaMenu } = useRetailHelper();

  return (
    <div
      className={`${
        isSecondLevel
          ? "dropdown-menu-list-submenu shadow-sm"
          : "dropdown-menu pb-0 animated fadeIn shadow-sm dropdown-halfwidth p-3"
      }`}
    >
      <ul className="list-unstyled row g-4 w-100">
        {megaMenu.map((childMegaMenu, index) => {
          return (
            <li className="col-md-2" key={childMegaMenu.id}>
              <a
                onClick={() => handleRedirectMegaMenu(childMegaMenu.link ?? "")}
                className="dropdown-item d-block text-center"
              >
                {childMegaMenu.imageUrl && (
                  <LazyImage src={childMegaMenu.imageUrl} alt="" />
                )}
                <span className="d-block ">{childMegaMenu.name}</span>{" "}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default HorizontalCardBlock;
