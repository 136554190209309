/** @format */

import nProgress from "nprogress";

import ActionTypes from "../../../constants/action_types";
import BlogReducerInterface from "./BlogReducerInterface";

/** @format */

const initialState: BlogReducerInterface = {
  isLoading: false,
};

const reducer = (state = initialState, action: any): BlogReducerInterface => {
  switch (action.type) {
    case ActionTypes.GET_ALL_BLOGS_REQUEST:
      // Preloading the code of filter product screen
      nProgress.start();
      return {
        ...state,
        isLoading: true,
        error: null,
        isAllBlogsFetchSuccessful: false,
      };
    case ActionTypes.GET_ALL_BLOGS_SUCCESS:
      nProgress.done();
      return {
        ...state,
        isLoading: false,
        error: null,
        isAllBlogsFetchSuccessful: true,
        blogsDataModel: action.payload.data,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.GET_ALL_BLOGS_FAILURE:
      nProgress.done();
      return {
        ...state,
        isLoading: false,
        isAllBlogsFetchSuccessful: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.GET_BLOG_DETAIL_REQUEST:
      // Preloading the code of filter product screen
      nProgress.start();
      return {
        ...state,
        isLoading: true,
        error: null,
        isBlogDetailFetchSuccessful: false,
      };
    case ActionTypes.GET_BLOG_DETAIL_SUCCESS:
      nProgress.done();
      return {
        ...state,
        isLoading: false,
        error: null,
        isBlogDetailFetchSuccessful: true,
        blogSingle: action.payload.data,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.GET_BLOG_DETAIL_FAILURE:
      nProgress.done();
      return {
        ...state,
        isLoading: false,
        isBlogDetailFetchSuccessful: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.SET_ACTIVE_SLUG_BLOG:
      return {
        ...state,
        activeSlug: action.payload,
      };

    case ActionTypes.RESET_BLOGS_STATE:
      return {
        ...state,
        isAllBlogsFetchSuccessful: false,
        isBlogDetailFetchSuccessful: false,
        error: null,
        successMessage: null,
      };

    default:
      return state;
  }
};

export default reducer;
