/** @format */

import { useSnackbar as useNotistackSnackbar, VariantType } from "notistack";
import { useState } from "react";

type SnackbarOptions = {
  variant?: VariantType;
};

export type SnackbarHandler = (
  message: string,
  options?: SnackbarOptions
) => void;

const useSnackbar = (): { showSnackbar: SnackbarHandler } => {
  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();
  const [snackbarKey, setSnackbarKey] = useState<string | number | null>(null);

  const showSnackbar: SnackbarHandler = (
    message,
    { variant = "default" } = {}
  ) => {
    // Dismiss the previous snackbar if it exists
    if (snackbarKey !== null) {
      closeSnackbar(snackbarKey);
    }

    // Show the new snackbar
    const key = enqueueSnackbar(message, {
      variant,

      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      autoHideDuration: 3000,
    });
    setSnackbarKey(key);
  };

  return { showSnackbar };
};

export default useSnackbar;
