/** @format */

import React from "react";
import { TemplatePageCategoryDetail } from "../../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import LazyImage from "../../../utils/Lazy Image/LazyImage";

/** @format */
interface MultipleLinearAds2Props {
  item: TemplatePageCategoryDetail;
}

const MultipleLinearAds2: React.FC<MultipleLinearAds2Props> = ({ item }) => {
  return (
    <div className="homemidbanner" key={item.id}>
      <div className="container">
        <div className="row">
          <div className=" col-md-6">
            <div className="bannerimg">
              <LazyImage
                link={item?.imageAds?.[0]?.link}
                className="img-fluid"
                alt=""
                src={item?.imageAds?.[0]?.imageUrl}
              />
            </div>
          </div>
          <div className=" col-md-6">
            <div className="bannerimg">
              <LazyImage
                link={item?.imageAds?.[0]?.link}
                className="img-fluid"
                alt=""
                src={item?.imageAds?.[1]?.imageUrl}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleLinearAds2;
