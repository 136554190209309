/** @format */

import { SearchOutlined } from "@ant-design/icons";
import { Input, Popover } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ActionTypes from "../../constants/action_types";
import useAppSelector from "../../hooks/useAppSelector";
import { QueryString } from "../../redux/reducers/FilterProductsReducer/FilterProductsInterface";
import { useApiCalls } from "../../utils/useApiCalls";

export const SearchHeader = ({ storeId }: { storeId: string }) => {
  const dispatch = useDispatch();

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [isSearchPopoverOpen, setIsSearchPopoverOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (searchKeyword.length > 0) {
      setIsSearchPopoverOpen(true);
    }
  }, [searchKeyword]);

  const { isLoading, searchResult } = useAppSelector((state) => state.common);
  const { getAllFilterProducts } = useApiCalls();

  const brandsArray = Array.from(
    new Set(
      searchResult &&
        searchResult
          .map((item) => item.brand)
          .filter((brand) => brand !== null && brand !== undefined)
    )
  );

  const categoriesArray = Array.from(
    new Set(
      searchResult &&
        searchResult
          .map((item) => item.category)
          .filter((category) => category !== null && category !== undefined)
    )
  );

  useEffect(() => {
    if (searchKeyword) {
      const searchTimeout = setTimeout(() => {
        dispatch({
          type: ActionTypes.SEARCH_PRODUCTS_REQUEST,
          payload: {
            Page: 1,
            PageSize: 10,
            SearchKeywords: searchKeyword,
            StoreId: storeId,
          },
        });
      }, 500);
      return () => {
        clearTimeout(searchTimeout);
      };
    }
  }, [searchKeyword]);

  return (
    <div className="w-100">
      <Popover
        id={
          searchResult == undefined || !searchKeyword
            ? "popover-search-hide"
            : ""
        }
        placement="bottom"
        trigger={"click"}
        open={isSearchPopoverOpen}
        onOpenChange={(e) => {
          setIsSearchPopoverOpen(e);
        }}
        content={
          searchResult && searchResult.length == 0 ? (
            <div className="home-search d-flex justify-content-center">
              <div className="d-flex align-items-center gap-2">
                <SearchOutlined style={{ fontSize: "18px" }} />
                <p>No Results Found.</p>
              </div>
            </div>
          ) : (
            <div className="row home-search">
              <div className="col-12 col-md-7">
                <ul className="search_desc ">
                  {searchResult?.slice(0, 8)?.map((item, index) => {
                    return (
                      <li
                        className="d-flex py-2"
                        key={index}
                        onClick={() => {
                          getAllFilterProducts({
                            searchKeywords: item.name,
                          });
                          dispatch({
                            type: ActionTypes.SET_QUERY_STRINGS,
                            payload: {
                              searchKeywords: item.name,
                            } as QueryString,
                          });
                          setIsSearchPopoverOpen(false);
                        }}
                      >
                        <a>
                          <img
                            src={item.imageUrl}
                            className="me-3 img-fluid"
                            width="45px"
                            height="45px"
                            alt=""
                          />
                        </a>
                        <p>
                          <a>
                            {item.name}
                            <span className="d-block">
                              {item.category && `${item.category}`}
                              {item.brand && `${item.brand}`}
                            </span>
                          </a>
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="col-md-5">
                <div className="search_brand bg-light p-4">
                  {brandsArray && brandsArray.length > 0 && (
                    <>
                      <span className="desc fw-bold">Recommended Brands</span>
                      <ul>
                        {brandsArray.map((item, index) => {
                          const foundBrand = searchResult?.find(
                            (searchItem) => searchItem.brand === item
                          );
                          return (
                            <li key={index}>
                              <a
                                onClick={() => {
                                  getAllFilterProducts({
                                    brandIds: [`${foundBrand?.id}`],
                                  });
                                  dispatch({
                                    type: ActionTypes.SET_QUERY_STRINGS,
                                    payload: {
                                      brandIds: [`${foundBrand?.id}`],
                                    } as QueryString,
                                  });
                                }}
                              >
                                {item}{" "}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                  {categoriesArray && categoriesArray.length > 0 && (
                    <div className="mt-2">
                      <span className="desc fw-bold mt-4">
                        Related Categories
                      </span>
                      <ul>
                        {categoriesArray.map((item, index) => {
                          const foundCategory = searchResult?.find(
                            (searchRe) => searchRe.category == item
                          );

                          return (
                            <li key={index}>
                              <a
                                onClick={() => {
                                  getAllFilterProducts({
                                    categoryIds: [
                                      `${foundCategory?.categorySlug}`,
                                    ],
                                  });
                                  dispatch({
                                    type: ActionTypes.SET_QUERY_STRINGS,
                                    payload: {
                                      categoryIds: [
                                        `${foundCategory?.categorySlug}`,
                                      ],
                                    } as QueryString,
                                  });
                                }}
                              >
                                {item}{" "}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        }
      >
        <Input
          onPressEnter={() => {
            if (searchKeyword) {
              getAllFilterProducts({
                searchKeywords: searchKeyword,
              });
              dispatch({
                type: ActionTypes.SET_QUERY_STRINGS,
                payload: {
                  searchKeywords: searchKeyword,
                } as QueryString,
              });
              setIsSearchPopoverOpen(false);
            }
          }}
          style={{
            border: "1px solid #ced4d9",
            borderRadius: "50%",
          }}
          value={searchKeyword}
          onChange={(e) => {
            setSearchKeyword(e.target.value);
          }}
          className="w-100 input-bg-white bg-white rounded-start border-radius ps-3 form-control"
          type="text"
          placeholder="Search for products"
        />
      </Popover>
    </div>
  );
};
