/** @format */

import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie, getLocalStorage } from "../../Helpers/FrontendHelper";
import Routes_Constants from "../../constants/routes_configs";
import useAppSelector from "../../hooks/useAppSelector";

interface ProtectedProps {
  children: React.ReactNode;
  isMakePaymentRoute?: boolean;
}

function Protected({ children, isMakePaymentRoute }: ProtectedProps) {
  const token = getCookie("token");
  const location = useLocation();
  const { homePageDataModel } = useAppSelector((state) => state.homePage);

  if (isMakePaymentRoute && getLocalStorage("isGuestChecout")) {
    return <>{children}</>;
  }

  if (!token) {
    return (
      <Navigate
        to={Routes_Constants.AUTHENTICATE}
        state={{ from: location.pathname }}
        replace
      />
    );
  }

  return <>{children}</>;
}

export default Protected;
