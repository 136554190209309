/** @format */

import { Button } from "antd";
import React from "react";
import ActionTypes from "../../../constants/action_types";
import ProductSingleContainer from "../../../containers/product_single_container/product_single_container";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { QueryString } from "../../../redux/reducers/FilterProductsReducer/FilterProductsInterface";
import { TemplatePageCategoryDetail } from "../../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import { useApiCalls } from "../../../utils/useApiCalls";

/** @format */
interface FeaturedProductsProps {
  item: TemplatePageCategoryDetail;
}

const FeaturedProducts: React.FC<FeaturedProductsProps> = ({ item }) => {
  const { getAllFilterProducts } = useApiCalls();
  const dispatch = useAppDispatch();

  return (
    <section className="pt-50" key={item.id}>
      <div className="featured_product">
        <div className="container">
          <div className="section-header-left mb-3 text-start">
            <div className="row">
              <div className="col-xl-10 col-lg-9 col-8">
                <h4 className="text-dark header-title fw-bold">
                  {item.labelName}
                </h4>
              </div>
              <div className="col-xl-2 col-lg-2 col-4 text-end justify-content-end d-flex">
                <Button
                  onClick={() => {
                    getAllFilterProducts({
                      type: item.type,
                      slug: item.slug,
                    });
                    dispatch({
                      type: ActionTypes.SET_QUERY_STRINGS,
                      payload: {
                        type: item.type,
                        slug: item.slug,
                      } as QueryString,
                    });
                  }}
                  type="default"
                  className="d-flex align-items-center justify-content-end fw-bold"
                >
                  View All
                </Button>
              </div>
            </div>
          </div>
          <div className="row row-cols-2 row-cols-xxl-5 row-cols-md-5 g-4">
            {item.templatePageCategorySectionDetails &&
              item.templatePageCategorySectionDetails?.[0]?.products
                ?.slice(0, 10)
                ?.map((item) => {
                  return (
                    <div key={item.id} className="cols">
                      <ProductSingleContainer product={item} />
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedProducts;
