/** @format */

import { Button, Popover, Tooltip } from "antd";
import React, { useState } from "react";
import Loadable from "react-loadable";
import ActionTypes from "../../constants/action_types";
import { ANIMATION_CLASS } from "../../constants/definitions";
import Routes_Constants from "../../constants/routes_configs";
import useAppDispatch from "../../hooks/useAppDispatch";
import { CartProduct } from "../../redux/reducers/CommonReducer/CommonReducerInterface";
import LazyImage from "../../utils/Lazy Image/LazyImage";
import { useApiCalls } from "../../utils/useApiCalls";
import { useRetailHelper } from "../../utils/useRetailHelper";
import EmptyCart from "../empty_cart/empty_cart";

const CheckoutLoadable = Loadable({
  loader: () =>
    import("../../containers/checkout_container/checkout_container"),
  loading: () => null,
});

interface CartPopoverProps {
  isCartPopoverOpen: boolean;
}

const CartPopover: React.FC<CartPopoverProps> = ({ isCartPopoverOpen }) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (isVisible: any) => {
    setVisible(isVisible);
  };
  const {
    closeCartPopover,
    openCartPopover,
    getCartProducts,
    removeProductFromCart,
    getTotalAmountOfCart,
    isProductAddedInCart,
    currencySymbol,
    handleNavigation,
    totalPriceOfModifiers,
  } = useRetailHelper();
  const { getProductDetailBySlug } = useApiCalls();
  const dispatch = useAppDispatch();
  return (
    <>
      <Popover
        trigger={"click"}
        open={isCartPopoverOpen}
        onOpenChange={() => {
          closeCartPopover();
        }}
        className="w-40"
        placement="bottomLeft"
        content={
          isProductAddedInCart() ? (
            <div className=" p-2 cart-popover-container-with-items">
              <div className="widget-cart-item ">
                {getCartProducts().map((product: CartProduct, index) => {
                  return (
                    <div
                      key={index}
                      data-aos={ANIMATION_CLASS}
                      className="d-flex align-items-center justify-content-between pb-2 pt-2 border-bottom"
                    >
                      <div className="d-flex align-items-center">
                        <a
                          className="flex-shrink-0 "
                          onClick={() => {
                            getProductDetailBySlug(product.slug);
                            dispatch({
                              type: ActionTypes.SET_ACTIVE_SLUG,
                              payload: product.slug,
                            });
                            closeCartPopover();
                          }}
                        >
                          <LazyImage
                            src={product.imageUrl}
                            className="img-fluid"
                            width={64}
                            alt="Product"
                          />
                        </a>
                        <div className="dropdown_product-title ps-3">
                          <a
                            onClick={() => {
                              getProductDetailBySlug(product.slug);
                              dispatch({
                                type: ActionTypes.SET_ACTIVE_SLUG,
                                payload: product.slug,
                              });
                              closeCartPopover();
                            }}
                            style={{ fontWeight: "500" }}
                          >
                            {product.name}
                          </a>
                          <div>
                            <div className="dropdown_product-meta">
                              <span className="text-accent me-2">
                                {currencySymbol()}{" "}
                                {product.price +
                                  totalPriceOfModifiers(
                                    product?.priceModifiers ?? []
                                  )}
                              </span>
                              <span className="text-muted">
                                x {product.quantity}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <Tooltip title="Remove">
                        <button
                          className="btn-close text-danger"
                          type="button"
                          aria-label="Remove"
                          onClick={() => {
                            removeProductFromCart(index);
                            if (getCartProducts().length == 1) {
                              closeCartPopover();
                            }
                          }}
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </Tooltip>
                    </div>
                  );
                })}
              </div>
              <div className="d-flex flex-wrap justify-content-between align-items-center py-3">
                <div className="fs-sm me-2 py-2">
                  <span className="text-muted">Subtotal:</span>
                  <span className="text-accent fs-base ms-1">
                    {currencySymbol()} {getTotalAmountOfCart()}
                  </span>
                </div>
              </div>
              <a
                onClick={() => {
                  handleNavigation(Routes_Constants.CHECKOUT, CheckoutLoadable);
                  closeCartPopover();
                }}
              >
                {" "}
                <Button loading={false} type="default" className="w-100">
                  <i className="fas fa-shopping-cart me-2 fs-base align-middle" />
                  Go to Cart
                </Button>
              </a>
            </div>
          ) : (
            <EmptyCart />
          )
        }
        arrow={false}
      >
        <div
          className="d-flex align-items-center "
          aria-expanded="false"
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (getCartProducts().length) {
              openCartPopover();
            }
          }}
        >
          <a className="navbar-tool-icon-box bg-secondary ">
            <span className="navbar-tool-label">
              {getCartProducts().length}
            </span>
            <i className="navbar-tool-icon fas fa-shopping-cart" />
          </a>
          <a className="navbar-tool-text text-header ">
            <small className="">My Cart</small>
            {currencySymbol()} {getTotalAmountOfCart()}
          </a>
        </div>
      </Popover>
      {isCartPopoverOpen && (
        <div
          className="backdrop"
          onClick={() => {
            closeCartPopover();
          }}
        />
      )}
    </>
  );
};

export default CartPopover;
