/** @format */

import { Button, Divider, message } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import Loadable from "react-loadable";
import { toStringAsFixed } from "../../Helpers/FrontendHelper";
import CustomBreadcrumb from "../../components/custom_breadcrump/CustomBreadcrumb";
import EmptyCart from "../../components/empty_cart/empty_cart";
import ProductSelectedAttribute from "../../components/product_selected_attribute/ProductSelectedAttribute";
import ActionTypes from "../../constants/action_types";
import { UNAUTORIZED_PAYMENT_MESSAGE } from "../../constants/definitions";
import Routes_Constants from "../../constants/routes_configs";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { CartProduct } from "../../redux/reducers/CommonReducer/CommonReducerInterface";
import LazyImage from "../../utils/Lazy Image/LazyImage";
import useProtectedNavigation from "../../utils/handleProtectedNavigation";
import { useApiCalls } from "../../utils/useApiCalls";
import { useRetailHelper } from "../../utils/useRetailHelper";

const MakePaymentLoadable: any = Loadable({
  loader: () =>
    import("../../containers/make_payment_container/make_payment_container"),
  loading: () => null,
});

const Checkout: React.FC = () => {
  const {
    getCartProducts,
    getIndividualProductTotal,
    increaseQuantityOfProduct,
    decreaseQuantityOfProduct,
    getTotalAmountOfCart,
    removeProductFromCart,
    calculateTotalTax,
    getTotalAmountWithTaxOfCart,
    currencySymbol,
    isStoreTaxInclusive,
    isProductAddedInCart,
    totalPriceOfModifiers,
    calculateModifierTax,
  } = useRetailHelper();
  const { getProductDetailBySlug } = useApiCalls();
  const dispatch = useAppDispatch();
  const [handleProtectedNavigation] = useProtectedNavigation();
  const { handleNavigation } = useRetailHelper();
  const { isLoginSuccessFul } = useAppSelector((state) => state.authentication);
  const { homePageDataModel } = useAppSelector((state) => state.homePage);
  return (
    <>
      <Helmet>
        <title>Checkout</title>
      </Helmet>

      <section className="breadcrumbsection"></section>
      <section>
        <div className="viewcart">
          <div className="container">
            <CustomBreadcrumb items={[{ title: "Cart", link: "" }]} />

            {isProductAddedInCart() ? (
              <div className="row">
                <div className="col-lg-8">
                  <div className="bg-white rounded-3 p-4 mt-2 mb-4 card">
                    <div className="cardbody ">
                      <div>
                        <div>
                          {getCartProducts().map(
                            (product: CartProduct, index) => {
                              return (
                                <div
                                  key={index}
                                  className="d-flex justify-content-between align-items-center row"
                                >
                                  <div className="product-col col-md-5 col-5">
                                    <div className="product d-flex align-items-center">
                                      <a
                                        className="t-img"
                                        onClick={() => {
                                          getProductDetailBySlug(product.slug);
                                          dispatch({
                                            type: ActionTypes.SET_ACTIVE_SLUG,
                                            payload: product.slug,
                                          });
                                        }}
                                      >
                                        <LazyImage
                                          src={product.imageUrl}
                                          alt=""
                                        />
                                      </a>
                                      <div className="d-none d-md-block">
                                        <a
                                          className="product-title ms-3"
                                          onClick={() => {
                                            getProductDetailBySlug(
                                              product.slug
                                            );
                                            dispatch({
                                              type: ActionTypes.SET_ACTIVE_SLUG,
                                              payload: product.slug,
                                            });
                                          }}
                                        >
                                          {product.name}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-7 col-7">
                                    <div className="row justify-content-center align-items-center gap-2">
                                      <div className="col-md-12">
                                        <a
                                          className="text-dark text-start small d-md-none"
                                          onClick={() => {
                                            getProductDetailBySlug(
                                              product.slug
                                            );
                                            dispatch({
                                              type: ActionTypes.SET_ACTIVE_SLUG,
                                              payload: product.slug,
                                            });
                                          }}
                                        >
                                          {product.name}
                                        </a>
                                      </div>
                                      <div className="price-col col-md-2 col-5 d-none d-md-block">
                                        {currencySymbol()}{" "}
                                        {(
                                          product.price +
                                          totalPriceOfModifiers(
                                            product?.priceModifiers ?? []
                                          )
                                        ).toFixed(2)}
                                      </div>
                                      <div className="quantity-col col-md-2 col-4 d-flex justify-content-center">
                                        <div className="qty-box mb-0">
                                          <ul className="list-unstyled d-flex">
                                            <li className="d-flex quantity buttons_added">
                                              <input
                                                type="button"
                                                defaultValue="-"
                                                className="minus"
                                                onClick={() => {
                                                  decreaseQuantityOfProduct(
                                                    index
                                                  );
                                                }}
                                              />
                                              <input
                                                type="number"
                                                className="qty text"
                                                size={4}
                                                value={product.quantity}
                                                onChange={() => {}}
                                              />
                                              <input
                                                type="button"
                                                defaultValue="+"
                                                className="plus"
                                                onClick={() => {
                                                  if (
                                                    product.quantity + 1 >
                                                      product.productVariation
                                                        ?.stockCount &&
                                                    product.productVariation
                                                      ?.stockCount != null
                                                  ) {
                                                    message.error(
                                                      `Stock is not available more than ${product.productVariation?.stockCount}`
                                                    );
                                                  } else {
                                                    increaseQuantityOfProduct(
                                                      index
                                                    );
                                                  }
                                                }}
                                              />
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="total-col col-md-2 col-5">
                                        {currencySymbol()}{" "}
                                        {getIndividualProductTotal(product)}
                                      </div>
                                      <div className="t-rem col-md-1 col-1">
                                        <a>
                                          <i
                                            onClick={() => {
                                              removeProductFromCart(index);
                                            }}
                                            style={{ color: "red" }}
                                            className="fas fa-trash-alt"
                                          />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <ProductSelectedAttribute product={product} />

                                  {index < getCartProducts().length - 1 && (
                                    <Divider className="mt-3 mb-3" />
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End .table table-wishlist */}
                </div>
                <div className="col-lg-4 pt-2">
                  <div className="bg-white rounded-3 p-4 card ms-lg-auto price-cart sticky-top  mb-4">
                    <div className="py-2 px-xl-2">
                      <ul className="list-unstyled fs-sm pb-2 border-bottom">
                        <li className="d-flex justify-content-between align-items-center fw-bold">
                          <span className="me-2">Subtotal:</span>
                          <span className="text-end">
                            {currencySymbol()} {getTotalAmountOfCart()}
                          </span>
                        </li>
                        {/* <li className="d-flex justify-content-between align-items-center fw-bold">
                        <span className="me-2">Shipping:</span>
                        <span className="text-end">—</span>
                      </li> */}
                        <li className="d-flex justify-content-between align-items-center fw-bold">
                          <span className="me-2">
                            {isStoreTaxInclusive()
                              ? "Total Tax Inclued:"
                              : "Total Tax"}
                          </span>
                          <span className="text-end">
                            {currencySymbol()}{" "}
                            {toStringAsFixed(calculateTotalTax())}
                          </span>
                        </li>
                      </ul>
                      <h3 className="fw-bold text-center my-4">
                        {currencySymbol()} {getTotalAmountWithTaxOfCart()}
                      </h3>
                      {isLoginSuccessFul ? (
                        <a
                          onClick={() => {
                            handleProtectedNavigation(
                              UNAUTORIZED_PAYMENT_MESSAGE,
                              Routes_Constants.MAKE_PAYMENT
                            );
                          }}
                        >
                          <Button className="d-block w-100" type="default">
                            <i className="fas fa-credit-card me-2 fs-base align-middle" />
                            Proceed To Checkout
                          </Button>
                        </a>
                      ) : (
                        <div
                          className={`${
                            homePageDataModel?.storeDetails
                              ?.enableGuestCheckOut && "d-flex gap-2"
                          }`}
                        >
                          <a
                            onClick={() => {
                              handleProtectedNavigation(
                                UNAUTORIZED_PAYMENT_MESSAGE,
                                Routes_Constants.MAKE_PAYMENT
                              );
                            }}
                          >
                            <Button className="d-block w-100" type="default">
                              <i className="fas fa-credit-card me-2 fs-base align-middle" />
                              {homePageDataModel?.storeDetails
                                ?.enableGuestCheckOut
                                ? "  Member Checkout"
                                : "Proceed To Checkout"}
                            </Button>
                          </a>
                          {homePageDataModel?.storeDetails
                            ?.enableGuestCheckOut && (
                            <a
                              onClick={() => {
                                handleNavigation(
                                  Routes_Constants.MAKE_PAYMENT,
                                  MakePaymentLoadable,
                                  {
                                    isGuestCheckout: true,
                                  }
                                );
                              }}
                            >
                              <Button className="d-block w-100" type="primary">
                                <i className="fas fa-user me-2 fs-base align-middle" />
                                Guest Checkout
                              </Button>
                            </a>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <EmptyCart />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Checkout;
