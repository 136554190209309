/** @format */

import {
  getLocalStorage,
  isAuth,
  removeCookie,
  removeLocalStorage,
  setCookie,
  setLocalStorage,
} from "../../../Helpers/FrontendHelper";
import ActionTypes from "../../../constants/action_types";
import {
  TOKEN_LOCAL_STORAGE,
  USER_INFORMATION_LOCAL_STORAGE,
} from "../../../constants/definitions";
import AuthenticationReducerInterface from "./AuthenticationReducerInterface";

/** @format */

const initialState: AuthenticationReducerInterface = {
  isLoading: false,
  isLoginSuccessFul: isAuth() ? true : false,
  loginResponse:
    getLocalStorage(USER_INFORMATION_LOCAL_STORAGE)?.userInformation ?? {},
};

const reducer = (
  state = initialState,
  action: any
): AuthenticationReducerInterface => {
  switch (action.type) {
    case ActionTypes.AUTHENTICATE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoginSuccessFul: false,
      };
    case ActionTypes.AUTHENTICATE_USER_SUCCESS:
      setCookie(TOKEN_LOCAL_STORAGE, action.payload.data?.token);
      setLocalStorage(USER_INFORMATION_LOCAL_STORAGE, {
        userInformation: {
          name: action.payload.data?.name,
          email: action.payload.data?.email,
          userId: action.payload.data?.userId,
          image: action.payload.data?.image,
        },
      });
      return {
        ...state,
        isLoading: false,
        isLoginSuccessFul: true,
        loginResponse: action.payload?.data,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.AUTHENTICATE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoginSuccessFul: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.SEND_OTP_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSendOtpSuccessFul: false,
      };
    case ActionTypes.SEND_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successMessage: action.payload?.data?.message,
        isSendOtpSuccessFul: true,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.SEND_OTP_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSendOtpSuccessFul: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };

    case ActionTypes.SEND_OTP_RESEND_REQUEST:
      return {
        ...state,
        resendOTPLoading: true,
        isResendOTPSuccess: false,
        error: null,
      };
    case ActionTypes.SEND_OTP_RESEND_SUCCESS:
      return {
        ...state,
        resendOTPLoading: false,
        isResendOTPSuccess: true,
      };
    case ActionTypes.SEND_OTP_RESEND_FAILURE:
      return {
        ...state,
        isResendOTPSuccess: false,
        resendOTPLoading: false,
      };
    case ActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSendOtpSuccessFul: false,
      };
    case ActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successMessage: action.payload?.data?.message,
        isSendOtpSuccessFul: true,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSendOtpSuccessFul: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        isChangePasswordSuccesful: false,
      };
    case ActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successMessage: action.payload?.data?.message,
        isChangePasswordSuccesful: true,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isChangePasswordSuccesful: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.CHANGE_PASSWORD_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isChangePasswordSuccesful: false,
      };
    case ActionTypes.CHANGE_PASSWORD_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successMessage: action.payload?.data?.message,
        isChangePasswordSuccesful: true,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.CHANGE_PASSWORD_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isChangePasswordSuccesful: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.REGSITER_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoginSuccessFul: false,
      };
    case ActionTypes.REGSITER_USER_SUCCESS:
      setCookie(TOKEN_LOCAL_STORAGE, action.payload.data?.token);
      setLocalStorage(USER_INFORMATION_LOCAL_STORAGE, {
        userInformation: {
          name: action.payload.data?.name,
          email: action.payload.data?.email,
          userId: action.payload.data?.userId,
          image: action.payload.data?.image,
        },
      });
      return {
        ...state,
        isLoading: false,
        isLoginSuccessFul: true,
        loginResponse: action.payload?.data,

        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.REGSITER_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoginSuccessFul: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.RESET_AUTHENTICATION_STATE:
      return {
        ...state,
        isLoginSuccessFul: false,
        error: null,
        isSendOtpSuccessFul: false,
        successMessage: null,
        isChangePasswordSuccesful: false,
      };
    case ActionTypes.LOGOUT_REQUEST:
      removeCookie(TOKEN_LOCAL_STORAGE);
      removeLocalStorage(USER_INFORMATION_LOCAL_STORAGE);
      return {
        ...state,
        isLoginSuccessFul: false,
        loginResponse: undefined,
      };
    default:
      return state;
  }
};

export default reducer;
