/** @format */
import { combineReducers } from "redux";
import HomePageReducer from "./HomePageReducer/HomePageReducer";
import HomePageReducerInterface from "./HomePageReducer/HomePageReducerInterface";

import AuthenticationReducer from "./AuthenticationReducer/AuthenticationReducer";
import AuthenticationReducerInterface from "./AuthenticationReducer/AuthenticationReducerInterface";

import CommonReducer from "./CommonReducer/CommonReducer";
import CommonReducerInterface from "./CommonReducer/CommonReducerInterface";

import FilterProductsInterface from "./FilterProductsReducer/FilterProductsInterface";
import FilterProductsReducer from "./FilterProductsReducer/FilterProductsReducer";

import ProductDetailsInterface from "./ProductDetailsReducer/ProductDetailsInterface";
import ProductDetailsReducer from "./ProductDetailsReducer/ProductDetailsReducer";

import GiftCardReducer from "./GiftCardReducer/GIftCardReducer";
import GiftCardReducerInterface from "./GiftCardReducer/GiftCardReducerInterface";

import MakePaymentInterface from "./MakePaymentReducer/MakePaymentInterface";
import MakePaymentReducer from "./MakePaymentReducer/MakePaymentReducer";

import ProfileReducer from "./ProfileReducer/ProfileReducer";
import ProfileInterface from "./ProfileReducer/ProfileReducerInterface";

import PageReducer from "./PageReducer/PageReducer";
import PageInterface from "./PageReducer/PageReducerInterface";

import WishlistReducer from "./WishlistReducer/WishlistReducer";
import WishlistInterface from "./WishlistReducer/WishlistReducerInterface";

import BlogReducer from "./BlogReducer/BlogReducer";
import BlogReducerInterface from "./BlogReducer/BlogReducerInterface";

import MegaMenuInterface from "./MegaMenuReducer/MegaMenuInterface";
import MegaMenuReducer from "./MegaMenuReducer/MegaMenuReducer";

export interface RootState {
  homePage: HomePageReducerInterface;
  productDetails: ProductDetailsInterface;
  filterProducts: FilterProductsInterface;
  common: CommonReducerInterface;
  authentication: AuthenticationReducerInterface;
  giftCard: GiftCardReducerInterface;
  makePayment: MakePaymentInterface;
  profile: ProfileInterface;
  page: PageInterface;
  wishlist: WishlistInterface;
  blogs: BlogReducerInterface;
  megaMenu: MegaMenuInterface;
}

export const rootReducer = combineReducers<RootState>({
  homePage: HomePageReducer,
  productDetails: ProductDetailsReducer,
  filterProducts: FilterProductsReducer,
  common: CommonReducer,
  authentication: AuthenticationReducer,
  giftCard: GiftCardReducer,
  makePayment: MakePaymentReducer,
  profile: ProfileReducer,
  page: PageReducer,
  wishlist: WishlistReducer,
  blogs: BlogReducer,
  megaMenu: MegaMenuReducer,
});
