/** @format */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ActionTypes from "../../constants/action_types";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import FliterProducts from "../../pages/filter-products/FliterProducts";
import {
  AdditionalFilter,
  CheckedFilter,
  FilterTypeOption,
  ProductCategory,
  QueryString,
} from "../../redux/reducers/FilterProductsReducer/FilterProductsInterface";
import { SegmentedValue } from "../../types/commonInterfaces";
import { useApiCalls } from "../../utils/useApiCalls";

const FilterProductsContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { filterProductsDataModel, shouldClearCheckedFilters } = useAppSelector(
    (state) => state.filterProducts
  );
  const { getAllFilterProducts } = useApiCalls();

  const [activeCategory, setActiveCategory] = useState<
    ProductCategory | undefined
  >(undefined);
  const [viewType, setViewType] = useState<SegmentedValue>("grid");
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<string[]>(
    []
  );
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [priceRange, setPriceRange] = useState<number[]>([]);
  const priceAdditionalFilter: AdditionalFilter | undefined =
    filterProductsDataModel?.searchFilters?.additionalFilters?.find(
      (item) => item.type == "4"
    );
  const minPrice: number = Number(priceAdditionalFilter?.filters?.[0]?.value);
  const maxPrice: number = Number(priceAdditionalFilter?.filters?.[1]?.value);

  const [activeAddtionalFilter, setActiveAdditionalFilter] =
    useState<string>("");
  const [activeDiscount, setActiveDiscount] = useState<string>("");

  const [checkedFilters, setCheckedFilters] = useState<CheckedFilter[] | []>(
    []
  );
  const [isCheckedFilterChange, setIsCheckFilterChanged] = useState(false);

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (shouldClearCheckedFilters) {
      setCheckedFilters([]);
      setIsCheckFilterChanged(true);
      dispatch({
        type: ActionTypes.SHOULD_NOT_CLEAR_CHECKED_FILTERS,
      });
    }
  }, [shouldClearCheckedFilters]);

  const getFilterProductsWithQuery = (payload?: QueryString) => {
    const filters = {
      page: payload?.page ? payload.page : 1,
      pageSize: payload?.pageSize ? payload.pageSize : 24,
      searchKeywords: payload?.searchKeywords ? payload.searchKeywords : "",
      categoryIds: payload?.categoryIds ? payload.categoryIds : [],
      brandIds: payload?.brandIds ? payload.brandIds : [],
      filterOptionsIds: payload?.filterOptionsIds
        ? payload.filterOptionsIds
        : [],
      priceMax: payload?.priceMax ? payload.priceMax : "",
      priceMin: payload?.priceMin ? payload.priceMin : "",
      sort: payload?.sort ? payload.sort : "",
      discount: payload?.discount ? payload.discount : "",
      type: payload?.type ? payload?.type : "",
      slug: payload?.slug ? payload?.slug : "",
    };

    getAllFilterProducts(filters);
    dispatch({
      type: ActionTypes.SET_QUERY_STRINGS,
      payload: filters,
    });
  };

  useEffect(() => {
    getFilterProductsWithQuery({
      page: searchParams.get("page") ? parseInt(searchParams.get("page")!) : 1,
      pageSize: searchParams.get("pageSize")
        ? parseInt(searchParams.get("pageSize")!)
        : 24,
      searchKeywords: searchParams.get("searchKeywords") || "",
      categoryIds: searchParams.get("categoryIds")
        ? searchParams.get("categoryIds")!.split("&")
        : [],
      brandIds: searchParams.get("brandIds")
        ? searchParams.get("brandIds")!.split("&")
        : [],
      filterOptionsIds: searchParams.get("filterOptionsIds")
        ? searchParams.get("filterOptionsIds")!.split("&")
        : [],
      priceMax: searchParams.get("priceMax") || "",
      priceMin: searchParams.get("priceMin") || "",
      sort: searchParams.get("sort") || "",
      discount: searchParams.get("discount") || "",
      type: searchParams.get("type") || "",
      slug: searchParams.get("slug") || "",
    });
  }, [dispatch, location.search]);

  useEffect(() => {
    if (filterProductsDataModel && checkedFilters.length == 0) {
      const categoryIdsFromURL = searchParams.get("categoryIds")
        ? searchParams.get("categoryIds")?.split("&")
        : [];

      const brandIdsFromURL = searchParams.get("brandIds")
        ? searchParams.get("brandIds")?.split("&")
        : [];
      // Extract filter options IDs from URL parameters
      const filterOptionsIdsFromURL = searchParams.get("filterOptionsIds")
        ? searchParams.get("filterOptionsIds")?.split("&")
        : [];
      // Map category IDs from URL to corresponding categories
      const selectedCategories =
        filterProductsDataModel?.searchFilters?.productCategories?.filter(
          (category) => categoryIdsFromURL?.includes(category.slug)
        );
      // Map brand IDs from URL to corresponding brands
      const selectedBrands =
        filterProductsDataModel?.searchFilters?.brands?.filter((brand) =>
          brandIdsFromURL?.includes(brand.id)
        );
      // Map filter options IDs from URL to corresponding filterTypeOptions
      const selectedFilterOptions: any = [];
      filterProductsDataModel.searchFilters.filterTypes.forEach(
        (filterOption) => {
          filterOption.filterTypeOptions.forEach((filterTypeOption) => {
            if (filterOptionsIdsFromURL?.includes(filterTypeOption.id)) {
              selectedFilterOptions.push(filterTypeOption);
            }
          });
        }
      );

      // Extract sorting and discount values from URL parameters
      const sortValueFromURL = searchParams.get("sort") || "";
      const discountValueFromURL = searchParams.get("discount") || "";

      // Extract type and slug values from URL parameters
      const typeFromURL = searchParams.get("type") || "";
      const slugFromURL = searchParams.get("slug") || "";
      const searchKeywordsFromURL = searchParams.get("searchKeywords") || "";

      // Map sorting value from URL to corresponding option
      const selectedSortOption =
        filterProductsDataModel?.searchFilters?.additionalFilters
          ?.find((item) => item.type === "1")
          ?.filters.find((option) => option.value === sortValueFromURL);

      // Map discount value from URL to corresponding option
      const selectedDiscountOption =
        filterProductsDataModel?.searchFilters?.additionalFilters
          ?.find((item) => item.type === "2")
          ?.filters.find((option) => option.value === discountValueFromURL);
      //

      // Adding the CheckedFilters Array

      const checkedFilters: CheckedFilter[] = [];

      selectedCategories.forEach((category) => {
        checkedFilters.push({
          Name: category.slug,
          Id: category.id,
          isCategory: true,
        });
      });
      selectedFilterOptions.forEach((filterOption: FilterTypeOption) => {
        checkedFilters.push({
          Name: filterOption.name,
          Id: filterOption.id,
          isFilterOption: true,
        });
      });
      selectedBrands.forEach((brand) => {
        checkedFilters.push({
          Name: brand?.name,
          Id: brand.id,
          isBrand: true,
        });
      });
      if (selectedSortOption) {
        checkedFilters.push({
          Name: selectedSortOption.name,
          Id: selectedSortOption.id,
          isAdditionalFilter: true,
        });
      }
      if (selectedDiscountOption) {
        checkedFilters.push({
          Name: selectedDiscountOption.name,
          Id: selectedDiscountOption.value,
          isDiscount: true,
        });
      }
      if (slugFromURL) {
        checkedFilters.push({
          Name: slugFromURL,
          type: typeFromURL,
          Id: slugFromURL,
          isTemplateSection: true,
          slug: slugFromURL,
        });
      }
      if (searchKeywordsFromURL) {
        checkedFilters.push({
          Name: searchKeywordsFromURL,
          Id: "",
        });
      }
      setCheckedFilters(checkedFilters);
    }
  }, [filterProductsDataModel, isCheckedFilterChange]);

  useEffect(() => {
    // Function to parse the query parameter as an array if it exists, otherwise return an empty array
    const parseQueryArray = (paramName: string): string[] => {
      const paramValue = searchParams.get(paramName);
      return paramValue ? paramValue.split("&") : [];
    };

    // Check if activeCategory is undefined, then set it from the query parameter
    if (activeCategory === undefined) {
      const categoryId = searchParams.get("categoryIds");

      setActiveCategory(
        categoryId
          ? filterProductsDataModel?.searchFilters.productCategories.find(
              (item) => item.slug == categoryId.split("&")[0]
            )
          : undefined
      );
    }

    // Check if selectedFilterOptions is an empty array, then set it from the query parameter
    if (selectedFilterOptions.length === 0) {
      setSelectedFilterOptions(parseQueryArray("filterOptionsIds"));
    }

    // Check if selectedBrands is an empty array, then set it from the query parameter
    if (selectedBrands.length === 0) {
      setSelectedBrands(parseQueryArray("brandIds"));
    }

    // Check if activeAdditionalFilter is an empty string, then set it from the query parameter
    if (activeAddtionalFilter === "") {
      const sortValue = searchParams.get("sort");
      setActiveAdditionalFilter(sortValue || "");
    }
    if (activeDiscount === "") {
      const discountValue = searchParams.get("discount");
      setActiveDiscount(discountValue || "");
    }
  }, [dispatch, location.search]);

  const onActiveCategoryChange = (category: ProductCategory | undefined) => {
    setActiveCategory(category);
  };

  useEffect(() => {
    // Making Category null if there is not category in checked list
    if (!checkedFilters.some((filter) => filter.isCategory)) {
      setActiveCategory(undefined);
    }
    // Check if there are any active filters in the checkedFilters array
    const hasActiveFilters = checkedFilters.some(
      (filter) =>
        filter.isCategory ||
        filter.isFilterOption ||
        filter.isBrand ||
        filter.isAdditionalFilter ||
        filter.isDiscount ||
        filter.isPriceRange ||
        filter.isTemplateSection ||
        filter.isPagination
    );

    // If there are active filters, fetch the filtered products
    if (hasActiveFilters) {
      // Create a payload object for the API call based on the checked filters

      if (
        checkedFilters.filter((filter) => filter.isFilterOption)?.length > 0
      ) {
        dispatch({
          type: ActionTypes.LOCK_FILTER_OPTIONS,
        });
      } else {
        dispatch({
          type: ActionTypes.UNLOCK_FILTER_OPTIONS,
        });
      }

      const payload: QueryString = {
        categoryIds: checkedFilters
          .filter((filter) => filter.isCategory)
          .map((filter) => filter.Name),
        filterOptionsIds: checkedFilters
          .filter((filter) => filter.isFilterOption)
          .map((filter) => filter.Id),
        brandIds: checkedFilters
          .filter((filter) => filter.isBrand)
          .map((filter) => filter.Id),
        sort: checkedFilters.filter((filter) => filter.isAdditionalFilter)?.[0]
          ?.Id,
        discount: checkedFilters.filter((filter) => filter.isDiscount)?.[0]?.Id,
        priceMin: checkedFilters
          .filter((filter) => filter.isPriceRange)?.[0]
          ?.minPrice?.toString(),
        priceMax: checkedFilters
          .filter((filter) => filter.isPriceRange)?.[0]
          ?.maxPrice?.toString(),
        slug: checkedFilters
          .filter((filter) => filter.isTemplateSection)?.[0]
          ?.slug?.toString(),
        type: checkedFilters
          .filter((filter) => filter.isTemplateSection)?.[0]
          ?.type?.toString(),
        page: parseInt(
          checkedFilters?.filter((filter) => filter.isPagination)?.[0]?.Id
        ),
      };

      // Call the function to fetch filtered products with the payload
      getFilterProductsWithQuery(payload);
    }
  }, [checkedFilters]);

  return (
    <FliterProducts
      onActiveCategoryChange={onActiveCategoryChange}
      activeCategory={activeCategory}
      filterProductsDataModel={filterProductsDataModel}
      viewType={viewType}
      setViewType={setViewType}
      getFilterProductsWithQuery={getFilterProductsWithQuery}
      setSelectedFilterOptions={setSelectedFilterOptions}
      selectedFilterOptions={selectedFilterOptions}
      activeAddtionalFilter={activeAddtionalFilter}
      setActiveAdditionalFilter={setActiveAdditionalFilter}
      activeDiscount={activeDiscount}
      setActiveDiscount={setActiveDiscount}
      selectedBrands={selectedBrands}
      setSelectedBrands={setSelectedBrands}
      priceRange={priceRange}
      setPriceRange={setPriceRange}
      minPrice={minPrice}
      maxPrice={maxPrice}
      checkedFilters={checkedFilters}
      setCheckedFilters={setCheckedFilters}
      priceAdditionalFilter={priceAdditionalFilter}
    />
  );
};

export default FilterProductsContainer;
