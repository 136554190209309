/** @format */

// import brotli from "brotli";
import { Elements } from "@stripe/react-stripe-js";
import React from "react";
import { Helmet } from "react-helmet";
import useAppSelector from "../../hooks/useAppSelector";
import getStripeInstance from "../../utils/stripe";
import LayoutContainer from "../layout/layout_container";
import MakePaymentContainer from "./make_payment_container";

const MakePaymentTest: React.FC = () => {
  const { homePageDataModel } = useAppSelector((state) => state.homePage);
  const publishableKeyStore =
    homePageDataModel?.storeDetails?.paymentMethods?.find(
      (paymentM, index) => paymentM.identifier == "7"
    )?.publishableKey;

  return (
    <>
      <Helmet>
        <title>{homePageDataModel?.storeDetails?.storeName}</title>
        <meta
          property="og:title"
          content={homePageDataModel?.storeDetails?.storeName}
        />
        <meta
          property="og:description"
          content={homePageDataModel?.storeDetails?.storeName}
        />
        <meta
          property="og:image"
          content={homePageDataModel?.storeDetails?.imageUrl}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Elements stripe={getStripeInstance(publishableKeyStore ?? "")}>
        <LayoutContainer>
          <MakePaymentContainer />
        </LayoutContainer>
      </Elements>
    </>
  );
};

export default MakePaymentTest;
