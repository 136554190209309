/** @format */

import moment from "moment";
import ActionTypes from "../constants/action_types";
import useAppDispatch from "../hooks/useAppDispatch";
import useAppSelector from "../hooks/useAppSelector";
import { QueryString } from "../redux/reducers/FilterProductsReducer/FilterProductsInterface";
import { GetOrderHistoryRequest } from "../types/commonInterfaces";
import { useRetailHelper } from "./useRetailHelper";

interface ApiCallsInterface {
  getProductDetailBySlug: (slug: string) => void;
  getAllFilterProducts: (queryString: QueryString) => void;
  getProfileDetails: () => void;
  getOrdersHistory: (payload?: GetOrderHistoryRequest) => void;
  getPageDetails: (slug: string) => void;
  getAllWishlistsProduct: () => void;
  addProductToWishlist: (productId: string) => void;
  deleteWishlistProduct: (id: string) => void;
  getOrderDetail: (orderId: string) => void;
  getAllBlogs: (page?: string) => void;
  getBlogBySlug: (slug: string) => void;
}

export function useApiCalls(): ApiCallsInterface {
  const dispatch = useAppDispatch();
  const { loginResponse } = useAppSelector((state) => state.authentication);
  const { formatDate } = useRetailHelper();
  const { homePageDataModel } = useAppSelector((state) => state.homePage);

  const getProductDetailBySlug = (slug: string) => {
    dispatch({
      type: ActionTypes.GET_PRODUCT_DETAILS_REQUEST,
      payload: {
        Slug: slug,
      },
    });
  };
  const getAllFilterProducts = (queryString: QueryString) => {
    dispatch({
      type: ActionTypes.GET_ALL_PRODUCTS_REQUEST,
      payload: {
        Page: queryString.page ?? 1,
        PageSize: queryString.pageSize ?? 24,
        SearchKeywords: queryString.searchKeywords ?? "",
        ExternalFilter: {
          Categories: queryString.categoryIds ?? [],
          Brands: queryString.brandIds ?? [],
          FilterOptions: queryString.filterOptionsIds ?? [],
          PriceMin: queryString.priceMin ?? "",
          PriceMax: queryString.priceMax ?? "",
          Sort: queryString.sort ?? "",
          Discount: queryString.discount ?? "",
        },
        Type: queryString.type ?? "",
        Slug: queryString.slug ?? "",
      },
    });
  };
  const getProfileDetails = () => {
    dispatch({
      type: ActionTypes.GET_PROFILE_DETAILS_REQUEST,
      payload: {
        UserId: loginResponse?.userId,
      },
    });
  };
  const getOrdersHistory = (payload?: GetOrderHistoryRequest) => {
    const orderedDateFrom = moment()
      .subtract(15, "days")
      .format(
        `${homePageDataModel?.storeDetails?.dateFormat
          ?.split(" ")[0]
          ?.toUpperCase()} HH:mm:ss`
      );

    const orderedDateTo = moment().format(
      `${homePageDataModel?.storeDetails?.dateFormat
        ?.split(" ")[0]
        ?.toUpperCase()} HH:mm:ss`
    );

    dispatch({
      type: ActionTypes.GET_ORDER_HISTORY_REQUEST,
      payload: payload
        ? payload
        : {
            Page: 1,
            PageSize: 10,
            SearchKeywords: "",
            ExternalFilter: {
              OrderedDateFrom: orderedDateFrom,
              OrderedDateTo: orderedDateTo,
            },
          },
    });
  };

  const getPageDetails = (slug: string) => {
    dispatch({
      type: ActionTypes.GET_PAGE_DETAIL_REQUEST,
      payload: { Slug: slug, StoreId: homePageDataModel?.storeDetails?.id },
    });
  };

  const getAllWishlistsProduct = () => {
    dispatch({
      type: ActionTypes.GET_ALL_WISHLIST_PRODUCT_REQUEST,
      payload: {
        StoreId: homePageDataModel?.storeDetails?.id,
        UserId: loginResponse?.userId,
      },
    });
  };

  const addProductToWishlist = (productId: string) => {
    dispatch({
      type: ActionTypes.ADD_PRODUCT_WISHLIST_REQUEST,
      payload: {
        ProductId: productId,
        StoreId: homePageDataModel?.storeDetails?.id,
        UserId: loginResponse?.userId,
      },
    });
  };
  const deleteWishlistProduct = (id: string) => {
    dispatch({
      type: ActionTypes.DELETE_WISHLIST_ITEM_REQUEST,
      payload: {
        Id: id,
        StoreId: homePageDataModel?.storeDetails?.id,
        UserId: loginResponse?.userId,
      },
    });
  };
  const getOrderDetail = (orderId: string) => {
    dispatch({
      type: ActionTypes.GET_ORDER_DETAIL_REQUEST,
      payload: {
        OrderId: orderId,
      },
    });
  };
  const getAllBlogs = (page?: string) => {
    dispatch({
      type: ActionTypes.GET_ALL_BLOGS_REQUEST,
      payload: {
        Page: page ?? 1,
        PageSize: 10,
        StoreId: homePageDataModel?.storeDetails?.id,
      },
    });
  };
  const getBlogBySlug = (slug: string) => {
    dispatch({
      type: ActionTypes.GET_BLOG_DETAIL_REQUEST,
      payload: { Slug: slug, StoreId: homePageDataModel?.storeDetails?.id },
    });
  };
  return {
    getProductDetailBySlug,
    getAllFilterProducts,
    getProfileDetails,
    getOrdersHistory,
    getPageDetails,
    getAllWishlistsProduct,
    addProductToWishlist,
    deleteWishlistProduct,
    getOrderDetail,
    getAllBlogs,
    getBlogBySlug,
  };
}
