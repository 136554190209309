/** @format */

import { Avatar } from "antd";
import React from "react";
import { MegaMenuLayoutTypes } from "../../constants/definitions";
import { MegaMenuWithChildrenAndCardsViewModel } from "../../redux/reducers/MegaMenuReducer/MegaMenuInterface";
import { useRetailHelper } from "../../utils/useRetailHelper";
import HorizontalCardBlock from "./HorizontalCardBlock";
import VerticalCardBlockMegaM from "./VerticalCardBlockMegaM";

interface VerticalMegaMenuProps {
  megaMenu: MegaMenuWithChildrenAndCardsViewModel[];
  isSecondLevel?: boolean;
}

const VerticalMegaMenu: React.FC<VerticalMegaMenuProps> = ({
  megaMenu,
  isSecondLevel,
}) => {
  const { handleRedirectMegaMenu } = useRetailHelper();
  return (
    <ul
      className={`${
        isSecondLevel
          ? "dropdown-menu-list-submenu shadow-sm second-level-vertical-menu"
          : "dropdown-menu dropdown-menu-lg shadow animated fadeIn border-0 p-3 v-menu"
      }`}
    >
      {megaMenu.map((childMegaMenuLev1, index) => {
        return (
          <li className="dropdown-menu-list" key={childMegaMenuLev1.id}>
            <a
              onClick={() =>
                handleRedirectMegaMenu(childMegaMenuLev1.link ?? "")
              }
              className="dropdown-item d-flex justify-content-between mb-1 py-1"
            >
              <div>
                {childMegaMenuLev1.imageUrl && (
                  <Avatar src={childMegaMenuLev1.imageUrl} />
                )}{" "}
                <span className="ms-1">{childMegaMenuLev1.name}</span>
              </div>
              {childMegaMenuLev1.childrens.length > 0 && (
                <>
                  <div>
                    <i className="fas fa-caret-right" />
                  </div>
                  {childMegaMenuLev1.childMegaMenuType ==
                    MegaMenuLayoutTypes.HorizontalCardBlock && (
                    <HorizontalCardBlock
                      megaMenu={childMegaMenuLev1.childrens}
                      isSecondLevel={true}
                    />
                  )}
                  {childMegaMenuLev1.childMegaMenuType ==
                    MegaMenuLayoutTypes.VerticalCardBlock && (
                    <VerticalCardBlockMegaM
                      megaMenu={childMegaMenuLev1.childrens}
                      isSecondLevel={true}
                    />
                  )}
                  {childMegaMenuLev1.childMegaMenuType ==
                    MegaMenuLayoutTypes.Vertical && (
                    <VerticalMegaMenu
                      megaMenu={childMegaMenuLev1.childrens}
                      isSecondLevel={true}
                    />
                  )}
                </>
              )}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default VerticalMegaMenu;
