/** @format */

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Form, Input, message } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toStringAsFixed } from "../../Helpers/FrontendHelper";
import ActionTypes from "../../constants/action_types";
import {
  CARD_ELEMENT_OPTIONS,
  CHANNEL_PLATFORM,
  EMAIL_VALIDATION_MESSAGE,
  EMPTY_FIELD_MESSAGE,
} from "../../constants/definitions";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useSnackbar from "../../hooks/useSnackbar";
import MakePayment from "../../pages/make-payment/MakePayment";
import { OrderType } from "../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import {
  MakePaymentFormValues,
  MakePaymentRequest,
} from "../../types/commonInterfaces";
import { useRetailHelper } from "../../utils/useRetailHelper";

const MakePaymentContainer = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        email: "gajurel.ram01@gmail.com",
      },
    });

    if (error) {
      message.error(error.message);
      return;
    }

    const response = await fetch(
      "http://localhost:8001/api/payment/create-payment-intent",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          email: "gajurel.ram01@gmail.com",
        }),
      }
    );

    const paymentIntentResponse = await response.json();

    if (paymentIntentResponse.error) {
      message.error(paymentIntentResponse.error);
    } else {
      message.success("Stripe Success !");
    }
  };

  const location = useLocation();
  const isGuestCheckout = location.state?.isGuestCheckout;
  const [makePaymentForm] = Form.useForm<MakePaymentFormValues>();
  const [voucherCode, setVoucherCode] = useState<string | undefined>();
  const { homePageDataModel } = useAppSelector((state) => state.homePage);
  const { loginResponse } = useAppSelector((state) => state.authentication);
  const { countires } = useAppSelector((state) => state.common);
  const {
    isLoading,
    error,
    successMessage,
    isCheckVoucherSuccess,
    voucherDiscount,
    isAddDeliveryLocationSuccess,
    addDeliverLocationLoading,
    deliveryAddresses,
    makePaymentLoading,
    isMakePaymentSuccessful,
  } = useAppSelector((state) => state.makePayment);
  const [activeOrderType, setActiveOrderType] = useState<OrderType>();
  const [deliveryAddress, setDeliveryAddress] = useState<any>({});
  const selectedDate = Form.useWatch("pickUpDeliveryDateTime", makePaymentForm);
  const [selectedWeekDay, setSelectedWeekDay] = useState<string>(
    moment().format("dddd").toLowerCase()
  );
  const [finalOrderNote, setFinalOrderNote] = useState<string>("");
  const [stripeLoading, setStripeLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const {
    getTableId,
    isStoreTaxInclusive,
    getVoucherDiscountAmount,
    getDiscountTax,
    getTotalAmountWithTaxOfCart,
    calculateTotalTax,
    getTotalAmountOfCart,
    getDeliveryAmount,
    getDeliveryTax,
    formatDate,
    getCartProducts,
    calculateProductTax,
    clearCartProducts,
    calculateModifierTax,
    createRequestObjectForFilterOptions,
  } = useRetailHelper();

  const onRedeemVoucherHandler = () => {
    if (voucherCode) {
      dispatch({
        type: ActionTypes.REDEEM_VOUCHER_REQUEST,
        payload: {
          StoreId: homePageDataModel?.storeDetails?.id,
          VoucherCode: voucherCode,
          UserId: loginResponse?.userId,
        },
      });
    }
  };

  useEffect(() => {
    if (deliveryAddress.deliveryLocation) {
      dispatch({
        type: ActionTypes.ADD_DELIVERY_LOCATION_REQUEST,
        payload: {
          Id: deliveryAddress.id ?? "",
          UserId: loginResponse?.userId,
          StoreId: homePageDataModel?.storeDetails?.id,
          Latitude: String(deliveryAddress.latitude),
          Longitude: String(deliveryAddress.longitude),
          DeliveryLocation: String(deliveryAddress.deliveryLocation),
        },
      });
    }
  }, [deliveryAddress]);

  const onAddDeliveryLocationSubmit = () => {
    if (deliveryAddress.deliveryLocation) {
      dispatch({
        type: ActionTypes.ADD_DELIVERY_LOCATION_REQUEST,
        payload: {
          Id: deliveryAddress.id ?? "",
          UserId: loginResponse?.userId,
          StoreId: homePageDataModel?.storeDetails?.id,
          Latitude: String(deliveryAddress.latitude),
          Longitude: String(deliveryAddress.longitude),
          DeliveryLocation: String(deliveryAddress.deliveryLocation),
        },
      });
    }
  };

  const onMakePaymentFormSubmitHandler = async (
    values: MakePaymentFormValues
  ) => {
    const requiredKeys = [
      // "recieverCountryPhoneNumberPrefix",
      // "recieverCountryId",
      "recieverName",
      // "recieverEmail",
      // "recieverPhoneNumber",
      // "recieverPostalCode",
      // "message",
      // "sendEmailToReceiver",
    ];

    const hasAnyRequiredKey = requiredKeys.some((key) => key in values);
    if (
      activeOrderType?.identifier == "2" &&
      !deliveryAddress?.deliveryLocation
    ) {
      message.error("Please Choose Delivery Address !");
      return;
    }

    if (!stripe || !elements) {
      message.error("Stripe has not been loaded !");
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      message.error("Card has not been loaded !");
      return;
    }
    setStripeLoading(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        email: makePaymentForm.getFieldValue("stripeEmail"),
      },
    });
    setStripeLoading(false);

    if (error) {
      message.error(error.message);
      return;
    }

    dispatch({
      type: ActionTypes.MAKE_PAYMENT_REQUEST,
      payload: {
        IsGuestCheckout: isGuestCheckout,
        StoreId: homePageDataModel?.storeDetails?.id,
        OrderTypeId: activeOrderType?.id,
        UserId: isGuestCheckout ? "" : loginResponse?.userId,
        ChannelPlatform: CHANNEL_PLATFORM,
        TableId: getTableId(),
        Description: finalOrderNote,
        DiscountAmount: toStringAsFixed(
          isStoreTaxInclusive()
            ? getVoucherDiscountAmount() - getDiscountTax()
            : getVoucherDiscountAmount()
        ),
        DiscountPercentage: isCheckVoucherSuccess ? voucherDiscount : "",
        DiscountAmountWithTax: toStringAsFixed(
          isStoreTaxInclusive()
            ? getVoucherDiscountAmount()
            : getVoucherDiscountAmount() + getDiscountTax()
        ),
        DeliveryAddress: deliveryAddress?.deliveryLocation ?? "",
        TotalAmount: toStringAsFixed(getTotalAmountWithTaxOfCart()),
        DeliveryAmount: toStringAsFixed(
          isStoreTaxInclusive()
            ? getDeliveryAmount() - getDeliveryTax()
            : getDeliveryAmount()
        ),
        DeliveryAmountWithTax: toStringAsFixed(
          isStoreTaxInclusive()
            ? getDeliveryAmount()
            : getDeliveryAmount() + getDeliveryTax()
        ),
        TotalTaxAmount: toStringAsFixed(
          calculateTotalTax() + getDeliveryTax() - getDiscountTax()
        ),
        TotalWithoutTaxAmount: toStringAsFixed(
          isStoreTaxInclusive()
            ? getTotalAmountOfCart() - calculateTotalTax()
            : getTotalAmountOfCart()
        ),
        VoucherRequestViewModel: {
          VoucherCode: isCheckVoucherSuccess ? voucherCode : "",
        },
        PickUpDeliveryDateTime:
          formatDate(values.pickUpDeliveryDateTime)?.split(" ")?.[0] +
          " " +
          (activeOrderType?.identifier != "2" ? values.time : "00:00:00"),
        PaymentMethodId: homePageDataModel?.storeDetails?.paymentMethods.find(
          (item) => item.identifier == "7"
        )?.id,
        PaymentMethodStripeId: paymentMethod.id,
        // CreditCardRequestModel: {
        //   NameOnCard: values.name,
        //   CardNumber: values.cardNumber,
        //   CVCNumber: values.cvc,
        //   ExpiryMonth: values.expiry?.split("/")[0],
        //   ExpiryYear: values.expiry?.split("/")[1],
        // },

        CustomerRequestModel: isGuestCheckout
          ? {
              Name: values.fullName,
              Email: values.email,
              PhoneNumber: values.phoneNumber,
              CountryId: values.countryId,
              CountryPhoneNumberPrefixId: values.countryPhoneNumberPrefixId,
              PostalCode: values.postalCode,
            }
          : null,
        ReceiverRequestModel:
          hasAnyRequiredKey && values.recieverName
            ? {
                Id: "",
                CountryPhoneNumberPrefixId:
                  values.recieverCountryPhoneNumberPrefixId ?? "",
                CountryId: values.recieverCountryId ?? "",
                Name: values.recieverName ?? "",
                // Email: values.recieverEmail ?? "",
                PhoneNumber: values.recieverPhoneNumber ?? "",
                PostalCode: values.recieverPostalCode ?? "",
                Message: values.message ?? "",
                SendEmailToReceiver: false,
              }
            : null,
        OrderDetailsRequestModel: getCartProducts().map((item, index) => {
          return {
            ProductId: item.productId,
            ProductPrice: toStringAsFixed(item.actualPrice),
            ProductVariationId: item.productVariationId,
            Description: item.description,
            Quantity: item.quantity.toString(),
            Total: toStringAsFixed(item.quantity * item.price),
            TotalTax: toStringAsFixed(calculateProductTax(item)),
            OrderItemsSelectOptionsRequestModel:
              createRequestObjectForFilterOptions(
                item.productFilterTypeFilterOptions ?? []
              ),
            DiscountWithoutTax: toStringAsFixed(0),
            OrderItemsPriceModifierRequestModel: item.priceModifiers?.map(
              (modifier) => {
                return {
                  ModifierName: modifier.name,
                  ModifierPrice: modifier.price,
                  TotalModifierPrice: toStringAsFixed(
                    parseFloat(modifier.price) * item.quantity
                  ),
                  Quantity: toStringAsFixed(item.quantity),
                  PriceVariationModifierId: modifier.id,
                  // Changing this as Cart Product
                  TotalTax: toStringAsFixed(
                    calculateModifierTax(
                      parseFloat(modifier.price),
                      item.quantity,
                      Number(item.tax)
                    )
                  ),
                };
              }
            ),
          };
        }),
      } as MakePaymentRequest,
    });
  };

  useEffect(() => {
    if (homePageDataModel) {
      setActiveOrderType(homePageDataModel.storeDetails?.orderTypes[0]);
      makePaymentForm.setFields([
        {
          name: "pickUpDeliveryDateTime",
          value: dayjs(moment().format("YYYY/MM/DD"), "YYYY/MM/DD"),
        },
      ]);
    }
  }, [homePageDataModel]);

  useEffect(() => {
    if (activeOrderType?.identifier == "2" && !isGuestCheckout) {
      dispatch({
        type: ActionTypes.GET_DELIVERY_LOCATIONS_OF_USER_REQUEST,
        payload: {
          UserId: loginResponse?.userId,
        },
      });
    }
    dispatch({
      type: "CLEAR_DELIVERY_STATE",
    });
    setDeliveryAddress({});
  }, [activeOrderType]);

  useEffect(() => {
    if (isAddDeliveryLocationSuccess && !isGuestCheckout) {
      dispatch({
        type: ActionTypes.GET_DELIVERY_LOCATIONS_OF_USER_REQUEST,
        payload: {
          UserId: loginResponse?.userId,
        },
      });
    }
  }, [isAddDeliveryLocationSuccess]);

  useEffect(() => {
    if (isMakePaymentSuccessful) {
      makePaymentForm.resetFields();
      setDeliveryAddress({});
      setVoucherCode("");
      clearCartProducts();
      setFinalOrderNote("");
      dispatch({
        type: "CLEAR_DELIVERY_STATE",
      });
    }
  }, [isMakePaymentSuccessful]);

  return (
    <>
      <MakePayment
        voucherCode={voucherCode}
        setVoucherCode={setVoucherCode}
        onRedeemVoucherHandler={onRedeemVoucherHandler}
        onMakePaymentFormSubmitHandler={onMakePaymentFormSubmitHandler}
        makePaymentForm={makePaymentForm}
        homePageDataModel={homePageDataModel}
        activeOrderType={activeOrderType}
        setActiveOrderType={setActiveOrderType}
        isLoading={isLoading}
        isCheckVoucherSuccess={isCheckVoucherSuccess}
        voucherDiscount={voucherDiscount}
        deliveryAddress={deliveryAddress}
        setDeliveryAddress={setDeliveryAddress}
        onAddDeliveryLocationSubmit={onAddDeliveryLocationSubmit}
        addDeliverLocationLoading={addDeliverLocationLoading}
        deliveryAddresses={deliveryAddresses}
        makePaymentLoading={makePaymentLoading}
        isMakePaymentSuccessful={isMakePaymentSuccessful}
        selectedDate={selectedDate}
        selectedWeekDay={selectedWeekDay}
        setSelectedWeekDay={setSelectedWeekDay}
        finalOrderNote={finalOrderNote}
        setFinalOrderNote={setFinalOrderNote}
        isGuestCheckout={isGuestCheckout}
        countries={countires}
        cardElement={
          <>
            <div className="row">
              <div className="col-md-6">
                {" "}
                <Form.Item
                  name={"stripeEmail"}
                  label="Email Address"
                  rules={[
                    {
                      required: true,
                      message: EMPTY_FIELD_MESSAGE,
                    },
                    {
                      type: "email",
                      message: EMAIL_VALIDATION_MESSAGE,
                    },
                  ]}
                >
                  <Input placeholder="Please enter email address" />
                </Form.Item>
              </div>
              <div className="col-md-6">
                {" "}
                <Form.Item
                  name={"cardNumber"}
                  label="Card Number"
                  rules={[
                    {
                      required: true,
                      message: EMPTY_FIELD_MESSAGE,
                    },
                  ]}
                >
                  <CardNumberElement
                    className="stripe-input"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                {" "}
                <Form.Item
                  name={"expiryDate"}
                  label="Expiry Date"
                  rules={[
                    {
                      required: true,
                      message: EMPTY_FIELD_MESSAGE,
                    },
                  ]}
                >
                  <CardExpiryElement
                    className="stripe-input"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                {" "}
                <Form.Item
                  name={"CVC"}
                  label="CVC"
                  rules={[
                    {
                      required: true,
                      message: EMPTY_FIELD_MESSAGE,
                    },
                  ]}
                >
                  <CardCvcElement
                    className="stripe-input"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </Form.Item>
              </div>
            </div>
          </>
        }
        stripeLoading={stripeLoading}
      />
    </>
  );
};

export default MakePaymentContainer;
