/** @format */

import { Button, Empty, Tooltip } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import CustomBreadcrumb from "../../components/custom_breadcrump/CustomBreadcrumb";
import PageLoader from "../../components/page_loader/PageLoader";
import ActionTypes from "../../constants/action_types";
import {
  ANIMATION_CLASS,
  NO_WISHLIST_MESSAGE,
} from "../../constants/definitions";
import LayoutContainer from "../../containers/layout/layout_container";
import useAppDispatch from "../../hooks/useAppDispatch";
import { WishlistDataModel } from "../../redux/reducers/WishlistReducer/WishlistReducerInterface";
import LazyImage from "../../utils/Lazy Image/LazyImage";
import { useApiCalls } from "../../utils/useApiCalls";
import { useRetailHelper } from "../../utils/useRetailHelper";

interface WishlistProps {
  wishlistDetails: WishlistDataModel[] | undefined;
  isLoading: boolean;
}

const Wishlist: React.FC<WishlistProps> = ({ wishlistDetails, isLoading }) => {
  const dispatch = useAppDispatch();
  const { getProductDetailBySlug, deleteWishlistProduct } = useApiCalls();
  const { currencySymbol, isProductInWishlist } = useRetailHelper();
  return (
    <LayoutContainer>
      <Helmet>
        <title>{"My Wishlists"}</title>
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <PageLoader loading={!wishlistDetails}>
        <section className="pt-3 pb-3">
          <div className="container">
            <CustomBreadcrumb items={[{ title: "My Wishlists", link: "" }]} />
            <div className="tab-vertical1">
              <div className="row g-0 d-flex gap-4 justify-content-center">
                {wishlistDetails?.length == 0 ? (
                  <Empty className="mt-4" description={NO_WISHLIST_MESSAGE} />
                ) : (
                  <>
                    <div className="text-center pb-0">
                      <h6 className="text-center fw-bold text-theme">
                        Explore wishlist and Make Them Yours!
                      </h6>
                      <small className="text-muted">
                        Shop Your Heart Out: Add Your Favorite Items to the Cart
                        and Complete Your Purchase!
                      </small>
                    </div>
                    <div className="row row-cols-lg-4 row-cols-1 row-cols-md-2 g-4 flex-wrap">
                      {wishlistDetails?.map((item, index) => {
                        return (
                          <div
                            data-aos={ANIMATION_CLASS}
                            className="col"
                            key={item.id}
                          >
                            <div
                              key={item.id}
                              className="card product-card card-static pb-3 h-100"
                            >
                              {/* <span className="badge bg-danger badge-shadow">
                                {item.discountPercentage
                                  ? item.discountPercentage + "% Off"
                                  : ""}
                              </span> */}
                              <Tooltip title="Remove From Wishlist">
                                <button
                                  onClick={() => {
                                    deleteWishlistProduct(item.id);
                                  }}
                                  className="wishlist_btn btn-sm m-2"
                                  style={
                                    isProductInWishlist(item.productId)
                                      ? {}
                                      : { color: "black" }
                                  }
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="left"
                                  aria-label="Add to wishlist"
                                  data-bs-original-title="Add to wishlist"
                                >
                                  {isProductInWishlist(item.productId) ? (
                                    <i
                                      className="fas fa-heart"
                                      style={{ fontSize: "20px" }}
                                    />
                                  ) : (
                                    <i
                                      className="far fa-heart"
                                      style={{ fontSize: "20px" }}
                                    />
                                  )}
                                </button>
                              </Tooltip>

                              <span
                                onClick={() => {
                                  dispatch({
                                    type: ActionTypes.SET_ACTIVE_SLUG,
                                    payload: item.slug,
                                  });
                                  getProductDetailBySlug(item.slug);
                                }}
                              >
                                <LazyImage
                                  src={item.imageUrl}
                                  alt="Product"
                                  className="cursor-pointer"
                                />
                              </span>

                              <div className="card-body py-2">
                                <a className="product-meta d-block fs-xs pb-1">
                                  {item.category} {item.brand ? item.brand : ""}
                                </a>
                                <h3
                                  onClick={() => {
                                    dispatch({
                                      type: ActionTypes.SET_ACTIVE_SLUG,
                                      payload: item.slug,
                                    });
                                    getProductDetailBySlug(item.slug);
                                  }}
                                  className="product-title fs-sm cursor-pointer"
                                >
                                  <a> {item.name}</a>
                                </h3>
                                <div className="text-success fs-12 fw-bold my-1">
                                  {/* {item.promotionalMessage} */}
                                </div>
                                <div className="product-price">
                                  <span className="text-accent">
                                    {currencySymbol()}{" "}
                                    {item.discountPercentage
                                      ? item.discountPrice
                                      : item.actualPrice}
                                  </span>
                                  {/* <del className="fs-sm text-muted">
                                    {item.discountPercentage && (
                                      <del className="fs-sm text-muted">
                                        {currencySymbol()} {item.actualPrice}
                                      </del>
                                    )}
                                  </del> */}
                                </div>
                              </div>
                              <div className="d-grid w-100 ">
                                <Button
                                  onClick={() => {
                                    dispatch({
                                      type: ActionTypes.OPEN_ADD_TO_CART_POPUP,
                                    });
                                    dispatch({
                                      type: ActionTypes.GET_PRODUCT_DETAILS_COPY_WITHOUT_REDIRECTION_REQUEST,
                                      payload: {
                                        Slug: item.slug,
                                      },
                                    });
                                  }}
                                  type="primary"
                                >
                                  ADD TO CART{" "}
                                </Button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </PageLoader>
    </LayoutContainer>
  );
};

export default Wishlist;
