/** @format */

import nProgress from "nprogress";
import ActionTypes from "../../../constants/action_types";
import ProductDetailsInterface from "./ProductDetailsInterface";

/** @format */

const initialState: ProductDetailsInterface = {
  isLoading: false,
};

const reducer = (
  state = initialState,
  action: any
): ProductDetailsInterface => {
  switch (action.type) {
    case ActionTypes.GET_PRODUCT_DETAILS_REQUEST:
      nProgress.start();
      return {
        ...state,
        isLoading: true,
        isProductFetchSuccesful: false,
      };
    case ActionTypes.GET_PRODUCT_DETAILS_SUCCESS:
      nProgress.done();
      return {
        ...state,
        isLoading: false,
        isProductFetchSuccesful: true,
        productDetailsDataModel: action.payload.data,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.GET_PRODUCT_DETAILS_FAILURE:
      nProgress.done();

      return {
        ...state,
        isLoading: false,
        isProductFetchSuccesful: false,
      };
    case ActionTypes.GET_PRODUCT_DETAILS_COPY_WITHOUT_REDIRECTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.GET_PRODUCT_DETAILS_COPY_WITHOUT_REDIRECTION_SUCCESS:
      return {
        ...state,
        isLoading: false,

        productDetailsDataModel: action.payload.data,
        // ...action.payload.additionalData, // Merge additional data from the API response
      };
    case ActionTypes.GET_PRODUCT_DETAILS_COPY_WITHOUT_REDIRECTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.SET_ACTIVE_SLUG:
      return {
        ...state,
        activeSlug: action.payload,
      };
    case ActionTypes.RESET_PRODUCT_STATE:
      return {
        ...state,
        isProductFetchSuccesful: false,
      };
    default:
      return state;
  }
};

export default reducer;
