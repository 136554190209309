/** @format */

import { Drawer } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import { MegaMenuWithChildrenAndCardsViewModel } from "../../redux/reducers/MegaMenuReducer/MegaMenuInterface";
import { useRetailHelper } from "../../utils/useRetailHelper";

interface MobileMegaMenuProps {
  megaMenu: MegaMenuWithChildrenAndCardsViewModel[] | undefined;
  isMobileMegaMenuOpen: boolean;
  setIsMobileMegaMenuOpen: Dispatch<SetStateAction<boolean>>;
}

const MobileMegaMenu: React.FC<MobileMegaMenuProps> = ({
  megaMenu,
  isMobileMegaMenuOpen,
  setIsMobileMegaMenuOpen,
}) => {
  const { handleRedirectMegaMenu } = useRetailHelper();

  const handleDropdownItemClick = (
    megaMenu: MegaMenuWithChildrenAndCardsViewModel
  ) => {
    if (megaMenu.childrens.length > 0) {
      return;
    } else {
      handleRedirectMegaMenu(megaMenu.link ?? "");
      setIsMobileMegaMenuOpen(false);
    }
  };
  return (
    <Drawer
      title=""
      placement="right"
      onClose={() => setIsMobileMegaMenuOpen(false)}
      open={isMobileMegaMenuOpen}
      width={"80vw"}
      className="mobile-mega-menu-drawer"
    >
      <div className="main_nav mobilemenu d-md-none">
        <div className="navbar navbar-expand-lg navbar-light navbar-stuck-menu mt-n2">
          <div className="container">
            <div className="">
              {megaMenu?.map((parentMegaMenu, index) => {
                return (
                  <ul
                    key={parentMegaMenu.id}
                    className="navbar-nav navbar-mega-nav pe-lg-2 me-lg-2 "
                  >
                    <li className="nav-item dropdown">
                      <a
                        className={`nav-link ps-lg-0 ${
                          parentMegaMenu.childrens.length > 0 &&
                          "dropdown-toggle"
                        }`}
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        onClick={() => handleDropdownItemClick(parentMegaMenu)}
                      >
                        {parentMegaMenu.name}
                      </a>
                      {parentMegaMenu.childrens?.length > 0 && (
                        <ul className="dropdown-menu">
                          {parentMegaMenu.childrens.map((childLev1, index) => {
                            return (
                              <li
                                className="dropdown mega-dropdown"
                                key={childLev1.id}
                              >
                                <a
                                  onClick={() =>
                                    handleDropdownItemClick(childLev1)
                                  }
                                  className={`dropdown-item ${
                                    childLev1.childrens.length > 0 &&
                                    "dropdown-toggle"
                                  }`}
                                  data-bs-toggle="dropdown"
                                >
                                  {childLev1.name}
                                </a>
                                {childLev1.childrens.length > 0 && (
                                  <ul className="dropdown-menu">
                                    {childLev1.childrens.map(
                                      (childLev2, index) => {
                                        return (
                                          <li
                                            key={childLev2.id}
                                            className="dropdown mega-dropdown"
                                          >
                                            <a
                                              onClick={() =>
                                                handleDropdownItemClick(
                                                  childLev2
                                                )
                                              }
                                              className={`dropdown-item `}
                                              data-bs-toggle="dropdown"
                                            >
                                              {childLev2.name}
                                            </a>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  </ul>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default MobileMegaMenu;
