/** @format */

import moment from "moment";
import nProgress from "nprogress";
import React from "react";
import Loadable from "react-loadable";
import { useLocation, useNavigate } from "react-router-dom";
import ActionTypes from "../constants/action_types";
import useAppDispatch from "../hooks/useAppDispatch";
import useAppSelector from "../hooks/useAppSelector";
import {
  BannerSection,
  CartProduct,
} from "../redux/reducers/CommonReducer/CommonReducerInterface";
import {
  ProductFilterTypeFilterOption,
  ProductPriceVariationModifiers,
} from "../redux/reducers/ProductDetailsReducer/ProductDetailsInterface";
import { OrderItemsSelectOptionsRequestModel } from "../types/commonInterfaces";

interface RetailHelper {
  openCartPopover: () => void;
  closeCartPopover: () => void;
  addProductToCart: (product: CartProduct) => void;
  getCartProducts: () => CartProduct[];
  clearCartProducts: () => void;
  removeProductFromCart: (index: number) => void;
  increaseQuantityOfProduct: (index: number) => void;
  decreaseQuantityOfProduct: (index: number) => void;
  getIndividualProductTotal: (product: CartProduct) => number;
  getTotalAmountOfCart: () => number;
  getTotalAmountWithTaxOfCart: () => number;
  calculateProductTax: (product: CartProduct) => number;
  calculateModifierTax: (
    modifierPrice: number,
    modifierQuantity: number,
    modifierTaxPercentage: number
  ) => number;
  calculateTotalTax: () => number;
  getVoucherDiscountAmount: () => number;
  getDiscountTax: () => number;
  getDeliveryAmount: () => number;
  getDeliveryTax: () => number;
  totalPriceOfModifiers: (
    selectedPriceModifiers: ProductPriceVariationModifiers[]
  ) => number;
  isProductAddedInCart: () => boolean;
  isStoreTaxInclusive: () => boolean;
  currencySymbol: () => string;
  formatDate: (data: string) => string;
  handleNavigation: (pathname: string, loadable: any, state?: any) => void;
  createLoadable: (route: string) => any;
  getTableId: () => string;
  getDefaultSelectedCountry: () => string | undefined;
  isRedirectToChangePassword: () => boolean;
  getValueFromQuery: (queryKey: string) => string;
  isProductInWishlist: (productId: string) => boolean | undefined;
  createRequestObjectForFilterOptions: (
    filterTypes: ProductFilterTypeFilterOption[]
  ) => OrderItemsSelectOptionsRequestModel[];
  getBannerFromIdentifier: (identifier: string) => BannerSection | undefined;
  getTimeArray: (
    identifier: string,
    selectedWeekDay: string
  ) => { label: string; value: string }[];
  getPickupDeliveryMessage: (
    identifier: string,
    selectedWeekDay: string
  ) => string | undefined;
  getPostalCodeFromLatLng: (
    lat: number,
    lng: number,
    GOOGLE_ADDRESS_API_KEY: string
  ) => void;
  handleRedirectMegaMenu: (link: string) => void;
}

export function useRetailHelper(): RetailHelper {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { cartData, countires } = useAppSelector((state) => state.common);
  const { homePageDataModel } = useAppSelector((state) => state.homePage);
  const { voucherDiscount, deliveryPrice } = useAppSelector(
    (state) => state.makePayment
  );
  const { wishlistDetails } = useAppSelector((state) => state.wishlist);
  const { banners } = useAppSelector((state) => state.common);
  function useQuery() {
    const { search } = useLocation();

    // Encode the '+' character as '%2B'
    const encodedSearch = search.replace(/\+/g, "%2B");

    return React.useMemo(
      () => new URLSearchParams(encodedSearch),
      [encodedSearch]
    );
  }
  const query = useQuery();

  const openCartPopover = () => {
    dispatch({
      type: ActionTypes.OPEN_CART_POPOVER,
    });
  };

  const closeCartPopover = () => {
    dispatch({
      type: ActionTypes.CLOSE_CART_POPOVER,
    });
  };
  const addProductToCart = (product: CartProduct) => {
    dispatch({
      type: ActionTypes.ADD_PRODUCT_TO_CART,
      payload: product,
    });
  };
  const removeProductFromCart = (index: number) => {
    dispatch({
      type: ActionTypes.REMOVE_PRODUCT_FROM_CART,
      payload: index,
    });
  };
  const clearCartProducts = () => {
    dispatch({
      type: ActionTypes.CLEAR_CART,
    });
  };
  const getCartProducts = () => {
    return cartData;
  };
  const getIndividualProductTotal = (product: CartProduct) => {
    // Calculate the subtotal for the base product (quantity * price)
    let subtotal = product.price * product.quantity;

    // Check if the product has modifiers
    if (product.priceModifiers && product.priceModifiers.length > 0) {
      // Calculate the total price of modifiers and add to the subtotal
      const modifierTotal = product.priceModifiers.reduce(
        (modifierAcc, modifier) => {
          return (
            modifierAcc + (parseFloat(modifier.price) || 0) * product.quantity
          ); // Multiply modifier amount by quantity
        },
        0
      );
      subtotal += modifierTotal;
    }

    return Number(subtotal.toFixed(2));
  };

  const increaseQuantityOfProduct = (index: number) => {
    dispatch({
      type: ActionTypes.INCREASE_QUANTITY_OF_PRODUCT_IN_CART,
      payload: index,
    });
  };
  const decreaseQuantityOfProduct = (index: number) => {
    dispatch({
      type: ActionTypes.DECREASE_QUANTITY_OF_PRODUCT_IN_CART,
      payload: index,
    });
  };
  const getTotalAmountOfCart = () => {
    if (!cartData || cartData.length === 0) {
      return 0;
    }

    // Reduce the cart data to calculate the total amount
    const totalAmount = cartData.reduce((acc, cartItem) => {
      // Calculate the subtotal for each product (quantity * price)
      let subtotal = cartItem.quantity * cartItem.price;

      // Check if the cart item has modifiers
      if (cartItem.priceModifiers && cartItem.priceModifiers.length > 0) {
        // Calculate the total price of modifiers and add to the subtotal
        const modifierTotal = cartItem.priceModifiers.reduce(
          (modifierAcc, modifier) => {
            return (
              modifierAcc +
              (parseFloat(modifier.price) || 0) * cartItem.quantity
            ); // Multiply modifier amount by quantity
          },
          0
        );
        subtotal += modifierTotal;
      }

      // Add the subtotal to the accumulator
      return acc + subtotal;
    }, 0);

    return Number(totalAmount.toFixed(2));
  };

  const getTotalAmountWithTaxOfCart = () => {
    if (!cartData || cartData.length === 0) {
      return 0;
    }

    // Reduce the cart data to calculate the total amount
    let totalAmount = cartData.reduce((acc, cartItem) => {
      // Calculate the subtotal for each product (quantity * price)
      let subtotal = cartItem.quantity * cartItem.price;

      // Check if the cart item has modifiers
      if (cartItem.priceModifiers && cartItem.priceModifiers.length > 0) {
        // Calculate the total price of modifiers and add to the subtotal
        const modifierTotal = cartItem.priceModifiers.reduce(
          (modifierAcc, modifier) => {
            return (
              modifierAcc +
              (parseFloat(modifier.price) || 0) * cartItem.quantity
            ); // Multiply modifier amount by quantity
          },
          0
        );
        subtotal += modifierTotal;
      }

      // If taxType is 'exclusive', calculate the product tax
      if (homePageDataModel?.storeDetails?.taxExclusiveInclusiveType === "3") {
        const productTax = calculateProductTax(cartItem);
        // Add the product tax to the subtotal
        subtotal += productTax;
      }

      // Add the subtotal to the accumulator
      return acc + subtotal;
    }, 0);

    // Check if there is a voucher discount
    const voucherDiscountAmount = getVoucherDiscountAmount();
    const deliveryAmount = getDeliveryAmount();
    totalAmount -= voucherDiscountAmount ? voucherDiscountAmount : 0;
    totalAmount += deliveryAmount ? deliveryAmount : 0;

    return Number(totalAmount.toFixed(2));
  };
  const isProductAddedInCart = () => {
    if (!cartData || cartData.length === 0) {
      return false;
    } else {
      return true;
    }
  };
  // Function to calculate the tax for a single product
  const calculateProductTax = (product: CartProduct): number => {
    const taxPercentageValue = parseFloat(product.tax) / 100;

    // Calculate the total price of modifiers
    let modifierTotal = 0;
    // if (product.priceModifiers && product.priceModifiers.length > 0) {
    //   modifierTotal = product.priceModifiers.reduce((modifierAcc, modifier) => {
    //     return modifierAcc + (parseFloat(modifier.price) || 0);
    //   }, 0);
    // }

    if (isStoreTaxInclusive()) {
      return (
        (taxPercentageValue / (1 + taxPercentageValue)) *
        (product.price + modifierTotal) * // Include modifier amount in the price
        product.quantity
      );
    } else {
      return (
        taxPercentageValue * (product.price + modifierTotal) * product.quantity
      ); // Include modifier amount in the price
    }
  };

  // Function to calculate the total tax of all products in the cart, including modifier tax
  const calculateTotalTax = (): number => {
    if (!cartData || cartData.length === 0) {
      return 0;
    }

    // Calculate the total tax based on the tax type (inclusive or exclusive)
    const totalTax = cartData.reduce((acc, product) => {
      const productTax = calculateProductTax(product);

      // Calculate total tax for product modifiers
      const modifierTax = product.priceModifiers?.reduce(
        (modifierAcc, modifier) => {
          const modifierPrice = parseFloat(modifier.price) || 0;
          const modifierQuantity = product.quantity || 0;
          const modifierTaxPercentage = parseFloat(product.tax) || 0;

          const modifierTaxAmount = calculateModifierTax(
            modifierPrice,
            modifierQuantity,
            modifierTaxPercentage
          );
          return modifierAcc + modifierTaxAmount;
        },
        0
      );

      return acc + productTax + (modifierTax ?? 0);
    }, 0);

    return totalTax;
  };

  // Function to calculate the tax for a single modifier
  const calculateModifierTax = (
    modifierPrice: number,
    modifierQuantity: number,
    modifierTaxPercentage: number
  ): number => {
    let taxAmount =
      modifierPrice * modifierQuantity * (modifierTaxPercentage / 100);

    if (isStoreTaxInclusive()) {
      taxAmount = taxAmount / (1 + modifierTaxPercentage / 100);
    }

    return taxAmount;
  };

  const getVoucherDiscountAmount = (): number => {
    const discount =
      ((getTotalAmountOfCart() + getDeliveryAmount()) *
        Number(voucherDiscount)) /
      100;

    return discount > 0 ? discount : 0;
  };
  const getDeliveryAmount = (): number => {
    if (
      Number(getTotalAmountOfCart()) >
      parseFloat(
        homePageDataModel?.storeDetails?.freeShippingDiscountAmountLimit ?? ""
      )
    ) {
      return 0;
    } else {
      return Number(deliveryPrice ?? 0);
    }
  };
  const totalPriceOfModifiers = (
    selectedPriceModifiers: ProductPriceVariationModifiers[]
  ): number => {
    return selectedPriceModifiers
      .map((modifier) => parseFloat(modifier.price))
      .reduce((total, price) => total + price, 0);
  };
  const getDiscountTax = (): number => {
    const discountTax =
      (Number(calculateTotalTax() + getDeliveryTax()) /
        (getTotalAmountOfCart() + getDeliveryAmount())) *
      getVoucherDiscountAmount();

    return discountTax > 0 ? discountTax : 0;
  };

  const getDeliveryTax = (): number => {
    if (
      Number(getTotalAmountOfCart()) >
      parseFloat(
        homePageDataModel?.storeDetails?.freeShippingDiscountAmountLimit ?? ""
      )
    ) {
      return 0;
    }
    let staticTaxPercent = 10;

    const deliveryTax =
      Number(getDeliveryAmount()) *
      (staticTaxPercent / (100 + staticTaxPercent));
    // const deliveryTax =
    //   (Number(calculateTotalTax()) / getTotalAmountOfCart()) *
    //   getDeliveryAmount();
    return deliveryTax > 0 ? deliveryTax : 0;
  };

  const currencySymbol = () => {
    return homePageDataModel?.storeDetails?.currencySymbol ?? "";
  };
  const isStoreTaxInclusive = () => {
    return homePageDataModel?.storeDetails?.taxExclusiveInclusiveType == "2";
  };

  const formatDate = (date: string) => {
    const dateFormat = new Date(date);
    const dateFormatISO = dateFormat.toISOString();
    const formattedDate = moment(dateFormatISO).format(
      homePageDataModel?.storeDetails?.dateFormat.toUpperCase().split(" ")[0] +
        " HH:mm:ss"
    );
    return formattedDate;
  };
  const handleNavigation = (pathname: string, loadable: any, state?: any) => {
    if (loadable) {
      nProgress.start();
      loadable.preload().then(() => {
        nProgress.done();
        navigate(pathname, {
          state: state,
        });
      });
    }
  };
  const createLoadable = (route: string) => {
    return Loadable({
      loader: () => import(`${route}`),
      loading: () => null,
    });
  };

  const getDefaultSelectedCountry = (): string | undefined => {
    return countires?.find((item) => item.isSelected)?.id;
  };

  const getTableId = () => {
    return query.get("TableId") ?? "";
  };
  const isRedirectToChangePassword = () => {
    return query.get("code") && query.get("email") ? true : false;
  };
  const getValueFromQuery = (queryKey: string) => {
    return query.get(queryKey) ?? "";
  };

  const isProductInWishlist = (productId: string) => {
    return wishlistDetails?.some((item) => item.productId === productId);
  };

  // Function to create the request object
  const createRequestObjectForFilterOptions = (
    filterTypes: ProductFilterTypeFilterOption[]
  ) => {
    const requestArray: OrderItemsSelectOptionsRequestModel[] = [];

    filterTypes
      ?.filter((item) => item.showOnPriceSection)
      ?.forEach((filterType) => {
        const selectedOption = filterType.filterTypeOptions.find(
          (option) => option.isChecked
        );

        if (selectedOption) {
          const requestItem = {
            FilterTypeId: filterType.id,
            SelectOptionName: filterType.name,
            SelectOptionValue: selectedOption.name,
            FilterTypeOptions: [selectedOption.id],
          };
          requestArray.push(requestItem);
        }
      });

    return requestArray;
  };

  const getBannerFromIdentifier = (identifier: string) => {
    return banners?.find((item) => item.identifier == identifier);
  };
  const getTimeArray = (
    identifier: string,
    selectedWeekDay: string
  ): { label: string; value: string }[] => {
    let arr: { label: string; value: string }[] = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        const minutes = j * 15;
        arr.push({
          label: `${i < 10 ? "0" + i : i}:${
            minutes < 10 ? "0" + minutes : minutes
          }`,
          value: `${i < 10 ? "0" + i : i}:${
            minutes < 10 ? "0" + minutes : minutes
          }:00`,
        });
      }
    }

    let startIndex: number;
    let endIndex: number;

    if (identifier === "1") {
      startIndex = arr.findIndex((item) => {
        return (
          item.label ===
          homePageDataModel?.storeDetails?.pickUpHours?.find(
            (item) => item.weekDayName.toLowerCase() === selectedWeekDay
          )?.openHour
        );
      });
      endIndex = arr.findIndex((item) => {
        return (
          item.label ===
          homePageDataModel?.storeDetails?.pickUpHours?.find(
            (item) => item.weekDayName.toLowerCase() === selectedWeekDay
          )?.closeHour
        );
      });
    } else if (identifier === "2") {
      startIndex = arr.findIndex((item) => {
        return (
          item.label ===
          homePageDataModel?.storeDetails?.deliveryHours?.find(
            (item) => item.weekDayName.toLowerCase() === selectedWeekDay
          )?.openHour
        );
      });
      endIndex = arr.findIndex((item) => {
        return (
          item.label ===
          homePageDataModel?.storeDetails?.deliveryHours?.find(
            (item) => item.weekDayName.toLowerCase() === selectedWeekDay
          )?.closeHour
        );
      });
    }

    arr = arr.filter((item, index) => index >= startIndex && index <= endIndex);

    return arr;
  };

  const getPickupDeliveryMessage = (
    identifier: string,
    selectedWeekDay: string
  ) => {
    if (identifier == "1") {
      return homePageDataModel?.storeDetails?.pickUpHours?.find(
        (item) => item.weekDayName.toLowerCase() === selectedWeekDay
      )?.message;
    } else if (identifier == "2") {
      return homePageDataModel?.storeDetails?.deliveryHours?.find(
        (item) => item.weekDayName.toLowerCase() === selectedWeekDay
      )?.message;
    } else {
      return "";
    }
  };

  const getPostalCodeFromLatLng = async (
    lat: number,
    lng: number,
    GOOGLE_ADDRESS_API_KEY: string
  ) => {
    const googleGeocodeApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_ADDRESS_API_KEY}`;
    await fetch(googleGeocodeApiUrl)
      .then((response) => response.json())
      .then((data) => {
        const postalCodeResult = data.results[0].address_components.find(
          (component: any) => component.types.includes("postal_code")
        );
        if (postalCodeResult) {
          const postalCode = postalCodeResult.long_name;
          return postalCode;
        }
      })
      .catch((error) => {
        return "";
      });
  };

  // const extractAndRemoveQueryParams = (url: string) => {
  //   // Create a URL object from the provided URL
  //   const urlObject = new URL(url);

  //   // Check if the path contains "filter-products"
  //   if (urlObject.pathname.includes("filter-products")) {
  //     // Extract and remove query parameters
  //     const queryParams = urlObject.searchParams.toString();
  //     urlObject.search = ""; // Remove all query parameters from the URL

  //     return queryParams;
  //   }

  //   // If "filter-products" is not found in the path, return null
  //   return null;
  // };

  const handleRedirectMegaMenu = (link: string) => {
    if (link) {
      const currentDomain = window.location.origin;
      // const currentDomain = "https://retail.posapt.au";

      const pathAfterDomain = link.substring(
        link.indexOf(currentDomain) + currentDomain.length
      );

      navigate(pathAfterDomain);
    }
  };

  return {
    openCartPopover,
    closeCartPopover,
    addProductToCart,
    getCartProducts,
    removeProductFromCart,
    getIndividualProductTotal,
    increaseQuantityOfProduct,
    decreaseQuantityOfProduct,
    getTotalAmountOfCart,
    isProductAddedInCart,
    calculateTotalTax,
    calculateModifierTax,
    getTotalAmountWithTaxOfCart,
    currencySymbol,
    isStoreTaxInclusive,
    formatDate,
    handleNavigation,
    createLoadable,
    getTableId,
    getVoucherDiscountAmount,
    totalPriceOfModifiers,
    getDiscountTax,
    getDeliveryAmount,
    getDeliveryTax,
    calculateProductTax,
    getDefaultSelectedCountry,
    isRedirectToChangePassword,
    getValueFromQuery,
    clearCartProducts,
    isProductInWishlist,
    createRequestObjectForFilterOptions,
    getBannerFromIdentifier,
    getTimeArray,
    getPickupDeliveryMessage,
    getPostalCodeFromLatLng,
    handleRedirectMegaMenu,
  };
}
