/** @format */

import { Form } from "antd";
import { useEffect, useState } from "react";
import ActionTypes from "../../constants/action_types";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useSnackbar from "../../hooks/useSnackbar";
import GiftCard from "../../pages/gift-card/GiftCard";
import { GiftCardTemplateImage } from "../../redux/reducers/GiftCardReducer/GiftCardReducerInterface";
import { GiftCardFormValues } from "../../types/commonInterfaces";
import { useRetailHelper } from "../../utils/useRetailHelper";

const GiftCardContainer = () => {
  const [giftCardForm] = Form.useForm<GiftCardFormValues>();
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const { formatDate } = useRetailHelper();
  const [activeTabGiftCardForm, setActiveTabGiftCardForm] = useState<number>(0);
  const [activeGiftCardImage, setActiveGiftCardImage] = useState<
    GiftCardTemplateImage | undefined
  >(undefined);
  const [activeGiftCardType, setActiveGiftCardType] = useState<
    number | undefined
  >(0);
  const [isSendGiftCardModalOpen, setIsSendGiftCardModalOpen] =
    useState<boolean>(false);
  const [giftCardNumber, setGiftCardNumber] = useState<string | undefined>();
  const { countires } = useAppSelector((state) => state.common);
  const {
    giftCardImages,
    isSendGiftCardSuccessful,
    sendGiftCardLoading,
    error,
    successMessage,
    isGetGiftCardBalanceSuccessful,
    getGiftCardBalanceLoading,
    giftCardBalance,
  } = useAppSelector((state) => state.giftCard);
  const { homePageDataModel } = useAppSelector((state) => state.homePage);

  useEffect(() => {
    if (!giftCardImages) {
      dispatch({
        type: ActionTypes.GET_ALL_GIFT_CARDS_REQUEST,
        payload: {
          StoreId: homePageDataModel?.storeDetails?.id,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (
      activeGiftCardType == 0 ||
      (activeGiftCardType && activeGiftCardType > 0)
    ) {
      if (giftCardImages) {
        setActiveGiftCardImage(
          giftCardImages?.[activeGiftCardType]?.giftCardTemplateImages[0]
        );
      }
    }
  }, [activeGiftCardType, giftCardImages]);

  const onGiftCardfFormSubmit = (values: GiftCardFormValues) => {
    setIsSendGiftCardModalOpen(true);
    dispatch({
      type: ActionTypes.SEND_GIFT_CARDS_REQUEST,
      payload: {
        Amount: activeGiftCardImage?.amount,
        Message: values.message,
        ScheduledDate: formatDate(values.scheduleDate),
        GiftCardTemplateImageId: activeGiftCardImage?.id,
        CreditCardDetailsRequestModel: {
          NameOnCard: values.name,
          CardNumber: values.cardNumber,
          CVCNumber: values.cvc,
          ExpiryMonth: values.expiry?.split("/")[0],
          ExpiryYear: values.expiry?.split("/")[1],
        },
        SenderRequestModel: {
          Id: "",
          CountryPhoneNumberPrefixId: values.senderCountryPhoneNumberPrefixId,
          CountryId: values.senderCountryId,
          Name: values.senderName,
          Email: values.senderEmail,
          PhoneNumber: values.senderPhoneNumber,
          PostalCode: values.senderPostalCode,
        },
        ReceiverRequestModel: {
          Id: "",
          CountryPhoneNumberPrefixId: values.recieverCountryPhoneNumberPrefixId,
          CountryId: values.recieverCountryId,
          Name: values.recieverName,
          Email: values.recieverEmail,
          PhoneNumber: values.recieverPhoneNumber,
          PostalCode: values.recieverPostalCode,
        },
      },
    });
  };
  const getGiftCardBalanceSubmitHandler = () => {
    if (giftCardNumber) {
      dispatch({
        type: ActionTypes.CHECK_GIFT_CARD_BALANCE_REQUEST,
        payload: {
          GiftCardCode: giftCardNumber,
        },
      });
    }
  };

  useEffect(() => {
    if (isGetGiftCardBalanceSuccessful) {
      setGiftCardNumber("");
    }
  }, [isGetGiftCardBalanceSuccessful]);

  useEffect(() => {
    if (isSendGiftCardSuccessful) {
      giftCardForm.resetFields();
    }
  }, [isSendGiftCardSuccessful]);

  return (
    <GiftCard
      activeTabGiftCardForm={activeTabGiftCardForm}
      setActiveTabGiftCardForm={setActiveTabGiftCardForm}
      giftCardForm={giftCardForm}
      onGiftCardFormSubmit={onGiftCardfFormSubmit}
      countries={countires}
      giftCardImages={giftCardImages}
      activeGiftCardImage={activeGiftCardImage}
      setActiveGiftCardImage={setActiveGiftCardImage}
      activeGiftCardType={activeGiftCardType}
      setActiveGiftCardType={setActiveGiftCardType}
      sendGiftCardLoading={sendGiftCardLoading}
      giftCardNumber={giftCardNumber}
      setGiftCardNumber={setGiftCardNumber}
      getGiftCardBalanceSubmitHandler={getGiftCardBalanceSubmitHandler}
      getGiftCardBalanceLoading={getGiftCardBalanceLoading}
      giftCardBalance={giftCardBalance}
      isSendGiftCardModalOpen={isSendGiftCardModalOpen}
      setIsSendGiftCardModalOpen={setIsSendGiftCardModalOpen}
    />
  );
};

export default GiftCardContainer;
