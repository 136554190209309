/** @format */

import { Tag } from "antd";
import React from "react";
import ActionTypes from "../../../constants/action_types";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { QueryString } from "../../../redux/reducers/FilterProductsReducer/FilterProductsInterface";
import {
  TemplatePageCategoryDetail,
  homePageDataModel,
} from "../../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import LazyImage from "../../../utils/Lazy Image/LazyImage";
import { useApiCalls } from "../../../utils/useApiCalls";

/** @format */
interface Banner4Props {
  item: TemplatePageCategoryDetail;
  homePageDataModel: homePageDataModel;
}

const Banner4: React.FC<Banner4Props> = ({ item, homePageDataModel }) => {
  const { getAllFilterProducts } = useApiCalls();
  const dispatch = useAppDispatch();

  return (
    <section className="slidersection newcat_home pt-3" key={item.id}>
      <div className="container">
        <div className="row g-2">
          <div className="col-lg-3">
            <div className="ms-2 d-lg-none d-flex justify-content-end">
              <a
                className="btn btn-primary mb-3"
                data-bs-toggle="offcanvas"
                href="#offcanvasCategory"
                role="button"
                aria-controls="offcanvasCategory"
              >
                <i className="fas fa-sort-amount-up me-2" /> View Categories
              </a>
            </div>
            <div
              className="offcanvas offcanvas-start offcanvas-collapse"
              tabIndex={-1}
              id="offcanvasCategory"
              aria-labelledby="offcanvasCategoryLabel"
              aria-modal="true"
              role="dialog"
            >
              <div className="offcanvas-header d-lg-none justify-content-end">
                {/* <h5 class="offcanvas-title" id="offcanvasCategoryLabel">Filter</h5> */}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                />
              </div>
              <div className="offcanvas-body ps-lg-2 pt-lg-0">
                <div className="category-box homecategory">
                  <div className="sec-title">
                    <h6>Categories</h6>
                  </div>
                  {/* accordion */}
                  <ul className="nav nav-category p-3 border">
                    {homePageDataModel?.productCategories?.map(
                      (item, index) => {
                        const isLastCategory =
                          index ===
                          (homePageDataModel.productCategories?.length ?? 0) -
                            1;
                        return (
                          <li
                            className={`nav-item ${
                              isLastCategory ? "" : "border-bottom"
                            } w-100`}
                            key={item.id}
                          >
                            <a
                              onClick={() => {
                                getAllFilterProducts({
                                  categoryIds: [`${item.slug}`],
                                });
                                dispatch({
                                  type: ActionTypes.SET_QUERY_STRINGS,
                                  payload: {
                                    categoryIds: [`${item.slug}`],
                                  } as QueryString,
                                });
                              }}
                              className="nav-link "
                            >
                              <div className="d-flex gap-3 align-items-center">
                                <LazyImage
                                  src={item.imageUrl}
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "100%",
                                  }}
                                />
                                {item.name}
                              </div>

                              <Tag>{item.productCount}</Tag>
                              {/* <i className="fas fa-chevron-right" /> */}
                            </a>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-12 mainban">
            <div className="single_banner mb-30">
              <div className="banner_thumb mb-2">
                <LazyImage
                  link={item?.imageAds[0]?.link}
                  src={item?.imageAds[0]?.imageUrl}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner4;
