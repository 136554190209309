/** @format */

const ActionTypes = {
  INITIALIZE_STORE_REQUEST: "INITIALIZE_STORE_REQUEST",
  INITIALIZE_STORE_SUCCESS: "INITIALIZE_STORE_SUCCESS",
  INITIALIZE_STORE_FAILURE: "INITIALIZE_STORE_FAILURE",

  GET_LANDING_PAGE_DATA_REQUEST: "GET_LANDING_PAGE_DATA_REQUEST",
  GET_LANDING_PAGE_DATA_SUCCESS: "GET_LANDING_PAGE_DATA_SUCCESS",
  GET_LANDING_PAGE_DATA_FAILURE: "GET_LANDING_PAGE_DATA_FAILURE",

  GET_STORE_DETAILS_REQUEST: "GET_STORE_DETAILS_REQUEST",
  GET_STORE_DETAILS_SUCCESS: "GET_STORE_DETAILS_SUCCESS",
  GET_STORE_DETAILS_FAILURE: "GET_STORE_DETAILS_FAILURE",

  GET_ALL_CATEGORIES_REQUEST: "GET_ALL_CATEGORIES_REQUEST",
  GET_ALL_CATEGORIES_SUCCESS: "GET_ALL_CATEGORIES_SUCCESS",
  GET_ALL_CATEGORIES_FAILURE: "GET_ALL_CATEGORIES_FAILURE",

  GET_PRODUCT_DETAILS_REQUEST: "GET_PRODUCT_DETAILS_REQUEST",
  GET_PRODUCT_DETAILS_SUCCESS: "GET_PRODUCT_DETAILS_SUCCESS",
  GET_PRODUCT_DETAILS_FAILURE: "GET_PRODUCT_DETAILS_FAILURE",

  GET_PRODUCT_DETAILS_COPY_WITHOUT_REDIRECTION_REQUEST:
    "GET_PRODUCT_DETAILS_COPY_WITHOUT_REDIRECTION_REQUEST",
  GET_PRODUCT_DETAILS_COPY_WITHOUT_REDIRECTION_SUCCESS:
    "GET_PRODUCT_DETAILS_COPY_WITHOUT_REDIRECTION_SUCCESS",
  GET_PRODUCT_DETAILS_COPY_WITHOUT_REDIRECTION_FAILURE:
    "GET_PRODUCT_DETAILS_COPY_WITHOUT_REDIRECTION_FAILURE",

  GET_ALL_PRODUCTS_REQUEST: "GET_ALL_PRODUCTS_REQUEST",
  GET_ALL_PRODUCTS_SUCCESS: "GET_ALL_PRODUCTS_SUCCESS",
  GET_ALL_PRODUCTS_FAILURE: "GET_ALL_PRODUCTS_FAILURE",

  SEARCH_PRODUCTS_REQUEST: "SEARCH_PRODUCTS_REQUEST",
  SEARCH_PRODUCTS_SUCCESS: "SEARCH_PRODUCTS_SUCCESS",
  SEARCH_PRODUCTS_FAILURE: "SEARCH_PRODUCTS_FAILURE",

  AUTHENTICATE_USER_REQUEST: "AUTHENTICATE_USER_REQUEST",
  AUTHENTICATE_USER_SUCCESS: "AUTHENTICATE_USER_SUCCESS",
  AUTHENTICATE_USER_FAILURE: "AUTHENTICATE_USER_FAILURE",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",

  SEND_OTP_REQUEST: "SEND_OTP_REQUEST",
  SEND_OTP_SUCCESS: "SEND_OTP_SUCCESS",
  SEND_OTP_FAILURE: "SEND_OTP_FAILURE",

  SEND_OTP_RESEND_REQUEST: "SEND_OTP_RESEND_REQUEST",
  SEND_OTP_RESEND_SUCCESS: "SEND_OTP_RESEND_SUCCESS",
  SEND_OTP_RESEND_FAILURE: "SEND_OTP_RESEND_FAILURE",

  REGSITER_USER_REQUEST: "REGSITER_USER_REQUEST",
  REGSITER_USER_SUCCESS: "REGSITER_USER_SUCCESS",
  REGSITER_USER_FAILURE: "REGSITER_USER_FAILURE",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  CHANGE_PASSWORD_PROFILE_REQUEST: "CHANGE_PASSWORD_PROFILE_REQUEST",
  CHANGE_PASSWORD_PROFILE_SUCCESS: "CHANGE_PASSWORD_PROFILE_SUCCESS",
  CHANGE_PASSWORD_PROFILE_FAILURE: "CHANGE_PASSWORD_PROFILE_FAILURE",

  LIST_COUNTRIES_REQUEST: "LIST_COUNTRIES_REQUEST",
  LIST_COUNTRIES_SUCCESS: "LIST_COUNTRIES_SUCCESS",
  LIST_COUNTRIES_FAILURE: "LIST_COUNTRIES_FAILURE",

  GET_ALL_GIFT_CARDS_REQUEST: "GET_ALL_GIFT_CARDS_REQUEST",
  GET_ALL_GIFT_CARDS_SUCCESS: "GET_ALL_GIFT_CARDS_SUCCESS",
  GET_ALL_GIFT_CARDS_FAILURE: "GET_ALL_GIFT_CARDS_FAILURE",

  CHECK_GIFT_CARD_BALANCE_REQUEST: "CHECK_GIFT_CARD_BALANCE_REQUEST",
  CHECK_GIFT_CARD_BALANCE_SUCCESS: "CHECK_GIFT_CARD_BALANCE_SUCCESS",
  CHECK_GIFT_CARD_BALANCE_FAILURE: "CHECK_GIFT_CARD_BALANCE_FAILURE",

  SEND_GIFT_CARDS_REQUEST: "SEND_GIFT_CARDS_REQUEST",
  SEND_GIFT_CARDS_SUCCESS: "SEND_GIFT_CARDS_SUCCESS",
  SEND_GIFT_CARDS_FAILURE: "SEND_GIFT_CARDS_FAILURE",

  REDEEM_VOUCHER_REQUEST: "REDEEM_VOUCHER_REQUEST",
  REDEEM_VOUCHER_SUCCESS: "REDEEM_VOUCHER_SUCCESS",
  REDEEM_VOUCHER_FAILURE: "REDEEM_VOUCHER_FAILURE",
  REMOVE_CHECK_VOUCHER: "REMOVE_CHECK_VOUCHER",

  ADD_DELIVERY_LOCATION_REQUEST: "ADD_DELIVERY_LOCATION_REQUEST",
  ADD_DELIVERY_LOCATION_SUCCESS: "ADD_DELIVERY_LOCATION_SUCCESS",
  ADD_DELIVERY_LOCATION_FAILURE: "ADD_DELIVERY_LOCATION_FAILURE",

  GET_PROFILE_DETAILS_REQUEST: "GET_PROFILE_DETAILS_REQUEST",
  GET_PROFILE_DETAILS_SUCCESS: "GET_PROFILE_DETAILS_SUCCESS",
  GET_PROFILE_DETAILS_FAILURE: "GET_PROFILE_DETAILS_FAILURE",

  GET_ORDER_HISTORY_REQUEST: "GET_ORDER_HISTORY_REQUEST",
  GET_ORDER_HISTORY_SUCCESS: "GET_ORDER_HISTORY_SUCCESS",
  GET_ORDER_HISTORY_FAILURE: "GET_ORDER_HISTORY_FAILURE",

  GET_ORDER_DETAIL_REQUEST: "GET_ORDER_DETAIL_REQUEST",
  GET_ORDER_DETAIL_SUCCESS: "GET_ORDER_DETAIL_SUCCESS",
  GET_ORDER_DETAIL_FAILURE: "GET_ORDER_DETAIL_FAILURE",

  UPDATE_PROFILE_DETAILS_REQUEST: "UPDATE_PROFILE_DETAILS_REQUEST",
  UPDATE_PROFILE_DETAILS_SUCCESS: "UPDATE_PROFILE_DETAILS_SUCCESS",
  UPDATE_PROFILE_DETAILS_FAILURE: "UPDATE_PROFILE_DETAILS_FAILURE",

  GET_PAGE_DETAIL_REQUEST: "GET_PAGE_DETAIL_REQUEST",
  GET_PAGE_DETAIL_SUCCESS: "GET_PAGE_DETAIL_SUCCESS",
  GET_PAGE_DETAIL_FAILURE: "GET_PAGE_DETAIL_FAILURE",

  GET_ALL_WISHLIST_PRODUCT_REQUEST: "GET_ALL_WISHLIST_PRODUCT_REQUEST",
  GET_ALL_WISHLIST_PRODUCT_SUCCESS: "GET_ALL_WISHLIST_PRODUCT_SUCCESS",
  GET_ALL_WISHLIST_PRODUCT_FAILURE: "GET_ALL_WISHLIST_PRODUCT_FAILURE",

  ADD_PRODUCT_WISHLIST_REQUEST: "ADD_PRODUCT_WISHLIST_REQUEST",
  ADD_PRODUCT_WISHLIST_SUCCESS: "ADD_PRODUCT_WISHLIST_SUCCESS",
  ADD_PRODUCT_WISHLIST_FAILURE: "ADD_PRODUCT_WISHLIST_FAILURE",

  DELETE_WISHLIST_ITEM_REQUEST: "DELETE_WISHLIST_ITEM_REQUEST",
  DELETE_WISHLIST_ITEM_SUCCESS: "DELETE_WISHLIST_ITEM_SUCCESS",
  DELETE_WISHLIST_ITEM_FAILURE: "DELETE_WISHLIST_ITEM_FAILURE",

  GET_ALL_BANNERS_REQUEST: " GET_ALL_BANNERS_REQUEST",
  GET_ALL_BANNERS_SUCCESS: " GET_ALL_BANNERS_SUCCESS",
  GET_ALL_BANNERS_FAILURE: " GET_ALL_BANNERS_FAILURE",

  SEND_ENQUIERY_REQUEST: " SEND_ENQUIERY_REQUEST",
  SEND_ENQUIERY_SUCCESS: " SEND_ENQUIERY_SUCCESS",
  SEND_ENQUIERY_FAILURE: " SEND_ENQUIERY_FAILURE",

  GET_ALL_BLOGS_REQUEST: " GET_ALL_BLOGS_REQUEST",
  GET_ALL_BLOGS_SUCCESS: " GET_ALL_BLOGS_SUCCESS",
  GET_ALL_BLOGS_FAILURE: " GET_ALL_BLOGS_FAILURE",

  GET_BLOG_DETAIL_REQUEST: " GET_BLOG_DETAIL_REQUEST",
  GET_BLOG_DETAIL_SUCCESS: " GET_BLOG_DETAIL_SUCCESS",
  GET_BLOG_DETAIL_FAILURE: " GET_BLOG_DETAIL_FAILURE",

  GET_ALL_MEGA_MENUS_REQUEST: "GET_ALL_MEGA_MENUS_REQUEST",
  GET_ALL_MEGA_MENUS_SUCCESS: "GET_ALL_MEGA_MENUS_SUCCESS",
  GET_ALL_MEGA_MENUS_FAILURE: "GET_ALL_MEGA_MENUS_FAILURE",

  GET_DELIVERY_LOCATIONS_OF_USER_REQUEST:
    "GET_DELIVERY_LOCATIONS_OF_USER_REQUEST",
  GET_DELIVERY_LOCATIONS_OF_USER_SUCCESS:
    "GET_DELIVERY_LOCATIONS_OF_USER_SUCCESS",
  GET_DELIVERY_LOCATIONS_OF_USER_FAILURE:
    "GET_DELIVERY_LOCATIONS_OF_USER_FAILURE",

  MAKE_PAYMENT_REQUEST: "MAKE_PAYMENT_REQUEST",
  MAKE_PAYMENT_SUCCESS: "MAKE_PAYMENT_SUCCESS",
  MAKE_PAYMENT_FAILURE: "MAKE_PAYMENT_FAILURE",
  MAKE_TRANSACTION_COMPLETED: "MAKE_TRANSACTION_COMPLETED",

  OPEN_CART_POPOVER: "OPEN_CART_POPOVER",
  CLOSE_CART_POPOVER: "CLOSE_CART_POPOVER",

  ADD_PRODUCT_TO_CART: "ADD_PRODUCT_TO_CART",
  REMOVE_PRODUCT_FROM_CART: "REMOVE_PRODUCT_FROM_CART",
  INCREASE_QUANTITY_OF_PRODUCT_IN_CART: "INCREASE_QUANTITY_OF_PRODUCT_IN_CART",
  DECREASE_QUANTITY_OF_PRODUCT_IN_CART: "DECREASE_QUANTITY_OF_PRODUCT_IN_CART",
  CLEAR_CART: "CLEAR_CART",

  SET_ACTIVE_SLUG: "SET_ACTIVE_SLUG",
  SET_QUERY_STRINGS: "SET_QUERY_STRINGS",

  SET_ACTIVE_SLUG_PAGE: "SET_ACTIVE_SLUG_PAGE",
  SET_ACTIVE_SLUG_BLOG: "SET_ACTIVE_SLUG_BLOG",

  OPEN_LOGIN_POPUP: "OPEN_LOGIN_POPUP",
  CLOSE_LOGIN_POPUP: "CLOSE_LOGIN_POPUP",
  SET_LOGIN_POPUP_MESSAGE: "SET_LOGIN_POPUP_MESSAGE",
  SET_CALLBACK_FUNCTION: "SET_CALLBACK_FUNCTION",

  OPEN_ADD_TO_CART_POPUP: "OPEN_ADD_TO_CART_POPUP",
  CLOSE_ADD_TO_CART_POPUP: "CLOSE_ADD_TO_CART_POPUP",
  MAKE_SUBSCRIBE_POPUP_CLOSED: "MAKE_SUBSCRIBE_POPUP_CLOSED",

  RESET_PRODUCT_STATE: "RESET_PRODUCT_STATE",
  RESET_FILTER_PRODUCT_STATE: "RESET_FILTER_PRODUCT_STATE",
  RESET_AUTHENTICATION_STATE: "RESET_AUTHENTICATION_STATE",
  RESET_GIFT_CARD_STATE: "RESET_GIFT_CARD_STATE",
  RESET_MAKE_PAYMENT_STATE: "RESET_MAKE_PAYMENT_STATE",
  RESET_PROFILE_STATE: "RESET_PROFILE_STATE",
  RESET_PAGE_STATE: "RESET_PAGE_STATE",
  RESET_WISHLIST_STATE: "RESET_WISHLIST_STATE",
  RESET_BLOGS_STATE: "RESET_BLOGS_STATE",
  CLEAR_WISHLIST_PRODUCTS: "CLEAR_WISHLIST_PRODUCTS",

  LOCK_FILTER_OPTIONS: "LOCK_FILTER_OPTIONS",
  UNLOCK_FILTER_OPTIONS: "UNLOCK_FILTER_OPTIONS",

  SHOULD_CLEAR_CHECKED_FILTERS: "SHOULD_CLEAR_CHECKED_FILTERS",
  SHOULD_NOT_CLEAR_CHECKED_FILTERS: "SHOULD_NOT_CLEAR_CHECKED_FILTERS",

  // ... add more action types
};

export default ActionTypes;
