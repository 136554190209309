/** @format */

import {
  CloseOutlined,
  DollarCircleOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Tabs as AntTab,
  Button,
  DatePicker,
  Divider,
  Empty,
  FloatButton,
  Form,
  FormInstance,
  Image,
  Input,
  Modal,
  Select,
  Tag,
} from "antd";
import React, { Dispatch, SetStateAction, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Helmet } from "react-helmet";
import { AiFillCheckCircle } from "react-icons/ai";
import { BsHouseDoor } from "react-icons/bs";
import Slider from "react-slick";
import BeatLoader from "react-spinners/BeatLoader";
import { Tab, Tabs } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PageLoader from "../../components/page_loader/PageLoader";
import {
  EMAIL_VALIDATION_MESSAGE,
  EMPTY_FIELD_MESSAGE,
  NO_GIFT_CARD_MESSAGE,
  PageIdentifiers,
} from "../../constants/definitions";
import useAppSelector from "../../hooks/useAppSelector";
import { Country } from "../../redux/reducers/CommonReducer/CommonReducerInterface";
import {
  GiftCardBalance,
  GiftCardImageResponse,
  GiftCardTemplateImage,
} from "../../redux/reducers/GiftCardReducer/GiftCardReducerInterface";
import { GiftCardFormValues } from "../../types/commonInterfaces";
import LazyImage from "../../utils/Lazy Image/LazyImage";
import isMobile from "../../utils/isMobile";
import { useRetailHelper } from "../../utils/useRetailHelper";
import {
  cardNumberValidator,
  cvcValidator,
  formatCVC,
  formatCreditCardNumber,
  formatExpirationDate,
  validateNumber,
} from "../../utils/validators";
import SendGiftCard from "./SendGiftCardModal";

interface GiftCardProps {
  activeTabGiftCardForm: number;
  setActiveTabGiftCardForm: Dispatch<SetStateAction<number>>;
  giftCardForm: FormInstance<GiftCardFormValues>;
  onGiftCardFormSubmit: (values: GiftCardFormValues) => void;
  countries?: Country[];
  giftCardImages?: GiftCardImageResponse;
  activeGiftCardImage?: GiftCardTemplateImage;
  setActiveGiftCardImage?: Dispatch<
    SetStateAction<GiftCardTemplateImage | undefined>
  >;
  activeGiftCardType?: number;
  setActiveGiftCardType?: Dispatch<SetStateAction<number | undefined>>;
  sendGiftCardLoading: boolean | undefined;
  giftCardNumber: string | undefined;
  setGiftCardNumber: Dispatch<SetStateAction<string | undefined>>;
  getGiftCardBalanceSubmitHandler: () => void;
  getGiftCardBalanceLoading: boolean | undefined;
  giftCardBalance: GiftCardBalance | undefined;
  isSendGiftCardModalOpen: boolean;
  setIsSendGiftCardModalOpen: Dispatch<SetStateAction<boolean>>;
}

const GiftCard: React.FC<GiftCardProps> = ({
  activeTabGiftCardForm,
  setActiveTabGiftCardForm,
  giftCardForm,
  onGiftCardFormSubmit,
  countries,
  giftCardImages,
  activeGiftCardImage,
  setActiveGiftCardImage,
  activeGiftCardType,
  setActiveGiftCardType,
  sendGiftCardLoading,
  giftCardNumber,
  setGiftCardNumber,
  getGiftCardBalanceSubmitHandler,
  getGiftCardBalanceLoading,
  giftCardBalance,
  isSendGiftCardModalOpen,
  setIsSendGiftCardModalOpen,
}) => {
  const { currencySymbol, getDefaultSelectedCountry, getBannerFromIdentifier } =
    useRetailHelper();
  const [isCheckBalanceModalVisible, setIsCheckBalanceVisible] =
    useState<boolean>(false);
  const { loginResponse } = useAppSelector((state) => state.authentication);
  const giftCardBanner = getBannerFromIdentifier(PageIdentifiers.GiftCard);

  return (
    <>
      <Helmet>
        <title>Gift Card</title>
      </Helmet>
      <>
        <PageLoader loading={!giftCardImages}>
          <Modal
            visible={isCheckBalanceModalVisible}
            onCancel={() => {
              setIsCheckBalanceVisible(false);
            }}
            maskClosable={true}
            footer={null}
            closable={isMobile ? true : false}
            centered
            style={{ maxWidth: "80vw" }}
            closeIcon={isMobile ? true : false}
          >
            {isCheckBalanceModalVisible && (
              <div
                className="custom-close-button"
                onClick={() => setIsCheckBalanceVisible(false)}
              >
                <CloseOutlined className="close-icon" />
              </div>
            )}

            <div className=" fs-sm">
              <p className="mb-1">
                <span className="fw-bold text-theme">Gift Card Number</span>
              </p>

              <div className="col-sm-12">
                <div className="mb-3">
                  <Input
                    onPressEnter={(e) => {
                      e.preventDefault();
                    }}
                    value={giftCardNumber}
                    onChange={(e) => {
                      setGiftCardNumber(e.target.value);
                    }}
                    placeholder="Enter Gift Card No."
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex align-items-center">
                  <div>
                    <Button
                      type="primary"
                      onClick={() => {
                        getGiftCardBalanceSubmitHandler();
                      }}
                      loading={getGiftCardBalanceLoading}
                    >
                      {getGiftCardBalanceLoading && (
                        <BeatLoader
                          loading={true}
                          color="white"
                          size={8}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )}
                      {getGiftCardBalanceLoading
                        ? "Loading..."
                        : "Check Balance"}
                    </Button>
                  </div>
                  {giftCardBalance && (
                    <div className="product-price ms-2 d-block">
                      <span className="text-accent ">
                        {currencySymbol()} {giftCardBalance.amount}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal>
          <SendGiftCard
            setIsSendGiftCardOpen={setIsSendGiftCardModalOpen}
            isSendGiftCardOpen={isSendGiftCardModalOpen}
          />
          <FloatButton
            onClick={() => {
              setIsCheckBalanceVisible(true);
            }}
            type="primary"
            icon={<DollarCircleOutlined className="mb-1" />}
            shape={isMobile ? "circle" : "square"}
            description={isMobile ? "" : "Check Gift Card Balance"}
            style={{
              right: isMobile ? 20 : 24,
              bottom: isMobile ? 80 : "",
              width: !isMobile ? "100px" : "",
              padding: !isMobile ? "5px" : "",
              fontSize: "14px",
            }}
          />
          <section className="productdetails_top giftcards pb-50 mt-4 mt-md-3">
            <Carousel>
              <Carousel.Item key={1}>
                <LazyImage
                  link={giftCardBanner?.banners?.[0]?.link ?? ""}
                  className="d-block w-100"
                  style={{
                    maxHeight: "300px",
                  }}
                  src={giftCardBanner?.banners?.[0]?.imageUrl}
                />
                <div className="row">
                  <div className="col-md-6">
                    {/* <Carousel.Caption>
                        {item.title && <h1>{item.title}</h1>}
                        {item.description && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                item.description ?? ""
                              ),
                            }}
                          />
                        )}

                        {item.linkText && (
                          <a href={item.link}>
                            <Button className="banner-btn" danger>
                              {item.linkText}
                            </Button>
                          </a>
                        )}
                      </Carousel.Caption> */}
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
            <div className="container">
              <div className="mt-4">
                {/* <CustomBreadcrumb items={[{ title: "Gift Card", link: "" }]} /> */}
              </div>
              <div className="c0197 c0192 center-align">
                <div className="row text-center justify-content-center gap-2">
                  <div className="col-md-3">
                    <p className="fs-5 fw-bold mb-3">
                      <strong>How does it work?</strong>
                    </p>
                    <p>
                      1. Choose the value of the e-Gift card from as little as
                      $20
                    </p>
                    <p>2. Write your own personalised message</p>
                    <p>3. Tell us the lucky recipient's email address</p>
                    <p>4. Tell us when to send them the best-present-ever</p>
                    <p />
                    <p>
                      <br />
                    </p>
                    <p />
                  </div>

                  <div className="col-md-3">
                    <p className="fs-5 fw-bold mb-3">
                      <strong>When it's time to shop</strong>
                    </p>
                    <p>
                      Use your code to redeem your gift in the Payment section
                      of our checkout
                    </p>
                    <p>
                      <strong className="fs-5 fw-bold">or</strong>
                    </p>
                    <p>
                      Add it to your account and the credit amount will apply to
                      your next order
                    </p>
                    <p>
                      <br />
                    </p>
                    <p />
                  </div>
                  <div className="col-md-3">
                    <p className="fs-5 fw-bold mb-3">
                      <strong>Where do I find the code?</strong>
                    </p>
                    <p>
                      <strong className=" fw-bold">e-Gift cards:</strong> Check
                      your email for your code
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="overlay" /> */}

              {giftCardImages?.length == 0 ? (
                <Empty className="mt-4" description={NO_GIFT_CARD_MESSAGE} />
              ) : (
                <div className="row g-4 justify-content-center">
                  <div className="col-md-12 ">
                    <div className="card  border-0 h-100">
                      <div className="card-body pb-4">
                        <Tabs
                          activeTab={activeGiftCardType ?? 0}
                          onTabClick={(e, index) => {
                            if (setActiveGiftCardType) {
                              setActiveGiftCardType(index);
                            }
                          }}
                        >
                          {giftCardImages?.map((item, index) => {
                            return <Tab key={index}>{item.type}</Tab>;
                          })}
                        </Tabs>

                        <Divider />
                        <div className="giftimg">
                          <div className="row align-items-center justify-content-center">
                            <div className="col-md-6">
                              <div className=" shadow">
                                <Image
                                  src={activeGiftCardImage?.image}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <Divider />
                          {(giftCardImages &&
                            (activeGiftCardType == 0 ||
                              (activeGiftCardType && activeGiftCardType > 0)) &&
                            giftCardImages[activeGiftCardType]
                              ?.giftCardTemplateImages.length > 4) ||
                          isMobile ? (
                            <Slider
                              {...{
                                dots: true,
                                slidesToShow: isMobile ? 2 : 4,
                                arrows: true,
                              }}
                            >
                              {giftCardImages &&
                                (activeGiftCardType == 0 ||
                                  (activeGiftCardType &&
                                    activeGiftCardType > 0)) &&
                                giftCardImages[
                                  activeGiftCardType
                                ]?.giftCardTemplateImages?.map(
                                  (item, index) => {
                                    return (
                                      <div
                                        className="items position-relative"
                                        key={item.id}
                                        onClick={() => {
                                          if (setActiveGiftCardImage) {
                                            setActiveGiftCardImage(item);
                                          }
                                        }}
                                      >
                                        {activeGiftCardImage?.id == item.id && (
                                          <div
                                            style={{
                                              background: "white",
                                              borderRadius: "50%",
                                              padding: 0,
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              top: "15px",
                                              right: "10px",
                                            }}
                                            className="position-absolute"
                                          >
                                            <AiFillCheckCircle
                                              style={{
                                                color: "#0DB19C",
                                              }}
                                              size="35"
                                            />
                                          </div>
                                        )}
                                        <LazyImage
                                          style={{ cursor: "pointer" }}
                                          src={item.image}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    );
                                  }
                                )}
                            </Slider>
                          ) : (
                            <div className="d-flex gap-4">
                              {giftCardImages &&
                                (activeGiftCardType == 0 ||
                                  (activeGiftCardType &&
                                    activeGiftCardType > 0)) &&
                                giftCardImages[
                                  activeGiftCardType
                                ]?.giftCardTemplateImages?.map(
                                  (item, index) => {
                                    return (
                                      <div
                                        className="items position-relative"
                                        key={item.id}
                                        onClick={() => {
                                          if (setActiveGiftCardImage) {
                                            setActiveGiftCardImage(item);
                                          }
                                        }}
                                      >
                                        {activeGiftCardImage?.id == item.id && (
                                          <div
                                            style={{
                                              background: "white",
                                              borderRadius: "50%",
                                              padding: 0,
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              top: "15px",
                                              right: "10px",
                                              cursor: "pointer",
                                            }}
                                            className="position-absolute"
                                          >
                                            <AiFillCheckCircle
                                              style={{
                                                color: "#0DB19C",
                                              }}
                                              size="35"
                                            />
                                          </div>
                                        )}

                                        <LazyImage
                                          style={{ cursor: "pointer" }}
                                          src={item.image}
                                          alt=""
                                        />
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Form
                    form={giftCardForm}
                    onFinish={onGiftCardFormSubmit}
                    labelAlign="left"
                    labelCol={{
                      span: 24,
                    }}
                    fields={[
                      {
                        name: ["senderCountryId"],
                        value: getDefaultSelectedCountry(),
                      },
                      {
                        name: ["senderCountryPhoneNumberPrefixId"],
                        value: getDefaultSelectedCountry(),
                      },
                      {
                        name: ["recieverCountryId"],
                        value: getDefaultSelectedCountry(),
                      },
                      {
                        name: ["recieverCountryPhoneNumberPrefixId"],
                        value: getDefaultSelectedCountry(),
                      },
                      {
                        name: ["senderName"],
                        value: loginResponse?.name,
                      },
                      {
                        name: ["senderEmail"],
                        value: loginResponse?.email,
                      },
                      {
                        name: ["senderPhoneNumber"],
                        value: loginResponse?.phoneNumber,
                      },
                    ]}
                    name="normal_login"
                    className="w-100 row login-form mt-3"
                  >
                    <div className="col-md-7">
                      <div className="card  border-0 h-100">
                        <div className="card-body bg-white ">
                          <AntTab
                            type="card"
                            items={[
                              {
                                key: "2",
                                label: "Reciever Details",
                                children: (
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="mb-3">
                                        <Form.Item
                                          label="Receiver Name"
                                          name="recieverName"
                                          rules={[
                                            {
                                              required: true,
                                              message: EMPTY_FIELD_MESSAGE,
                                            },
                                          ]}
                                        >
                                          <Input
                                            prefix={
                                              <UserOutlined className="site-form-item-icon" />
                                            }
                                            placeholder="Sender Name"
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mb-3">
                                        <Form.Item
                                          label="Receiver Email"
                                          name="recieverEmail"
                                          rules={[
                                            {
                                              required: true,
                                              message: EMPTY_FIELD_MESSAGE,
                                            },
                                            {
                                              type: "email",
                                              message: EMAIL_VALIDATION_MESSAGE,
                                            },
                                          ]}
                                        >
                                          <Input
                                            prefix={
                                              <MailOutlined className="site-form-item-icon" />
                                            }
                                            placeholder="Email"
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mb-3">
                                        <Form.Item
                                          label="Phone"
                                          name="recieverPhoneNumber"
                                          rules={[
                                            {
                                              required: true,
                                              message: EMPTY_FIELD_MESSAGE,
                                            },
                                            {
                                              validator: validateNumber,
                                            },
                                          ]}
                                        >
                                          <Input
                                            addonBefore={
                                              <Form.Item
                                                name="recieverCountryPhoneNumberPrefixId"
                                                noStyle
                                              >
                                                <Select
                                                  placeholder="Prefix"
                                                  style={{
                                                    width: 80,
                                                  }}
                                                >
                                                  {countries &&
                                                    countries?.map((item) => (
                                                      <Select.Option
                                                        value={item.id}
                                                        key={item.id}
                                                      >
                                                        {" "}
                                                        <span
                                                          style={{
                                                            fontSize: "14px",
                                                          }}
                                                        >
                                                          {
                                                            item.countryPhoneNumberPrefix
                                                          }
                                                        </span>
                                                      </Select.Option>
                                                    ))}
                                                </Select>
                                              </Form.Item>
                                            }
                                            placeholder="Phone"
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mb-3">
                                        <Form.Item
                                          label="Country"
                                          name="recieverCountryId"
                                          rules={[
                                            {
                                              required: true,
                                              message: EMPTY_FIELD_MESSAGE,
                                            },
                                          ]}
                                        >
                                          <Select placeholder="Country">
                                            {countries &&
                                              countries?.map((item) => (
                                                <Select.Option
                                                  value={item.id}
                                                  key={item.id}
                                                >
                                                  {" "}
                                                  <span
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    <img
                                                      src={item.imageUrl}
                                                      style={{
                                                        height: "20px",
                                                        width: "30px",
                                                        marginRight: "4px",
                                                      }}
                                                    />
                                                    {item.value}
                                                  </span>
                                                </Select.Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mb-3">
                                        <Form.Item
                                          label="Postal Code"
                                          name="recieverPostalCode"
                                          rules={[
                                            {
                                              required: true,
                                              message: EMPTY_FIELD_MESSAGE,
                                            },
                                            {
                                              validator: validateNumber,
                                            },
                                          ]}
                                        >
                                          <Input
                                            prefix={
                                              <BsHouseDoor className="site-form-item-icon" />
                                            }
                                            placeholder="Postal Code"
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mb-3">
                                        <Form.Item
                                          label="Schedule Date"
                                          name="scheduleDate"
                                          rules={[
                                            {
                                              required: true,
                                              message: EMPTY_FIELD_MESSAGE,
                                            },
                                          ]}
                                        >
                                          <DatePicker
                                            style={{ width: "100%" }}
                                            placeholder="Schedule Date"
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="mb-3">
                                        <Form.Item
                                          label="Message"
                                          name="message"
                                          rules={[
                                            {
                                              required: true,
                                              message: EMPTY_FIELD_MESSAGE,
                                            },
                                          ]}
                                        >
                                          <Input.TextArea
                                            rows={3}
                                            placeholder="Please enter Message"
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                  </div>
                                ),
                              },
                              {
                                key: "1",
                                label: "Sender details",
                                children: (
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="mb-3">
                                        <Form.Item
                                          label="Sender Name"
                                          name="senderName"
                                          rules={[
                                            {
                                              required: true,
                                              message: EMPTY_FIELD_MESSAGE,
                                            },
                                          ]}
                                        >
                                          <Input
                                            prefix={
                                              <UserOutlined className="site-form-item-icon" />
                                            }
                                            placeholder="Sender Name"
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mb-3">
                                        <Form.Item
                                          label="Sender Email"
                                          name="senderEmail"
                                          rules={[
                                            {
                                              required: true,
                                              message: EMPTY_FIELD_MESSAGE,
                                            },
                                          ]}
                                        >
                                          <Input
                                            prefix={
                                              <MailOutlined className="site-form-item-icon" />
                                            }
                                            placeholder="Please Enter Email"
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mb-3">
                                        <Form.Item
                                          label="Postal Code"
                                          name="senderPostalCode"
                                          rules={[
                                            {
                                              required: true,
                                              message: EMPTY_FIELD_MESSAGE,
                                            },
                                            {
                                              validator: validateNumber,
                                            },
                                          ]}
                                        >
                                          <Input
                                            prefix={
                                              <BsHouseDoor className="site-form-item-icon" />
                                            }
                                            placeholder="Postal Code"
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="mb-3">
                                        <Form.Item
                                          label="Country"
                                          name="senderCountryId"
                                          rules={[
                                            {
                                              required: true,
                                              message: EMPTY_FIELD_MESSAGE,
                                            },
                                          ]}
                                        >
                                          <Select placeholder="Country">
                                            {countries &&
                                              countries?.map((item) => (
                                                <Select.Option
                                                  value={item.id}
                                                  key={item.id}
                                                >
                                                  {" "}
                                                  <span
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    <img
                                                      src={item.imageUrl}
                                                      style={{
                                                        height: "20px",
                                                        width: "30px",
                                                        marginRight: "4px",
                                                      }}
                                                    />
                                                    {item.value}
                                                  </span>
                                                </Select.Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mb-3">
                                        <Form.Item
                                          label="Phone"
                                          name="senderPhoneNumber"
                                          rules={[
                                            {
                                              required: true,
                                              message: EMPTY_FIELD_MESSAGE,
                                            },
                                            {
                                              validator: validateNumber,
                                            },
                                          ]}
                                        >
                                          <Input
                                            addonBefore={
                                              <Form.Item
                                                name="senderCountryPhoneNumberPrefixId"
                                                noStyle
                                              >
                                                <Select
                                                  placeholder="Prefix"
                                                  style={{
                                                    width: 120,
                                                  }}
                                                >
                                                  {countries &&
                                                    countries?.map((item) => (
                                                      <Select.Option
                                                        value={item.id}
                                                        key={item.id}
                                                      >
                                                        {" "}
                                                        <span
                                                          style={{
                                                            fontSize: "14px",
                                                          }}
                                                        >
                                                          {
                                                            item.countryPhoneNumberPrefix
                                                          }
                                                        </span>
                                                      </Select.Option>
                                                    ))}
                                                </Select>
                                              </Form.Item>
                                            }
                                            placeholder="Phone"
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                  </div>
                                ),
                              },
                            ]}
                          />
                          {/* <Tabs
                        activeTab={activeTabGiftCardForm}
                        onTabClick={(e, index) => {
                          setActiveTabGiftCardForm(index);
                        }}
                      >
                        <Tab key={0}>Reciever Details</Tab>
                        <Tab key={1}>Sender Details</Tab>
                      </Tabs> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-card border-0 card card-body">
                        <h2 className="h6 pt-1 pb-3 mb-3 border-bottom fw-bold text-theme">
                          Checkout and Pay for Your Gift Card
                        </h2>
                        <p className="fs-sm mb-2 text-muted">
                          We accept credit cards:&nbsp;&nbsp;
                          <img
                            className="d-inline-block align-middle"
                            src="assets/images/card (1).png"
                            style={{ width: 186 }}
                            alt="Cerdit Cards"
                          />
                        </p>
                        <Form.Item
                          label="Full name "
                          rules={[
                            {
                              required: true,
                              message: EMPTY_FIELD_MESSAGE,
                            },
                          ]}
                          name="name"
                        >
                          <Input
                            // value={name}
                            // onChange={(e) => setName(e.target.value)}
                            type="text"
                            name="username"
                            placeholder="Name on Card"
                          />
                        </Form.Item>
                        <Form.Item
                          className="w-100"
                          label="Card Number"
                          name="cardNumber"
                          rules={[
                            {
                              required: true,
                              message: EMPTY_FIELD_MESSAGE,
                            },
                            {
                              validator: cardNumberValidator,
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) => {
                              giftCardForm.setFieldValue(
                                "cardNumber",
                                formatCreditCardNumber(e.target.value)
                              );

                              // setCardNumber(e.target.value);
                            }}
                            type="text"
                            name="cardNumber"
                            placeholder="Your card number"
                          />
                        </Form.Item>
                        <div className="row">
                          <div className="col-8 col-md-7">
                            <Form.Item
                              label={"Expiry"}
                              name="expiry"
                              rules={[
                                {
                                  required: true,
                                  message: EMPTY_FIELD_MESSAGE,
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                onChange={(e) => {
                                  giftCardForm.setFieldValue(
                                    "expiry",
                                    formatExpirationDate(e.target.value)
                                  );
                                }}
                                placeholder="MM/YY"
                                name=""
                              />
                            </Form.Item>
                          </div>
                          <div className="col-4 col-md-5">
                            <Form.Item
                              name="cvc"
                              rules={[
                                {
                                  validator: cvcValidator,
                                },
                                {
                                  required: true,
                                  message: EMPTY_FIELD_MESSAGE,
                                },
                              ]}
                              label="CVC"
                            >
                              <Input
                                type="text"
                                placeholder="CVC"
                                onChange={(e) => {
                                  giftCardForm.setFieldValue(
                                    "cvc",
                                    formatCVC(e.target.value, undefined)
                                  );
                                }}
                                // value={cvc}
                                // onChange={(e) => setCvc(e.target.value)}
                              />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-md-12">
                            <div className="d-flex flex-column flex-md-row justify-content-start gap-3">
                              <Button
                                htmlType="submit"
                                type="primary"
                                className="d-flex align-items-center justify-content-center gap-2  px-5"
                                loading={sendGiftCardLoading}
                              >
                                {"Make Payment"}
                              </Button>
                              <Tag
                                bordered={false}
                                color="magenta"
                                className="d-flex align-items-center fs-6 p-2 d-block fw-bold"
                                style={
                                  isMobile
                                    ? { width: "30vw", fontSize: "20px" }
                                    : {}
                                }
                              >
                                Amount : {currencySymbol()}{" "}
                                {activeGiftCardImage?.amount ?? "0"}
                              </Tag>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              )}
            </div>
            {/* End .product-details-top */}
          </section>
        </PageLoader>
      </>
    </>
  );
};

export default GiftCard;
